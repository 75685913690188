import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Headline, Paragraph } from '_atoms';
import { CollapsibleCard } from '_molecules';
import { nonProdDataTestId } from '_utils';
import { VscFilePdf } from 'react-icons/vsc';
import { RedFlagItem } from '_types';
import { localizeDate } from '../../../translations/utils';
import { ReportQueryParams } from './ReportCard';
import { getRedFlagKeywords, Keywords, RedFlagKeyword } from './Keywords';
import classnames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { resultsRoutes } from '../../../routes';

type RedFlagsProps = {
    redFlags: RedFlagItem[];
    className?: string;
    filters?: ReportQueryParams;
    onFiltersChange: (
        reportLabel: string,
        filter: ReportQueryParams,
        clear: boolean,
    ) => void;
};

export const RedFlags: FC<RedFlagsProps> = ({
    redFlags,
    filters,
    onFiltersChange,
}) => {
    const { t } = useTranslation();

    const isPrint = useRouteMatch(resultsRoutes.printVersion.path);

    const keywords: RedFlagKeyword[] = useMemo(() => {
        return getRedFlagKeywords(redFlags);
    }, [redFlags]);

    const redFlagHeaders = Object.values(
        t<string, Record<string, string>>('redFlags.headers', {
            returnObjects: true,
        }),
    );

    const sourceNames: Record<string, string> = t('sourceName', {
        returnObjects: true,
    });

    return (
        <CollapsibleCard
            id="red_flags"
            header={
                <>
                    <div
                        className={classnames('flex items-baseline space-x-3')}
                    >
                        <Headline Level="h5">{t('reports.redFlags')}</Headline>
                        <Paragraph
                            color="mediumDark"
                            size="small"
                            className="font-jost"
                        >
                            {t('reports.resultsCount', {
                                count: redFlags.length,
                            })}
                        </Paragraph>
                    </div>
                    <Keywords
                        className="mt-2"
                        keywords={keywords}
                        onClick={(reportLabel) =>
                            onFiltersChange(
                                reportLabel,
                                { expanded: true },
                                false,
                            )
                        }
                        reportLabel={'red_flags'}
                        t={t}
                    ></Keywords>
                </>
            }
            headerColor={
                redFlags.length > 0 ? 'bg-error-2/30' : 'bg-neutral-100'
            }
            headerBorder={
                redFlags.length > 0 ? 'border-error-2' : 'border-success-1'
            }
            headerWrapperClasses="items-center"
            className="border-0"
            bodyClasses={redFlags.length > 0 ? '' : ''}
            initiallyExpanded={isPrint ? true : filters?.expanded ?? false}
            onToggleChange={(value) =>
                onFiltersChange('red_flags', { expanded: value }, false)
            }
            toggleEnabled={redFlags.length > 0}
            additionalHtmlAttributes={{
                'data-testid': nonProdDataTestId(
                    'analysis overview critical issues card',
                ),
            }}
        >
            {redFlags.length > 0 ? (
                <div className="space-y-3 divide-y">
                    <div className="grid grid-cols-6 gap-3 items-center w-full">
                        {redFlagHeaders.map((header, index) => (
                            <Paragraph
                                key={index}
                                size="label"
                                weight="bold"
                                color="light"
                            >
                                {header}
                            </Paragraph>
                        ))}
                    </div>
                    <div className="divide-y">
                        {redFlags.map(
                            (
                                {
                                    country = '',
                                    name,
                                    type,
                                    sources,
                                    description,
                                    reportUrl,
                                    roles,
                                },
                                index,
                            ) => (
                                <div
                                    key={index}
                                    className="grid grid-cols-6 gap-3 items-center py-3 break-words"
                                >
                                    <Paragraph
                                        size="default"
                                        weight="bold"
                                        color="dark"
                                    >
                                        {name}
                                    </Paragraph>
                                    <Paragraph
                                        size="default"
                                        color="dark"
                                        HtmlTag="div"
                                    >
                                        <RedFlagCompanyRoles roles={roles} />
                                    </Paragraph>
                                    <Paragraph
                                        size="default"
                                        weight="bold"
                                        color="dark"
                                    >
                                        {t(`redFlags.rawTypes.${type}`, type)}
                                    </Paragraph>
                                    <Paragraph size="default" color="dark">
                                        {country}
                                    </Paragraph>
                                    <Paragraph size="default" color="dark">
                                        {sources
                                            .map(
                                                (source) =>
                                                    sourceNames[source] ??
                                                    source,
                                            )
                                            .join(', ')}
                                    </Paragraph>
                                    <Paragraph
                                        size="default"
                                        color="dark"
                                        className="flex items-center"
                                    >
                                        {description}
                                    </Paragraph>
                                    {reportUrl ? (
                                        <a
                                            key={index}
                                            className="block text-sm text-primary-4 hover:text-primary-3 font-normal focus:outline-none mb-1"
                                            href={reportUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Badge className="gap-2">
                                                <VscFilePdf />
                                                {t('redFlags.reportsItem')}
                                            </Badge>
                                        </a>
                                    ) : null}
                                </div>
                            ),
                        )}
                    </div>
                </div>
            ) : null}
        </CollapsibleCard>
    );
};

const RedFlagCompanyRoles: FC<{ roles: RedFlagItem['roles'] }> = ({
    roles,
}) => {
    const { t } = useTranslation();

    if (!roles || roles.length === 0) {
        return <>{t('redFlags.roleNotAvailable')}</>;
    }

    if (roles.length === 1) {
        return <RedFlagCompanyRole {...roles[0]} />;
    }

    return (
        <ul className="list-disc pl-4">
            {roles.map((role, index) => (
                <li key={index}>
                    <RedFlagCompanyRole {...role} />
                </li>
            ))}
        </ul>
    );
};

const RedFlagCompanyRole = (role: Required<RedFlagItem>['roles'][0]) => {
    const { t } = useTranslation();

    return (
        <>
            <span className="block">
                {role.value.role || t('redFlags.unknownRole')}
            </span>
            <Paragraph size="small" color="light" HtmlTag="span">
                {role.value.from ? (
                    <>
                        {`${localizeDate(role.value.from)} - ${
                            role.value.to
                                ? localizeDate(role.value.to)
                                : t('redFlags.positionIsCurrent')
                        }`}
                    </>
                ) : (
                    <>{t('redFlags.unknownTimeframe')}</>
                )}
            </Paragraph>
        </>
    );
};
