import classNames from 'classnames';
import React, { FC } from 'react';

const colors = {
    blue: 'from-primary-3 to-primary-4',
    green: 'from-green-400 to-green-500',
    error: 'from-error-2 to-error-1',
};

export const ProgressBar: FC<{
    value: number;
    color?: keyof typeof colors;
}> = ({ value, color = 'blue' }): JSX.Element => {
    return (
        <div className="w-full bg-gray-200 rounded-full h-4 overflow-hidden">
            <div
                className={classNames(
                    'bg-gradient-to-r h-full transition-all',
                    color === 'blue' && colors.blue,
                    color === 'green' && colors.green,
                    color === 'error' && colors.error,
                )}
                style={{ width: `${value}%` }}
            />
        </div>
    );
};
