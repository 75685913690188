import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { FormSchema } from '../features/targets/TargetNew/wizard/form.interface';

interface StoredFromData {
    formData: Partial<FormSchema>;
    cvImages: string[];
}

interface ExistingTargetCreationState {
    [caseId: string]: StoredFromData;
}

interface TargetCreationState {
    candidateCreationData: ExistingTargetCreationState;
    setTargetFormData: (caseId: string, data: StoredFromData) => void;
    clearCandidateData: (caseId: string) => void;
    getTargetFormData: (caseId: string) => StoredFromData;
}

// Take note of JS cloning for deep-nested objects
export const useTargetCreationStateStore = create<TargetCreationState>()(
    devtools(
        persist(
            (set, get) => ({
                candidateCreationData: {},
                setTargetFormData: (caseId, update) =>
                    set((state) => {
                        return {
                            candidateCreationData: {
                                ...state.candidateCreationData,
                                [caseId]: update,
                            },
                        };
                    }),
                clearCandidateData: (caseId: string) =>
                    set((state) => {
                        const { [caseId]: _, ...candidateCreationData } =
                            state.candidateCreationData;
                        return { candidateCreationData };
                    }),
                getTargetFormData: (caseId: string) => {
                    const { formData = {}, cvImages = [] } =
                        get().candidateCreationData[caseId] || {};
                    return { formData, cvImages };
                },
            }),
            { name: 'candidateCreationStateStore' },
        ),
    ),
);
