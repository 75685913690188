import { FC, useEffect } from 'react';
import { generatePath, Link, Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TargetStatus } from '../../../services/dataService';
import { routes } from '../../../routes';
import { Notification } from '_molecules';
import { PageNotFound } from '_organisms';
import {
    useCaseQuery,
    useTargetPreselectedCandidatesQuery,
    useTargetQuery,
} from '_queries';
import { Button, LoadingSpinner, Paragraph } from '_atoms';
import { nonProdDataTestId } from '_utils';
import { useUserState } from '../../../context/User';
import { USER_GROUP_ADMINISTRATORS } from '../../../services/authenticationService';

export const TargetShow: FC = () => {
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { t } = useTranslation();
    const [{ userRoles }] = useUserState();

    const caseQuery = useCaseQuery(caseId);
    const preselectionData = useTargetPreselectedCandidatesQuery(
        caseId,
        targetId,
        false,
    );

    const { data: targetData, ...targetQuery } = useTargetQuery(
        'targetShow',
        caseId,
        targetId,
    );

    useEffect(() => {
        if (
            targetData?.status === TargetStatus.CandidateSelectionPending &&
            preselectionData.isIdle
        ) {
            preselectionData.refetch();
        }
    }, [preselectionData, targetData?.status]);

    // TODO: pushing to the history here is a quickfix for simplifying the "open"
    //       button logic in the case overview. In the long run we probably would
    //       want TargetShow be the universal entrypoint to the target regardless
    //       of what state it is in. This component should be adapted to render
    //       the appropriate component based on target state instead of rerouting
    //       to other root level components doing that job.
    switch (targetData?.status) {
        case TargetStatus.Created:
        case TargetStatus.FetchingCandidates:
        case TargetStatus.CandidateSelectionCompleted:
        case TargetStatus.FetchingDetails:
            // Do nothing. Show waiting screen and retry on next refresh
            break;
        case TargetStatus.CandidateSelectionPending:
            if (preselectionData.isSuccess) {
                return (
                    <Redirect
                        to={generatePath(routes.targetCandidates.path, {
                            caseId,
                            targetId,
                        })}
                    />
                );
            }
            break;
        // !!!: Backend currently transitions directly to completed once result
        //      overview profile is available. This will need some adaption to
        //      separate a "completed" from the "we have at least some data to
        //      display" state at which point the latter also needs to be handled
        //      here.
        case TargetStatus.HasInitialProfile:
        case TargetStatus.Completed:
        case TargetStatus.CompletedWithIrregularities:
            return (
                <Redirect
                    to={generatePath(routes.dashboardOverview.path, {
                        caseId,
                        targetId,
                    })}
                />
            );
    }

    if (caseQuery.isError || targetQuery.isError) {
        return <PageNotFound />;
    }

    const waitingForCandidateGeneration =
        targetQuery.isSuccess &&
        targetData?.status &&
        [
            TargetStatus.Created,
            TargetStatus.FetchingCandidates,
            TargetStatus.CandidateSelectionPending,
        ].includes(targetData.status);

    const waitingForReportGeneration =
        targetQuery.isSuccess &&
        targetData?.status &&
        [
            TargetStatus.CandidateSelectionCompleted,
            TargetStatus.FetchingDetails,
        ].includes(targetData.status);

    // if it's neither of the above conditions, we just see a blank screen for now

    return (
        <div className="container-fluid px-16 h-full -mt-4 pt-4 flex flex-col">
            {targetQuery.isLoading && (
                <div className="mt-20">
                    <LoadingSpinner message={t('profileLoading')} />
                </div>
            )}
            {waitingForCandidateGeneration && (
                <figure
                    className="absolute inset-0 -top-10 flex flex-col items-center justify-center pointer-events-none"
                    data-testid={nonProdDataTestId('loading icon')}
                >
                    <img
                        className="h-full w-full"
                        src={`${process.env.PUBLIC_URL}/loading-animation.gif`}
                        style={{ maxHeight: 300, maxWidth: 300 }}
                        alt="loading animation"
                    />
                    <figcaption className="text-center w-1/2">
                        <Paragraph
                            color="blue"
                            size="medium"
                            weight="bold"
                            className="whitespace-pre-line"
                        >
                            {t('loading.searchingCandidates')}
                        </Paragraph>
                    </figcaption>
                </figure>
            )}
            {waitingForReportGeneration && (
                <div className="flex flex-col items-center justify-center flex-grow text-center">
                    <Paragraph className="whitespace-pre-line leading-relaxed">
                        {t('loading.collectingData')}
                    </Paragraph>
                    <div className="flex items-center justify-center mb-2 mt-5">
                        <Link
                            to={generatePath(routes.newTargetData.path, {
                                caseId,
                            })}
                        >
                            <Button className="mx-2">
                                {t('createNewTarget')}
                            </Button>
                        </Link>
                        {userRoles.includes(USER_GROUP_ADMINISTRATORS) && (
                            <Link to={routes.caseNew.path}>
                                <Button className="mx-2">
                                    {t('createNewCase')}
                                </Button>
                            </Link>
                        )}
                    </div>
                    <Link to="/">
                        <Button level="primaryGhost" className="my-2">
                            {t('backToOverview')}
                        </Button>
                    </Link>
                </div>
            )}
            {targetData?.workflowErrors?.length ? (
                <Notification
                    headline={t('workflowErrors.headline')}
                    level="error"
                    message={targetData.workflowErrors
                        .map(({ type }) => t(`workflowErrors.${type}`))
                        .join(', ')}
                    link={{
                        to: generatePath(routes.caseShow.path, { caseId }),
                        text: t('back'),
                    }}
                />
            ) : null}
        </div>
    );
};
