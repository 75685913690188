import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { CaseData } from '../../services/dataService';

export const useUpdateCaseMutation = (): UseMutationResult<
    CaseData,
    unknown,
    { caseId: string; title: string }
> => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ caseId, title }) =>
            axios.put(`https://${backendBaseUrl}/cases/${caseId}`, { title }),
        {
            onSuccess: () => queryClient.invalidateQueries('cases'),
        },
    );
};
