import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Error, LoadingSpinner } from '_atoms';
import {
    Alert,
    Pagination,
    SearchInput,
    SocialMediaImage,
    Tabs,
} from '_molecules';
import { ResultsProps } from '../../../features/dashboard/Results';
import type { SocialAccountConnectionArticle } from '../../../services/searchService';
import { SearchFilterValue } from '_types';
import { useElasticSearch } from '../../../hooks/useElasticSearch';
import { getCdnUrl, nonProdDataTestId } from '_utils';

type SocialMediaContactsFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

type AccountCount = {
    source: string;
    counts: Record<string, number>;
    total: number;
};

type SocialMediaContactsProps = Pick<ResultsProps, 'targetData'>;

export const trimConnectionUserName = (username: string): string => {
    if (!username) {
        return '';
    }
    const facebookQueryStringsIndex1 = username.indexOf('&');
    const facebookQueryStringsIndex2 = username.indexOf('?');
    const facebookQueryStringsIndex =
        facebookQueryStringsIndex1 !== -1 && facebookQueryStringsIndex2 !== -1
            ? Math.min(facebookQueryStringsIndex1, facebookQueryStringsIndex2)
            : Math.max(facebookQueryStringsIndex1, facebookQueryStringsIndex2);
    return facebookQueryStringsIndex !== -1
        ? username.slice(0, facebookQueryStringsIndex).trim()
        : username.trim();
};

export const SocialMediaContacts: FC<SocialMediaContactsProps> = () => {
    const { t } = useTranslation();

    const [accountCounts, setAccountCounts] = useState<Array<AccountCount>>([]);
    const [socialMediaConnections, setSocialMediaConnections] = useState<
        Array<any>
    >([]);

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const {
        searchInputProps: {
            renderedFilters,
            onFilterChange,
            ...searchInputProps
        },
        data,
        isError,
        isLoading,
        handlePageChange,
    } = useElasticSearch<
        SocialMediaContactsFilters,
        SocialAccountConnectionArticle[]
    >({
        caseId,
        targetId,
        entityType: 'socialMediaConnection',
        pageSize: 600,
    });

    const [selectedAccountType, setSelectedAccountType] = useState<string>('');
    const [selectedConnectionType, setSelectedConnectionType] =
        useState<string>('');

    useEffect(() => {
        if (data?.count) {
            const accountTypes = Object.keys(data.count);
            if (accountTypes?.length) {
                const newAccountCounts = accountTypes.map((accountType) => ({
                    source: accountType,
                    counts: data.count[accountType],
                    total: (
                        Object.values(data.count[accountType]) as number[]
                    ).reduce((acc, count) => acc + count, 0),
                }));

                setAccountCounts(newAccountCounts);

                const firstAccountType = newAccountCounts[0].source;
                setSelectedAccountType((sat) => (sat ? sat : firstAccountType));
                const firstConnectionType = newAccountCounts.find(
                    (accountCount) => accountCount.source === firstAccountType,
                )?.counts[0];
                setSelectedConnectionType((sct) =>
                    sct ? sct : firstConnectionType,
                );

                setSocialMediaConnections(data.items);
            }
        }
    }, [data]);

    useEffect(() => {
        onFilterChange({
            ...(selectedAccountType
                ? {
                      accountType: {
                          type: 'query',
                          query: 'terms',
                          value: [selectedAccountType],
                      },
                  }
                : {}),
            ...(selectedConnectionType
                ? {
                      connectionType: {
                          query: 'terms',
                          type: 'query',
                          value: [selectedConnectionType],
                      },
                  }
                : {}),
        });
    }, [onFilterChange, selectedAccountType, selectedConnectionType]);

    const connectionTypeTabs = accountCounts?.length
        ? Object.entries(
              (
                  accountCounts?.find(
                      (accountCount) =>
                          accountCount.source === selectedAccountType,
                  ) || accountCounts[0]
              ).counts,
          )
        : [];

    return (
        <div>
            <SearchInput
                {...searchInputProps}
                className="mb-5"
                hideFilters={true}
            >
                {renderedFilters}
            </SearchInput>

            {accountCounts && accountCounts?.length ? (
                <>
                    <Tabs
                        tabTitles={accountCounts.map(
                            (actionCount) =>
                                `${actionCount.source} (${Object.values(
                                    actionCount.counts,
                                ).reduce((sum, count) => sum + count, 0)})`,
                        )}
                        onChange={(index) => {
                            const newSelectedAccountType =
                                accountCounts[index].source;
                            setSelectedAccountType(newSelectedAccountType);
                            const selectedAccountCounts = Object.entries(
                                accountCounts.find(
                                    (ac) =>
                                        ac.source === newSelectedAccountType,
                                )?.counts || accountCounts[0].counts,
                            );
                            const newSelectedConnectionType =
                                (selectedAccountCounts.find(
                                    ([_key, value]) => value > 0,
                                ) || selectedAccountCounts[0])[0];
                            setSelectedConnectionType(
                                newSelectedConnectionType,
                            );
                        }}
                    />
                    <Tabs
                        tabTitles={connectionTypeTabs.map(([key, value]) => {
                            return `${key} (${value})`;
                        })}
                        onChange={(index) => {
                            setSelectedConnectionType(
                                connectionTypeTabs[index][0],
                            );
                        }}
                        selectedIndex={connectionTypeTabs.findIndex(
                            ([key, _]) => key === selectedConnectionType,
                        )}
                    />
                </>
            ) : null}
            <div
                className="relative z-10"
                data-testid={nonProdDataTestId('socialmediaposts')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : socialMediaConnections?.length ? (
                    <Fragment>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-8 py-6 items-center relative my-3 w-full">
                            {socialMediaConnections
                                .sort(
                                    (
                                        a: SocialAccountConnectionArticle,
                                        b: SocialAccountConnectionArticle,
                                    ) =>
                                        a.connectionUsername <
                                        b.connectionUsername
                                            ? -1
                                            : 1,
                                )
                                .map((item: SocialAccountConnectionArticle) => (
                                    <a
                                        key={item.id}
                                        className="flex items-center text-sm text-primary-4 hover:text-primary-3 font-normal focus:outline-none"
                                        href={item.connectionProfileUrl}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <SocialMediaImage
                                            size="small"
                                            zoomOnHover={true}
                                            src={getCdnUrl(
                                                item.connectionProfileImageCdnUrl,
                                            )}
                                        />
                                        <span className="truncate">
                                            {trimConnectionUserName(
                                                item.connectionUsername ||
                                                    item.connectionProfileName,
                                            )}
                                        </span>
                                    </a>
                                ))}
                        </div>

                        {data?.paging && data?.paging.pagesCount > 1 && (
                            <div className="px-5">
                                <Pagination
                                    paging={data?.paging}
                                    setPageNumber={(page) =>
                                        handlePageChange(page)
                                    }
                                />
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <Alert alertType="info" message={t('noData')} />
                )}
            </div>
        </div>
    );
};
