import { FC } from 'react';
import { IconType } from 'react-icons';
import {
    RiFacebookCircleFill,
    RiInstagramLine,
    RiLinkedinBoxFill,
    RiTwitterXFill,
    RiXingFill,
} from 'react-icons/ri';

export type ImageItemSourceIconProps = {
    sources: string[];
};

enum ImageSource {
    Facebook = 'facebook',
    Twitter = 'twitter',
    Instagram = 'instagram',
    LinkedIn = 'linkedin',
    Xing = 'xing',
}

const IconMap: Record<ImageSource, IconType> = {
    [ImageSource.Facebook]: RiFacebookCircleFill,
    [ImageSource.Twitter]: RiTwitterXFill,
    [ImageSource.Instagram]: RiInstagramLine,
    [ImageSource.LinkedIn]: RiLinkedinBoxFill,
    [ImageSource.Xing]: RiXingFill,
};

export const ImageItemSourceIcon: FC<ImageItemSourceIconProps> = ({
    sources,
}) => {
    const normalizedSources = sources.map(
        (s) => s.toLowerCase() as ImageSource,
    );
    const sourceIconKey = normalizedSources.find((source) => !!IconMap[source]);
    if (!sourceIconKey) {
        return null;
    }

    const Icon = IconMap[sourceIconKey];
    return <Icon className="text-neutral-400" />;
};
