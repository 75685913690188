import React from 'react';
import { formatDate } from '_utils';
import { FallbackImage } from '_molecules';
import { Image } from '_atoms';
import { useTranslation } from 'react-i18next';

interface ProfileInfoProps {
    imageUrl?: string;
    name?: string;
    createdAt?: string;
}

export const ProfileInfo = (props: ProfileInfoProps): React.ReactElement => {
    const { imageUrl, name, createdAt } = props;
    const { t } = useTranslation();

    return (
        <div className="flex items-center">
            <div className="w-10 mr-4">
                {imageUrl ? (
                    <Image
                        aspectRatio="portrait"
                        alt={t('fallbackProfileImage')}
                        src={imageUrl}
                        className="rounded-md"
                    />
                ) : (
                    <FallbackImage />
                )}
            </div>
            <div>
                <div className="font-bold text-base text-primary-1 capitalize">
                    {name ?? '-'}
                </div>
                <div className="text-sm text-neutral-600">
                    {t('riskAnalysis.profileInfo.createdOn')}:{' '}
                    {createdAt ? formatDate(createdAt) : '-'}
                </div>
            </div>
        </div>
    );
};
