import {
    ChangeEvent,
    FC,
    UIEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    CaseSearchResultItemDto,
    useGlobalSearchQueryResult,
} from '../../../../hooks/queries/useGlobalSearchQueryResult';
import { Card, Input, LoadingSpinner, Paragraph } from '_atoms';
import { MdClose, MdSearch } from 'react-icons/md';
import classnames from 'classnames';
import { GlobalSearchResults } from './GlobalSearchResults';
import { useTranslation } from 'react-i18next';

export type GlobalSearchProps = {
    placeholder?: string;
    className?: string;
};

export const GlobalSearch: FC<GlobalSearchProps> = () => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const searchResultsBox = useRef<HTMLInputElement>(null);

    const [query, setQuery] = useState('');

    const [showSearchResults, setShowSearchResults] = useState(false);
    const [settled, setSettled] = useState(false);

    const useQueryResult = useGlobalSearchQueryResult(query, () =>
        setSettled(true),
    );

    const clearSearchParams = (): void => {
        setQuery('');
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setSettled(false);
    };

    const handleEscapeKeydown = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
            // Toggle the state when the Escape key is pressed
            inputRef.current?.blur();
            setShowSearchResults(false);
        }
    };

    const handleClickOutside = (event: MouseEvent): void => {
        if (
            !inputRef.current?.contains(event.target as Node) &&
            !searchResultsBox.current?.contains(event.target as Node)
        ) {
            setShowSearchResults(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeydown);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('keydown', handleEscapeKeydown);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onScroll: UIEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            if (useQueryResult.isLoading) {
                return;
            }

            const { currentTarget } = event;

            if (
                currentTarget.scrollTop + currentTarget.clientHeight <
                currentTarget.scrollHeight
            ) {
                return;
            }

            !useQueryResult.isLoading &&
                useQueryResult
                    .fetchNextPage()
                    .then()
                    .catch((error) => {
                        console.error(error);
                    });
        },
        [useQueryResult],
    );

    const sharedIconClassNames =
        'align-middle absolute top-1/2 -translate-y-1/2 right-4';

    const resultInfoClassName = 'p-2 text-center';

    const searchResultItems =
        useQueryResult.data?.pages.flatMap<CaseSearchResultItemDto>(
            (page) => page.items,
        ) ?? [];

    const searchResultItemCount = useQueryResult.data?.pages[0]?.total ?? 0;

    return (
        <div className="relative font-jost text-left">
            <div className="flex justify-center">
                <div className="relative max-w-[600px] w-full">
                    <div className="font-bold text-label text-neutral-600 mb-1">
                        {t('globalSearch.title')}
                    </div>
                    <div className="relative">
                        <Input
                            type="text"
                            value={query}
                            onChange={onChange}
                            onFocus={() => {
                                setShowSearchResults(true);
                            }}
                            ref={inputRef}
                        />
                        {query.length > 0 ? (
                            <MdClose
                                className={classnames(
                                    sharedIconClassNames,
                                    'cursor-pointer',
                                )}
                                onClick={() => {
                                    clearSearchParams();
                                    inputRef.current?.focus();
                                }}
                            />
                        ) : (
                            <MdSearch
                                className={classnames(
                                    sharedIconClassNames,
                                    'text-primary-4 pointer-events-none',
                                )}
                            />
                        )}
                        {Boolean(
                            (showSearchResults && searchResultItems.length) ||
                                (showSearchResults &&
                                    !searchResultItems.length &&
                                    settled &&
                                    query) ||
                                (useQueryResult.isLoading && query),
                        ) && (
                            <Card
                                className="absolute z-20 top-full w-full shadow-md bg-white"
                                ref={searchResultsBox}
                            >
                                {Boolean(searchResultItems.length) && (
                                    <>
                                        <Paragraph
                                            size="label"
                                            color="light"
                                            weight="bold"
                                            className={classnames(
                                                resultInfoClassName,
                                                'border-b border-b-neutral-400',
                                            )}
                                        >
                                            {t('globalSearch.resultsCount', {
                                                count: searchResultItemCount,
                                            })}
                                        </Paragraph>
                                        <div
                                            onScroll={onScroll}
                                            className="overflow-auto max-h-96 flex flex-col relative"
                                        >
                                            <GlobalSearchResults
                                                items={searchResultItems}
                                            />
                                        </div>
                                    </>
                                )}
                                {Boolean(
                                    !searchResultItems.length &&
                                        settled &&
                                        query,
                                ) && (
                                    <Paragraph
                                        size="label"
                                        color="light"
                                        weight="bold"
                                        className={resultInfoClassName}
                                    >
                                        {t('globalSearch.noResults')}
                                    </Paragraph>
                                )}
                                {Boolean(useQueryResult.isLoading && query) && (
                                    <Paragraph
                                        size="label"
                                        color="light"
                                        weight="bold"
                                        className={resultInfoClassName}
                                        HtmlTag="div"
                                    >
                                        <LoadingSpinner />
                                    </Paragraph>
                                )}
                            </Card>
                        )}
                    </div>
                    <div className="text-sm text-neutral-600 mt-1">
                        {t('globalSearch.hint')}
                    </div>
                </div>
            </div>
        </div>
    );
};
