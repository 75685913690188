import { Field, FieldAttributes, getIn, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

type StyledFieldProps = FieldAttributes<unknown> & {
    label?: string;
    autofilled?: boolean;
    recommended?: boolean;
    unused?: boolean;
    maxLength?: number;
};

/**
 * Wrapper around Formik field that applies our own styling, adds a label and
 * displays any relevant required / recommended / error status.
 */
export const StyledField: FC<StyledFieldProps> = ({
    name,
    label,
    autofilled,
    required,
    unused,
    recommended,
    children,
    placeholder,
    maxLength,
    className,
    ...rest
}) => {
    const { t } = useTranslation();
    const context = useFormikContext();

    const error = getIn(context.errors, name);
    const touched = getIn(context.touched, name);

    const annotationTextClass =
        error && touched
            ? 'text-error-2'
            : autofilled
            ? 'text-yellow-400'
            : 'text-primary-4';
    const focusBorderClass =
        error && touched ? 'focus:border-error-2' : 'focus:border-primary-4';

    let annotation;
    if (error && touched) {
        annotation = t(error, { maxLength });
    } else if (autofilled) {
        annotation = t('autofilled');
    } else if (required) {
        annotation = t('required');
    } else if (recommended) {
        annotation = t('recommended');
    }

    const fieldBorder =
        error && touched ? 'border-error-2' : 'border-neutral-400';

    return (
        <div className={rest.type === 'checkbox' ? 'flex flex-col' : ''}>
            <div className="flex justify-between">
                {label && (
                    <label
                        htmlFor={name}
                        className="block text-sm font-bold font-jost text-neutral-500"
                    >
                        {label}
                    </label>
                )}
                {annotation && (
                    <span
                        className={classnames('text-sm', annotationTextClass)}
                    >
                        {annotation}
                    </span>
                )}
            </div>
            <div
                className={
                    rest.type === 'checkbox'
                        ? 'flex flex-grow items-center justify-center'
                        : 'mt-1'
                }
            >
                <Field
                    name={unused ? ' ' : name}
                    required={required}
                    disabled={unused}
                    className={classnames(
                        rest.type === 'checkbox'
                            ? 'shadow-sm block rounded-md bg-neutral-50 p-3 text-base'
                            : 'shadow-sm block w-full rounded-md bg-neutral-50 px-4 py-3.5 text-base',
                        focusBorderClass,
                        fieldBorder,
                        {
                            'opacity-50 cursor-not-allowed':
                                unused || rest.disabled,
                        },
                        className,
                    )}
                    placeholder={unused ? t('unused') : placeholder}
                    {...rest}
                >
                    {children}
                </Field>
            </div>
        </div>
    );
};
