import React, { useMemo } from 'react';
import { RegisterDataArticle } from '../../../../../services/searchService';
import { Meta } from './components/Meta';
import { useTranslation } from 'react-i18next';
import { SharedArticleContentProps } from './types';

interface CompanyProps extends SharedArticleContentProps {
    article: RegisterDataArticle;
}

export const Company = (props: CompanyProps): React.ReactElement => {
    const { article } = props;

    const { t } = useTranslation();

    const meta = useMemo(
        () => [
            {
                label: t('riskAnalysis.articleDetails.company'),
                list: article.companyName ? [article.companyName] : undefined,
            },
            {
                label: t('riskAnalysis.articleDetails.role'),
                list: article.companyTargetRoles.map(
                    (target) => target.roleName,
                ),
            },
            {
                label: t('riskAnalysis.articleDetails.websites'),
                list: article.companyWebsites,
            },
            {
                label: t('riskAnalysis.articleDetails.phone'),
                list: article.companyPhones,
            },
            {
                label: t('riskAnalysis.articleDetails.prevNames'),
                list: article.companyNamePrevious?.map(({ name }) => name),
            },
            {
                label: t('riskAnalysis.articleDetails.address'),
                list: article.companyAddresses.map((address) =>
                    [address.street, address.city, address.zip, address.country]
                        .filter(Boolean)
                        .map(
                            (part) =>
                                `${part.charAt(0).toUpperCase()}${part
                                    .slice(1)
                                    .toLowerCase()}`,
                        )
                        .join(', '),
                ),
            },
            {
                label: t('riskAnalysis.articleDetails.companyActivity'),
                list: article.companyMainActivity,
            },
        ],
        [article, t],
    );

    return <Meta meta={meta} />;
};
