import React from 'react';
import { Card } from '_atoms';
import classNames from 'classnames';
import classnames from 'classnames';
import { EntityType } from '_types';
import { useTranslation } from 'react-i18next';
import { BiBuilding } from 'react-icons/bi';
import { FaUsers } from 'react-icons/fa';
import {
    RiFileTextLine,
    RiImage2Line,
    RiMoneyEuroCircleLine,
    RiNewspaperLine,
} from 'react-icons/ri';
import { hasFlag } from 'country-flag-icons';
import { getCode } from 'country-list';
import Flags, { FlagComponent } from 'country-flag-icons/react/1x1';
import { IconType } from 'react-icons';

interface ArticlePreviewCardProps {
    onClick: () => void;
    isActive: boolean;
    title: string;
    subtitle?: string;
    type: EntityType;
    suspicionsCount?: number;
    country?: string;
    onSuspicionClick?: () => void;
}

const articleTypeToIcon: Record<EntityType, IconType> = {
    targetcompany: BiBuilding,
    social: FaUsers,
    webpage: RiFileTextLine,
    press: RiNewspaperLine,
    image: RiImage2Line,
    financial: RiMoneyEuroCircleLine,
};

const getCountryIcon = (country: string): FlagComponent | undefined => {
    const value = country === 'United Kingdom' ? 'GB' : country;
    const isoCode = hasFlag(value) ? value : getCode(value);
    return isoCode
        ? ((Flags as any)[isoCode] as FlagComponent | undefined)
        : undefined;
};

export const ArticlePreviewCard: React.FC<ArticlePreviewCardProps> = ({
    onClick,
    onSuspicionClick,
    isActive,
    title,
    subtitle,
    children,
    type,
    suspicionsCount,
    country,
}) => {
    const { t } = useTranslation();

    const Icon = articleTypeToIcon[type];
    const CountryIcon = country ? getCountryIcon(country) : undefined;

    return (
        <Card
            className={classNames(
                'p-4 flex flex-col border-2 rounded-lg relative max-h-44 min-h-44',
                isActive ? 'border-primary-4' : 'border-neutral-400',
            )}
            onClick={onClick}
        >
            <div className="w-4 h-4 border border-neutral-400 flex items-center justify-center rounded-full absolute right-2 top-2">
                <div
                    className={classNames(
                        'w-2 h-2 rounded-full flex',
                        isActive ? 'bg-primary-4' : 'bg-transparent',
                    )}
                />
            </div>
            <div className="flex gap-1 items-start">
                <div className="relative">
                    <div className="flex justify-center items-center rounded-full w-10 h-10 p-2 bg-primary-1 text-font-light">
                        <Icon />
                    </div>
                    {CountryIcon && (
                        <div className="absolute bottom-[-5px] right-[-5px] w-[18px] h-[18px]">
                            <CountryIcon className="rounded-full" />
                        </div>
                    )}
                </div>
                <div className="font-jost text-primary-1 pl-4 line-clamp-2">
                    <div className="text-base font-bold">{title}</div>
                    {subtitle && (
                        <div className="font-normal line-clamp-1">
                            {subtitle}
                        </div>
                    )}
                </div>
            </div>
            <div className="flex-1 pt-4">{children}</div>
            <div className="flex justify-end">
                {suspicionsCount && (
                    <div
                        className={classnames(
                            'text-neutral-500 font-jost font-bold',
                            onSuspicionClick &&
                                'hover:underline cursor-pointer',
                        )}
                        onClick={onSuspicionClick}
                    >
                        {`${suspicionsCount} `}
                        {t(
                            `riskAnalysis.articleMeta.${
                                suspicionsCount === 1
                                    ? 'suspicion'
                                    : 'suspicions'
                            }`,
                        )}
                    </div>
                )}
            </div>
        </Card>
    );
};
