import { FC, Fragment } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { StyledLink } from '../StyledLink';
import classnames from 'classnames';

export type Breadcrumb = {
    label: string | JSX.Element;
    route?: string;
};

type BreadcrumbsProps = {
    className?: string;
    breadcrumbs: Breadcrumb[];
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
    breadcrumbs,
    className,
}: BreadcrumbsProps) => {
    return (
        <div
            className={classnames(
                className,
                'flex items-center text-label font-jost font-bold',
            )}
        >
            {breadcrumbs?.map(({ route, label }, index) => {
                return (
                    <Fragment key={index}>
                        {index > 0 && (
                            <div className="px-1 text-neutral-500">
                                <MdChevronRight />
                            </div>
                        )}
                        {route ? (
                            <StyledLink to={route}>{label}</StyledLink>
                        ) : (
                            <span className="text-neutral-500">{label}</span>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};
