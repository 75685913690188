import { LegacyTargetCandidateInfoItem } from '@indicium/common/src/types/Target/TargetCandidate';
import { TFunction } from 'react-i18next';
import { TargetCandidateSource } from '_types';
import { getActiveLanguage } from '../../../translations/utils';
import { Locale, LocalizationService } from '@indicium/common';

const icijKeysMap: {
    [key: string]: {
        key?: string;
        values?: (values: string[]) => string[];
    };
} = {
    leakSource: {
        key: 'source',
    },
    officerId: {
        key: 'url',
        values: (values: string[]) =>
            values.map(
                (officerId) =>
                    `https://offshoreleaks.icij.org/nodes/${officerId}`,
            ),
    },
    countries: {
        values: (values: string[]) =>
            values.map(
                (country) =>
                    LocalizationService.getCountry(
                        country,
                        getActiveLanguage() as Locale,
                    )?.name ?? country,
            ),
    },
};

const icijInfoMap = (key: string, values: string[]) => {
    return {
        key: icijKeysMap[key]?.key ?? key,
        values: icijKeysMap[key]?.values?.(values) ?? values,
    };
};

export const processCandidatesInfo = (
    sortedInfo: {
        key: string;
        values?: string[];
    }[],
    sources: TargetCandidateSource[],
    translate: TFunction<'translation'>,
): LegacyTargetCandidateInfoItem[] => {
    if (sources.includes('icijLeaks')) {
        const icijInfo: Record<string, string[]> = {};

        sortedInfo.forEach((info) => {
            const { key, values } = icijInfoMap(info.key, info.values ?? []);
            icijInfo[key] = values;
        });

        const { source = [], validUntil = [], ...restInfo } = icijInfo;

        const combinedLeakSource = [
            `${translate(`icijLeakSource.${source[0]}`)} (${translate(
                'validUntil',
                {
                    validUntil: validUntil[0],
                },
            )})`,
        ];

        const infoList: LegacyTargetCandidateInfoItem[] = Object.entries(
            restInfo,
        ).map(([key, values]) => ({
            key,
            values,
        }));

        infoList.push({
            key: 'leakSource',
            values: combinedLeakSource,
        });

        return infoList;
    }
    return sortedInfo.map(({ key, values = [] }) => ({ key, values }));
};
