import { useQuery, UseQueryResult } from 'react-query';
import { UserData } from 'src/types/UserData';
import { users } from '../../services/nestApiService';

export const useUsersQuery = (
    customerId?: string,
): UseQueryResult<UserData[], string> =>
    useQuery('users', () => users.getAll(customerId), {
        refetchOnWindowFocus: false,
    });
