import type { ScoreMatchRecord } from '../../../../services/searchService';
import React, { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline } from '_atoms';
import { IoMdStar } from 'react-icons/io';

interface ConfidenceScoreMatchesProps extends HTMLAttributes<HTMLDivElement> {
    data: ScoreMatchRecord;
    isPositive: boolean;
}

export const ConfidenceScoreMatches: FC<ConfidenceScoreMatchesProps> = ({
    data,
    isPositive,
}) => {
    const { t } = useTranslation();

    const isNotProduction = process.env.REACT_APP_STAGE !== 'production';

    return (
        <div>
            {Object.entries(data)
                .sort((a, b) => {
                    return Number(b[0]) - Number(a[0]);
                })
                .map(([score, values]) => (
                    <div key={score} className={'pb-3'}>
                        <div className={'flex items-end'}>
                            {Array.from({
                                length: Math.round(Number(score) / 5),
                            }).map((_, index) => (
                                <IoMdStar
                                    key={index}
                                    size={15}
                                    className={'inline p-0 text-yellow-400'}
                                />
                            ))}
                            {isNotProduction && (
                                <Headline
                                    Level={'h6'}
                                    className={'font-normal pl-2'}
                                >
                                    {`(${isPositive ? '+' : '–'}${score})`}
                                </Headline>
                            )}
                        </div>
                        <table>
                            <tbody>
                                {Object.entries(values)
                                    .sort((a, b) => {
                                        return a[0].localeCompare(b[0]);
                                    })
                                    .map(([value, fields]) => (
                                        <tr key={value}>
                                            <td className="pl-6">{value}</td>
                                            <td className="text-gray-600 pl-4">
                                                <span>
                                                    {`(${fields
                                                        .map((field) =>
                                                            t(
                                                                `confidenceScore.matches.${field}`,
                                                            ),
                                                        )
                                                        .join(', ')})`}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                ))}
        </div>
    );
};
