import { CompanySource } from '_types';

export const VALID_SOURCES: CompanySource[] = [
    'orbis',
    'companyhouse',
    'socialMedia',
    'userInput',
];

export const getValidSource = (source: string): CompanySource | undefined =>
    VALID_SOURCES.find((validSource) => validSource === source);
