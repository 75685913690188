import { useQuery, UseQueryResult } from 'react-query';
import { cases } from '../../services/nestApiService';
import { CaseData } from '../../services/dataService';

export const useCaseQuery = (caseId?: string): UseQueryResult<CaseData> =>
    useQuery(
        ['case', caseId],
        async () => cases.findOne(caseId).then((r) => r.data),
        {
            refetchOnWindowFocus: false,
            enabled: !!caseId && caseId !== 'new',
        },
    );
