import { useQuery, UseQueryResult } from 'react-query';
import { CrimeLlmSuspicion } from '_types';
import { suspicions } from '../../services/nestApiService';

export const useCrimeLlmSuspicionsQuery = (): UseQueryResult<
    CrimeLlmSuspicion[]
> => {
    return useQuery(
        ['crime-llm-suspicions'],
        () => suspicions.listCrimeLlmSuspicions(),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );
};
