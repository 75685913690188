export interface Entities {
    entities: [string, Entity][];
}

export type LinkedEntity = [
    string,
    {
        name: string;
        items: Array<{
            id: string;
            title: string;
            details: Array<{ label: string; value: string }>;
        }>;
        flags?: Array<{
            displayText: string;
        }>;
        markInactive: boolean;
    },
];

export type Entity = Company | Person;

export interface Company {
    // Specifies the type of entity as 'Company'
    type: 'Company' | 'SynthCompany';
    // Unique identifier for the company
    id: string;
    // Name of the company
    name: string;
    // Array of trade register numbers associated with the company
    trade_register_number: string[];
    // Optional date of incorporation of the company
    incorporation_date?: string | null;
    // Optional trade description in English
    trade_description_en?: string | null;
    // Optional trade description in original language
    trade_description_original?: string | null;
    // Optional language of the original trade description
    trade_description_language?: string | null;
    // Optional postal code of the company's address
    postcode?: string | null;
    // Optional city of the company's address
    city?: string | null;
    // Optional ISO code of the country where the company is located
    country_iso_code?: string | null;
    // Optional first line of the company's address
    address_line1?: string | null;
    // Optional second line of the company's address
    address_line2?: string | null;
    // Optional third line of the company's address
    address_line3?: string | null;
    // Optional fourth line of the company's address
    address_line4?: string | null;
    // Optional type of address (e.g., headquarters, branch)
    address_type?: string | null;
    // Optional latitude coordinate of the company's location
    latitude?: number | null;
    // Optional longitude coordinate of the company's location
    longitude?: number | null;
    // Optional array of phone numbers associated with the company
    phone_number?: string[] | null;
    // Optional array of email addresses associated with the company
    email?: string[] | null;
    // Optional array of website URLs associated with the company
    website?: string[] | null;
    // Optional array of additional postal codes
    postcode_additional?: string[] | null;
    // Optional array of additional cities
    city_additional?: string[] | null;
    // Optional array of additional country ISO codes
    country_iso_code_additional?: string[] | null;
    // Optional array of additional first address lines
    address_line1_additional?: string[] | null;
    // Optional array of additional second address lines
    address_line2_additional?: string[] | null;
    // Optional array of additional third address lines
    address_line3_additional?: string[] | null;
    // Optional array of additional fourth address lines
    address_line4_additional?: string[] | null;
    // Optional array of additional address types
    address_type_additional?: string[] | null;
    // Optional listing status of the company (e.g., public, private)
    listing_status?: string | null;
    // Indicates if the company has a generic red flag
    woco: boolean;
    // Indicates if the company is under sanctions
    woco_sanction: boolean;
    // Indicates if the company has enforcement actions
    woco_enforcement: boolean;
    // Indicates if the company is linked to a government
    woco_government_linked: boolean;
    // Indicates if the company has adverse media coverage
    woco_adverse_media: boolean;
    // Indicates if the company is an associated entity
    woco_associated_entity: boolean;
    // Indicates if the company has registrations
    woco_registrations: boolean;
    // Indicates if the company has current Politically Exposed Persons (PEPs)
    woco_current_pep: boolean;
    // Indicates if the company is a State-Owned Enterprise (SOE)
    woco_soe: boolean;
    // Array of trade codes associated with the company
    trade_codes: TradeCode[];
    // Array of status information for the company
    status: Status[];
    // Array of previous names of the company
    previous_names: PreviousName[];
    // Array of beneficial owners of the company
    bos: BeneficialOwner[];
    // Array of directors of the company
    directors: Director[];
    // Array of shareholders of the company
    shareholders: Shareholder[];
    // Array of subsidiaries of the company
    subsidiaries: Subsidiary[];
    // Optional count of directors in the company
    director_count?: number | null;

    // Optionally associated compliance catalyst entities
    coca_entities?: CoCaEntity[] | null;
}

export interface Person {
    // Specifies the type of entity as 'Person'
    type: 'Person' | 'SynthPerson';
    // Unique identifier for the person
    id: string;
    // Optional formal title or honorific
    salutation?: string | null;
    // Optional complete name of the person
    full_name?: string | null;
    // Optional given name of the person
    first_name?: string | null;
    // Optional family name of the person
    last_name?: string | null;
    // Optional middle name of the person
    middle_name?: string | null;
    // Optional age of the person
    age?: number | null;
    // Optional gender identity of the person
    gender?: string | null;
    // Optional birthplace of the person
    place_of_birth?: string | null;
    // Optional date of birth of the person
    birth_date?: string | null;
    // Optional city of residence of the person
    home_city?: string | null;
    // Optional country of residence of the person
    home_country?: string | null;
    // Optional personal email address of the person
    home_email?: string | null;
    // Optional label indicating multiple nationalities
    multiple_nationalities_label?: string | null;
    // Indicates if the person has a generic red flag
    woco_generic_red_flag: boolean;
    // Indicates if the person is under sanctions
    woco_sanction: boolean;
    // Indicates if the person has enforcement actions
    woco_enforcement: boolean;
    // Indicates if the person is a Politically Exposed Person (PEP)
    woco_pep: boolean;
    // Array of Membership objects associated with the person
    memberships: Membership[];

    // Optionally associated compliance catalyst entities
    coca_entities?: CoCaEntity[] | null;
}

// Name of the company associated with the membership
export interface Membership {
    company_name: string;
    // Unique identifier for the company
    company_id: string;
    // Display representation of the member's function or role
    function_display: string;
    // Array of Function objects representing the member's roles
    // eslint-disable-next-line @typescript-eslint/ban-types
    functions: Function[];
    // Member's work email address (optional)
    work_email?: string | null;
    // Member's work phone number (optional)
    work_phone?: string | null;
    // Country where the member works (optional)
    work_country?: string | null;
    // Province or state where the member works (optional)
    work_province_or_state?: string | null;
    // City where the member works (optional)
    work_city?: string | null;
    // Current status of the membership
    status: string;
    // Date when the membership nomination began (optional)
    beginning_nomination_date?: string | null;
    // Date when the membership expires (optional)
    end_expiration_date?: string | null;
}

export enum MembershipFunctionRole {
    Management = 'Management',
    Shareholder = 'Shareholder',
    Board = 'Board',
    Advisor = 'Advisor',
    Staff = 'Staff',
    Unknown = 'Unknown',
}

export enum MembershipFunctionRoleCode {
    Management = 'MGM',
    Shareholder = 'SHH',
    Board = 'MBC',
    Advisor = 'ADV',
    Staff = 'CSP',
    Unknown = 'UNK',
}

export interface Function {
    role: MembershipFunctionRole;
    label?: string | null;
}

export type CompanyRelatedEntityLegalEntity =
    | 'Bank'
    | 'Corporate'
    | 'Financial'
    | 'Foundation'
    | 'Insurance'
    | 'Trust'
    | 'PrivateEquity'
    | 'VentureCapital'
    | 'Unknown';

export type CompanyRelatedEntityType =
    | 'Individual'
    | { LegalEntity: CompanyRelatedEntityLegalEntity }
    | 'OtherNamed'
    | { Other: string }
    | 'Unknown';

export interface TradeCode {
    // Type of trade code (e.g., SIC, NAICS)
    type: string;
    // Actual code value
    code: string;
    // Human-readable description of the code
    label: string;
}

export interface Status {
    // Current status of the entity
    status: string;
    // Date when the status was last updated or changed
    date?: string | null;
}

export interface PreviousName {
    // Former name of the entity
    name: string;
    // Date when the name was changed
    date?: string | null;
}

// Unique identifier for the beneficial owner
export interface BeneficialOwner {
    // Unique identifier for the beneficial owner
    id: string;
    // Universal Company Identifier (optional)
    uci?: string | null;
    // Formal greeting or title (optional)
    salutation?: string | null;
    // Full name of the beneficial owner
    name: string;
    // First name of the beneficial owner (optional)
    first_name?: string | null;
    // Last name of the beneficial owner (optional)
    last_name?: string | null;
    // Type of entity (e.g., individual, company)
    entity_type: CompanyRelatedEntityType;
    // Full address of the beneficial owner (optional)
    address?: string | null;
    // City of residence for the beneficial owner (optional)
    city?: string | null;
    // Postal code of the beneficial owner's address (optional)
    postcode?: string | null;
    // ISO code of the country where the beneficial owner resides
    country_iso_code: string;
    // Gender of the beneficial owner (optional)
    gender?: string | null;
}

// Unique identifier for the director
export interface Director {
    // Unique identifier for the director
    id: string;
    // Optional BVD (Bureau van Dijk) identifier
    bvd_id?: string | null;
    // Optional formal title or greeting (e.g., Mr., Ms., Dr.)
    salutation?: string | null;
    // Complete name of the director
    full_name: string;
    // Optional first name
    first_name?: string | null;
    // Optional middle name
    middle_name?: string | null;
    // Last name (surname) of the director
    last_name: string;
    // Type of directorship (e.g., Executive, Non-Executive)
    dir_type: string;
    // Role or position of the director, using Function2 interface
    function: DirectorFunction;
    // Optional gender information
    gender?: string | null;
}

export interface DirectorFunction {
    function: string;
    status: string;
}

export interface Shareholder {
    // Unique identifier for the shareholder
    id: string;
    // Universal Company Identifier (optional)
    uci?: string | null;
    // Formal greeting or title (optional)
    salutation?: string | null;
    // Full name of the shareholder
    name: string;
    // First name of the shareholder (optional)
    first_name?: string | null;
    // Last name of the shareholder (optional)
    last_name?: string | null;
    // Type of entity (e.g., individual, company)
    entity_type: CompanyRelatedEntityType;
    // First line of the shareholder's address (optional)
    address_line1?: string | null;
    // Second line of the shareholder's address (optional)
    address_line2?: string | null;
    // Third line of the shareholder's address (optional)
    address_line3?: string | null;
    // Fourth line of the shareholder's address (optional)
    address_line4?: string | null;
    // City of the shareholder's address (optional)
    city?: string | null;
    // Postal code of the shareholder's address (optional)
    postcode?: string | null;
    // ISO country code of the shareholder's address
    country_iso_code: string;
}

export interface Subsidiary {
    // Unique identifier for the subsidiary
    id: string;
    // Orbis identifier for the subsidiary
    orbisid: string;
    // Legal Entity Identifier (optional)
    lei?: string | null;
    // Stock ticker symbol
    ticker: string;
    // ISO country code of the subsidiary
    country_iso_code: string;
    // State or province of the subsidiary (optional)
    state_province?: string | null;
    // City of the subsidiary (optional)
    city?: string | null;
    // Type of entity (e.g., company, individual)
    entity_type: CompanyRelatedEntityType;
    // NACE core industry classification code (optional)
    nace_core_code?: string | null;
    // NACE core industry classification label (optional)
    nace_core_label?: string | null;
    // NAICS core industry classification code (optional)
    naics_core_code?: string | null;
    // NAICS core industry classification label (optional)
    naics_core_label?: string | null;
    // US SIC core industry classification code (optional)
    ussic_core_code?: string | null;
    // US SIC core industry classification label (optional)
    ussic_core_label?: string | null;
    // Hierarchical level of the subsidiary
    level: string;
    // Direct ownership percentage (optional)
    direct_pct?: string | null;
    // Total ownership percentage
    total_pct: string;
    // Description of possible percentage change (optional)
    possible_pct_change_description?: string | null;
    // Type of link between parent and subsidiary
    link_type: string;
    // Source of the subsidiary information
    information_source: string;
    // Date of the subsidiary information
    information_date: string;
    // Operating revenue (optional)
    opre?: number | null;
    // Total assets (optional)
    toas?: number | null;
    // Number of employees (optional)
    empl?: number | null;
    // Indicator for liability status
    liability_indicator: boolean;
    // Indicator for beneficial ownership (any type)
    beneficially_held_indicator?: boolean;
}

export enum CoCaEntityType {
    Individual = 'Individual',
    Organisation = 'Organisation',
}

export enum CoCaEntitySubCategory {
    Administrative = 'Administrative',
    AircraftHijacking = 'Aircraft Hijacking',
    AntitrustViolations = 'AntiTrust violations',
    ArmsTrafficking = 'ArmsTrafficking',
    Associate = 'Associate',
    Attorney = 'Attorney',
    BankFraud = 'BankFraud',
    Bribery = 'Bribery',
    Burglary = 'Burglary',
    Conspiracy = 'Conspiracy',
    Corruption = 'Corruption',
    Counterfeiting = 'Counterfeiting',
    Courts = 'Courts',
    Cybercrime = 'Cybercrime',
    CrimeAgainstHumanity = 'Crime Agnst Humanity',
    Debarred = 'Debarred',
    Diplomat = 'Diplomat',
    Disciplined = 'Disciplined',
    Disqualified = 'Disqualified',
    DrugTrafficking = 'DrugTrafficking',
    Embezzlement = 'Embezzlement',
    EndUseControl = 'End Use Control',
    EnvironmentalCrimes = 'EnvironmentalCrimes',
    Espionage = 'Espionage',
    ExcludedParty = 'ExcludedParty',
    Explosives = 'Explosives',
    ExtortionRacketeeringThreats = 'Extort-Rack-Threats',
    FamilyMember = 'Family Member',
    FinancialCrimes = 'Financial Crimes',
    Forgery = 'Forgery',
    Fraud = 'Fraud',
    Fugitive = 'Fugitive',
    GamblingOperations = 'Gambling Operations',
    GovtBranchMember = 'Govt Branch Member',
    GovtLinkedCorp = 'Govt Linked Corp',
    GovtOwnedCorp = 'Govt Owned Corp',
    HealthcareFraud = 'HealthcareFraud',
    HumanRightsAbuse = 'Human Rights Abuse',
    HumanTrafficking = 'HumanTrafficking',
    InsiderTrading = 'InsiderTrading',
    InsuranceFraud = 'InsuranceFraud',
    IntlOrgLeadership = 'Intl Org Leadership',
    InterstateCommerce = 'InterstateCommerce',
    ISISForeignSupport = 'ISISForeignSupport',
    Kidnapping = 'Kidnapping',
    LaborViolations = 'Labor Violations',
    ManagementGovtCorp = 'ManagementGovtCorp',
    Military = 'Military',
    MoneyLaundering = 'MoneyLaundering',
    MortgageFraud = 'MortgageFraud',
    MostWanted = 'MostWanted',
    Murder = 'Murder',
    NotApplicable = 'N/A',
    NGOLeadership = 'NGO Leadership',
    OwnershipOrControl = 'Ownership Or Control',
    Peonage = 'Peonage',
    PEPControlledBus = 'PEP Controlled bus',
    Piracy = 'Piracy',
    Pollution = 'Pollution',
    Pornography = 'Pornography',
    PriceManipulation = 'Price Manipulation',
    RICO = 'RICO',
    SecuritiesFraud = 'SecuritiesFraud',
    SeniorPartyMember = 'Senior Party Member',
    Smuggling = 'Smuggling',
    StolenProperty = 'Stolen Property',
    UnionLeadership = 'Union leadership',
    Unauthorized = 'Unauthorized',
    WarCrimes = 'War Crimes',
    WireFraud = 'WireFraud',
    WMD = 'WMD',

    Other = 'Other',
}

export type OtherCoCaEntitySubCategory = {
    type: CoCaEntitySubCategory.Other;
    value: string;
};

export type CoCaEntitySubCategoryType =
    | CoCaEntitySubCategory
    | OtherCoCaEntitySubCategory;

export type CoCaEntity = {
    external_id: string;
    name: string;
    country: string;
    position_or_cas: string;
    sanctionlist_source_code: string;
    sanctionlist_country_iso_code: string;
    aka_names?: Array<string>;
    entity_type: CoCaEntityType;
    entity_level: string;
    entity_date: string;
    sanctionlist_source: string;
    individual?: {
        primary_name?: string | null;
        primary_name2?: string | null;
        function?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        suffix?: string | null;
        national_code?: string | null;
        passport_code?: string | null;
        other_code?: string | null;
        birthdate?: Array<string>;
        birthplace?: string | null;
        picture_path: string;
        gender: string;
    };
    addresses?: Array<{
        address: string;
        city: string;
        state_province?: string | null;
        country_code: string;
        country_label: string;
        postal_code: string;
    }>;
    web_sources: Array<{
        source_id: string;
        url: string;
    }>;
    category: string;
    sub_category: CoCaEntitySubCategoryType;
};

export enum EntityRelationType {
    BeneficialOwner = 'BO',
    Director = 'Director',
    Shareholder = 'Shareholder',
    Subsidiary = 'Subsidiary',
    Manager = 'Manager',
    Board = 'Board',
    Advisor = 'Advisor',
    Staff = 'Staff',
    Unknown = 'Unknown',
}
