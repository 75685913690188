import React, { FC } from 'react';

interface SemiCircleProgressBarProps {
    progress: number; // Progress value between 0 and 100
}

export const SemiCircleProgressBar: FC<SemiCircleProgressBarProps> = ({
    progress,
}) => {
    const width = 120;
    const halfWidth = width / 2;
    const strokeWidth = 5;
    const radius = width / 2 - strokeWidth;
    const semiPerimeter = Math.PI * radius;
    const offset = semiPerimeter - (progress / 100) * semiPerimeter;

    return (
        <svg width={width} height={width / 2}>
            {/* Background semi-circle */}
            <circle
                cx={halfWidth}
                cy={halfWidth}
                r={radius}
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeDasharray={semiPerimeter}
                strokeDashoffset="0"
                transform={`rotate(-180, ${halfWidth}, ${halfWidth})`}
                className="stroke-neutral-400"
            />
            {/* Foreground progress semi-circle */}
            <circle
                cx={halfWidth}
                cy={halfWidth}
                r={radius}
                fill="transparent"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray={semiPerimeter}
                strokeDashoffset={offset}
                transform={`rotate(-180, ${halfWidth}, ${halfWidth})`}
                style={{
                    transition: 'stroke-dashoffset 0.5s ease',
                }}
                className="stroke-primary-4"
            />
        </svg>
    );
};
