import * as Yup from 'yup';
import { useQuery, UseQueryResult } from 'react-query';
import { targets } from '../../services/nestApiService';

const STEP_STATUSES = ['IDLE', 'FAILED', 'STARTED', 'SUCCEEDED'] as const;

type StepStatus = typeof STEP_STATUSES[number];

const STEP_GROUP_NAMES = [
    'ANALYZING_COMPANIES',
    'ANALYZING_SOCIAL_MEDIA',
    'ANALYZING_PRESS_WEBSITES',
    'GENERATING_NETWORK_GRAPH',
    'GENERATING_REPORT',
] as const;

type StepGroupName = typeof STEP_GROUP_NAMES[number];

const stepGroupSchema = Yup.object({
    name: Yup.mixed<StepGroupName>().oneOf(STEP_GROUP_NAMES.slice()).required(),
    state: Yup.mixed<StepStatus>().oneOf(STEP_STATUSES.slice()).required(),
});

export type StepGroup = Yup.InferType<typeof stepGroupSchema>;

const stepGroupsSchema = Yup.array().of(stepGroupSchema).required();

export const useStepGroupsQuery = (
    caseId: string,
    targetId: string,
): UseQueryResult<ReadonlyArray<StepGroup>> => {
    return useQuery(
        ['case', caseId, 'target', targetId, 'stepGroups'],
        () => targets.getStepGroups(caseId, targetId),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            keepPreviousData: true,
            select: (data): ReadonlyArray<StepGroup> => {
                return stepGroupsSchema.isValidSync(data)
                    ? data
                    : STEP_GROUP_NAMES.map((name) => ({
                          name,
                          state: 'IDLE' as const,
                      }));
            },
            refetchInterval: (data) => {
                if (data === undefined) {
                    return 60_000;
                }

                const allStepsFailedOrSucceeded = data.every(({ state }) =>
                    ['FAILED', 'SUCCEEDED'].includes(state),
                );

                return allStepsFailedOrSucceeded ? false : 60000;
            },
        },
    );
};
