import { FC } from 'react';
import { ExtendedSubmitHandler, FormSchema, FormType } from './form.interface';
import { formConfigMap } from './form-configs';
import { DynamicForm } from './DynamicForm';

export const ShowForm: FC<{
    selectedFormType: FormType;
    onSubmit: ExtendedSubmitHandler<Partial<FormSchema>>;
    onCancel: () => void;
    formValues: Partial<FormSchema>;
    editMode?: boolean;
    editIndex?: number;
}> = (props) => {
    const {
        selectedFormType,
        onSubmit,
        onCancel,
        formValues,
        editMode,
        editIndex,
    } = props;

    if (!selectedFormType || !formConfigMap[selectedFormType]) {
        return null;
    }

    const { formFields, parentKey, isArray } = formConfigMap[selectedFormType];

    let arrayIndex = undefined;
    if (isArray) {
        const arrayValuesLength = ((formValues[parentKey] as unknown[]) ?? [])
            .length;
        arrayIndex = editMode ? editIndex : arrayValuesLength;
    }

    return (
        <DynamicForm
            fields={formFields}
            onSubmit={onSubmit}
            onCancel={onCancel}
            defaultValues={formValues}
            parentKey={parentKey}
            arrayIndex={arrayIndex}
        />
    );
};
