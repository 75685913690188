import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Headline } from '_atoms';
import { SpotlightTarget } from '@atlaskit/onboarding';

interface NavigationBarProps {
    onBack?: () => void;
    onContinue?: () => void;
    isContinueButtonVisible?: boolean;
    isContinueButtonDisabled?: boolean;
    isBackButtonVisible?: boolean;
    isBackButtonDisabled?: boolean;
    title?: string;
    subTitle?: string;
}

export const NavigationBar = (
    props: NavigationBarProps,
): React.ReactElement => {
    const { t } = useTranslation();
    const {
        onBack,
        onContinue,
        isContinueButtonVisible = true,
        isContinueButtonDisabled = false,
        isBackButtonDisabled = false,
        isBackButtonVisible = true,
        title = t('caseNew.caseTitle'),
        subTitle,
    } = props;

    return (
        <div className="z-9 sticky top-0 bg-neutral-250">
            <div className="flex justify-between space-x-6 xl:max-w-max-content mx-auto p-9 relative after:w-full after:absolute after:h-0.5 after:bg-neutral-300 after:left-0 after:bottom-0">
                <div className="space-y-5">
                    <Headline Level="h3" color="dark" weight="bold">
                        {title}
                    </Headline>
                    <Headline Level="h4" color="dark">
                        {subTitle}
                    </Headline>
                </div>
                <div className="flex justify-end items-center space-x-4">
                    <div className="flex justify-end space-x-6">
                        {isBackButtonVisible && (
                            <Button
                                level="primaryGhost"
                                className="w-52"
                                onClick={onBack}
                                disabled={isBackButtonDisabled}
                            >
                                {t('back')}
                            </Button>
                        )}
                        {isContinueButtonVisible && (
                            <SpotlightTarget name="onboardingCaseNewContinue">
                                <Button
                                    disabled={isContinueButtonDisabled}
                                    level="primary"
                                    className="w-52"
                                    onClick={onContinue}
                                >
                                    {t('continue')}
                                </Button>
                            </SpotlightTarget>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
