import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Headline, Paragraph } from '_atoms';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Keyword } from './types';
import { Keywords } from '../RiskAnalysis/Keywords';

type SmallReportCardProps = {
    name: string;
    keywords?: Keyword[];
    borderColor?: string;
    backgroundColor?: string;
    className?: string;
    onClick?: (reportLabel: string, suspicionId?: string) => void;
    accessToken?: string;
    suspicionsCount?: number;
    confirmedSuspicionCount?: number;
    additionalHtmlAttributes?: Record<string, string | undefined>;
    reportLabel: string;
};

export const SmallReportCard: FC<SmallReportCardProps> = ({
    name,
    className,
    backgroundColor = 'bg-neutral-100',
    borderColor = 'border-transparent',
    onClick = noop,
    suspicionsCount = 0,
    confirmedSuspicionCount = 0,
    additionalHtmlAttributes,
    keywords,
    reportLabel,
}) => {
    const { t } = useTranslation();

    return (
        <Card
            statusColor={borderColor}
            backgroundColor={backgroundColor}
            className={classnames('p-4 shadow-md cursor-default', className)}
            onClick={() => onClick(reportLabel)}
            additionalHtmlAttributes={additionalHtmlAttributes}
        >
            <div className="card-title space-y-2">
                <div className={classnames('flex-col items-center')}>
                    <div
                        className={classnames('flex items-baseline space-x-3')}
                    >
                        <Headline Level="h5" weight="bold">
                            {name}
                        </Headline>
                        <Paragraph
                            color="light"
                            size="small"
                            className="font-jost"
                        >
                            {suspicionsCount > 0 &&
                                reportLabel !== 'red_flags' &&
                                `${t('confirmedSuspicionsCount', {
                                    hits: confirmedSuspicionCount,
                                })} / `}
                            {t('reports.resultsCount', {
                                count: suspicionsCount,
                            })}
                        </Paragraph>
                    </div>
                    <Keywords
                        className="mt-2"
                        keywords={keywords}
                        onClick={onClick}
                        reportLabel={reportLabel}
                        t={t}
                    />
                </div>
            </div>
        </Card>
    );
};
