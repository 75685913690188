import { SuspicionArticle } from '_types';
import React, { FC, ReactElement } from 'react';
import { Press } from './Press';
import { SuspicionItemEntityType } from '_enums';
import { SearchEngine } from './SearchEngine';
import { Company } from './Company';
import { SocialMediaPost } from './SocialMediaPost';
import {
    HighlightChunks,
    IndicatorsWithTooltip,
} from '../../../../../components/_atoms/Highlight/types';
import { SharedArticleContentProps } from './types';

interface ArticleContentProps {
    article: SuspicionArticle;
    highlightChunks: HighlightChunks;
    indicators?: IndicatorsWithTooltip[];
}

type ComponentMap = {
    [key in SuspicionItemEntityType]: FC<
        SharedArticleContentProps & { article: any }
    > | null;
};

const componentMap: ComponentMap = {
    [SuspicionItemEntityType.Press]: Press,
    [SuspicionItemEntityType.GoogleResult]: SearchEngine,
    [SuspicionItemEntityType.TargetCompany]: Company,
    [SuspicionItemEntityType.SocialMediaPost]: SocialMediaPost,
    [SuspicionItemEntityType.SocialMediaConnection]: null,
    [SuspicionItemEntityType.Image]: null,
};

export const ArticleContent: FC<ArticleContentProps> = ({
    article,
    highlightChunks,
    indicators,
}): ReactElement | null => {
    const indicatorTooltips = new Map(
        indicators?.map((i) => [i.highlightText, i.tooltipForText]),
    );

    const Component = componentMap[article.entity.type];

    if (!Component) {
        return null;
    }

    return (
        <Component
            article={article.entity.value}
            highlightChunks={highlightChunks}
            tooltips={indicatorTooltips}
        />
    );
};
