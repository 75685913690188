import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface WizardDateInputProps {
    value: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    label?: string;
    errorMsg?: string;
    hintMsg?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
}

export const WizardDateInput = forwardRef<
    HTMLInputElement,
    WizardDateInputProps
>((props, ref) => {
    const { t } = useTranslation();
    const {
        value,
        onChange,
        onBlur,
        label,
        errorMsg,
        required,
        disabled,
        hintMsg,
        ...rest
    } = props;

    const input = (
        <input
            ref={ref}
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            className={classnames(
                'bg-neutral-50 px-4 py-3.5 text-base focus:ring-0 border-neutral-400 focus:border-primary-4 hover:border-primary-4 block w-full rounded-md transition-all border-2',
                disabled && 'opacity-50',
            )}
            disabled={disabled}
            {...rest}
        />
    );
    return (
        <div className="w-full max-w-flow-text-base mx-auto relative">
            {label ? (
                <label>
                    <span className="block text-sm font-bold font-jost text-neutral-500">
                        {t(label)}
                        {required && <span className="text-error-1"> *</span>}
                    </span>
                    {input}
                </label>
            ) : (
                input
            )}

            <span className="text-neutral-500 block text-sm">
                {t(hintMsg ? hintMsg : 'dateOrYear')}
            </span>

            {errorMsg && (
                <div className="text-error-1 text-sm"> {t(errorMsg)}</div>
            )}
        </div>
    );
});

WizardDateInput.displayName = 'WizardDateInput';
