import { Dispatch } from 'react';
import { IFilterFactory } from 'src/interfaces/filter-factory.interface';
import {
    DateRangeFilterFactory,
    SelectFilterFactory,
    OptionsFilterFactory,
    OptionsRangeFilterFactory,
    RelevanceFilterFactory,
} from '../components/_atoms/Filter/factories';
import { SearchState, SearchAction } from '../hooks/useSearch';

// TODO: refactor this and its subcomponents or create new versions later

class FiltersFactoryMapper {
    protected factories: Record<string, IFilterFactory> = {};

    constructor() {
        const dateRangeFilterFactory = new DateRangeFilterFactory();
        const selectFilterFactory = new SelectFilterFactory();
        const optionsFilterFactory = new OptionsFilterFactory();
        const optionsRangeFilterFactory = new OptionsRangeFilterFactory();
        const relevanceFilterFactory = new RelevanceFilterFactory();

        this.factories[dateRangeFilterFactory.type] = dateRangeFilterFactory;
        this.factories[selectFilterFactory.type] = selectFilterFactory;
        this.factories[optionsFilterFactory.type] = optionsFilterFactory;
        this.factories[optionsRangeFilterFactory.type] =
            optionsRangeFilterFactory;
        this.factories[relevanceFilterFactory.type] = relevanceFilterFactory;
    }

    factory = (type: string): IFilterFactory | undefined => {
        if (type) {
            return this.factories[type];
        }
    };
}

class FiltersFactory {
    protected factoryMapper: FiltersFactoryMapper;

    constructor() {
        this.factoryMapper = new FiltersFactoryMapper();
    }

    create(
        item: any,
        searchState: SearchState<any>,
        dispatcher: Dispatch<SearchAction<any>>,
    ) {
        const { type, field } = item;
        const factory = this.factoryMapper.factory(type);
        return factory?.create({ field, item, searchState, dispatcher });
    }
}

export default FiltersFactory;
