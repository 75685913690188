import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';

export type HeadlineLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold' | 'light';
type LineHeightLevel = 'default' | 'small' | 'large';
type ColorLevel =
    | 'default'
    | 'light'
    | 'medium'
    | 'dark'
    | 'white'
    | 'blue'
    | 'inherit';

export interface HeadlineProps
    extends DetailedHTMLProps<
        HTMLAttributes<HTMLHeadingElement>,
        HTMLHeadingElement
    > {
    Level: HeadlineLevel;
    weight?: WeightLevel;
    lineHeight?: LineHeightLevel;
    color?: ColorLevel;
    children: ReactNode;
}

export const Headline: FC<HeadlineProps> = ({
    Level,
    className,
    weight,
    lineHeight,
    color = 'default',
    children,
    ...rest
}: HeadlineProps) => {
    const styles = {
        h1: 'text-h1 font-bold',
        h2: 'text-h2 font-bold',
        h3: 'text-h3 font-bold',
        h4: 'text-h4 font-bold',
        h5: 'text-h5 font-bold',
        h6: 'text-form-label font-bold',
    };

    const textWeight = {
        light: 'font-light',
        normal: 'font-normal',
        medium: 'font-medium',
        semibold: 'font-semibold',
        bold: 'font-bold',
    };

    const textColor = {
        default: 'text-primary-1',
        light: 'text-neutral-400',
        medium: 'text-neutral-500',
        dark: 'text-primary-1',
        white: 'text-font-light',
        blue: 'text-primary-4',
        inherit: 'text-inherit',
    };

    const textLineHeight = {
        default: 'leading-7',
        small: 'leading-6',
        large: 'leading-8',
    };

    return (
        <Level
            className={classnames(
                className,
                'font-jost',
                styles[Level],
                textColor[color],
                weight && textWeight[weight],
                lineHeight && textLineHeight[lineHeight],
            )}
            {...rest}
        >
            {children}
        </Level>
    );
};
