import classnames from 'classnames';
import { FC } from 'react';
import { ColorLevel, Paragraph, WeightLevel } from '_atoms';
import { nonProdDataTestId } from '_utils';

type TableDataProps = {
    text?: string | number;
    textColor?: ColorLevel;
    textWeight?: WeightLevel;
    dataTestId?: string;
    className?: string;
};

export const TableData: FC<TableDataProps> = ({
    text,
    textColor = 'default',
    textWeight = 'normal',
    dataTestId,
    className,
}: TableDataProps) => {
    return (
        <td
            className={classnames(
                // typeof text === 'string' && text.includes('@')
                //     ? 'break-all'
                //     : 'break-word',
                'break-word',
                className,
            )}
            data-testid={nonProdDataTestId(dataTestId)}
        >
            <Paragraph weight={textWeight} color={textColor}>
                {text ?? '-'}
            </Paragraph>
        </td>
    );
};
