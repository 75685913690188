import { FC } from 'react';
import classnames from 'classnames';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Headline, Paragraph, Button } from '_atoms';

type ErrorProps = {
    headline: string;
    message?: string;
    target?: {
        link: string;
        text: string;
    };
    className?: string;
};

export const Error: FC<ErrorProps> = ({
    headline,
    message,
    target,
    className,
}: ErrorProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div
            className={classnames(
                className,
                `bg-neutral-100 shadow-lg rounded-md p-6 z-20 relative`,
            )}
            style={{ marginTop: '20vh' }}
        >
            <div className="row">
                <div className="col-12">
                    <Headline Level="h3">{headline}</Headline>
                </div>
                {message && (
                    <div className="col-12">
                        <Paragraph>{message}</Paragraph>
                    </div>
                )}
            </div>

            <div className="row mt-5">
                <div className="col-12 flex space-x-4 justify-end">
                    {target?.link ? (
                        <Link to={target?.link}>
                            <Button
                                className="w-52"
                                type="submit"
                                level="primary"
                                disabled={false}
                            >
                                {target?.text}
                            </Button>
                        </Link>
                    ) : (
                        <Button
                            className="w-52"
                            type="submit"
                            level="primary"
                            disabled={false}
                            onClick={() => history.goBack()}
                        >
                            {t('back')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
