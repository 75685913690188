import { Card } from '_atoms';
import { ProfileImage } from '_molecules';
import React from 'react';
import { ProfileData } from '../Profile/ProfileData';
import { ProfileData as ProfileDataType } from '../../../services/dataService';

interface ProfileInfoProps {
    targetImages: string[];
    profileData?: ProfileDataType;
}

export const ProfileInfo = (props: ProfileInfoProps): React.ReactElement => {
    const { targetImages, profileData } = props;

    return (
        <>
            <Card className="space-x-5 items-center shadow-md row border-0 profile-pic">
                <ProfileImage images={targetImages} />
            </Card>
            <Card
                statusColor="border-white"
                className="items-center shadow-md row border-0"
            >
                <ProfileData data={profileData} isOverview={true} jobs={[]} />
            </Card>
        </>
    );
};
