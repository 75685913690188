import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { Suspicion } from '_types';
import { suspicions } from '../../services/nestApiService';

export const useSuspicionsQuery = (
    reportLabel?: string,
): UseQueryResult<Suspicion[]> => {
    const { caseId } = useParams<{ caseId: string }>();

    return useQuery(
        ['suspicions', reportLabel].filter(Boolean),
        () =>
            suspicions.list(
                reportLabel,
                reportLabel === 'own_keywords' ? caseId : undefined,
            ),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: !!reportLabel,
        },
    );
};
