import { useQuery, UseQueryResult } from 'react-query';
import { cases } from '../../services/nestApiService';
import { CaseKeyword } from '../../services/dataService';

export const useCaseKeywordsQuery = (
    caseId: string,
): UseQueryResult<CaseKeyword[]> =>
    useQuery(
        ['caseKeywords', caseId],
        async () => cases.getKeywords(caseId).then((r) => r.data),
        {
            refetchOnWindowFocus: false,
        },
    );
