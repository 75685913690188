import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Card,
    Headline,
    LoadingSpinner,
    OverlayWithSpinner,
    Paragraph,
} from '_atoms';
import { ProfileImage } from '_molecules';
import { ProfileData } from '../Profile/ProfileData';
import { ResultsProps } from '../Results';
import { useSummaryReportsQuery } from '_queries';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { SmallReportCard } from './SmallReportCard';
import { formatDateTime, nonProdDataTestId } from '_utils';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';
import { ReactComponent as LoadIcon } from '_assets/icons/loading.svg';
import classNames from 'classnames';
import { resultsRoutes } from '../../../routes';
import { ReportsQuery } from '../RiskAnalysis/ReportCard';
import { getTestIdForReportCard } from '../RiskAnalysis/helpers';
import { getRedFlagKeywords, RedFlagKeyword } from '../RiskAnalysis/Keywords';
import { TargetStatus } from '../../../services/dataService';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { noop } from 'lodash';
import { SearchStatistics } from '../OverviewV2/SearchStatistics';

function getAnalysisIconAndLabel({
    isCompleted,
    hasSuspicions,
}: {
    isCompleted: boolean;
    hasSuspicions: boolean;
}): { icon: typeof LoadIcon; iconClassName: string; label: string } {
    if (!isCompleted) {
        return {
            icon: LoadIcon,
            label: 'overviewAnalysisIncomplete',
            iconClassName: 'text-blue-500',
        };
    }
    if (hasSuspicions) {
        return {
            icon: ExclamationIcon,
            label: 'overviewAnalysisResult',
            iconClassName: 'text-yellow-500',
        };
    }
    return {
        icon: CheckIcon,
        label: 'overviewAnalysisNoResult',
        iconClassName: 'text-green-500',
    };
}

export const Overview: FC<
    ResultsProps & {
        onFinishLoading?: () => void;
    }
> = ({
    targetData,
    redFlags = [],
    targetImages,
    caseData,
    onFinishLoading = noop,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    const { targetId } = useParams<{ targetId: string; caseId: string }>();

    const summaryReports = useSummaryReportsQuery(
        targetId,
        targetData?.status === TargetStatus.HasInitialProfile,
        onFinishLoading,
    );

    const hasSuspicions =
        summaryReports.data?.some(
            (summarySection) => summarySection.suspicionsCount,
        ) || Boolean(redFlags.length);

    const isCompleted =
        targetData?.status === TargetStatus.Completed ||
        targetData?.status === TargetStatus.CompletedWithIrregularities;

    const redirectToReport = (reportLabel: string, suspicionId?: string) => {
        const filters: ReportsQuery = {
            [reportLabel]: {
                expanded: true,
                ...(suspicionId ? { suspicionItemId: suspicionId } : {}),
            },
        };
        const searchParams = new URLSearchParams();
        searchParams.append('filters', JSON.stringify(filters));
        history.push({
            pathname: generatePath(resultsRoutes.riskAnalysis.path, {
                targetId,
                caseId: caseData?.id,
            }),
            search: searchParams.toString(),
        });
    };

    const redFlagKeywords: RedFlagKeyword[] = useMemo(() => {
        return getRedFlagKeywords(redFlags);
    }, [redFlags]);

    const profileData = useMemo(() => {
        const data = targetData?.profile?.data;
        data?.occupation?.splice(10);
        return data;
    }, [targetData?.profile?.data]);

    const isEmptyData = useMemo(() => {
        if (!isCompleted) {
            return false;
        }
        if (!summaryReports.data) {
            return false;
        }
        return (
            summaryReports.data.every((d) => d.suspicionsCount === 0) &&
            redFlagKeywords.length === 0
        );
    }, [isCompleted, redFlagKeywords.length, summaryReports.data]);

    const {
        icon: ReportStatusIcon,
        iconClassName: reportStatusClassName,
        label: analysisLabel,
    } = getAnalysisIconAndLabel({
        isCompleted,
        hasSuspicions,
    });

    if (summaryReports.isLoading && !summaryReports.isFetched) {
        return (
            <div className="flex justify-center w-full z-0">
                <LoadingSpinner message={t('profileLoading')} />
            </div>
        );
    }

    return (
        <DefaultPageLayout
            title={
                targetData
                    ? `${targetData?.firstname} ${targetData?.lastname}`
                    : t('overview')
            }
            subtitle={
                targetData
                    ? `${t('createdAt')} ${formatDateTime(
                          targetData.createdAt,
                      )}`
                    : undefined
            }
        >
            <div className="row">
                <div className="w-1/4 space-y-5 overflow-always-hidden">
                    <Card className="space-x-5 items-center shadow-md row border-0 profile-pic">
                        <ProfileImage images={targetImages} />
                    </Card>
                    <Card
                        statusColor="border-white"
                        className="items-center shadow-md row border-0"
                    >
                        <ProfileData
                            data={profileData}
                            isOverview={true}
                            jobs={[]}
                        />
                    </Card>
                </div>

                <div className="w-3/4 space-y-5">
                    <Card
                        statusColor="border-primary-1"
                        backgroundColor="bg-primary-1"
                        className="py-3 pl-4 pr-4 shadow-md flex justify-between items-center"
                    >
                        <div className="flex items-center">
                            <div className="mr-4">
                                <ReportStatusIcon
                                    className={classNames(
                                        'w-14 h-14',
                                        reportStatusClassName,
                                    )}
                                />
                            </div>
                            <div>
                                <Headline Level="h3" color="white">
                                    {t('overviewAnalysis')}{' '}
                                    <span className="align-super text-sm text-primary-4">
                                        {t('overviewAnalysisBadge')}
                                    </span>
                                </Headline>
                                <Paragraph size="default" color="light">
                                    {t(analysisLabel)}
                                </Paragraph>
                            </div>
                        </div>
                    </Card>
                    <OverlayWithSpinner
                        className="space-y-5 mb-8"
                        showOverlay={summaryReports.isFetching}
                    >
                        {isEmptyData ? (
                            <div className="bg-white rounded p-8">
                                <div className="flex flex-col items-center justify-center gap-2">
                                    <img
                                        src="/magnifier.svg"
                                        className="w-60 mx-auto"
                                    ></img>
                                    <Headline Level="h4">
                                        {t('overviewAnalysisNoResult')}
                                    </Headline>
                                    <p className="mb-8 text-font-dark">
                                        {t('noSuspicionsDescription')}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <>
                                <SmallReportCard
                                    borderColor={
                                        redFlags.length
                                            ? 'border-error-2'
                                            : 'border-success-1'
                                    }
                                    backgroundColor={
                                        redFlags.length
                                            ? 'bg-error-2/30'
                                            : 'bg-neutral-100'
                                    }
                                    keywords={redFlagKeywords}
                                    name={t('reports.redFlags')}
                                    onClick={
                                        redFlags.length
                                            ? redirectToReport
                                            : undefined
                                    }
                                    suspicionsCount={redFlags.length}
                                    additionalHtmlAttributes={{
                                        'data-testid': nonProdDataTestId(
                                            'analysis overview critical issues',
                                        ),
                                    }}
                                    reportLabel="red_flags"
                                />
                                {summaryReports.isSuccess &&
                                    summaryReports.data?.map(
                                        ({
                                            label: reportLabel,
                                            suspicionsCount,
                                            suspicions,
                                            confirmedSuspicionCount,
                                        }) => (
                                            <SmallReportCard
                                                key={reportLabel}
                                                borderColor={
                                                    suspicionsCount > 0
                                                        ? 'border-warning-1'
                                                        : 'border-success-1'
                                                }
                                                backgroundColor={
                                                    suspicionsCount > 0
                                                        ? 'bg-warning-1/30'
                                                        : 'bg-neutral-100'
                                                }
                                                keywords={suspicions.map(
                                                    ({
                                                        id: suspicionItemId,
                                                        name,
                                                        label,
                                                        itemCount,
                                                        involvements,
                                                    }) => ({
                                                        name,
                                                        label,
                                                        suspicionItemId,
                                                        count: itemCount,
                                                        involvements,
                                                    }),
                                                )}
                                                name={t(
                                                    `reports.sections.${reportLabel}`,
                                                )}
                                                onClick={
                                                    suspicions.length
                                                        ? redirectToReport
                                                        : undefined
                                                }
                                                reportLabel={reportLabel}
                                                suspicionsCount={
                                                    suspicionsCount
                                                }
                                                confirmedSuspicionCount={
                                                    confirmedSuspicionCount
                                                }
                                                additionalHtmlAttributes={{
                                                    'data-testid':
                                                        nonProdDataTestId(
                                                            getTestIdForReportCard(
                                                                reportLabel,
                                                            ),
                                                        ),
                                                }}
                                            />
                                        ),
                                    )}
                            </>
                        )}
                    </OverlayWithSpinner>
                    {isEmptyData && <SearchStatistics />}
                </div>
            </div>
        </DefaultPageLayout>
    );
};
