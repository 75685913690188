import React from 'react';
import { CaseType } from '../CaseNew.types';
import { useTranslation } from 'react-i18next';
import { CaseTypeBaseButton } from './CaseTypeBaseButton';
import { BiUserCircle } from 'react-icons/bi';
import { SpotlightTarget } from '@atlaskit/onboarding';

interface CaseTypeButtonsProps {
    caseType?: CaseType;
    onClick?: (caseType: CaseType) => void;
}

export const CaseTypeButtons = (
    props: CaseTypeButtonsProps,
): React.ReactElement => {
    const { t } = useTranslation();
    const { onClick, caseType } = props;

    return (
        <SpotlightTarget name="onboardingCaseNewOptions">
            <div className="grid grid-cols-3 gap-4 mb-8 mt-8">
                <CaseTypeBaseButton
                    onClick={() => onClick?.(CaseType.Essential)}
                    isSelected={caseType === CaseType.Essential}
                    title={t(`caseNew.title.${CaseType.Essential}`)}
                    Icon={BiUserCircle}
                    infoItems={t(`caseNew.optionsInfo.${CaseType.Essential}`, {
                        returnObjects: true,
                    })}
                    sourceUsageInfo={t(
                        `caseNew.sourceUsageInfo.${CaseType.Essential}`,
                        {
                            returnObjects: true,
                        },
                    )}
                    isHidden={caseType && caseType !== CaseType.Essential}
                />
                <CaseTypeBaseButton
                    onClick={() => onClick?.(CaseType.Plus)}
                    isSelected={caseType === CaseType.Plus}
                    title={t(`caseNew.title.${CaseType.Plus}`)}
                    Icon={BiUserCircle}
                    infoItems={t(`caseNew.optionsInfo.${CaseType.Plus}`, {
                        returnObjects: true,
                    })}
                    sourceUsageInfo={t(
                        `caseNew.sourceUsageInfo.${CaseType.Plus}`,
                        {
                            returnObjects: true,
                        },
                    )}
                    isHidden={caseType && caseType !== CaseType.Plus}
                />
                <CaseTypeBaseButton
                    onClick={() => onClick?.(CaseType.Complete)}
                    isSelected={caseType === CaseType.Complete}
                    title={t(`caseNew.title.${CaseType.Complete}`)}
                    Icon={BiUserCircle}
                    infoItems={t(`caseNew.optionsInfo.${CaseType.Complete}`, {
                        returnObjects: true,
                    })}
                    sourceUsageInfo={t(
                        `caseNew.sourceUsageInfo.${CaseType.Complete}`,
                        {
                            returnObjects: true,
                        },
                    )}
                    isHidden={caseType && caseType !== CaseType.Complete}
                />
            </div>
        </SpotlightTarget>
    );
};
