import classNames from 'classnames';
import React from 'react';

interface DividerProps {
    className?: string;
}

export const Divider = (props: DividerProps): React.ReactElement => (
    <div
        className={classNames('bg-neutral-400 w-full h-[1px]', props.className)}
    />
);
