import { FC, FormEvent, useEffect, useState } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button, Input } from '_atoms';
import { nonProdDataTestId } from '_utils';

export type SearchInputProps = {
    onSearchChange: (searchState: { query: string }) => void;
    query: string;
    placeholder?: string;
    className?: string;
};

export const SearchBar: FC<SearchInputProps> = ({
    onSearchChange,
    placeholder,
    query,
    className = '',
}) => {
    const { t } = useTranslation();

    const [internalQuery, setInternalQuery] = useState<string>(query);

    useEffect(() => {
        setInternalQuery(query);
    }, [query]);

    const handleQueryChange = (e: FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setInternalQuery(value);
    };

    const isFilterApplied = internalQuery.length > 0;

    const submit = (e: FormEvent) => {
        e.preventDefault();
        onSearchChange({ query: internalQuery });
    };

    const clearFilters = () => {
        setInternalQuery('');
        onSearchChange({ query: '' });
    };

    return (
        <form className="flex items-center">
            <div className={classnames('flex-1 relative', className)}>
                <Input
                    type="text"
                    placeholder={placeholder || t('filters.search')}
                    value={internalQuery}
                    onChange={handleQueryChange}
                />
                {internalQuery.length > 0 && (
                    <button
                        className={classnames(
                            'absolute h-full z-2 p-3 right-0 cursor-pointer outline-none top-1/2 -translate-y-1/2 text-neutral-500 hover:text-primary-4 disabled:opacity-50',
                            !isFilterApplied && 'pointer-events-none opacity-0',
                        )}
                        title={t('filters.clearFilters')}
                        onClick={clearFilters}
                        disabled={!isFilterApplied}
                        type="button"
                    >
                        <MdClose className="align-middle" />
                    </button>
                )}
            </div>

            <div className="ml-4">
                <Button
                    type="submit"
                    onClick={submit}
                    data-testid={nonProdDataTestId('search_button')}
                    icon={MdSearch}
                />
            </div>
        </form>
    );
};
