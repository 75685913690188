export type ColorLevel =
    | 'default'
    | 'red'
    | 'darkRed'
    | 'green'
    | 'yellow'
    | 'blue'
    | 'white';
export type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';

export const backgroundColor: Record<ColorLevel, string> = {
    default: 'bg-neutral-100',
    green: 'bg-green-200',
    red: 'bg-error-2',
    darkRed: 'bg-error-1',
    yellow: 'bg-warning-1',
    blue: 'bg-primary-4',
    white: 'bg-neutral-100',
};

export const textColor: Record<ColorLevel, string> = {
    default: 'text-neutral-500',
    red: 'text-error-1',
    darkRed: 'text-font-light',
    green: 'text-green-600',
    yellow: 'text-yellow-900',
    blue: 'text-font-light',
    white: 'text-primary-4',
};

export const selBackgroundColor: Record<ColorLevel, string> = {
    default: 'bg-primary-4',
    green: 'bg-green-600',
    red: 'bg-error-1',
    darkRed: 'bg-neutral-100',
    yellow: 'bg-yellow-900',
    blue: 'bg-neutral-100',
    white: 'bg-primary-4',
};

export const selTextColor: Record<ColorLevel, string> = {
    default: 'text-font-light',
    red: 'text-error-2',
    darkRed: 'text-error-1',
    green: 'text-green-200',
    yellow: 'text-warning-1',
    blue: 'text-primary-4',
    white: 'text-font-light',
};

export const checkedColor: Record<ColorLevel, string> = {
    default: 'text-primary-4',
    green: 'text-green-600',
    red: 'text-error-1',
    darkRed: 'text-font-light',
    yellow: 'text-yellow-900',
    blue: 'text-font-light',
    white: 'text-primary-4',
};

export const focusRingColor: Record<ColorLevel, string> = {
    default: 'focus:ring-blue-500',
    red: 'focus:ring-error-1',
    darkRed: 'focus:ring-white',
    green: 'focus:ring-green-600',
    yellow: 'focus:ring-yellow-900',
    blue: 'focus:ring-white',
    white: 'focus:ring-primary-4',
};

export const focusBorderColor: Record<ColorLevel, string> = {
    default: 'focus:border-blue-500',
    red: 'focus:border-error-1',
    darkRed: 'focus:border-white',
    green: 'focus:border-green-600',
    yellow: 'focus:border-yellow-900',
    blue: 'focus:border-white',
    white: 'focus:border-primary-4',
};

export const textWeight: Record<WeightLevel, string> = {
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold',
};
