import { FC } from 'react';
import { SuspicionItemStatus } from '_enums';
import ProblemIcon from '_assets/icons/suspicion-status/problem.svg';
import NoProblemIcon from '_assets/icons/suspicion-status/no_problem.svg';
import PendingIcon from '_assets/icons/suspicion-status/pending.svg';
import WrongTargetIcon from '_assets/icons/suspicion-status/wrong_target.svg';
import classNames from 'classnames';

type SuspicionStatusIconProps = {
    status: SuspicionItemStatus;
    size?: number;
    padding?: number;
};

const SuspicionStatusIconMap: Record<SuspicionItemStatus, string> = {
    problem: ProblemIcon,
    no_problem: NoProblemIcon,
    pending: PendingIcon,
    wrong_target: WrongTargetIcon,
};

/**
 * @TODO: Refactor -> replace with DynamicSVG component
 */
export const SuspicionStatusIcon: FC<SuspicionStatusIconProps> = ({
    status,
    size = 16,
    padding = 1,
}) => {
    const Icon = SuspicionStatusIconMap[status];
    return (
        <div className={classNames(`shadow-md rounded-md p-${padding}`)}>
            <img src={Icon} alt={status} width={size} height={size} />
        </div>
    );
};
