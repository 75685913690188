import { CompanyRelatedEntityType, Entity, EntityRelationType } from '../types';

export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

export const formatShareholderEntityType = (
    _entityType: CompanyRelatedEntityType,
): string => {
    // switch (entityType.kind) {
    //     case 'Individual':
    //         return 'Individual';
    //     case 'LegalEntity':
    //         return `Legal entity (${entityType.legalEntity
    //             .replace(/([A-Z])/g, ' $1')
    //             .trim()})`;
    //     case 'OtherNamed':
    //         return 'Other named';
    //     case 'Other':
    //         return `Other (${entityType.value})`;
    //     case 'Unknown':
    //         return 'Unknown';
    //     default:
    //         return 'Unknown';
    // }
    return 'Unknown';
};

export const canonicalize_name = (name: string): string =>
    name && name.toLowerCase().replace(/\W+/, '');

export const entity_woco_flag_count = (entity: Entity): number => {
    if (entity.type === 'Person') {
        return (
            Number(entity.woco_pep) +
            Number(entity.woco_sanction) +
            Number(entity.woco_enforcement) +
            Number(entity.woco_generic_red_flag)
        );
    }

    if (entity.type === 'Company') {
        if (!entity.woco) {
            return 0;
        }

        return (
            Number(entity.woco_adverse_media) +
            Number(entity.woco_associated_entity) +
            Number(entity.woco_current_pep) +
            Number(entity.woco_enforcement) +
            Number(entity.woco_government_linked) +
            Number(entity.woco_registrations) +
            Number(entity.woco_sanction) +
            Number(entity.woco_soe)
        );
    }

    return 0;
};

export const entity_flag_count = (entity?: Entity): number => {
    if (!entity) {
        return 0;
    }

    const coca_entity_count = entity.coca_entities?.length || 0;

    const woco_flag_count = entity_woco_flag_count(entity);

    return coca_entity_count + woco_flag_count;
};

export const get_entity_relations = (entity: Entity): Set<string> => {
    if (entity.type === 'Person') {
        return new Set(
            entity.memberships
                .map((membership) => membership.company_id)
                .filter(Boolean),
        );
    }

    if (entity.type === 'Company') {
        return new Set(
            [
                ...(entity.bos?.map((bo) => bo.uci || bo.id) || []),
                ...(entity.directors?.map((director) => director.id) || []),
                ...(entity.shareholders?.map(
                    (shareholder) => shareholder.uci || shareholder.id,
                ) || []),
                ...(entity.subsidiaries?.map((subsidiary) => subsidiary.id) ||
                    []),
            ].filter(Boolean),
        );
    }

    return new Set();
};

export type EntityItem = [string, EntityRelationType];
