import { useMutation, UseMutationResult } from 'react-query';
import { suspicions } from '../../services/nestApiService';
import { LinkSuspicionItemParams } from '../../types/SuspicionItem';

export const useLinkSuspicionItemMutation = (): UseMutationResult<
    unknown,
    unknown,
    LinkSuspicionItemParams
> => {
    return useMutation(
        async (payload: LinkSuspicionItemParams) =>
            suspicions.items.link(payload),
        {
            retry: (attempt) => attempt < 3,
            retryDelay: (attempt) => 2000 * Math.pow(2, attempt - 1),
        },
    );
};
