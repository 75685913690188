import React from 'react';
import { ArticlePreviewCard } from './components/ArticlePreviewCard';
import { SocialAccountPostArticle } from '../../../../../services/searchService';
import { formatDateTime } from '_utils';

import { SharedArticlePreviewProps } from './types';

interface SocialMediaPostProps extends SharedArticlePreviewProps {
    article: SocialAccountPostArticle;
}

export const SocialMediaPost = (
    props: SocialMediaPostProps,
): React.ReactElement => {
    const { onClick, onSuspicionClick, isActive, article, suspicionsCount } =
        props;

    return (
        <ArticlePreviewCard
            onClick={onClick}
            isActive={isActive}
            title={article.postProfileName}
            subtitle={formatDateTime(article.postDatePublished) ?? undefined}
            type="social"
            suspicionsCount={suspicionsCount}
            onSuspicionClick={onSuspicionClick}
        >
            <div className="text-sm line-clamp-3">{article.postBody}</div>
        </ArticlePreviewCard>
    );
};
