import { FC, useState } from 'react';
import { MdFemale, MdMale } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Card,
    Checkbox,
    Headline,
    LoadingSpinner,
    Modal,
    Paragraph,
    SourceInfo,
} from '_atoms';
import { ContentType, InfoBlock } from '_molecules';
import { Gender, LegacyTargetCandidateInfoItem } from '@indicium/common';
import { countBy } from 'lodash';
import { TargetCandidateSource } from '_types';
import { TargetPersonSelectionState } from '_enums';
import { TargetCandidateImages } from './TargetCandidateImages';
import classnames from 'classnames';
import {
    PendingCandidateIterator,
    SingleCandidateNavigationAction,
} from './TargetCandidates';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { SpotlightTarget } from '@atlaskit/onboarding';

export type StructuredCandidateProps = {
    id: string;
    name: string;
    jobTitles: string[];
    images: string[];
    info: LegacyTargetCandidateInfoItem[];
    sources?: TargetCandidateSource[];
    status?: TargetPersonSelectionState;
    disabled?: boolean;
    onStateChange: (
        {
            id,
            state,
        }: {
            id: string;
            state?: TargetPersonSelectionState;
        },
        action: SingleCandidateNavigationAction,
    ) => void;
    onGalleryOpen: ({ images }: { images: string[] }) => void;
    score?: number;
    fingerprintScore?: number;
    unifiedScore?: number;
    hardCriteriaScore?: number;
    iterator: PendingCandidateIterator;
    isListView?: boolean;
};

const GenderIcon: FC<{ genderInfo: LegacyTargetCandidateInfoItem | null }> = ({
    genderInfo,
}) => {
    if (!genderInfo) {
        return null;
    }
    const genderValuesCount = countBy(genderInfo.values);
    const [gender] = Object.keys(genderValuesCount);

    if (gender === Gender.female) {
        return <MdFemale />;
    }

    return <MdMale />;
};

const CUT_OFF = 3;

const prepareInfoBlockContent = (
    item: LegacyTargetCandidateInfoItem,
): ContentType[] =>
    item.values
        .map((value) => ({
            value,
            sources: item.sources,
        }))
        .filter((item) => !!item.value);

const CandidateInfoSection: FC<{
    sectionItems: (string | string[])[];
    infoMap: { [key: string]: LegacyTargetCandidateInfoItem };
    className?: string;
    setModalContents: (contents: {
        headline: string;
        content: ContentType[];
    }) => void;
    setShowModal: (show: boolean) => void;
}> = ({ sectionItems, infoMap, className, setModalContents, setShowModal }) => {
    const { t } = useTranslation();

    return (
        <>
            {sectionItems.map((itemKey) => {
                const key = Array.isArray(itemKey) ? itemKey[0] : itemKey;
                const itemClassName = Array.isArray(itemKey) ? itemKey[1] : '';
                const item = infoMap[key] ?? { key, values: [] };
                const needsToBeCutOff = item.values.length > CUT_OFF;

                const headline = t(`infoBlockItem.${key}`);
                const content = prepareInfoBlockContent(item);

                return (
                    <InfoBlock
                        key={key}
                        headline={headline}
                        content={content.slice(0, CUT_OFF)}
                        containerClassName={classnames(
                            className,
                            itemClassName,
                            'p-4 font-jost',
                            needsToBeCutOff &&
                                'cursor-pointer bg-transparent transition hover:bg-primary-4/15 rounded-2xl',
                        )}
                        type={key}
                        hiddenCount={
                            needsToBeCutOff ? content.length - CUT_OFF : 0
                        }
                        onClick={
                            needsToBeCutOff
                                ? () => {
                                      setModalContents({
                                          headline,
                                          content,
                                      });
                                      setShowModal(true);
                                  }
                                : undefined
                        }
                    />
                );
            })}
        </>
    );
};

const CandidateInfoGrid: FC<{
    infoOrder: (string | string[])[][];
    infoMap: { [key: string]: LegacyTargetCandidateInfoItem };
    className?: string;
    setModalContents: (contents: {
        headline: string;
        content: ContentType[];
    }) => void;
    setShowModal: (show: boolean) => void;
}> = ({ infoOrder, ...props }) => {
    return (
        // have cols increase by about 50% each time starting with about 115px width for the 1st column
        <div className="grid grid-cols-[13%_19%_27%_1fr] gap-1 mt-5 -mx-4 overflow-y-auto pr-10">
            {infoOrder.map((sectionItems, index) => (
                <CandidateInfoSection
                    key={index}
                    sectionItems={sectionItems}
                    {...props}
                />
            ))}
        </div>
    );
};

export const StructuredCandidate: FC<StructuredCandidateProps> = ({
    id,
    name,
    jobTitles,
    info,
    images,
    sources,
    status,
    disabled,
    onStateChange,
    onGalleryOpen,
    score,
    fingerprintScore,
    unifiedScore,
    hardCriteriaScore,
    iterator,
    isListView = false,
}) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const [modalContents, setModalContents] =
        useState<{
            headline: string;
            content: ContentType[];
        } | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    const [internalStatus, setInternalStatus] =
        useState<TargetPersonSelectionState | undefined>(status);

    // useEffect(() => {
    //     if (isListView) {
    //         onStateChange({ id, state: internalStatus });
    //         console.log('id', id, internalStatus);
    //     }
    // }, [id, internalStatus, isListView, onStateChange]);

    const handleAction = (action: SingleCandidateNavigationAction) => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);

            if (internalStatus !== undefined) {
                onStateChange({ id, state: internalStatus }, action);
            } else if (action === 'previous') {
                iterator.previous();
            } else {
                iterator.next();
            }
        }, 150);
    };

    const handleStatusUpdate = (
        isSelected: boolean,
        status?: TargetPersonSelectionState,
    ) => {
        setInternalStatus(isSelected ? status : undefined);
        // onStateChange({ id, state: status });
    };

    const infoMap: { [key: string]: LegacyTargetCandidateInfoItem } = {};
    info.filter((info) => info.values.length).forEach(
        (i) => (infoMap[i.key] = i),
    );
    infoMap['jobTitles'] = {
        key: 'jobTitles',
        values: jobTitles,
    };

    if (sources?.includes('icijLeaks')) {
        infoMap['leakSource'] = {
            key: 'leakSource',
            values: infoMap['leakSource'].values.concat(infoMap['url'].values),
        };
    }

    const infoOrder = sources?.includes('icijLeaks')
        ? [['countries', 'companies', 'addresses', 'leakSource']]
        : [
              [
                  'nationality',
                  !('dateRangeOfBirth' in infoMap) && 'dateOfBirth' in infoMap
                      ? 'dateOfBirth'
                      : 'dateRangeOfBirth',
                  'company',
                  'placeOfResidency',
              ],
              ['originCountry', 'phone', 'jobTitles', 'education'],
              ['language', 'email', 'username', 'url'],
          ];

    return (
        <>
            <Card
                className={classnames(
                    'border-2 rounded-2xl p-4 flex space-x-4 relative flex-grow',
                    internalStatus === TargetPersonSelectionState.Confirmed &&
                        'border-primary-4',
                    internalStatus === TargetPersonSelectionState.Ignored &&
                        'border-error-2',
                    isListView && 'max-h-150',
                )}
            >
                <TargetCandidateImages
                    images={images}
                    onClick={() => onGalleryOpen({ images })}
                    className="justify-self-center"
                    sizeOverride={'w-20 h-20'}
                />
                <div className="flex flex-col flex-grow">
                    <div className="flex justify-between space-x-5 items-center pr-10">
                        <div>
                            <Headline
                                Level="h4"
                                className="flex gap-x-1 items-center"
                            >
                                <span className="font-jost">{name}</span>
                                <GenderIcon genderInfo={infoMap.gender} />
                            </Headline>
                            {sources && sources.length > 0 ? (
                                <SourceInfo sources={sources} size="small" />
                            ) : null}
                        </div>
                        <div className="flex justify-end items-center space-x-5">
                            <SpotlightTarget name="onboardingTargetCandidatesExclude">
                                <Checkbox
                                    label={t('excludeCandidate')}
                                    onChange={(isSelected) =>
                                        handleStatusUpdate(
                                            isSelected,
                                            TargetPersonSelectionState.Ignored,
                                        )
                                    }
                                    initialIsSelected={
                                        internalStatus ===
                                        TargetPersonSelectionState.Ignored
                                    }
                                    labelPosition="append"
                                    labelColor="red"
                                    color="red"
                                    disabled={disabled}
                                />
                            </SpotlightTarget>
                            <SpotlightTarget name="onboardingTargetCandidatesInclude">
                                <Checkbox
                                    label={t('includeCandidate')}
                                    onChange={(isSelected) =>
                                        handleStatusUpdate(
                                            isSelected,
                                            TargetPersonSelectionState.Confirmed,
                                        )
                                    }
                                    initialIsSelected={
                                        internalStatus ===
                                        TargetPersonSelectionState.Confirmed
                                    }
                                    labelColor="blue"
                                    labelPosition="append"
                                    disabled={disabled}
                                />
                            </SpotlightTarget>
                        </div>
                    </div>

                    {process.env.REACT_APP_STAGE !== 'production' && (
                        <div className="absolute right-full top-0 w-48 p-2 !mr-2 bg-neutral-100">
                            <div className="text-sm text-neutral-500 leading-5">
                                {`Candidate group score: ${score}`}
                            </div>
                            <div className="text-sm text-neutral-500 leading-5">
                                {`Hard criteria score : ${parseFloat(
                                    (hardCriteriaScore ?? 0).toFixed(5),
                                )}`}
                            </div>
                            <div className="text-sm text-neutral-500 leading-5">
                                {`Unified score: ${parseFloat(
                                    (unifiedScore ?? 0).toFixed(5),
                                )}`}
                            </div>
                            <div className="text-sm text-neutral-500 leading-5">
                                {`Fingerprint score: ${parseFloat(
                                    (fingerprintScore ?? 0).toFixed(5),
                                )}`}
                            </div>
                        </div>
                    )}

                    <CandidateInfoGrid
                        infoOrder={infoOrder}
                        infoMap={infoMap}
                        setModalContents={setModalContents}
                        setShowModal={setShowModal}
                    />

                    <div
                        className={classnames(
                            'z-8 inset-0 absolute bg-neutral-200/70 flex justify-center items-center text-neutral-400 pointer-events-none opacity-0',
                            isLoading && 'opacity-100 pointer-events-auto',
                        )}
                    >
                        <LoadingSpinner />
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    title={modalContents?.headline ?? ''}
                    onClose={() => setShowModal(false)}
                    maxWidth="max-w-4xl"
                    className="text-center"
                >
                    <div className="text-left">
                        {modalContents?.content.map((content, index) => (
                            <Paragraph
                                key={index}
                                className="even:bg-neutral-200 p-2 rounded-md hyphens-auto break-words"
                            >
                                {content.value}
                            </Paragraph>
                        ))}
                    </div>
                    <div className="text-center">
                        <Button
                            level="primaryGhost"
                            onClick={() => setShowModal(false)}
                            className="min-w-48"
                        >
                            {t('closeButton')}
                        </Button>
                    </div>
                </Modal>
            </Card>
            {!isListView && (
                <>
                    {iterator.previousExists && (
                        <div
                            className={classnames(
                                'absolute top-1/2 -translate-y-1/2 translate-x-3 left-0 bg-neutral-100 rounded-full !ml-0',
                                isLoading
                                    ? 'opacity-0 pointer-events-none'
                                    : 'opacity-100',
                            )}
                        >
                            <Button
                                level="primaryGhost"
                                onClick={() => handleAction('previous')}
                                icon={FaAngleLeft}
                                size="small"
                            >
                                {t('previous')}
                            </Button>
                        </div>
                    )}
                    <div
                        className={classnames(
                            'absolute top-1/2 -translate-y-1/2 -translate-x-3 right-0 bg-neutral-100 rounded-full !ml-0',
                            isLoading
                                ? 'opacity-0 pointer-events-none'
                                : 'opacity-100',
                        )}
                    >
                        <Button
                            level="primaryGhost"
                            onClick={() => handleAction('next')}
                            iconIsRight
                            icon={FaAngleRight}
                            size="small"
                        >
                            {t('next')}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
