import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    DynamicSVG,
    Headline,
    Paragraph,
    StyledLink,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { FaRegEnvelope } from 'react-icons/fa6';
import { IoMdArrowBack } from 'react-icons/io';
import { MdOutlineSupport } from 'react-icons/md';
import { routes } from '../../../routes';

export const ContactSupportButton = ({
    type = 'button',
}: {
    type?: 'button' | 'icon';
}): React.ReactElement => {
    const { t } = useTranslation();
    const handleEmailClick = () => {
        const subject = t('somethingWentWrong.email.subject');
        const body = t('somethingWentWrong.email.body', {
            currentUrl: window.location.href,
        });

        const mailtoLink = `mailto:${INDICIUM_SUPPORT_EMAIL}?subject=${encodeURIComponent(
            subject,
        )}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    return type === 'button' ? (
        <Button
            className="min-w-44"
            type="button"
            level="primaryGhost"
            size="small"
            icon={FaRegEnvelope}
            onClick={handleEmailClick}
        >
            {t('somethingWentWrong.sendEmail')}
        </Button>
    ) : (
        <a className="text-sm underline text-primary-4 cursor-pointer hover:text-primary-3 transition-colors">
            <TooltipV2>
                <TooltipTriggerV2>
                    <MdOutlineSupport size={24} onClick={handleEmailClick} />
                </TooltipTriggerV2>
                <TooltipContentV2>
                    {t('somethingWentWrong.tooltip')}
                </TooltipContentV2>
            </TooltipV2>
        </a>
    );
};

const INDICIUM_SUPPORT_EMAIL = 'idasupport@indicium.ag';

export const SomethingWentWrongMessage = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="p-9 w-full relative">
            <StyledLink
                className="absolute font-bold flex items-center space-x-1"
                to={routes.landingPage.path}
                onClick={(e) => {
                    e.preventDefault();
                    window.location.href = routes.landingPage.path;
                }}
            >
                <IoMdArrowBack className="w-6 h-6" />
                <span>{t('somethingWentWrong.backHomeButton')}</span>
            </StyledLink>
            <div className="h-content-height max-w-max-content flex flex-col items-center justify-center mx-auto space-y-4">
                <DynamicSVG src="icons/maintenance" />
                <Headline Level="h3">{t('somethingWentWrong.title')}</Headline>
                <Headline
                    Level="h4"
                    weight="light"
                    color="dark"
                    className="pb-6 border-solid border-b border-neutral-400"
                >
                    {t('somethingWentWrong.subtitle')}
                </Headline>
                <Paragraph size="medium">
                    {t('somethingWentWrong.description')}
                </Paragraph>
                <div className="flex mt-6 gap-4">
                    <ContactSupportButton />
                </div>
            </div>
        </div>
    );
};
