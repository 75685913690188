import { Headline, Stats } from '_atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';

const searchStatistics = [
    {
        end: 22,
        duration: 2,
        countText: 'stats.billion',
        text: 'stats.identities',
    },
    {
        end: 3,
        duration: 1,
        countText: 'stats.billion',
        text: 'stats.phoneNumbers',
    },
    {
        end: 10,
        duration: 1.5,
        countText: 'stats.billion',
        text: 'stats.globalEmails',
    },
    {
        end: 150,
        duration: 3,
        countText: 'stats.million',
        text: 'stats.uniqueNewsArticlesAndSocials',
    },
    {
        end: 85,
        duration: 2.5,
        countText: 'stats.million',
        text: 'stats.onlineNewsSources',
    },
    {
        end: 2.5,
        duration: 1,
        countText: 'stats.million',
        text: 'stats.socialMediaFeeds',
        decimals: 1,
    },
    {
        end: 500,
        duration: 4,
        countText: 'stats.million',
        text: 'stats.globalCompanyEntries',
    },
];

export const SearchStatistics = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div>
            <Headline Level="h4" className="mb-2">
                {t('ourDataSources')}
            </Headline>
            <div className="flex flex-row flex-wrap gap-2 text-center">
                {searchStatistics.map(({ countText, text, ...p }, i) => (
                    <Stats
                        key={i}
                        countText={t(countText)}
                        text={t(text)}
                        {...p}
                    />
                ))}
            </div>
        </div>
    );
};
