import { FC, PropsWithChildren, ReactHTML, ReactNode, useState } from 'react';
import classnames from 'classnames';
import { Paragraph } from '_atoms';
import { useTranslation } from 'react-i18next';

export type CollapsibleSectionProps = PropsWithChildren<{
    tag?: keyof Pick<ReactHTML, 'article' | 'section' | 'div'>;
    initialState?: boolean;
    forceState?: boolean;
    title?: ReactNode;
}>;

export const CollapsibleSection: FC<CollapsibleSectionProps> = ({
    children,
    tag: Tag = 'section',
    initialState = false,
    forceState,
    title = 'Undefined',
}) => {
    const { t } = useTranslation();
    const [state, setState] = useState(initialState);
    const isExpanded = forceState ?? state;
    return (
        <>
            <span
                className="flex flex-row gap-4 items-baseline cursor-pointer"
                onClick={() => setState((prev) => !prev)}
            >
                {title}
                {isExpanded ? (
                    <Paragraph HtmlTag="span" color="blue">
                        {t('hide')}
                    </Paragraph>
                ) : (
                    <Paragraph HtmlTag="span" color="blue">
                        {t('show')}
                    </Paragraph>
                )}
            </span>
            <Tag
                className={classnames(
                    'transition-all overflow-hidden',
                    isExpanded ? 'max-h-[999em]' : 'max-h-0',
                )}
            >
                {children}
            </Tag>
        </>
    );
};
