import { RefObject, useEffect } from 'react';

export const useClickOutside: (
    reference: RefObject<HTMLDivElement | null>,
    onClose: (event: MouseEvent | TouchEvent) => void,
) => void = (reference, onClose) => {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const element = reference?.current;

            if (!element || element.contains(event.target as Node)) {
                return;
            }

            onClose(event);
        };

        document.addEventListener(`mousedown`, listener);
        document.addEventListener(`touchstart`, listener);

        return () => {
            document.removeEventListener(`mousedown`, listener);
            document.removeEventListener(`touchstart`, listener);
        };
    }, [reference, onClose]);
};
