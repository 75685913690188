import { IFilterFactory } from '../../../../interfaces/filter-factory.interface';
import { SearchActionTypes } from '../../../../hooks/useSearch';
import { BaseFilterProps } from '_types';
import { RelevanceFilter } from './RelevanceFilter';

export class RelevanceFilterFactory implements IFilterFactory {
    get type(): string {
        return 'Relevance';
    }

    create({
        field,
        item,
        searchState,
        dispatcher,
    }: BaseFilterProps): JSX.Element {
        const currentFilters = searchState.filters || {};

        const onChange = (value?: string[]): void => {
            dispatcher({
                type: SearchActionTypes.SetFilter,
                filters: {
                    ...currentFilters,
                    [field]: {
                        query: 'terms',
                        type: 'query',
                        value,
                    },
                },
            });
        };

        const isInitiallySelected = currentFilters[field]?.value?.length > 0;

        return (
            <RelevanceFilter
                key={field}
                title={item.title}
                option={item.options[0]}
                onChange={onChange}
                isInitiallySelected={isInitiallySelected}
            />
        );
    }
}
