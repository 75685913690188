import { FC, ReactNode } from 'react';
import classnames from 'classnames';

type ColoredBarProps = {
    children: ReactNode;
    border?: string;
    className?: string;
    onClick?: () => void;
};

export const ColoredBar: FC<ColoredBarProps> = ({
    children,
    className,
    border,
    onClick,
}: ColoredBarProps) => {
    return (
        <div
            className={classnames(
                className,
                'flex justify-between',
                (!className || className.indexOf('rounded-') === -1) &&
                    'rounded-md',
                (!className || className.indexOf('items-') === -1) &&
                    'items-start',
                (!className || className.indexOf('p-') === -1) && 'p-6',
                border ? ['border-l-6', border].join(' ') : '',
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
};
