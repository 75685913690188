import { DateRange, SocialMediaType } from '@indicium/common';
import { formatDate } from '_utils';
import { Education, Job } from '@indicium/common/src/types/Target/TargetTypes';

export const getEducationEntriesFromEducationInput = (
    educationEntries: Education[],
): Education[] =>
    educationEntries.map((education) => ({
        ...education,
        date:
            typeof education.date === 'string'
                ? education.date
                : generateDateRangeString(education.date),
    }));

export const emptyJobEntry = {
    title: '',
    date: '',
    selfEmployed: false,
    company: {
        name: '',
        website: '',
        vatNumber: '',
        commercialRegisterNumber: '',
        country: '',
        city: '',
    },
};

export const getJobEntriesFromJobInput = (jobEntries: Job[]): Job[] =>
    jobEntries.map((job) => ({
        ...emptyJobEntry,
        ...{ company: emptyJobEntry.company },
        ...job,
        date:
            typeof job.date === 'string'
                ? job.date
                : generateDateRangeString(job.date),
    }));

export const generateDateRangeString = (
    date: DateRange | undefined,
): string => {
    if (!date?.start || !date?.end) {
        return '';
    }

    const formattedStart = formatDate(date.start);
    const formattedEnd = formatDate(date.end);

    if (formattedStart === '' || formattedEnd === '') {
        return '';
    }

    return [formattedStart, formattedEnd].join('-');
};

export const getFormDateFromDateRange = (
    dateRange: DateRange | undefined,
    fallbackValue: string,
): string =>
    dateRange
        ? dateRange.start === dateRange.end
            ? formatDate(dateRange.start)
            : dateRange.end.slice(0, 4)
        : fallbackValue;

export const getSocialMediaProfiles = (
    socialMediaProfiles?: { type: SocialMediaType; value: string }[],
): { type: SocialMediaType; value: string }[] => {
    const socMediaProfiles = socialMediaProfiles?.reduce(
        (acc, profile) => ({
            ...acc,
            [profile.type as SocialMediaType]: profile.value,
        }),
        {} as Record<SocialMediaType, string>,
    );
    const fields = Object.keys(SocialMediaType).map((type) => ({
        type: type as SocialMediaType,
        value: '',
    }));

    if (socMediaProfiles) {
        return fields.map((profile) =>
            socMediaProfiles[profile.type]
                ? {
                      type: profile.type,
                      value: socMediaProfiles[profile.type],
                  }
                : profile,
        );
    } else {
        return fields;
    }
};

export const getContact = (
    savedContacts?: Array<{ type: 'phone' | 'email'; value: string }>,
): Array<{ type: string; value: string }> => {
    let contacts = [];

    if (savedContacts) {
        // contacts = savedContacts;
        const phone = savedContacts.find((contact) => contact.type === 'phone');
        contacts.push(
            phone
                ? { type: phone.type, value: phone.value }
                : { type: 'phone', value: '' },
        );

        const emails =
            savedContacts.filter((contact) => contact.type === 'email') || [];
        const emailsPlusEmptyFields =
            emails.length < 3
                ? emails.concat(
                      Array(3 - emails.length).fill({
                          type: 'email',
                          value: '',
                      }),
                  )
                : emails;
        emailsPlusEmptyFields.forEach((email) => {
            contacts.push({ type: email.type, value: email.value });
        });
    } else {
        contacts = [
            { type: 'phone', value: '' },
            { type: 'email', value: '' },
            { type: 'email', value: '' },
            { type: 'email', value: '' },
        ];
    }

    return contacts;
};
