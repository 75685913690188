import React, { FC, useState } from 'react';
import { HiMenuAlt2 } from 'react-icons/hi';
import {
    MdLibraryBooks,
    MdOutlineManageAccounts,
    MdPeopleOutline,
} from 'react-icons/md';
import { routes } from '../../routes';
import { LeftNavigationBar, Navigation } from './LeftNavigationBar';
import { useUserState } from '../../context/User';
import {
    USER_GROUP_ADMINISTRATORS,
    USER_GROUP_SUPER_USERS,
} from '../../services/authenticationService';
import { Maintenance } from './Maintenance';
import { TopNavigationBar } from './TopNavigationBar';
import { ErrorBoundary } from './ErrorBoundary';
import { SomethingWentWrongMessage } from '_organisms';

export const Layout: FC = ({ children }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [{ user, userRoles }] = useUserState();

    const NavigationItems: Navigation = [
        {
            id: '_cases',
            name: 'Cases',
            href: routes.casesList.path,
            icon: MdLibraryBooks,
            condition:
                !userRoles.includes(USER_GROUP_SUPER_USERS) ||
                userRoles.length !== 1,
            spotlightTarget: 'onboardingLeftMenuCases',
        },
        {
            id: '_users',
            name: 'User Administration',
            href: routes.users.path,
            icon: MdPeopleOutline,
            condition: userRoles.includes(USER_GROUP_ADMINISTRATORS),
            spotlightTarget: 'onboardingLeftMenuUsers',
        },
        {
            id: '_admin_customer',
            name: 'Customer Administration',
            href: routes.customerAdmin.path,
            icon: MdOutlineManageAccounts,
            condition: userRoles.includes(USER_GROUP_SUPER_USERS),
        },
    ] as const;

    const availableNavigationItems = NavigationItems.filter(
        ({ condition }) => condition,
    );

    return (
        <div className="flex h-screen bg-neutral-250 print:bg-white overflow-hidden">
            {user && (
                <LeftNavigationBar navigationItems={availableNavigationItems} />
            )}
            <div className="w-full flex flex-col">
                {user && <TopNavigationBar />}
                <div className="flex flex-col flex-1 overflow-hidden">
                    <div className="md:h-0 fixed flex justify-end w-full h-16">
                        <button
                            type="button"
                            className={`${
                                mobileMenuOpen && 'transform translate-x-16'
                            } px-4 text-neutral-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden`}
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <HiMenuAlt2
                                className="w-6 h-6"
                                aria-hidden="true"
                            />
                        </button>
                    </div>

                    {/* Main content */}
                    <div className="flex items-stretch flex-1 overflow-hidden">
                        <ErrorBoundary fallback={<SomethingWentWrongMessage />}>
                            <Maintenance>
                                <main className="flex-1 overflow-y-auto">
                                    {children}
                                </main>
                            </Maintenance>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </div>
    );
};
