import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from '../../../hooks/useClickOutside';
import langmap from 'langmap';
import { fallBackLanguage } from '../../../i18n';
import { getActiveLanguage } from '../../../translations/utils';
import classnames from 'classnames';
import { BiWorld } from 'react-icons/bi';
import { FiChevronDown } from 'react-icons/fi';

type LanguageSwitcherProps = {
    position?: 'bottom' | 'right';
};

export const LanguageSwitcher: FC<LanguageSwitcherProps> = ({
    position = 'bottom',
}) => {
    const { i18n } = useTranslation();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const closeMenu = () => setMenuIsOpen(false);

    const toggleMenu = () => setMenuIsOpen((isOpenState) => !isOpenState);

    useClickOutside(containerRef, closeMenu);

    // This fetches only the language codes of the languages we explicitly provide translations for
    const languageCodes = Object.keys(i18n.store.data);

    const activeLanguage = getActiveLanguage();

    return (
        <div className="w-full flex justify-center relative" ref={containerRef}>
            <button
                className={
                    'text-primary-4 focus:outline-none cursor-pointer hover:text-primary-3 transition-colors font-bold flex items-center gap-1.5'
                }
                onClick={toggleMenu}
            >
                <BiWorld />
                {activeLanguage.slice(0, 2).toUpperCase()}
                <FiChevronDown />
            </button>
            {/* if menu is open, first show active language, then show all other languages */}
            {menuIsOpen && (
                <div
                    className={classnames(
                        'absolute z-10 bg-neutral-100 drop-shadow-card rounded-md py-1 overflow-auto focus:outline-none text-base',
                        position == 'right' &&
                            'ml-2 -top-3 left-full w-full max-h-60 min-w-48',
                        position == 'bottom' && 'mt-3 top-full min-w-32',
                    )}
                >
                    <ul>
                        <li className="relative bg-primary-4/15">
                            <button
                                className="cursor-default w-full text-left py-2 px-3 focus:outline-none"
                                onClick={() => {
                                    closeMenu();
                                }}
                            >
                                {langmap[activeLanguage]?.nativeName ??
                                    langmap[fallBackLanguage]?.nativeName}
                            </button>
                        </li>
                        {languageCodes
                            .filter(
                                (languageCode) =>
                                    languageCode !== activeLanguage,
                            )
                            .map((languageCode) => (
                                <li
                                    key={languageCode}
                                    className="cursor-pointer relative hover:bg-primary-4/30 transition-all"
                                >
                                    <button
                                        className="w-full text-left py-2 px-3 focus:outline-none"
                                        onClick={() => {
                                            i18n.changeLanguage(languageCode);
                                            localStorage.setItem(
                                                'i18nextLng',
                                                languageCode,
                                            );
                                            closeMenu();
                                        }}
                                    >
                                        {langmap[languageCode]?.nativeName ??
                                            langmap[fallBackLanguage]
                                                ?.nativeName}
                                    </button>
                                </li>
                            ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
