import React from 'react';
import { Paragraph } from '_atoms';
import { useTranslation } from 'react-i18next';
import { RedFlagItem } from '_types';
import { localizeDate } from '../../../../translations/utils';

interface RedFlagCompanyRoleProps {
    role: NonNullable<RedFlagItem['roles']>[number];
}
export const RedFlagCompanyRole = (
    props: RedFlagCompanyRoleProps,
): React.ReactElement => {
    const { role } = props;
    const { t } = useTranslation();

    return (
        <>
            <span className="block">
                {role.value.role || t('redFlags.unknownRole')}
            </span>
            <Paragraph size="small" color="light" HtmlTag="span">
                {role.value.from
                    ? `${localizeDate(role.value.from)} - ${
                          role.value.to
                              ? localizeDate(role.value.to)
                              : t('redFlags.positionIsCurrent')
                      }`
                    : t('redFlags.unknownTimeframe')}
            </Paragraph>
        </>
    );
};
