import { freqMap } from '../freqMap';
import { normalize_values_average } from './utils';

type Fingerprint = number[];

const position_weights = freqMap.freq.map((count) => {
    // Add smoothing (e.g., +1) to prevent division by zero and overly-large weights
    const smoothed_count = count + 1;

    return Math.log(freqMap.total_terms / smoothed_count);
});

export const fp_similarity_with_skew = (
    vector_a: number[],
    vector_b: number[],
    freq_map: FpFrequencyMap,
): number => {
    let similarity = 0;

    const common_positions = vector_a.filter((pos) => vector_b.includes(pos));

    for (const position of common_positions) {
        const freq = freq_map.get(position) ?? 1;

        similarity += freq * position_weights[position];
    }

    const max_possible_similarity =
        vector_a.reduce((sum, pos) => sum + position_weights[pos], 0) +
        vector_b.reduce((sum, pos) => sum + position_weights[pos], 0);

    return similarity / (max_possible_similarity / 2);
};

type FpFrequencyMap = Map<number, number>;

export const fp_frequency_map = (fps: number[][]): FpFrequencyMap => {
    if (!fps.length) {
        return new Map();
    }

    const n = fps.length;
    const n_coeff = 1 / n;

    const pos_freqs: FpFrequencyMap = new Map();

    fps.flat().forEach((pos) =>
        pos_freqs.set(pos, (pos_freqs.get(pos) || 0) + n_coeff),
    );

    return pos_freqs;
};

const calc_fp_similarities = (
    reference_fingerprint: number[],
    similarity_fingerprints: number[][] = [],
    freq_map: FpFrequencyMap,
) =>
    similarity_fingerprints.map((fingerprint) =>
        fp_similarity_with_skew(reference_fingerprint, fingerprint, freq_map),
    );

export function calculateFingerprintScoreV1(
    referenceFingerprint: Fingerprint,
    positiveFingerprints: Fingerprint[] = [],
    negativeFingerprints: Fingerprint[] = [],
): number {
    const posFreqs = fp_frequency_map(positiveFingerprints);
    const negFreqs = fp_frequency_map(negativeFingerprints);

    const positiveSimilarities = calc_fp_similarities(
        referenceFingerprint,
        positiveFingerprints,
        posFreqs,
    );

    const negativeSimilarities = calc_fp_similarities(
        referenceFingerprint,
        negativeFingerprints,
        negFreqs,
    );

    const positive_score = normalize_values_average(positiveSimilarities);
    const negative_score = normalize_values_average(negativeSimilarities);

    return (positive_score - negative_score) / 2 + 0.5;
}
