import { EntitySuspicion } from '_types';
import { hasFlag } from 'country-flag-icons';
import flagIcons, { FlagComponent } from 'country-flag-icons/react/1x1';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomData, Highlight, Paragraph } from '_atoms';
import {
    BaseCard,
    FinancialInfo,
    OwnersAndManagers,
    Publications,
    SubsidiaryCompany,
} from '_organisms';
import { RegisterDataArticle } from 'src/services/searchService';
import { Tabs } from '_molecules';
import classnames from 'classnames';
import { getCompanyStatusClass } from '_utils';
import { Tab } from '@headlessui/react';
import { BasicData } from './CompanyCardTabs/BasicData';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';
import { CaseData, Target } from '../../../services/dataService';
import { RoleType } from '../../../features/dashboard/Registerdaten/RoleType';

interface CompanyCardProps extends HTMLAttributes<HTMLDivElement> {
    data: RegisterDataArticle;
    query?: string;
    hideArticleType?: boolean;
    action?: JSX.Element;
    score?: number;
    status?: string;
    suspicionItemId?: string;
    targetData?: Target;
    caseData?: CaseData;
    suspicionItems?: Array<EntitySuspicion>;
    initiallyExpanded?: boolean;
}

const getFlag = (country: string): JSX.Element => {
    const flags: Record<string, FlagComponent> = flagIcons;
    const CountryFlag = hasFlag(country) ? flags[country] : null;

    if (CountryFlag) {
        return <CountryFlag className="w-8 h-8 rounded-full" />;
    }

    return (
        <div className="pt-1 pl-1 inline-block text-center rounded-full">
            {country}
        </div>
    );
};

export const CompanyCard: FC<CompanyCardProps> = ({
    data,
    hideArticleType = false,
    targetData,
    caseData,
    suspicionItems,
    initiallyExpanded,
    ...props
}) => {
    const { t } = useTranslation();

    const companyTabs: Record<string, string> = t('companyCardTabs', {
        returnObjects: true,
    });

    const highlightChunks = buildHighlightChunks({
        target: targetData,
        caseKeywords: caseData?.keywords,
    });

    const highlight = {
        targetPerson: highlightChunks.targetPerson,
        caseKeywords: highlightChunks.caseKeywords,
    };

    const companyCardTabs = [
        {
            tabTitle: companyTabs.basicData,
            component: (
                <BasicData
                    data={data}
                    highlight={highlight}
                    initiallyExpanded={initiallyExpanded}
                />
            ),
            condition: true,
        },
        {
            tabTitle: companyTabs.subsidiaryCompany,
            component: (
                <SubsidiaryCompany
                    data={data.companySubsidiaries}
                    highlight={highlight}
                />
            ),
            condition: !!data?.companySubsidiaries?.length,
        },
        {
            tabTitle: companyTabs.ownersAndManagers,
            component: <OwnersAndManagers data={data} highlight={highlight} />,
            condition:
                data.companyManagers?.length > 0 ||
                data.companyShareowners?.length > 0,
        },
        {
            tabTitle: companyTabs.financialInformation,
            component: (
                <FinancialInfo
                    data={{
                        companyOperatingRevenue: data.companyOperatingRevenue,
                        companyGrossProfit: data.companyGrossProfit,
                        companyEmployeesData: data.companyEmployeesData,
                    }}
                    highlight={highlight}
                />
            ),
            condition: true,
        },
        {
            tabTitle: companyTabs.publications,
            component: <Publications data={data} />,
            condition: data?.companyPublications?.length > 0,
        },
    ];

    const companyStatus: Record<string, string> = t('companyStatus', {
        returnObjects: true,
    });

    const companyCEOs = data.companyManagers?.length
        ? data.companyManagers.filter(Boolean).map((manager) => {
              if (
                  manager.role?.role.toLowerCase() === 'chief executive officer'
              ) {
                  return manager.name;
              }
          })
        : [];

    const uniqueCompanyCEOs = [
        ...new Set(companyCEOs.filter((ceo): ceo is string => !!ceo)),
    ];

    const tag = data.companyTargetRoles.some(
        (role) =>
            role.roleType &&
            [RoleType.Manager, RoleType.Shareholder].includes(
                role.roleType as RoleType,
            ),
    )
        ? {
              label: t('commercialRegister.relevantLabel'),
              tooltip: t('commercialRegister.relevantTooltip'),
              color: 'blue',
          }
        : {
              label: t('commercialRegister.nonRelevantLabel'),
              tooltip: t('commercialRegister.nonRelevantTooltip'),
              color: 'green',
          };

    return (
        <BaseCard
            entityType="targetcompany"
            item={data}
            title={data.companyName}
            titleLink={data.companyWebsites?.[0]}
            subtitle={data.companyNationalLegalForm?.[0]}
            hideArticleType={hideArticleType}
            targetData={targetData}
            highlight={highlight}
            suspicionItems={suspicionItems}
            tag={tag}
            {...props}
        >
            <div>
                <div className="grid grid-cols-4 px-6 pb-2">
                    <>
                        {data.companyAddresses?.map((address, index) => (
                            <div
                                key={[
                                    address.country,
                                    address.city,
                                    address.zip,
                                    index,
                                ].join()}
                            >
                                <span className="w-6 inline-block">
                                    {getFlag(address.country)}
                                </span>
                                <div className="inline-block ml-5 mt-1 align-top">
                                    {address.city}
                                </div>
                            </div>
                        ))}
                    </>

                    <div className="w-full col-span-2">
                        {uniqueCompanyCEOs.length ? (
                            <CustomData
                                headline={t('mdCeo')}
                                width="w-full"
                                className="px-0"
                            >
                                <div className="grid grid-cols-2 gap-1 w-full">
                                    {uniqueCompanyCEOs
                                        .sort((a, b) => a.length - b.length)
                                        .map((ceo, index) => (
                                            <Paragraph color="dark" key={index}>
                                                <Highlight
                                                    searchWords={highlight}
                                                    textToHighlight={ceo}
                                                />
                                            </Paragraph>
                                        ))}
                                </div>
                            </CustomData>
                        ) : null}
                    </div>

                    <div className="flex justify-end px-2">
                        <div className="flex flex-col gap-2">
                            {Array.isArray(data.companyStatus) ? (
                                data.companyStatus.map((status, index) => (
                                    <span
                                        key={[data.id, status, index].join()}
                                        className={classnames(
                                            'break-normal font-bold text-md',
                                            status
                                                ? getCompanyStatusClass(status)
                                                : null,
                                        )}
                                    >
                                        {status
                                            ? companyStatus[
                                                  status.toLowerCase()
                                              ] || status
                                            : companyStatus.default}
                                    </span>
                                ))
                            ) : (
                                <span
                                    className={classnames(
                                        'break-normal font-bold text-md',
                                        data.companyStatus
                                            ? getCompanyStatusClass(
                                                  data.companyStatus,
                                              )
                                            : null,
                                    )}
                                >
                                    {data.companyStatus
                                        ? companyStatus[
                                              data.companyStatus.toLowerCase()
                                          ] || data.companyStatus
                                        : companyStatus.default}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <Tabs
                    tabTitles={companyCardTabs
                        .filter((tab) => tab.condition)
                        .map((tab) => tab.tabTitle)}
                >
                    <Tab.Panels>
                        {companyCardTabs.map((tab, i) => (
                            <Tab.Panel key={i}>{tab.component}</Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tabs>
            </div>
        </BaseCard>
    );
};
