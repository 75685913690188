import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Checkbox, Headline, Paragraph } from '_atoms';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { GoogleCandidate, GoogleCandidateStatus } from '_types';
import { TargetGoogleCandidateItem } from './TargetGoogleCandidateItem';
import { TargetPersonSelectionState } from '_enums';
import { getSelectionStateBorderClass, nonProdDataTestId } from '_utils';

type TargetGoogleCandidateGroupProps = {
    groupId: string;
    name: string;
    organizations: string[];
    candidates: GoogleCandidate[];
    groupSelectionState: GoogleCandidateStatus[];
    updateSelectionState: (
        groupId: string,
        selectionState: GoogleCandidateStatus[],
    ) => void;
    disabled?: boolean;
    score?: number;
    fingerprintScore?: number;
    unifiedScore?: number;
    hardCriteriaScore?: number;
};

export const TargetGoogleCandidateGroup: FC<TargetGoogleCandidateGroupProps> =
    ({
        groupId,
        name,
        candidates = [],
        groupSelectionState,
        updateSelectionState,
        organizations,
        score,
        fingerprintScore,
        unifiedScore,
        hardCriteriaScore,
        disabled = false,
    }) => {
        const { t } = useTranslation();
        const [isDrawerOpen, setIsDrawerOpen] = useState(true);

        const getGroupSelectionState = useCallback(() => {
            const isConfirmed = groupSelectionState.every(
                (candidate) =>
                    candidate.status === TargetPersonSelectionState.Confirmed,
            );
            const isIgnored = !isConfirmed
                ? groupSelectionState.every(
                      (candidate) =>
                          candidate.status ===
                          TargetPersonSelectionState.Ignored,
                  )
                : false;
            return isConfirmed
                ? TargetPersonSelectionState.Confirmed
                : isIgnored
                ? TargetPersonSelectionState.Ignored
                : undefined;
        }, [groupSelectionState]);

        const handleGroupStatusUpdate = useCallback(
            (isSelected: boolean, status?: TargetPersonSelectionState) => {
                const selectionState = isSelected ? status : undefined;
                updateSelectionState(
                    groupId,
                    groupSelectionState.map(({ id, imageSelectionStatus }) => ({
                        id,
                        status: selectionState,
                        imageSelectionStatus,
                    })),
                );
            },
            [updateSelectionState, groupId, groupSelectionState],
        );

        return (
            <Card
                statusColor={getSelectionStateBorderClass(
                    getGroupSelectionState(),
                )}
                className="border-2 rounded-2xl"
                additionalHtmlAttributes={{
                    'data-testid': nonProdDataTestId('target candidate card'),
                }}
            >
                <div className="flex justify-between items-center p-6">
                    <div className="space-y-1">
                        <Headline Level="h4" weight="bold" color="dark">
                            {name}
                        </Headline>

                        {process.env.REACT_APP_STAGE !== 'production' && (
                            <>
                                <div className="text-sm text-neutral-500 leading-5">
                                    {`Candidate group score: ${score}`}
                                </div>
                                <div className="text-sm text-neutral-500 leading-5">
                                    {`Hard criteria score: ${parseFloat(
                                        (hardCriteriaScore ?? 0).toFixed(5),
                                    )}`}
                                </div>
                                <div className="text-sm text-neutral-500 leading-5">
                                    {`Unified score: ${parseFloat(
                                        (unifiedScore ?? 0).toFixed(5),
                                    )}`}
                                </div>
                                <div className="text-sm text-neutral-500 leading-5">
                                    {`Fingerprint score: ${parseFloat(
                                        (fingerprintScore ?? 0).toFixed(5),
                                    )}`}
                                </div>
                            </>
                        )}

                        <Paragraph
                            size="default"
                            weight="normal"
                            color="light"
                            className="flex gap-1"
                        >
                            <span>
                                {[
                                    t('googleCandidates.results'),
                                    candidates.length,
                                ].join(': ')}
                            </span>
                            {candidates.length > 1 ? (
                                <span>
                                    {[
                                        t('googleCandidates.groupTopics'),
                                        organizations.length,
                                    ].join(': ')}
                                </span>
                            ) : null}
                        </Paragraph>
                    </div>
                    <div className="flex flex-col justify-between items-start">
                        <Checkbox
                            label={t('googleCandidates.selectGroup')}
                            labelPosition="append"
                            onChange={(isSelected) =>
                                handleGroupStatusUpdate(
                                    isSelected,
                                    TargetPersonSelectionState.Confirmed,
                                )
                            }
                            initialIsSelected={
                                getGroupSelectionState() ===
                                TargetPersonSelectionState.Confirmed
                            }
                            disabled={disabled}
                            additionalHtmlAttributes={{
                                'data-testid': nonProdDataTestId(
                                    'target candidate include checkbox',
                                ),
                            }}
                        />
                        <Checkbox
                            label={t('googleCandidates.excludeGroup')}
                            labelPosition="append"
                            onChange={(isSelected) =>
                                handleGroupStatusUpdate(
                                    isSelected,
                                    TargetPersonSelectionState.Ignored,
                                )
                            }
                            initialIsSelected={
                                getGroupSelectionState() ===
                                TargetPersonSelectionState.Ignored
                            }
                            className="mt-1"
                            color="red"
                            disabled={disabled}
                            additionalHtmlAttributes={{
                                'data-testid': nonProdDataTestId(
                                    'target candidate exclude checkbox',
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className="row px-6 pb-6 divide-x">
                    <div className="col-3">
                        {organizations.length > 0 && candidates.length > 1 && (
                            <div>
                                <Paragraph
                                    size="small"
                                    weight="bold"
                                    color="light"
                                    className="uppercase"
                                >
                                    {t('googleCandidates.groupTopics')}
                                </Paragraph>
                                <ul className="break-all space-y-2 text-sm mt-2">
                                    {organizations.map(
                                        (organization, index) => (
                                            <li key={organization + index}>
                                                {organization}
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="col-9">
                        <div className="w-full">
                            <Paragraph
                                size="small"
                                weight="bold"
                                color="light"
                                className="uppercase tracking-wide mb-3"
                            >
                                {t('googleCandidates.groupTitle')}
                            </Paragraph>
                        </div>
                        {candidates.length > 0 && (
                            <>
                                <div className="flex flex-col gap-3">
                                    {(isDrawerOpen
                                        ? candidates
                                        : candidates.slice(0, 2)
                                    ).map((candidate) => (
                                        <TargetGoogleCandidateItem
                                            key={candidate.id}
                                            candidate={candidate}
                                            candidateStatus={groupSelectionState.find(
                                                (gc) => gc.id === candidate.id,
                                            )}
                                            handleCandidateSelect={(state) => {
                                                const candidateStatusIndex =
                                                    groupSelectionState.findIndex(
                                                        (gc) =>
                                                            gc.id ===
                                                            candidate.id,
                                                    );
                                                groupSelectionState.splice(
                                                    candidateStatusIndex,
                                                    1,
                                                    state,
                                                );
                                                updateSelectionState(
                                                    groupId,
                                                    groupSelectionState,
                                                );
                                            }}
                                            disabled={disabled}
                                        />
                                    ))}
                                </div>
                                {candidates.length > 2 && (
                                    <button
                                        className="w-full text-primary-4 py-3 text-sm focus:outline-none"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsDrawerOpen(!isDrawerOpen);
                                        }}
                                    >
                                        {isDrawerOpen ? (
                                            <span className="flex justify-center items-center gap-2">
                                                <BsChevronUp />
                                                {t(
                                                    'googleCandidates.lessDetails',
                                                )}
                                                <BsChevronUp />
                                            </span>
                                        ) : (
                                            <span className="flex justify-center items-center gap-2">
                                                <BsChevronDown />
                                                {t(
                                                    'googleCandidates.moreDetails',
                                                )}
                                                <BsChevronDown />
                                            </span>
                                        )}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Card>
        );
    };
