import { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

export type StyledLinkProps = Partial<LinkProps> & { children?: ReactNode };

const hasToProp = (props: StyledLinkProps): props is LinkProps => {
    return 'to' in props;
};

const styledLinkClassName =
    'cursor-pointer text-primary-4 hover:text-primary-3 transition disabled:opacity-50';

export const StyledLink: FC<StyledLinkProps> = ({
    className,
    children,
    ...props
}) =>
    hasToProp(props) ? (
        <Link className={classnames(styledLinkClassName, className)} {...props}>
            {children}
        </Link>
    ) : (
        <span className={classnames(styledLinkClassName, className)} {...props}>
            {children}
        </span>
    );
