import * as Sentry from '@sentry/react';

Sentry.init({
    enabled:
        process.env.REACT_APP_STAGE === 'staging' ||
        process.env.REACT_APP_STAGE === 'production',
    environment: process.env.REACT_APP_STAGE,
    dsn: 'https://696d041e2cc69edca654aa17f96f2a52@o4507329237876736.ingest.de.sentry.io/4507329252360272',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
