import { FC } from 'react';
import { Headline } from '_atoms';
import classnames from 'classnames';
import { PageLayoutProps } from './DefaultPageLayout';
import { SiteWideAnnouncements } from './SiteWideAnnouncements';

export const ImmersivePageLayout: FC<
    PageLayoutProps & { fullScreen?: boolean }
> = (props) => {
    const { title, subtitle, className, children, fullScreen } = props;

    return (
        <>
            <SiteWideAnnouncements addWrapper />
            <div
                className={classnames(
                    'relative print:px-0 print:py-4 h-content-height flex flex-col overflow-hidden',
                    !fullScreen && 'p-9 xl:max-w-max-content container-fluid',
                    className,
                )}
            >
                {!fullScreen && (
                    <div className={classnames('pb-8 shrink-0 grow-0')}>
                        <Headline Level="h3">{title}</Headline>
                        {subtitle ? (
                            <span className="text-base text-neutral-500">
                                {subtitle}
                            </span>
                        ) : null}
                    </div>
                )}
                <div className="grow overflow-y-auto">{children}</div>
            </div>
        </>
    );
};
