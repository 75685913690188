import React, { FC, useMemo } from 'react';
import { Badge, Headline, LoadingSpinner, Paragraph } from '_atoms';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HourglassIcon } from '_assets/icons/overview-report-status/hourglass.svg';
import { ReactComponent as CheckMarkIcon } from '_assets/icons/overview-report-status/checkmark.svg';
import { ReactComponent as WarningIcon } from '_assets/icons/overview-report-status/warning-exclamation.svg';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';
import { ReactComponent as CircleIcon } from '_assets/icons/circle.svg';
import { SemiCircleProgressBar } from '../../../components/_atoms/ProgressBar/SemiCircleProgressBar';
import classnames from 'classnames';
import { StepGroup } from '../../../hooks/queries/useStepGroupsQuery';

const ReportStatusIcon: FC<ReportStatusDisplayProps & { classname: string }> = (
    props,
) => {
    if (props.status === 'inProgress') {
        return (
            <HourglassIcon
                className={classnames('animate-spin-and-stay', props.classname)}
            />
        );
    }

    if (props.hasSuspicions) {
        return (
            <WarningIcon
                className={classnames('animate-wiggle', props.classname)}
            />
        );
    }

    return (
        <CheckMarkIcon
            className={classnames('animate-zoom-in-out', props.classname)}
        />
    );
};

const translatedStatus = (
    status: ReportStatus,
    hasSuspicions: boolean,
    reportGenerationOnly: boolean,
): string => {
    if (status === 'inProgress') {
        if (reportGenerationOnly) {
            return 'reportStatusOverview.inProgressGenerationOnly';
        }

        return 'reportStatusOverview.inProgress';
    }

    if (status === 'completedWithIrregularities') {
        if (!hasSuspicions) {
            return 'reportStatusOverview.withIrregularitiesWithoutSuspicions';
        }

        return 'reportStatusOverview.withIrregularitiesWithSuspicions';
    }

    if (!hasSuspicions) {
        return 'reportStatusOverview.completedWithoutSuspicions';
    }

    return 'reportStatusOverview.completedWithSuspicions';
};

const getStepCompletionStatus = (status: ReportStatus): string => {
    if (status === 'inProgress') {
        return 'reportStatusOverview.stepsComplete';
    }

    return 'reportStatusOverview.stepsFailed';
};

const getBorderColor = (status: ReportStatus, hasSuspicions: boolean) => {
    if (status === 'inProgress') {
        return 'border-primary-4';
    }

    if (hasSuspicions) {
        return 'border-warning-1';
    }

    return 'border-success-1';
};

const getBackgroundColor = (
    status: ReportStatus,
    hasSuspicions: boolean,
): string => {
    if (status === 'inProgress') {
        return 'bg-primary-4';
    }

    if (hasSuspicions) {
        return 'bg-warning-1';
    }

    return 'bg-success-1';
};

const iconSize = 'h-4 w-4';

type ReportStatus = 'inProgress' | 'completed' | 'completedWithIrregularities';

interface ReportStatusDisplayProps {
    status: ReportStatus;
    hasSuspicions: boolean;
    stepGroups: ReadonlyArray<StepGroup>;
}

export const ReportStatusDisplay: FC<ReportStatusDisplayProps> = (
    props: ReportStatusDisplayProps,
) => {
    const { stepGroups, hasSuspicions, status } = props;
    const { t } = useTranslation();

    const failedStepGroupCount = stepGroups.filter(
        (stepGroup) => stepGroup.state === 'FAILED',
    ).length;

    const succeededOrFailedStepGroupCount = stepGroups.filter((stepGroup) =>
        ['FAILED', 'SUCCEEDED'].includes(stepGroup.state),
    ).length;

    const progressValue =
        (succeededOrFailedStepGroupCount * 100) / stepGroups.length;

    // TODO what is the point of this memoized variable?
    const reportGenerationOnly = useMemo(() => {
        if (status === 'inProgress') {
            const startedStepGroups = stepGroups.filter(
                (stepGroup) => stepGroup.state === 'STARTED',
            );

            return (
                startedStepGroups.length === 1 &&
                startedStepGroups[0].name === 'GENERATING_REPORT'
            );
        }
        return false;
    }, [stepGroups, status]);

    const translationStatusKey = translatedStatus(
        status,
        hasSuspicions,
        reportGenerationOnly,
    );

    const borderColor = getBorderColor(status, hasSuspicions);
    const backgroundColor = getBackgroundColor(status, hasSuspicions);

    return (
        <div
            className={classnames(
                'relative flex flex-col gap-2 items-center text-center border rounded-md p-6 pt-12 *:max-w-flow-text-lg',
                borderColor,
                backgroundColor,
                'bg-opacity-10',
            )}
        >
            {status === 'inProgress' && (
                <div
                    className={classnames(
                        'absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full border-b',
                        borderColor,
                    )}
                >
                    <SemiCircleProgressBar progress={progressValue} />
                </div>
            )}
            <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <ReportStatusIcon {...props} classname="h-13 w-13" />
            </div>
            {(status === 'inProgress' || failedStepGroupCount > 0) && (
                <Badge
                    background={status === 'inProgress' ? 'blue' : 'darkGray'}
                    className="flex gap-2"
                >
                    {failedStepGroupCount > 0 && (
                        <div className="text-white">
                            <ExclamationIcon className={iconSize} />
                        </div>
                    )}
                    <Paragraph color="white" weight="bold">
                        {`${
                            status === 'inProgress'
                                ? succeededOrFailedStepGroupCount
                                : failedStepGroupCount
                        } / ${stepGroups.length} ${t(
                            getStepCompletionStatus(status),
                        )}`}
                    </Paragraph>
                </Badge>
            )}
            <Headline Level="h4" color="dark">
                {t(`${translationStatusKey}.header`)}
            </Headline>
            <Paragraph color="light">
                {t(`${translationStatusKey}.subHeader`)}
            </Paragraph>
            <div className="grid grid-cols-3 gap-x-3 gap-y-2 border-t border-neutral-400 mt-5 pt-5 w-full">
                {stepGroups.map((stepGroup) => (
                    <div
                        key={stepGroup.name}
                        className="flex items-center gap-2 text-neutral-500"
                    >
                        <Paragraph color="light" size="small">
                            {t(`reportStatusOverview.steps.${stepGroup.name}`)}
                        </Paragraph>
                        <span>
                            {stepGroup.state === 'STARTED' && (
                                <LoadingSpinner
                                    height={18}
                                    width={18}
                                    className="pl-0 py-0"
                                />
                            )}
                            {stepGroup.state === 'SUCCEEDED' && (
                                <CheckIcon className={iconSize} />
                            )}
                            {stepGroup.state === 'FAILED' && (
                                <ExclamationIcon className={iconSize} />
                            )}
                            {stepGroup.state === 'IDLE' && (
                                <CircleIcon className={iconSize} />
                            )}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};
