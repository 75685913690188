import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '_atoms';
import { CaseData, Target } from '../../../services/dataService';
import { ArticlesBySuspicion } from '../Overview/ArticlesBySuspicion';
import { ReportFilters } from './ReportCard';
import { SummaryReport } from '_types';
import { translateAndSortSuspicions } from './helpers';

type ReportCardProps = {
    reportId: string;
    targetId: string;
    accessToken?: string;
    filters?: ReportFilters;
    className?: string;
    targetData?: Target;
    caseData?: CaseData;
    report: SummaryReport;
    minConfidenceScore?: number;
};

export const ReportCardBySuspicion: FC<ReportCardProps> = ({
    targetId,
    filters,
    accessToken = '',
    targetData,
    caseData,
    report,
}) => {
    const { t } = useTranslation();

    const filteredSuspicions = filters?.suspicionItemId
        ? report.suspicions.filter(
              (suspicion) => suspicion.id === filters?.suspicionItemId,
          )
        : report.suspicions;

    const suspicions = translateAndSortSuspicions(filteredSuspicions, t);

    return (
        <div className="py-4">
            <div className="flex flex-col gap-2">
                {suspicions?.length === 0 && (
                    <Tag className="self-center">{t('noData')}</Tag>
                )}

                {suspicions &&
                    suspicions.length > 0 &&
                    suspicions.map(
                        ({ id, name, label, itemCount, confirmedCount }) => (
                            <ArticlesBySuspicion
                                key={id}
                                id={id}
                                name={name}
                                label={label}
                                targetId={targetId}
                                filters={filters}
                                accessToken={accessToken}
                                targetData={targetData}
                                caseData={caseData}
                                entityCount={itemCount}
                                confirmedCount={confirmedCount}
                            />
                        ),
                    )}
            </div>
        </div>
    );
};
