import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { BiBuilding, BiQuestionMark } from 'react-icons/bi';
import { FaUsers } from 'react-icons/fa';
import {
    RiNewspaperLine,
    RiImage2Line,
    RiFileTextLine,
    RiMoneyEuroCircleLine,
} from 'react-icons/ri';
import { EntityType } from '_types';
import { IconType } from 'react-icons';

const entityIcon: Record<EntityType, IconType> = {
    targetcompany: BiBuilding,
    social: FaUsers,
    webpage: RiFileTextLine,
    press: RiNewspaperLine,
    image: RiImage2Line,
    financial: RiMoneyEuroCircleLine,
};

interface EntityIconProps extends HTMLAttributes<HTMLDivElement> {
    type: EntityType;
    iconColor?: string;
    bgColor?: string;
    width?: string;
    height?: string;
    logoPadding?: string;
    className?: string;
}

export const EntityIcon: FC<EntityIconProps> = ({
    type,
    iconColor = 'text-font-light',
    bgColor = 'bg-primary-4',
    logoPadding = 'p-4',
    className = '',
    ...props
}) => {
    const EntityIcon = type in entityIcon ? entityIcon[type] : BiQuestionMark;
    return (
        <div className={classNames('inline-block', className)} {...props}>
            <div
                className={classNames(
                    'flex justify-center items-center rounded-full',
                    iconColor,
                    bgColor,
                    logoPadding,
                )}
            >
                <EntityIcon />
            </div>
        </div>
    );
};
