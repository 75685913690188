export enum AuthSteps {
    SignIn = 'signIn',
    ResetRequired = 'resetRequired',
    Forgot = 'forgot',
    ResetCode = 'resetCode',
    ConfirmationCode = 'confirmationCode',
}

export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

export enum AuthExceptions {
    NotAuthorizedException = 'NotAuthorizedException',
    PasswordResetRequiredException = 'PasswordResetRequiredException',
    PasswordChallengeException = 'PasswordChallengeException',
    UserNotFoundException = 'UserNotFoundException',
    PasswordExpiredException = 'PasswordExpiredException',
    PasswordEmptyOrMismatchException = 'PasswordEmptyOrMismatchException',
    InvalidPasswordException = 'InvalidPasswordException',
    PasswordResetExpiredCodeException = 'PasswordResetExpiredCodeException',
    LimitExceededException = 'LimitExceededException',
    CodeMismatchException = 'CodeMismatchException',
    InvalidParameterException = 'InvalidParameterException',
}

export type SignInExceptions =
    | AuthExceptions.NotAuthorizedException
    | AuthExceptions.PasswordResetRequiredException
    | AuthExceptions.UserNotFoundException
    | AuthExceptions.PasswordExpiredException;

export type ResetExceptions =
    | AuthExceptions.PasswordEmptyOrMismatchException
    | AuthExceptions.InvalidPasswordException
    | AuthExceptions.NotAuthorizedException;

export type ForgotExceptions =
    | AuthExceptions.PasswordEmptyOrMismatchException
    | AuthExceptions.PasswordResetExpiredCodeException
    | AuthExceptions.LimitExceededException
    | AuthExceptions.CodeMismatchException
    | AuthExceptions.InvalidParameterException
    | AuthExceptions.PasswordChallengeException;
