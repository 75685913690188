type Password = string | null;

interface PasswordValidation {
    password: Password;
    confirmedPassword: Password;
}

export interface PasswordValidationRules {
    minLength: boolean;
    oneNumber: boolean;
    oneSpecialChar: boolean;
    oneUppercase: boolean;
    oneLowercase: boolean;
    passwordMatch: boolean;
    isValid: boolean;
}

export const PASSWORD_MIN_LENGTH = 8;

export const isMinLength = (password: Password, min: number): boolean =>
    password ? password?.length > min : false;

export const isOneNumber = (password: Password): boolean =>
    password ? /.*[0-9].*/.test(password) : false;

export const isOneSpecialChar = (password: Password): boolean =>
    password ? /.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*/.test(password) : false;

export const isOneUppercaseLetter = (password: Password): boolean =>
    password ? /.*[A-Z].*/.test(password) : false;

export const isOneLowerLetter = (password: Password): boolean =>
    password ? /.*[a-z].*/.test(password) : false;

export const hasPasswordMatch = (
    password: Password,
    confirmedPassword: Password,
): boolean =>
    !!(password && confirmedPassword && password === confirmedPassword);

export const usePasswordValidation = ({
    password,
    confirmedPassword,
}: PasswordValidation): PasswordValidationRules => {
    const minLength = isMinLength(password, PASSWORD_MIN_LENGTH);
    const oneNumber = isOneNumber(password);
    const oneSpecialChar = isOneSpecialChar(password);
    const oneUppercase = isOneUppercaseLetter(password);
    const oneLowercase = isOneLowerLetter(password);
    const passwordMatch = hasPasswordMatch(password, confirmedPassword);

    const isValid =
        minLength &&
        oneNumber &&
        oneSpecialChar &&
        oneUppercase &&
        oneLowercase &&
        passwordMatch;

    return {
        minLength,
        oneNumber,
        oneSpecialChar,
        oneUppercase,
        oneLowercase,
        passwordMatch,
        isValid,
    };
};
