import nationalitiesDE from '../../../services/countryCodeService/nationalities/de.json';
import nationalitiesEN from '../../../services/countryCodeService/nationalities/en.json';
import countriesDE from 'i18n-iso-countries/langs/de.json';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import { de, en } from '../../../translations';

export type CountrySelectOption = {
    code: string;
    name: string;
    sortWeight: string;
};

const prioritizeDACH = (input: [string, string][]): CountrySelectOption[] =>
    input
        .map(([code, name]) => {
            let sortWeight;
            switch (code) {
                case 'DE':
                    sortWeight = '0';
                    break;
                case 'AT':
                    sortWeight = '1';
                    break;
                case 'CH':
                    sortWeight = '2';
                    break;
                default:
                    sortWeight = name;
            }
            return { code, name, sortWeight };
        })
        .sort((a, b) => {
            if (a.sortWeight > b.sortWeight) {
                return 1;
            } else if (b.sortWeight > a.sortWeight) {
                return -1;
            } else {
                return 0;
            }
        });

const getLocalizedNationalities = (language: string) => {
    if (language.startsWith('en')) {
        return nationalitiesEN;
    }

    return nationalitiesDE;
};

export const getNationalitySelectionOptions = (
    language: string,
): CountrySelectOption[] => {
    const nationalityList = getLocalizedNationalities(language);

    return prioritizeDACH(Object.entries(nationalityList.nationalities));
};

const getLocalizedCountries = (language: string) => {
    if (language.startsWith('en')) {
        return countriesEN;
    }

    return countriesDE;
};

export const getCountrySelectionOptions = (
    language: string,
): CountrySelectOption[] => {
    const countryList = getLocalizedCountries(language);

    return prioritizeDACH(
        Object.entries(countryList.countries).flatMap(([code, names]): [
            string,
            string,
        ][] => [[code, Array.isArray(names) ? names[0] : names]]),
    );
};
export const getReducedCountrySelectionOptions = (
    language: string,
): CountrySelectOption[] => {
    const lang = language.startsWith('en') ? en : de;
    return getCountrySelectionOptions(language)
        .filter(({ code }) => ['DE', 'AT', 'CH'].includes(code))
        .concat([
            { code: 'others', name: lang.translation.others, sortWeight: '3' },
        ]);
};
