import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, OverlayWithSpinner } from '_atoms';
import { ResultsProps } from '../Results';
import { useStepGroupsQuery, useSuspicionsByCategory } from '_queries';
import { generatePath, useParams } from 'react-router-dom';
import { formatDateTime } from '_utils';
import { resultsRoutes } from '../../../routes';
import { TargetStatus } from '../../../services/dataService';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { SearchStatistics } from './SearchStatistics';
import { ProfileInfo } from './ProfileInfo';
import { riskSections } from '../RiskAnalysisV2/RiskAnalysis';
import { SuspicionRisk } from '../RiskAnalysisV2/SuspicionRisk';
import { SuspicionFilters, useFilters } from '../RiskAnalysisV2/useFilters';
import { SuspicionRiskLevel } from '_enums';
import { ReportStatusDisplay } from './ReportStatusDisplay';

export const OverviewV2: FC<ResultsProps> = ({
    targetData,
    targetImages,
    caseData,
    redFlags,
}) => {
    const { t } = useTranslation();

    const { redirectWithFilters } = useFilters();

    const { targetId, caseId } =
        useParams<{ targetId: string; caseId: string }>();

    const suspicionsByCategoryQuery = useSuspicionsByCategory(
        targetId,
        {},
        targetData?.status === TargetStatus.HasInitialProfile,
    );

    const stepGroupsQuery = useStepGroupsQuery(caseId, targetId);

    const hasSuspicions = useMemo(
        () =>
            Boolean(
                suspicionsByCategoryQuery.data?.high.length ||
                    suspicionsByCategoryQuery.data?.medium.length ||
                    suspicionsByCategoryQuery.data?.low.length,
            ),
        [suspicionsByCategoryQuery.data],
    );

    const isCompleted =
        targetData?.status === TargetStatus.Completed ||
        targetData?.status === TargetStatus.CompletedWithIrregularities;

    const targetSimplifiedStatus = useMemo(() => {
        if (targetData?.status === TargetStatus.Completed) {
            return 'completed';
        }

        if (targetData?.status === TargetStatus.CompletedWithIrregularities) {
            return 'completedWithIrregularities';
        }

        return 'inProgress';
    }, [targetData?.status]);

    const redirectToRiskAnalysis = (newFilters: SuspicionFilters): void => {
        const pathname = generatePath(resultsRoutes.riskAnalysis.path, {
            targetId,
            caseId: caseData?.id,
        });

        redirectWithFilters(pathname, newFilters);
    };

    const targetProfileData = targetData?.profile?.data;

    const profileData = useMemo(() => {
        if (!targetProfileData) {
            return undefined;
        }

        return {
            ...targetProfileData,
            occupation: targetProfileData?.occupation?.slice(0, 10),
        };
    }, [targetProfileData]);

    if (
        (suspicionsByCategoryQuery.isLoading &&
            !suspicionsByCategoryQuery.isFetched) ||
        (stepGroupsQuery.isLoading && !stepGroupsQuery.isFetched)
    ) {
        return (
            <div className="flex justify-center w-full z-0">
                <LoadingSpinner message={t('profileLoading')} />
            </div>
        );
    }

    const nonEmptyRiskLevels = riskSections.filter(
        (risk) => suspicionsByCategoryQuery.data?.[risk].length,
    );

    return (
        <DefaultPageLayout
            title={
                targetData
                    ? `${targetData?.firstname} ${targetData?.lastname}`
                    : t('overview')
            }
            subtitle={
                targetData
                    ? `${t('createdAt')} ${formatDateTime(
                          targetData.createdAt,
                      )}`
                    : undefined
            }
        >
            <div className="row">
                <div className="w-1/4 space-y-5 overflow-always-hidden">
                    <ProfileInfo
                        targetImages={targetImages}
                        profileData={profileData}
                    />
                </div>
                <div className="w-3/4 space-y-5">
                    {stepGroupsQuery.data && (
                        <ReportStatusDisplay
                            status={targetSimplifiedStatus}
                            hasSuspicions={hasSuspicions}
                            stepGroups={stepGroupsQuery.data}
                        />
                    )}
                    <OverlayWithSpinner
                        className="space-y-5 mb-8"
                        showOverlay={suspicionsByCategoryQuery.isFetching}
                    >
                        {(!isCompleted || (isCompleted && hasSuspicions)) && (
                            <div className="flex flex-col gap-7 mt-5">
                                {suspicionsByCategoryQuery.isSuccess &&
                                    (isCompleted
                                        ? nonEmptyRiskLevels
                                        : riskSections
                                    ).map((risk) => (
                                        <SuspicionRisk
                                            key={risk}
                                            risk={risk}
                                            suspicions={
                                                suspicionsByCategoryQuery.data[
                                                    risk
                                                ]
                                            }
                                            setFilters={redirectToRiskAnalysis}
                                            filters={{}}
                                            redFlags={
                                                risk === SuspicionRiskLevel.High
                                                    ? redFlags
                                                    : undefined
                                            }
                                            isLoading={!isCompleted}
                                        />
                                    ))}
                            </div>
                        )}
                    </OverlayWithSpinner>
                    {isCompleted && !hasSuspicions && <SearchStatistics />}
                </div>
            </div>
        </DefaultPageLayout>
    );
};
