import { TargetCandidatesGroup } from '_types';
import React from 'react';
import {
    LegacyTargetCandidateItem,
    LegacyTargetCandidateItemProps,
} from './LegacyTargetCandidateItem';
import { LegacyTargetCandidateInfoItem } from '@indicium/common';
import { getCdnUrl } from '_utils';
import { processCandidatesInfo } from './targetCandidates.helper';
import { useTranslation } from 'react-i18next';

const attributesToMoveToFront = [
    'placeOfResidency',
    'email',
    'phone',
    'company',
];

export const sortTargetInfo = (
    info: LegacyTargetCandidateInfoItem[],
): LegacyTargetCandidateInfoItem[] => {
    if (info.length === 0) {
        return info;
    }

    const frontItems = info.filter((item) =>
        attributesToMoveToFront.includes(item.key),
    );

    const remainingItems = info.filter(
        (item) => !attributesToMoveToFront.includes(item.key),
    );

    return frontItems.concat(remainingItems);
};

type CandidateProps = TargetCandidatesGroup &
    Pick<
        LegacyTargetCandidateItemProps,
        'onStateChange' | 'onGalleryOpen' | 'disabled'
    > & {
        unifiedScore?: number;
        fingerprintScore?: number;
        hardCriteriaScore?: number;
    };

export const LegacyStructuredCandidate = (
    props: CandidateProps,
): React.ReactElement => {
    const { t } = useTranslation();
    const { info = [] } = props;
    /*
     TODO: change this to actually use the new data with a rewritten candidate selection view
           this is currently the fastest way to get backwards compatibility without changing the feature set
           so the backend changes can be deployed
           The backend part of this was pushed back for now. See IOAT-1889 for more details.
    */
    const unsortedInfo: LegacyTargetCandidateInfoItem[] = Array.isArray(info)
        ? info
        : // This second case will never happen for now (see comment above)
          Object.entries(info).map(([key, value]) => ({
              key,
              values:
                  value?.map(
                      (v) => (v as unknown as Record<'value', string>).value,
                  ) ?? [], // lord forgive me, but in the current candidate info typing we assume that all values are strings
              // sources: value.map((v) => v.sources), // TODO: add support for new sources when we know what to do with them
          }));

    const sortedInfo = sortTargetInfo(unsortedInfo);

    return (
        <LegacyTargetCandidateItem
            key={props.groupId}
            id={props.groupId}
            images={(props.images || [])?.map((image) => getCdnUrl(image))}
            name={props.name}
            jobTitles={props.jobTitles}
            info={processCandidatesInfo(sortedInfo, props.sources, t)}
            sources={props.sources}
            onStateChange={props.onStateChange}
            status={props.status}
            onGalleryOpen={props.onGalleryOpen}
            score={props.score}
            hardCriteriaScore={props.hardCriteriaScore}
            unifiedScore={props.unifiedScore}
            fingerprintScore={props.fingerprintScore}
        />
    );
};
