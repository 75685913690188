import { LoadingSpinner } from '_atoms';
import classNames from 'classnames';
import { QRCodeCanvas } from 'qrcode.react';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLockClosed } from 'react-icons/hi';
import {
    setPreferredMFA,
    setupTOTP,
    signout,
    User,
    verifyTOTP,
} from '../../services/authenticationService';
import { AuthExceptions, AuthSteps, ResetExceptions } from './types';
import { UserActionTypes, useUserState } from '../../context/User';

interface SigninProps {
    onChangeStep: (step: AuthSteps) => void;
    onSuccess: (user: User) => void;
    onError: (code: ResetExceptions) => void;
}

export const SetupMFA: FC<SigninProps> = ({
    onError = console.error,
}: SigninProps) => {
    const { t } = useTranslation();
    const [confirmationCode, setConfirmationCode] =
        useState<string | null>(null);
    const [secretCode, setSecretCode] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [{ user }, dispatch] = useUserState();

    useEffect(() => {
        // get secret code for TOTP to show QR code
        (async () => {
            if (!user) {
                return;
            }
            setLoading(true);
            try {
                const code = await setupTOTP(user);
                setSecretCode(code);
            } catch (error) {
                if (
                    typeof error === 'object' &&
                    error !== null &&
                    'code' in error
                ) {
                    onError(error.code as ResetExceptions);
                } else {
                    onError(AuthExceptions.NotAuthorizedException);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [user, onError]);

    const handleSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!user || !confirmationCode) {
                return;
            }
            setLoading(true);

            try {
                await verifyTOTP(user, confirmationCode);
                await setPreferredMFA('TOTP', user);
                await signout();
                dispatch({ type: UserActionTypes.SET, user: null });
            } catch (error) {
                if (
                    typeof error === 'object' &&
                    error !== null &&
                    'code' in error
                ) {
                    onError(error.code as ResetExceptions);
                } else {
                    onError(AuthExceptions.NotAuthorizedException);
                }
            } finally {
                setLoading(false);
            }
        },
        [user, confirmationCode, dispatch, onError],
    );

    const qrCode =
        'otpauth://totp/Indicium:' +
        user?.attributes.email +
        '?secret=' +
        secretCode +
        '&issuer=Indicium';

    const mfaValid = confirmationCode?.length === 6;

    return (
        <form className="mt-8 space-y-6 p-8" onSubmit={handleSubmit}>
            <p className="whitespace-pre-line	">
                {t('authentication.instructions')}
            </p>
            <div className="w-full flex justify-center">
                <QRCodeCanvas value={qrCode} />
            </div>

            <input type="hidden" name="remember" defaultValue="true" />

            <div className="rounded-md shadow-sm -space-y-px">
                <div>
                    <label htmlFor="email-address" className="sr-only">
                        {t('authentication.oneTimePassword')}:
                    </label>
                    <input
                        id="confirmationCode"
                        name="confirmationCode"
                        type="number"
                        required
                        placeholder={t('authentication.oneTimePassword')}
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-neutral-300 placeholder-neutral-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-4 focus:border-primary-4 focus:z-10 sm:text-sm"
                        onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                </div>
            </div>

            <div>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <button
                        disabled={!mfaValid}
                        type="submit"
                        className={classNames(
                            !mfaValid &&
                                'opacity-30 disabled:bg-primary-4 disabled:cursor-not-allowed',
                            'group relative w-full flex justify-center py-2 px-4 mt-12 border border-transparent text-sm font-medium rounded-md text-font-light bg-primary-4 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-4',
                        )}
                    >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <HiOutlineLockClosed
                                className="h-5 w-5 text-blue-500 group-hover:text-primary-4"
                                aria-hidden="true"
                            />
                        </span>
                        {t('authentication.submitButton')}
                    </button>
                )}
            </div>
        </form>
    );
};
