import React from 'react';
import { CaseSettings, CaseType, Step } from '../CaseNew.types';
import { NavigationBar } from '../NavigationBar';
import { CaseTypeButtons } from '../CaseTypeButtons';
import { useTranslation } from 'react-i18next';
import { DataPrivacy } from './DataPrivacy';
import { CaseSettingsForm } from '../CaseSettingsForm';

interface StepCaseTypeSettingsProps {
    setCaseType?: (caseType?: CaseType) => void;
    setStep?: (step: Step) => void;
    caseType?: CaseType;
    settings: CaseSettings;
    setSettings?: React.Dispatch<React.SetStateAction<CaseSettings>>;
}

export const StepCaseTypeSettings = (
    props: StepCaseTypeSettingsProps,
): React.ReactElement => {
    const { caseType, setStep, settings, setSettings, setCaseType } = props;

    const { t } = useTranslation();

    // not used anymore IOAT-2429
    // const isContinueEnabled =
    //     caseType === CaseType.Essential ||
    //     (caseType === CaseType.Plus &&
    //         settings.publicInfo &&
    //         (!settings.customSearchTerms ||
    //             settings.keywords.some((keyword) => keyword.value))) ||
    //     (caseType === CaseType.Complete &&
    //         settings.keywords.some((keyword) => keyword.value));

    const onCaseTypeClick = (caseType?: CaseType) => {
        setCaseType?.(caseType);
    };

    return (
        <>
            <NavigationBar
                onBack={() => {
                    setCaseType?.();
                }}
                onContinue={() => setStep?.(Step.CaseAccessSettings)}
                isBackButtonVisible={!!caseType}
                isContinueButtonVisible={!!caseType}
                subTitle={t(
                    `caseNew.stepSubtitle.${caseType ? 'step2' : 'step1'}`,
                )}
            />
            <div className="container-fluid p-9 pt-0 xl:max-w-max-content">
                <CaseTypeButtons
                    caseType={caseType}
                    onClick={onCaseTypeClick}
                />
                {!caseType && <DataPrivacy />}
                {caseType && (
                    <CaseSettingsForm
                        caseType={caseType}
                        settings={settings}
                        setSettings={setSettings}
                    />
                )}
            </div>
        </>
    );
};
