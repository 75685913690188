import { useQuery, UseQueryResult } from 'react-query';
import { SocialMediaError } from '../../services/dataService';
import { targets } from '../../services/nestApiService';

export const useSocialMediaErrorsQuery = (
    caseId: string,
    targetId: string,
): UseQueryResult<SocialMediaError[]> =>
    useQuery(
        ['target', caseId, targetId, 'social-media-errors'],
        () => targets.getSocialMediaErrors(caseId, targetId),
        { refetchOnWindowFocus: false },
    );
