export const companyFilters = [
    {
        type: 'Options',
        title: 'status',
        field: 'companyStatus',
        options: [
            { label: 'active', value: 'active' },
            { label: 'inactive', value: 'inactive' },
        ],
    },
    {
        type: 'Options',
        title: 'source',
        field: 'sources',
        options: [
            { label: 'Orbis', value: 'orbis' },
            { label: 'Pipl', value: 'pipl' },
            { label: 'CompanyHouse', value: 'companyhouse' },
            { label: 'userInput', value: 'userInput' },
        ],
    },
];
