import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { theme } from '../../../tailwind.config';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

type SliderHorizontalProps = { images: string[] };
SwiperCore.use([Navigation, Pagination]);

export const SliderHorizontal: FC<SliderHorizontalProps> = ({
    images,
}: SliderHorizontalProps) => {
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const horizontalPrevButtonRef = useRef<HTMLDivElement | null>(null);
    const horizontalNextButtonRef = useRef<HTMLDivElement | null>(null);
    const horizontalPaginationRef = useRef<HTMLDivElement | null>(null);

    const [mainHorizontalSlider, setMainHorizontalSlider] =
        useState<SwiperCore | null>(null);

    useEffect(() => {
        if (
            horizontalPaginationRef &&
            horizontalNextButtonRef &&
            horizontalPrevButtonRef &&
            mainHorizontalSlider
        ) {
            mainHorizontalSlider.init();
            mainHorizontalSlider.update();
        }
    }, [
        horizontalPaginationRef,
        horizontalNextButtonRef,
        horizontalPrevButtonRef,
        mainHorizontalSlider,
    ]);

    return (
        <Fragment>
            <div>
                <Swiper
                    className="overflow-y-auto"
                    observer={true}
                    observeParents={true}
                    onSwiper={setMainHorizontalSlider}
                    lazy={{
                        loadOnTransitionStart: true,
                    }}
                    slidesPerView={3}
                    spaceBetween={30}
                    onSlideChange={(swiper) => {
                        setIsBeginning(swiper.isBeginning);
                        setIsEnd(swiper.isEnd);
                    }}
                    navigation={{
                        nextEl: horizontalNextButtonRef.current,
                        prevEl: horizontalPrevButtonRef.current,
                    }}
                    pagination={{
                        el: horizontalPaginationRef.current,
                        clickable: true,
                    }}
                >
                    {images.map((src, index) => (
                        <SwiperSlide key={index} style={{ height: 'auto' }}>
                            <img
                                className="w-full h-full object-cover rounded-md"
                                src={src}
                                alt={`profile picture ${index + 1}`}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="pointer-events-none absolute w-full inset-0 flex justify-between align-middle z-10">
                    <div
                        className={`pointer-events-auto my-auto transition duration-500 opacity-1 ${
                            isBeginning && 'opacity-0'
                        }`}
                        ref={horizontalPrevButtonRef}
                    >
                        <button
                            className="text-primary-4 p-2"
                            disabled={isBeginning}
                        >
                            <MdChevronLeft />
                        </button>
                    </div>
                    <div
                        className={`pointer-events-auto my-auto transition duration-500 opacity-1 ${
                            isEnd && 'opacity-0'
                        }`}
                        ref={horizontalNextButtonRef}
                    >
                        <button className="text-primary-4 p-2" disabled={isEnd}>
                            <MdChevronRight />
                        </button>
                    </div>
                </div>

                <style>
                    {`.swiper-pagination-bullet-active {
              background: ${theme.extend.colors.blue[400]};
            }`}
                </style>
                <div
                    className="w-full flex justify-center mt-14 space-x-1"
                    ref={horizontalPaginationRef}
                ></div>
            </div>
        </Fragment>
    );
};
