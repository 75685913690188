import React, { FC, HTMLAttributes, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
    Badge,
    EntityIcon,
    Headline,
    Highlight,
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { EntitySuspicion, EntityType } from '_types';
import { AllDataArticle } from '../../../services/searchService';
import { useReportTailoring } from '../../../hooks/useReportTailoring';
import { Target } from '../../../services/dataService';
import { isCustomerExpired, withinSpanOfDays } from '_utils';
import { useUserState } from '../../../context/User';
import { useCustomerQuery } from '_queries';
import { HighlightChunks } from '../../_atoms/Highlight/types';
import { Suspicions } from '_organisms/Suspicions/Suspicions';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';
import { ColorLevel } from '../../_atoms/Badge/Badge';
import { VscLink } from 'react-icons/vsc';
import { HeadlineLevel } from '../../_atoms/Headline/Headline';

type TileEntityType = EntityType | 'socialPost' | 'socialConnection';

const getEntityType = (tet: TileEntityType): EntityType =>
    ['socialPost', 'socialConnection'].includes(tet)
        ? 'social'
        : (tet as EntityType);

const entityTypeName: Record<TileEntityType, string> = {
    social: 'socialMediaSection',
    socialPost: 'socialMediaPostSection',
    socialConnection: 'socialMediaConnectionSection',
    press: 'pressSection',
    targetcompany: 'companySection',
    webpage: 'searchEngineSection',
    image: 'pictureSection',
    financial: 'financialSection',
};

interface BaseCardTagProps {
    label: string;
    tooltip?: string;
    color?: string;
}

interface BaseCardProps extends HTMLAttributes<HTMLDivElement> {
    entityType: TileEntityType;
    logo?: JSX.Element | HTMLImageElement;
    titleIcon?: JSX.Element | HTMLImageElement;
    title: string;
    titleLink?: string;
    item: AllDataArticle;
    hideArticleType?: boolean;
    score?: number;
    status?: string;
    suspicionItemId?: string;
    suspicionItems?: Array<EntitySuspicion>;
    highlight?: HighlightChunks;
    targetData?: Target;
    subtitle?: string;
    tag?: BaseCardTagProps;
    lastChild?: JSX.Element;
    className?: string;
    headlineLevel?: HeadlineLevel;
}

export const BaseCard: FC<BaseCardProps> = ({
    entityType,
    logo,
    titleIcon,
    titleLink,
    title,
    item,
    hideArticleType = false,
    children,
    score = 0,
    status,
    suspicionItemId,
    suspicionItems,
    highlight,
    targetData,
    subtitle,
    tag,
    lastChild,
    className,
    headlineLevel = 'h4',
    ...props
}) => {
    const { t } = useTranslation();
    const [{ customerId }] = useUserState();
    const { data: customer } = useCustomerQuery(customerId, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const { ReportTailoringActions } = useReportTailoring({
        targetId: item.targetId,
    });

    // if older than 30 days, don't allow editing the report anymore
    const reportIsTooOld =
        !!targetData?.updatedAt &&
        !withinSpanOfDays(targetData.updatedAt) &&
        process.env.REACT_APP_STAGE === 'production';

    const action =
        !isCustomerExpired(customer?.expiresAt) &&
        !reportIsTooOld &&
        typeof suspicionItemId !== 'undefined'
            ? ReportTailoringActions.UpdateItemsStatus(
                  [suspicionItemId],
                  status,
                  false,
              )
            : !isCustomerExpired(customer?.expiresAt) &&
              !reportIsTooOld &&
              ReportTailoringActions.AddToReport(item);

    const uniqueSuspicions: EntitySuspicion[] | undefined = useMemo(() => {
        const visited: Record<string, boolean> = {};
        return suspicionItems?.filter(({ suspicionId }) => {
            if (suspicionId in visited) {
                return false;
            }

            visited[suspicionId] = true;
            return true;
        });
    }, [suspicionItems]);
    const [selectedSuspicions, setSelectedSuspicions] = useState<Set<string>>(
        new Set(),
    );

    const uniqueStatuses: string | undefined = useMemo(() => {
        const visited: string[] = [];
        Array.from(selectedSuspicions)?.forEach((el) => {
            const status = suspicionItems?.find(
                (item) => item.id === el,
            )?.status;
            if (status && !visited.includes(status)) {
                visited.push(status);
            }
        });
        return visited.length == 1 ? visited[0] : undefined;
    }, [selectedSuspicions, suspicionItems]);

    const actions =
        !isCustomerExpired(customer?.expiresAt) &&
        !reportIsTooOld &&
        uniqueSuspicions?.length
            ? uniqueSuspicions.map((item) => ({
                  action: ReportTailoringActions.UpdateItemsStatus(
                      [item.id],
                      item.status,
                      false,
                  ),
                  suspicionItemId: item.id,
              }))
            : null;

    const allActions =
        !isCustomerExpired(customer?.expiresAt) &&
        !reportIsTooOld &&
        selectedSuspicions.size > 0
            ? {
                  action: ReportTailoringActions.UpdateItemsStatus(
                      Array.from(selectedSuspicions),
                      uniqueStatuses ?? 'pending',
                      !uniqueStatuses,
                  ),
              }
            : null;

    const updateSelectedState = (ids: string[], isSelected: boolean) => {
        const newSet = new Set(selectedSuspicions);
        if (isSelected) {
            ids.forEach((id) => {
                newSet.add(id);
            });
            setSelectedSuspicions(newSet);
            return;
        }

        ids.forEach((id) => {
            newSet.delete(id);
        });

        setSelectedSuspicions(newSet);
    };
    const handleSelectAll = (isSelected: boolean) => {
        if (!uniqueSuspicions) {
            return;
        }
        updateSelectedState(
            uniqueSuspicions.map((item) => item.id),
            isSelected,
        );
    };

    const handleSelectSuspicion = (
        suspicionId: string,
        isSelected: boolean,
    ) => {
        updateSelectedState([suspicionId], isSelected);
    };

    return (
        <div
            className={classNames(
                'bg-neutral-50 mx-0 rounded-md border-neutral-300 border-solid border overflow-always-hidden',
                className,
            )}
            {...props}
        >
            <div className="flex h-full">
                <div className="flex flex-col flex-1">
                    <div className="flex items-start gap-2 p-6">
                        {!hideArticleType && (
                            <EntityIcon type={getEntityType(entityType)} />
                        )}
                        {Boolean(titleIcon) && titleIcon}
                        <div className="flex flex-col flex-grow w-1">
                            <div
                                className={classNames(
                                    'flex gap-2',
                                    headlineLevel == 'h2' && 'items-center',
                                )}
                            >
                                <TooltipV2
                                    openDelay={400}
                                    placement="right"
                                    withArrow={true}
                                >
                                    <TooltipContentV2 className="px-3">
                                        {title}
                                    </TooltipContentV2>
                                    <TooltipTriggerV2 asChild={true}>
                                        <div className="truncate">
                                            <Headline
                                                className="truncate"
                                                Level={headlineLevel}
                                                weight="bold"
                                                color="dark"
                                            >
                                                {highlight ? (
                                                    <Highlight
                                                        searchWords={highlight}
                                                        textToHighlight={title}
                                                    />
                                                ) : (
                                                    title
                                                )}
                                            </Headline>
                                        </div>
                                    </TooltipTriggerV2>
                                </TooltipV2>
                                {Boolean(titleLink?.length) && (
                                    <a
                                        key={titleLink}
                                        href={titleLink}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="text-primary-4 align-super"
                                    >
                                        <VscLink size="18" />
                                    </a>
                                )}
                            </div>
                            {subtitle?.length ? (
                                <Headline className="truncate" Level="h6">
                                    <Highlight
                                        searchWords={highlight}
                                        textToHighlight={subtitle}
                                    />
                                </Headline>
                            ) : null}
                            {logo || !hideArticleType ? (
                                <div className="flex justify-between">
                                    {!hideArticleType ? (
                                        <Paragraph
                                            className="inline-block align-top"
                                            size="medium"
                                            weight="semibold"
                                            color="default"
                                        >
                                            {t(entityTypeName[entityType])}
                                        </Paragraph>
                                    ) : null}
                                    {logo}
                                </div>
                            ) : null}
                        </div>
                        {!!uniqueSuspicions && !tag ? null : (
                            <div className="flex flex-col items-end gap-3 text-neutral-500 ml-auto">
                                {tag && (
                                    <Badge background={tag.color as ColorLevel}>
                                        {tag.label}
                                        {Boolean(tag.tooltip) && (
                                            <TooltipV2
                                                openDelay={500}
                                                placement="bottom-start"
                                                withArrow={true}
                                            >
                                                <TooltipContentV2 className="px-3 max-w-72">
                                                    {tag.tooltip}
                                                </TooltipContentV2>
                                                <TooltipTriggerV2
                                                    asChild={true}
                                                >
                                                    <ExclamationIcon className="w-4 h-4 ml-2 flex justify-center items-center rotate-180" />
                                                </TooltipTriggerV2>
                                            </TooltipV2>
                                        )}
                                    </Badge>
                                )}
                                {!uniqueSuspicions ? (
                                    <div className="flex items-center gap-3 text-neutral-500 ml-auto">
                                        {score > 0 && (
                                            <Paragraph>
                                                {t('relevanceScore')}:{' '}
                                                <span className="text-green-500">
                                                    {[score, '%'].join('')}
                                                </span>
                                            </Paragraph>
                                        )}
                                        {action}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                    <>{children}</>
                </div>
                {uniqueSuspicions && (
                    <div className="flex flex-col rounded-tr-md flex-shrink-0 w-1/4">
                        <Suspicions
                            className={
                                'bg-neutral-200 border-l border-neutral-300 flex-grow rounded-tr-md'
                            }
                            handleSelectAll={handleSelectAll}
                            handleSelectSuspicion={handleSelectSuspicion}
                            selectedSuspicions={selectedSuspicions}
                            reportIsTooOld={reportIsTooOld}
                            uniqueSuspicions={uniqueSuspicions}
                            actions={actions}
                            allActions={allActions}
                        />
                    </div>
                )}
            </div>
            {Boolean(lastChild) && (
                <div className={classNames('border-t border-neutral-300')}>
                    {lastChild}
                </div>
            )}
        </div>
    );
};
