import { TargetCandidatesGroup } from '../../../../types/TargetCandidatesGroup';
import { ProcessTarget } from '@indicium/common/src/types/Target/TargetTypes';
import {
    dateRangesOverlap,
    datesTimesAreEqual,
    findCompanyMatches,
    normalizeCaseless,
    normalizeDedupStrings,
    toDate,
    toNameVariations,
} from './utils';
import { DateRange } from '@indicium/common';
import { isNeitherNullNorUndefined } from '_utils';
import { DynamicFormValueStatus } from '@indicium/common/src/types/DynamicForm/DynamicForm';
import { parseSocialMediaProfile } from '../../../dashboard/Profile/helpers';

export const candidateToReferenceInput = (
    candidate: TargetCandidatesGroup,
): NormalizedReferenceInput => {
    const normalizedCandidate = normalizeCandidate(candidate);

    return {
        names: [normalizedCandidate.name],
        jobs: normalizedCandidate.jobs,
        educations: normalizedCandidate.educations,
        dobs: normalizedCandidate.dob ? [normalizedCandidate.dob] : [],
        companies: normalizedCandidate.companies,
        usernames: normalizedCandidate.usernames,
        phones: normalizedCandidate.phones,
        genders: normalizedCandidate.gender ? [normalizedCandidate.gender] : [],
        locations: normalizedCandidate.locations,
        urls: normalizedCandidate.urls,
        emails: normalizedCandidate.emails,
        extras: [],
    };
};

export function groupReferenceInput(
    referenceInputGroup: NormalizedReferenceInput[],
): NormalizedReferenceInput {
    return {
        names: normalizeDedupStrings(
            referenceInputGroup.map(({ names }) => names).flat(),
        ),
        jobs: normalizeDedupStrings(
            referenceInputGroup
                .map(({ jobs }) => jobs)
                .flat()
                .filter((job) => job),
        ),
        educations: normalizeDedupStrings(
            referenceInputGroup
                .map(({ educations }) => educations)
                .flat()
                .filter((education) => education),
        ),
        dobs: referenceInputGroup.map(({ dobs }) => dobs).flat(),
        companies: normalizeDedupStrings(
            referenceInputGroup.map(({ companies }) => companies).flat(),
        ),
        usernames: normalizeDedupStrings(
            referenceInputGroup.map(({ usernames }) => usernames).flat(),
        ),
        phones: normalizeDedupStrings(
            referenceInputGroup.map(({ phones }) => phones).flat(),
        ),
        genders: normalizeDedupStrings(
            referenceInputGroup.map(({ genders }) => genders).flat(),
        ),
        locations: normalizeDedupStrings(
            referenceInputGroup.map(({ locations }) => locations).flat(),
        ),
        urls: normalizeDedupStrings(
            referenceInputGroup.map(({ urls }) => urls).flat(),
        ),
        emails: normalizeDedupStrings(
            referenceInputGroup.map(({ emails }) => emails).flat(),
        ),
        extras: normalizeDedupStrings(
            referenceInputGroup.map(({ extras }) => extras).flat(),
        ),
    };
}

export interface NormalizedReferenceInput {
    names: string[];
    jobs: string[];
    educations: string[];
    dobs: DateRange[];
    companies: string[];
    usernames: string[];
    phones: string[];
    emails: string[];
    genders: string[];
    locations: string[];
    urls: string[];
    extras: string[];
}

export function negativeUserInputToNormalizedReferenceInput(
    userInput?: ProcessTarget,
): NormalizedReferenceInput {
    if (!userInput) {
        return {
            names: [],
            jobs: [],
            educations: [],
            dobs: [],
            companies: [],
            phones: [],
            emails: [],
            usernames: [],
            genders: [],
            locations: [],
            urls: [],
            extras: [],
        };
    }

    const relatedPersons = (userInput.relatedPersons ?? [])
        .filter(({ status }) => status === DynamicFormValueStatus.Ignored)
        .map(({ value }) => value);

    const topics = (userInput.topics ?? [])
        .filter(({ status }) => status === DynamicFormValueStatus.Ignored)
        .map(({ value }) => value);

    const nicknames = (userInput.nicknames ?? [])
        .filter(({ status }) => status === DynamicFormValueStatus.Ignored)
        .map(({ value }) => value);

    const extras = normalizeDedupStrings(
        relatedPersons.concat(topics).concat(nicknames),
    );

    const companies = (userInput.organizations ?? [])
        .filter(({ status }) => status === DynamicFormValueStatus.Ignored)
        .map(({ value }) => value);

    const locations = (userInput.locations ?? [])
        .filter(({ status }) => status === DynamicFormValueStatus.Ignored)
        .map(({ value }) => value);

    const urls = normalizeDedupStrings(
        (userInput.websites ?? [])
            .filter(({ status }) => status === DynamicFormValueStatus.Ignored)
            .map(({ value }) => value),
    );

    return {
        names: [],
        jobs: [],
        educations: [],
        dobs: [],
        companies,
        phones: [],
        emails: [],
        usernames: [],
        genders: [],
        locations,
        urls,
        extras,
    };
}

export function userInputToNormalizedReferenceInput(
    userInput?: ProcessTarget,
): NormalizedReferenceInput {
    if (!userInput) {
        return {
            names: [],
            jobs: [],
            educations: [],
            dobs: [],
            companies: [],
            phones: [],
            emails: [],
            usernames: [],
            genders: [],
            locations: [],
            urls: [],
            extras: [],
        };
    }

    const relatedPersons = (userInput.relatedPersons ?? [])
        .filter(({ status }) => status === DynamicFormValueStatus.Confirmed)
        .map(({ value }) => value);

    const topics = (userInput.topics ?? [])
        .filter(({ status }) => status === DynamicFormValueStatus.Confirmed)
        .map(({ value }) => value);

    const nicknames = (userInput.nicknames ?? [])
        .filter(({ status }) => status === DynamicFormValueStatus.Confirmed)
        .map(({ value }) => value);

    const extras = normalizeDedupStrings(
        relatedPersons.concat(topics).concat(nicknames),
    );

    const names = normalizeDedupStrings(toNameVariations(userInput));

    const userInputJobList = (userInput.jobs ?? []).map(
        ({ title }) => title ?? '',
    );

    const jobs = normalizeDedupStrings(userInputJobList);

    const dobs = userInput.dateOfBirth ? [userInput.dateOfBirth] : [];

    const jobCompanies = (userInput.jobs ?? [])
        .map(({ company }) => company?.name ?? '')
        .filter(Boolean);

    const companies = normalizeDedupStrings(
        (userInput.jobs ?? [])
            .map(({ company }) => company?.name ?? '')
            .concat(
                (userInput.organizations ?? [])
                    .filter(
                        ({ status }) =>
                            status === DynamicFormValueStatus.Confirmed,
                    )
                    .map(({ value }) => value),
            )
            .concat(jobCompanies),
    );

    const phones = normalizeDedupStrings(
        (userInput.contact ?? [])
            .filter(({ type }) => type === 'phone')
            .map(({ value }) => value),
    );

    const emails = normalizeDedupStrings(
        (userInput.contact ?? [])
            .filter(({ type }) => type === 'email')
            .map(({ value }) => value),
    );

    const usernames = normalizeDedupStrings(
        (userInput.socialMediaProfiles ?? [])
            ?.map(({ type, value }) => {
                const socialMediaData = parseSocialMediaProfile(value);
                if (
                    !socialMediaData ||
                    socialMediaData.socialMediaType !== type
                ) {
                    return null;
                }
                return socialMediaData.username;
            })
            .filter(isNeitherNullNorUndefined),
    );

    const genders = userInput.gender ? [userInput.gender.toLowerCase()] : [];

    const locations = [
        userInput.countryOfBirth,
        userInput.placeOfBirth,
        userInput.countryOfResidence,
        ...(userInput.nationalities ?? []),
        ...(userInput.locations ?? [])
            .filter(({ status }) => status === DynamicFormValueStatus.Confirmed)
            .map(({ value }) => value),
    ].filter(isNeitherNullNorUndefined);

    const urls = normalizeDedupStrings(
        (userInput.contact ?? [])
            .filter(({ type }) => type === 'website')
            .map(({ value }) => value)
            .concat(
                (userInput.websites ?? [])
                    .filter(
                        ({ status }) =>
                            status === DynamicFormValueStatus.Confirmed,
                    )
                    .map(({ value }) => value),
            ),
    );

    return {
        names,
        jobs,
        educations: [],
        dobs,
        companies,
        phones,
        emails,
        usernames,
        genders,
        locations,
        urls,
        extras,
    };
}

export interface NormalizedCandidateInfo {
    name: string;
    jobs: string[];
    educations: string[];
    companies: string[];
    usernames: string[];
    phones: string[];
    gender?: string;
    locations: string[];
    urls: string[];
    emails: string[];
    dob?: DateRange;
}

function normalizeCandidate(
    candidate: TargetCandidatesGroup,
): NormalizedCandidateInfo {
    const companies: string[] = [];
    const usernames: string[] = [];
    const phones: string[] = [];
    let gender = undefined;
    let dob = undefined;
    const locations: string[] = [];
    const urls: string[] = [];
    const emails: string[] = [];
    const educations: string[] = [];

    if (Array.isArray(candidate.info)) {
        candidate.info.forEach(({ key, values }) => {
            if (key === 'dateRangeOfBirth' && values[0]) {
                dob = values[0] as unknown as DateRange;
            }
            if (key === 'company') {
                companies.push(...normalizeDedupStrings(values));
            }
            if (key === 'username') {
                usernames.push(...normalizeDedupStrings(values));
            }
            if (key === 'phone') {
                phones.push(...normalizeDedupStrings(values));
            }
            if (key === 'gender') {
                gender = normalizeCaseless(values[0]);
            }
            if (
                key === 'country' ||
                key === 'nationality' ||
                key === 'placeOfResidency' ||
                key === 'city'
            ) {
                locations.push(...normalizeDedupStrings(values));
            }
            if (key === 'url') {
                urls.push(...normalizeDedupStrings(values));
            }
            if (key === 'email') {
                emails.push(...normalizeDedupStrings(values));
            }
            if (key === 'education') {
                educations.push(...normalizeDedupStrings(values));
            }
        });
    }

    return {
        name: normalizeCaseless(candidate.name),
        jobs: normalizeDedupStrings(candidate.jobTitles),
        dob,
        companies,
        usernames,
        phones,
        gender,
        locations,
        urls,
        emails,
        educations,
    };
}

const getDobScore = (
    candidate: NormalizedCandidateInfo,
    inputReference: NormalizedReferenceInput,
    returnNegativeScore = true,
): number => {
    if (!candidate.dob || !inputReference.dobs.length) {
        return 0;
    }

    const candidateStart = toDate(candidate.dob.start);
    const candidateEnd = toDate(candidate.dob.end);

    const dobScores = inputReference.dobs.map((referenceInputDob) => {
        const userInputStart = toDate(referenceInputDob.start);
        const userInputEnd = toDate(referenceInputDob.end);

        if (
            datesTimesAreEqual(userInputStart, candidateStart) &&
            datesTimesAreEqual(userInputEnd, candidateEnd)
        ) {
            return 100;
        } else if (
            dateRangesOverlap(
                { start: userInputStart, end: userInputEnd },
                { start: candidateStart, end: candidateEnd },
            )
        ) {
            return 50;
        } else {
            return returnNegativeScore ? -100 : 0;
        }
    });

    return Math.max(...dobScores);
};

const getCommonPropertyScore = (
    normalizedCandidate: NormalizedCandidateInfo,
    inputReference: NormalizedReferenceInput,
): number => {
    let score = 0;

    if (inputReference.names.includes(normalizedCandidate.name)) {
        score += 1;
    }

    const jobMatches = normalizedCandidate.jobs.filter((job) =>
        inputReference.jobs.includes(job),
    );
    score += jobMatches.length;

    score += findCompanyMatches(
        normalizedCandidate.companies,
        inputReference.companies,
    );

    const usernameMatches = normalizedCandidate.usernames.filter((username) =>
        inputReference.usernames.includes(username),
    );

    score += usernameMatches.length;

    const phoneMatches = normalizedCandidate.phones.filter((phone) =>
        inputReference.phones.includes(phone),
    );

    score += phoneMatches.length;

    if (normalizedCandidate.gender) {
        const genderMatches = inputReference.genders.filter(
            (gender) => gender === normalizedCandidate.gender,
        );

        score += genderMatches.length;
    }

    const locationMatches = normalizedCandidate.locations.filter((location) =>
        inputReference.locations.some((referenceLocation) =>
            location.includes(referenceLocation),
        ),
    );
    score += locationMatches.length;

    const urlMatches = normalizedCandidate.urls.filter((url) =>
        inputReference.urls.includes(url),
    );

    score += urlMatches.length;

    return score;
};

export const scoreCandidate = (
    candidate: TargetCandidatesGroup,
    positiveReferenceInput: NormalizedReferenceInput,
    negativeReferenceInput: NormalizedReferenceInput,
): number => {
    const normalizedCandidate = normalizeCandidate(candidate);

    const positiveScore = getCommonPropertyScore(
        normalizedCandidate,
        positiveReferenceInput,
    );
    const positiveDobScore = getDobScore(
        normalizedCandidate,
        positiveReferenceInput,
    );

    const negativeScore = getCommonPropertyScore(
        normalizedCandidate,
        negativeReferenceInput,
    );
    const negativeDobScore = getDobScore(
        normalizedCandidate,
        negativeReferenceInput,
        true,
    );

    return (
        positiveScore + positiveDobScore - (negativeScore + negativeDobScore)
    );
};
