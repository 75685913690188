import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { TFunction, useTranslation } from 'react-i18next';
import { SuspicionsConfidenceScore } from '_enums';

const getConfidenceBlock = (
    confidenceScore: number,
    t: TFunction<'translation'>,
) => {
    if (confidenceScore > SuspicionsConfidenceScore.High) {
        return {
            text: t('confidenceScore.score30'),
            color: 'text-green-500',
        };
    }

    if (confidenceScore < SuspicionsConfidenceScore.Medium) {
        return {
            text: t('confidenceScore.score10'),
            color: 'text-error-2',
        };
    }

    return {
        text: t('confidenceScore.score20'),
        color: 'text-neutral-400',
    };
};

interface ConfidenceBarProps extends HTMLAttributes<HTMLDivElement> {
    confidenceScore: number;
}

export const ConfidenceBar: FC<ConfidenceBarProps> = ({
    confidenceScore,
    ...props
}) => {
    const { t } = useTranslation();

    const { text, color } = getConfidenceBlock(confidenceScore, t);

    return (
        <div {...props}>
            <div
                className={classNames(
                    'text-sm w-max px-3 py-1 ',
                    'bg-gray-100 rounded-xl font-semibold',
                    color,
                )}
            >
                {text}
            </div>
        </div>
    );
};
