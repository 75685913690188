/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    boundsToViewport,
    getSelectionBounds,
    ImageIcon,
    NodeStyle,
    TextIcon,
} from '@sayari/trellis';
import { useResizeDetector } from 'react-resize-detector';
import * as Force from '@sayari/trellis/layout/force';
import {
    Props as RendererProps,
    Renderer,
} from '@sayari/trellis/bindings/react/renderer';
import { Entity } from '../types';
import { TrellisEdge, TrellisNode } from '../payload_types';
import { GraphV3MetadataType } from '../g3-deserializer';
import { CompanyDetails } from './company/CompanyDetails';
import { PersonDetails } from './person/PersonDetails';
import { NodePointerEvent } from '@sayari/trellis/renderers/webgl';
import { FiMaximize, FiMinimize, FiMinus, FiPlus } from 'react-icons/fi';
import classnames from 'classnames';
import { TbLocation } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { Headline, Paragraph } from '_atoms';

const styleNode = (
    node: TrellisNode,
    isHoverTarget: boolean,
    isActive: boolean,
    highlightingActive: boolean,
    isHighlighted: boolean,
    flagCount: number,
): TrellisNode => {
    const greyedOut =
        highlightingActive && !isHighlighted && !isHoverTarget && !isActive;

    const isCompany = node.type === 'Company' || node.type === 'UserProvided';

    const isPerson = node.type === 'Person';

    const color = greyedOut
        ? '#EEE'
        : isCompany
        ? '#2893B0'
        : isPerson
        ? '#f19234'
        : '#4A5568';

    const isRoot = node.depth === 0;

    const iconUrlBase = isCompany
        ? 'corporate'
        : isPerson
        ? 'individual'
        : 'question-mark';

    const iconUrl =
        greyedOut || isRoot || isActive ? `${iconUrlBase}_white` : iconUrlBase;

    const bgColor = greyedOut ? '#EEE' : !isRoot ? '#FFFFFF' : color;

    // const size = (node.centrality ?? 0) * 50 + 5;
    const size = node.size ?? 10;

    const strokeWidth = isHoverTarget ? 6 : 2;

    const stroke = [
        {
            color: isActive ? color : bgColor,
            width: size,
        },
        {
            color,
            width: strokeWidth,
        },
    ];

    const style: NodeStyle = {
        label: {
            fontSize: 12,
            wordWrap: 300,
            placement: 'bottom' as const,
            fontFamily: 'Jost',
            color: greyedOut ? '#EEE' : '#173548', // font-dark
        },
        icon: {
            type: 'imageIcon',
            url: `/${iconUrl}.svg`,
            scale: 0.05 + size / 500,
        } as ImageIcon,
        color: isActive ? color : bgColor,
        stroke,
        badge:
            flagCount !== 0
                ? [
                      {
                          position: 45,
                          stroke: greyedOut ? '#EEE' : '#173548', // font-dark
                          color: greyedOut ? '#EEE' : '#F36966', // error-2,
                          radius: 4 + size / 10,
                          icon: {
                              type: 'textIcon',
                              family: 'Jost',
                              color: '#F9F9F9', // font-light
                              size: 5 + size / 10,
                              text: String(flagCount),
                          } as TextIcon,
                      },
                  ]
                : [],
    };

    return {
        ...node,
        radius: 10,
        style,
        label:
            node.label.length > 14 &&
            (greyedOut || !highlightingActive) &&
            !isHoverTarget
                ? node.label.slice(0, 14) + '...'
                : node.label,
    };
};

const EntityDetails = ({
    entity,
    onEntityClick,
    onBack,
    entityPath,
}: {
    entity: Entity | undefined;
    onEntityClick: (id: string) => void;
    onBack: () => void;
    entityPath: Entity[];
}) => {
    const { t } = useTranslation();

    if (!entity) {
        return (
            <Paragraph
                HtmlTag="div"
                className="text-center h-full flex flex-col justify-center"
            >
                {t('networkGraph.emptySidebar')}
            </Paragraph>
        );
    }

    const renderField = (
        label: string,
        value: string | number,
        key?: string,
    ) => (
        <div key={key} className="break-words">
            <Paragraph size="label" weight="bold" color="light">
                {label}
            </Paragraph>
            <Paragraph>{value}</Paragraph>
        </div>
    );

    const entityIcon = entity.type.toLowerCase().includes('person')
        ? '/individual.svg'
        : '/corporate.svg';

    const renderHeader = (title: string) => (
        <Headline Level="h4" className="mb-4 flex items-start gap-2">
            <img src={entityIcon} alt="entity-icon" className="w-8 h-8" />
            <span className="min-w-0 break-words">{title}</span>
        </Headline>
    );

    return (
        <>
            {entityPath.length > 1 && (
                <button
                    className="bg-transparent text-md flex gap-1 font-bold items-center text-primary-4 font-jost hover:text-primary-3 transition-colors"
                    onClick={onBack}
                >
                    {t('back')}
                </button>
            )}
            {(entity.type === 'Person' || entity.type === 'SynthPerson') && (
                <PersonDetails
                    entity={entity}
                    renderField={renderField}
                    onEntityClick={onEntityClick}
                    renderHeader={renderHeader}
                />
            )}
            {(entity.type === 'Company' || entity.type === 'SynthCompany') && (
                <CompanyDetails
                    entity={entity}
                    renderField={renderField}
                    onEntityClick={onEntityClick}
                    renderHeader={renderHeader}
                />
            )}
        </>
    );
};

const force = Force.Layout();

const controlButtonClassNames =
    'cursor-pointer border rounded select-none bg-neutral-100';

const controlButtonPadding = 'p-1';

const ICON_SIZE = 32;

const GraphRenderer: React.FC<
    RendererProps & {
        maximized?: boolean;
        onMaximize?: () => void;
        onMinimize?: () => void;
        onZoomIn?: () => void;
        onZoomOut?: () => void;
        onResetView?: () => void;
    }
> = ({
    maximized,
    onMaximize,
    onMinimize,
    onZoomIn,
    onZoomOut,
    onResetView,
    ...rendererProps
}) => (
    <div className="relative">
        <Renderer {...rendererProps} />
        <div className="absolute bottom-6 right-6 print:hidden">
            <div className="flex flex-col items-end text-primary-1 border-neutral-400 gap-2">
                <div
                    className={classnames(
                        controlButtonClassNames,
                        'flex flex-col',
                    )}
                >
                    <FiPlus
                        className={classnames(
                            controlButtonPadding,
                            'border-b border-neutral-400',
                        )}
                        size={ICON_SIZE - 2} // to compensate for the border of the wrapper div
                        onClick={onZoomIn}
                    />
                    <FiMinus
                        className={controlButtonPadding}
                        size={ICON_SIZE - 2} // to compensate for the border of the wrapper div
                        onClick={onZoomOut}
                    />
                </div>
                <TbLocation
                    className={classnames(
                        controlButtonClassNames,
                        controlButtonPadding,
                    )}
                    size={ICON_SIZE}
                    onClick={onResetView}
                />
                {maximized ? (
                    <FiMinimize
                        className={classnames(
                            controlButtonClassNames,
                            controlButtonPadding,
                        )}
                        size={ICON_SIZE}
                        onClick={onMinimize}
                    />
                ) : (
                    <FiMaximize
                        className={classnames(
                            controlButtonClassNames,
                            controlButtonPadding,
                        )}
                        size={ICON_SIZE}
                        onClick={onMaximize}
                    />
                )}
            </div>
        </div>
    </div>
);

function getFlagCount(node: TrellisNode): number {
    const cocaFlagCount = (node?.meta?.get(GraphV3MetadataType.CoCaFlagCount) ??
        0) as number;
    const wocoFlagCount = (node?.meta?.get(GraphV3MetadataType.WoCoFlagCount) ??
        0) as number;
    return cocaFlagCount + wocoFlagCount;
}

export const EntitiesContext = createContext<Map<string, Entity>>(new Map());

const options = {
    centerStrength: 0.05,
    // nodeStrength: -600, // haven't understood/found what this exactly corresponds to in the d3 force graph docs, might want to play around with it a bit
    // linkDistance: 180, // this is the distance between nodes, so if we want to make the graph more spread out, we could try increasing this a bit
    // linkStrength: undefined, // we don't ever want to change this bc we can only pass number here but the default value is a pretty nifty dynamic function https://d3js.org/d3-force/link#link_strength
    // centerStrength: 0.02, // this could work well to push everything apart a little bit more, if we move the graph to be centered on the root node first
    // nodePadding: 75, // this might be the "spacing" you were looking for instead of changing radius, but it also makes the hexagons happen
    // tick: 300 // probably don't want to change this, since this changes how often the graph is recalculated
};

export const SIDEBAR_WIDTH = 300; // equivalent to w-75

export type ConnectedEntities = {
    connected_nodes: Set<string>;
    connected_edges: Set<string>;
};

export const Graph: React.FC<{
    data: {
        nodes: TrellisNode[];
        edges: TrellisEdge[];
        entities: Map<string, Entity>;
        precomputedConnections: Map<string, ConnectedEntities>;
    };
    onWidthChange?: (width: number) => void;
    onMaximize?: () => void;
    onMinimize?: () => void;
}> = ({
    data: { nodes, edges, entities, precomputedConnections },
    onWidthChange = () => null,
    onMaximize = () => null,
    onMinimize = () => null,
}) => {
    const {
        width,
        height,
        ref: target_ref,
    } = useResizeDetector<HTMLDivElement>({
        onResize: (payload) => onWidthChange(payload.width ?? 0),
    });

    const sidebarRef = useRef<HTMLDivElement>(null);

    const [maximized, setMaximized] = useState(false);

    const current_width = useRef<number>();
    const current_height = useRef<number>();

    const [graph, set_graph] = useState<{
        nodes: TrellisNode[];
        edges: TrellisEdge[];
        x: number;
        y: number;
        zoom: number;
        hovered_node: string | undefined;
        hovered_edge: string | undefined;
        active_node: string | undefined;
        entity_path: Entity[];
    }>({
        nodes: [],
        edges: [],
        x: 0,
        y: 0,
        zoom: 1,
        hovered_node: undefined,
        hovered_edge: undefined,
        active_node: undefined,
        entity_path: [],
    });

    const [initial_viewport, set_initial_viewport] = useState({
        x: 0,
        y: 0,
        zoom: 1,
    });

    useEffect(() => {
        current_width.current = width;
        current_height.current = height;

        force({ nodes, edges, options }).then(
            ({
                nodes,
                edges,
            }: {
                nodes: TrellisNode[];
                edges: TrellisEdge[];
            }) => {
                const { x, y, zoom } = boundsToViewport(
                    getSelectionBounds(nodes, 60),
                    {
                        width: current_width.current
                            ? current_width.current - SIDEBAR_WIDTH
                            : 0,
                        height: current_height.current ?? 0,
                    },
                );

                set_initial_viewport({ x, y, zoom });

                set_graph((graph) => ({
                    ...graph,

                    nodes,
                    edges,
                    x,
                    y,
                    zoom,
                }));
            },
        );
    }, [width, height, nodes, edges]);

    const on_node_drag = useCallback(
        ({
            nodeX: node_x,
            nodeY: node_y,
            target: { id },
        }: {
            nodeX: number;
            nodeY: number;
            target: { id: string };
        }) => {
            set_graph((graph) => ({
                ...graph,
                nodes: graph.nodes.map((node) =>
                    node.id === id ? { ...node, x: node_x, y: node_y } : node,
                ),
            }));
        },
        [],
    );

    const on_viewport_drag = useCallback(
        ({
            viewportX: x,
            viewportY: y,
        }: {
            viewportX: number;
            viewportY: number;
        }) => {
            set_graph((graph) => ({ ...graph, x, y }));
        },
        [],
    );

    const on_viewport_wheel = useCallback(
        ({
            viewportX: x,
            viewportY: y,
            viewportZoom: zoom,
        }: {
            viewportX: number;
            viewportY: number;
            viewportZoom: number;
        }) => {
            set_graph((graph) => ({ ...graph, x, y, zoom }));
        },
        [],
    );

    const on_node_pointer_enter = useCallback(
        ({ target: { id } }: { target: { id: string } }) => {
            set_graph((graph) => {
                return {
                    ...graph,
                    hovered_node: id,
                };
            });
        },
        [],
    );

    const on_node_pointer_leave = useCallback(() => {
        set_graph((graph) => ({
            ...graph,
            hovered_node: undefined,
        }));
    }, []);

    const on_edge_pointer_enter = useCallback(
        ({ target: { id } }: { target: { id: string } }) => {
            if (graph.hovered_edge !== id) {
                set_graph((graph) => {
                    return {
                        ...graph,
                        hovered_edge: id,
                    };
                });
            }
        },
        [],
    );

    const on_edge_pointer_leave = useCallback(() => {
        set_graph((graph) => ({
            ...graph,
            hovered_edge: undefined,
        }));
    }, []);

    const on_node_click = useCallback(
        (e: NodePointerEvent) => {
            const { id } = e.target;

            if (graph.active_node === id) {
                set_graph((graph) => ({
                    ...graph,
                    active_node: undefined,
                    entity_path: [],
                }));
                return;
            }

            const entity = entities.get(id);
            if (entity) {
                set_graph((graph) => {
                    return {
                        ...graph,
                        active_node: id,
                        entity_path: [entity],
                    };
                });
            }
        },
        [entities, graph.active_node],
    );

    // const on_node_double_click = useCallback(
    //     ({ target: { id } }: { target: { id: string } }) => {
    //         const entity = entities.get(id);
    //         // console.log('doubleClick', id, entity);
    //         // console.log('graph', graph);
    //         if (entity) {
    //             set_graph((graph) => ({
    //                 ...graph,
    //                 entity_path: [entity],
    //                 edges: graph.edges.filter(
    //                     (edge) => edge.source === id || edge.target === id,
    //                 ),
    //             }));
    //         }
    //     },
    //     [entities],
    // );

    const on_entity_click = useCallback(
        (id: string) => {
            const entity = entities.get(id);
            if (entity) {
                set_graph((graph) => {
                    const node = graph.nodes.find((node) => node.id === id);

                    return {
                        ...graph,
                        active_node: id,
                        entity_path: [...graph.entity_path, entity],
                        x: -1 * (node?.x ?? 0),
                        y: -1 * (node?.y ?? 0),
                    };
                });
            }
        },
        [entities],
    );

    const on_back = useCallback(() => {
        set_graph((graph) => ({
            ...graph,
            entity_path: graph.entity_path.slice(0, -1),
        }));
    }, []);

    const root_node = nodes.find((node) => node.depth === 0);
    const root_entity = entities.get(root_node?.id ?? '0');

    const selected_entity =
        graph.entity_path.length > 0
            ? graph.entity_path[graph.entity_path.length - 1]
            : root_entity;

    useEffect(() => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollTop = 0;
        }
    }, [selected_entity]);

    const { connected_nodes: active_nodes, connected_edges: active_edges } =
        useMemo(
            () =>
                precomputedConnections.get(graph.active_node ?? '') ?? {
                    connected_nodes: new Set(),
                    connected_edges: new Set(),
                },
            [graph.active_node],
        );

    const styled_nodes = useMemo(
        () =>
            graph.nodes.map((node) =>
                styleNode(
                    node,
                    graph.hovered_node === node.id,
                    selected_entity !== undefined &&
                        selected_entity.id === node.id,
                    active_nodes.size > 0,
                    active_nodes.has(node.id),
                    getFlagCount(node),
                ),
            ),
        [graph.nodes, graph.hovered_node, active_nodes],
    );

    const styled_edges = useMemo(
        () =>
            graph.edges.map(
                (edge) =>
                    ({
                        ...edge,
                        label:
                            (active_edges.size > 0 &&
                                active_edges.has(edge.id) &&
                                edge.label.length > 5) ||
                            graph.hovered_edge === edge.id
                                ? edge.label
                                : edge.label.slice(0, 5) + '...',
                        style: {
                            label: {
                                fontFamily: 'OpenSans',
                                fontSize: 8,
                                color:
                                    active_edges.size > 0 &&
                                    !active_edges.has(edge.id) &&
                                    graph.hovered_edge !== edge.id
                                        ? '#EEE'
                                        : '#000',
                            },
                            width: 1,
                            arrow: 'forward' as const,
                            stroke:
                                active_edges.has(edge.id) ||
                                graph.hovered_edge === edge.id
                                    ? '#000'
                                    : undefined,
                        },
                    } as TrellisEdge),
            ),
        [graph.edges, active_edges, graph.hovered_edge],
    );

    if (width === undefined || height === undefined) {
        return (
            <div
                ref={target_ref}
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <span />
            </div>
        );
    }

    return (
        <div ref={target_ref} className="relative overflow-hidden flex h-full">
            <GraphRenderer
                width={width - SIDEBAR_WIDTH}
                height={height}
                nodes={styled_nodes}
                edges={styled_edges}
                x={graph.x}
                y={graph.y}
                zoom={graph.zoom}
                onNodeDrag={on_node_drag}
                onNodePointerEnter={on_node_pointer_enter}
                onNodePointerLeave={on_node_pointer_leave}
                onEdgePointerEnter={on_edge_pointer_enter}
                onEdgePointerLeave={on_edge_pointer_leave}
                onViewportDrag={on_viewport_drag}
                onViewportWheel={on_viewport_wheel}
                onNodeClick={on_node_click}
                // onNodeDoubleClick={on_node_double_click}
                maximized={maximized}
                onMaximize={() => {
                    onMaximize();
                    setMaximized(true);
                }}
                onMinimize={() => {
                    onMinimize();
                    setMaximized(false);
                }}
                onZoomIn={() =>
                    set_graph((graph) => ({ ...graph, zoom: graph.zoom * 1.5 }))
                }
                onZoomOut={() =>
                    set_graph((graph) => ({ ...graph, zoom: graph.zoom / 1.5 }))
                }
                onResetView={() =>
                    set_graph((graph) => ({ ...graph, ...initial_viewport }))
                }
            />
            <div
                ref={sidebarRef}
                className="h-full bg-neutral-100 p-6 overflow-y-auto shadow-lg drop-shadow-lg break-words print:hidden space-y-4"
                style={{
                    width: SIDEBAR_WIDTH,
                }}
            >
                <EntitiesContext.Provider value={entities}>
                    <EntityDetails
                        entity={selected_entity}
                        onEntityClick={on_entity_click}
                        onBack={on_back}
                        entityPath={graph.entity_path}
                    />
                </EntitiesContext.Provider>
            </div>
        </div>
    );
};
