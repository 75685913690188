import { FC, useEffect, useState } from 'react';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, LoadingSpinner } from '_atoms';
import { Table } from '_organisms/Table';
import { routes } from '../../../routes';
import {
    useCaseAgentsQuery,
    useCaseQuery,
    useUpdateCaseAgentsMutation,
    useUsersQuery,
} from '_queries';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

export const AssignCaseAgents: FC = () => {
    const history = useHistory();
    const { caseId } = useParams<{ caseId: string }>();
    const { t } = useTranslation();

    const { data: caseData, ...caseQuery } = useCaseQuery(caseId);
    const { data: caseAgentsData, ...caseAgentsQuery } =
        useCaseAgentsQuery(caseId);
    const { data: usersData = [], ...usersQuery } = useUsersQuery();
    const updateCaseAgentsMutation = useUpdateCaseAgentsMutation();

    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

    const handleSubmit = () => {
        updateCaseAgentsMutation.mutate({
            caseId,
            userIds: selectedUserIds ?? [],
        });
    };

    useEffect(() => {
        if (caseAgentsData) {
            setSelectedUserIds(caseAgentsData.map(({ userId }) => userId));
        }
    }, [caseAgentsData]);

    if (caseAgentsQuery.isError) {
        history.push(generatePath(routes.caseShow.path, { caseId }), {
            error: t('caseAgentsAssignError'),
        });
    }

    if (updateCaseAgentsMutation.isSuccess) {
        history.push(generatePath(routes.caseShow.path, { caseId }), {
            success: t('caseAgentsUpdated'),
        });
    }

    if (caseQuery.isLoading) {
        return (
            <div className="mt-20">
                <LoadingSpinner message={t('profileLoading')} />
            </div>
        );
    }

    return (
        <DefaultPageLayout
            title={`${t('assignUserToCase')} "${caseData?.title}"`}
            headerActions={
                <div className="flex justify-end space-x-4">
                    <Link to={generatePath(routes.caseShow.path, { caseId })}>
                        <Button
                            level="primaryGhost"
                            disabled={updateCaseAgentsMutation.isLoading}
                        >
                            {t('back')}
                        </Button>
                    </Link>

                    <Button
                        disabled={
                            updateCaseAgentsMutation.isLoading ||
                            selectedUserIds.length === 0
                        }
                        level="primary"
                        onClick={handleSubmit}
                    >
                        {t('save')}
                    </Button>
                </div>
            }
        >
            <Table
                headlines={[t('name'), t('email')]}
                items={usersData.map(({ id, firstname, lastname, email }) => ({
                    id,
                    name: `${firstname} ${lastname}`,
                    email,
                }))}
                additions={{
                    checkbox: true,
                }}
                selectedItems={selectedUserIds}
                setSelectedItems={setSelectedUserIds}
            />
            {usersQuery.isLoading && <LoadingSpinner />}
        </DefaultPageLayout>
    );
};
