import { Form, Formik } from 'formik';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useParams } from 'react-router-dom';
import { routes } from '../../../routes';
import {
    Button,
    DatePicker,
    Error,
    Headline,
    LoadingSpinner,
    StyledField,
} from '_atoms';
import { useCustomerQuery, useUpdateCustomerMutation } from '_queries';
import { UserInputForUpdateCustomer } from '@indicium/common';
import { UserAdmin } from '../UserAdmin';

export const CustomerAdminUpdate: FC = () => {
    const { customerId } = useParams<{ customerId: string }>();
    const { t } = useTranslation();

    const { data: customer, ...customerQuery } = useCustomerQuery(customerId);

    const { mutate, error, isError, isLoading, isSuccess } =
        useUpdateCustomerMutation();

    const handleSubmit = (params: UserInputForUpdateCustomer) =>
        mutate([customerId, params]);

    if (isSuccess) {
        return <Redirect to={routes.customerAdmin.path} />;
    }

    if (isError) {
        console.error(
            `Something went wrong, could not update Customer: ${error}`,
        );
    }

    return (
        <Fragment>
            <div className="container-fluid mb-20 px-16">
                <div className="row">
                    <Headline
                        Level="h3"
                        color="dark"
                        className="mt-8 mb-4"
                        weight="bold"
                    >
                        {`${t('updateCustomer.action')}${
                            customer ? `: ${customer.name}` : ''
                        }`}
                    </Headline>
                </div>

                {customerQuery.isLoading && <LoadingSpinner />}

                {customerQuery.isError && (
                    <Error
                        headline={t('noPageFound')}
                        message={t('goBack')}
                        target={{
                            link: routes.customerAdmin.path,
                            text: t('back'),
                        }}
                    />
                )}

                {customer && (
                    <Formik
                        initialValues={{
                            name: customer.name,
                            limitOfReports: customer.limitOfReports,
                            expiresAt: customer.expiresAt,
                        }}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <div className="mb-6 rounded-md bg-neutral-100 pt-5 px-6 pb-9">
                                <section className="row space-y-7">
                                    <div className="col-12">
                                        <Headline
                                            Level="h4"
                                            color="dark"
                                            weight="bold"
                                        >
                                            {t('updateCustomer.formHeadline')}
                                        </Headline>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <StyledField
                                            type="text"
                                            name="name"
                                            label={t('companyName')}
                                        />
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <StyledField
                                            type="number"
                                            name="limitOfReports"
                                            label={t('limitOfReports')}
                                        />
                                    </div>
                                    <div className="col-12 md:col-3 lg:col-2">
                                        <span className="block text-sm font-medium text-neutral-500 mb-1">
                                            {t('expiresAt')}
                                        </span>
                                        <StyledField name="expiresAt">
                                            {({
                                                field,
                                                form: { setFieldValue },
                                            }) => {
                                                return (
                                                    <DatePicker
                                                        selected={
                                                            field.value
                                                                ? new Date(
                                                                      field.value as string,
                                                                  )
                                                                : null
                                                        }
                                                        dateFormat="dd.MM.yyyy"
                                                        onChange={(date) =>
                                                            setFieldValue(
                                                                field.name,
                                                                date,
                                                            )
                                                        }
                                                        minDate={new Date()}
                                                        isClearable
                                                    />
                                                );
                                            }}
                                        </StyledField>
                                    </div>
                                </section>
                            </div>

                            <div className="flex justify-end space-x-6">
                                <div className="col-4">
                                    <Link to={routes.customerAdmin.path}>
                                        <Button
                                            className="w-full"
                                            level="primaryGhost"
                                            type="button"
                                        >
                                            {t('back')}
                                        </Button>
                                    </Link>
                                </div>
                                <div className="col-4">
                                    <Button
                                        disabled={isLoading}
                                        className="w-full"
                                        level="primary"
                                        type="submit"
                                    >
                                        {t('continue')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                )}
            </div>
            <UserAdmin customerIdOverride={customerId} />
        </Fragment>
    );
};
