import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '_atoms';
import { SliderVertical } from '_organisms';
import '../../../i18n';
import { FallbackImage } from '../FallbackImage/FallbackImage';
import { ImageAspectRatio } from '../../_atoms/Image/Image';

type ProfileImageProps = {
    images: string[];
    onClick?: () => void;
    aspectRatio?: keyof typeof ImageAspectRatio;
};

export const ProfileImage: FC<ProfileImageProps> = ({
    images,
    onClick,
    aspectRatio,
}) => {
    const { t } = useTranslation();

    if (images.length === 0) {
        return <FallbackImage />;
    }

    if (images.length === 1) {
        return (
            <Image
                aspectRatio={aspectRatio || 'portrait'}
                alt={t('fallbackProfileImage')}
                src={images[0]}
                className="rounded-md"
                onClick={onClick}
            />
        );
    }

    return <SliderVertical images={images} onClick={onClick} />;
};
