import { FC, Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import { RadioGroup as HUIRadioGroup } from '@headlessui/react';
import { Headline, Paragraph } from '_atoms';
import { nonProdDataTestId } from '_utils';

type RadioGroupOption = {
    label: string;
    value: string;
    title?: string;
};

type RadioGroupProps = {
    className?: string;
    options: RadioGroupOption[];
    // TODO: improve typing; allowed string should appear as 'value' in 'options'
    preselected?: string;
    title?: string;
    onSelect?: (value: string) => void;
    disabled?: boolean;
};

export const RadioGroup: FC<RadioGroupProps> = ({
    className,
    options,
    preselected,
    title,
    onSelect,
    disabled = false,
}: RadioGroupProps) => {
    const [selected, setSelected] = useState(preselected);

    useEffect(() => setSelected(preselected), [preselected]);

    const handleChange = (value: string) => {
        if (onSelect) {
            onSelect(value);
        }
        setSelected(value);
    };

    return (
        <HUIRadioGroup
            className={className}
            value={selected}
            onChange={handleChange}
            disabled={disabled}
        >
            {title ? (
                <HUIRadioGroup.Label className="sr-only">
                    {title}
                </HUIRadioGroup.Label>
            ) : null}
            {options.map(({ label, value, title }, optionIdx) => (
                <HUIRadioGroup.Option
                    className={classnames(
                        'flex flex-row cursor-pointer mt-1 first:mt-0 border-2',
                        title ? 'px-4 py-3' : '',
                        preselected === value && title
                            ? 'bg-blue-100 border-primary-4'
                            : 'border-transparent',
                    )}
                    key={optionIdx}
                    value={value}
                    data-testid={nonProdDataTestId(`option${optionIdx + 1}`)}
                >
                    {({ active, checked }) => (
                        <Fragment>
                            <span
                                className={classnames(
                                    'flex-shrink-0',
                                    checked
                                        ? 'bg-primary-4 border-transparent'
                                        : 'bg-neutral-100 border-neutral-300',
                                    active
                                        ? 'ring-2 ring-offset-2 ring-blue-500'
                                        : '',
                                    'mt-0.5',
                                    'h-4 w-4 cursor-pointer rounded-full border flex items-center justify-center',
                                )}
                                aria-hidden="true"
                            >
                                <span className="rounded-full bg-neutral-100 w-1.5 h-1.5" />
                            </span>
                            {title ? (
                                <div className="col-12 my-0 row">
                                    <div className="col-8">
                                        <HUIRadioGroup.Label
                                            as={Headline}
                                            className="ml-3 mx-0 px-0 font-bold"
                                            color="dark"
                                            Level="h6"
                                        >
                                            {title}
                                        </HUIRadioGroup.Label>
                                        <HUIRadioGroup.Label
                                            as={Paragraph}
                                            className={classnames(
                                                'ml-3 mt-0 mb-1 first:mt-0 font-bold',
                                                checked ? 'text-primary-3' : '',
                                            )}
                                            lineHeight="small"
                                            size="small"
                                        >
                                            {label}
                                        </HUIRadioGroup.Label>
                                    </div>
                                </div>
                            ) : (
                                <HUIRadioGroup.Label
                                    as={Paragraph}
                                    className="ml-2 mt-1 first:mt-0"
                                    lineHeight="small"
                                    size="small"
                                >
                                    {label}
                                </HUIRadioGroup.Label>
                            )}
                        </Fragment>
                    )}
                </HUIRadioGroup.Option>
            ))}
        </HUIRadioGroup>
    );
};
