import { Card, DynamicSVG, Headline, Paragraph } from '_atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const DataPrivacy = (): React.ReactElement => {
    const { t } = useTranslation();

    const bulletPoints: string[] = t('caseNew.dataPrivacy.bullets', {
        returnObjects: true,
    });

    return (
        <Card className="min-h-full flex flex-col items-center p-8">
            <div className="flex justify-center mb-6 w-20 h-20">
                <DynamicSVG
                    src={'icons/gdpr'}
                    className="w-20 h-20 text-neutral-500"
                />
            </div>
            <Headline
                Level="h5"
                weight="bold"
                color="dark"
                className="mx-5 mb-3 text-center"
            >
                {t('caseNew.dataPrivacy.title')}
            </Headline>
            <ul className="list-disc mx-9">
                {bulletPoints.map((bullet, index) => (
                    <li key={index} className="text-neutral-400">
                        <Paragraph
                            weight="normal"
                            color="default"
                            className="inline"
                        >
                            {bullet}
                        </Paragraph>
                    </li>
                ))}
            </ul>
        </Card>
    );
};
