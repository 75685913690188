import { FC, Fragment, MutableRefObject } from 'react';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import {
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useInteractions,
    useRole,
} from '@floating-ui/react';

export type ActionButtonInjectedChildrenProps = {
    open: boolean;
    close: (
        focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>,
    ) => void;
};

export type ActionButtonProps = {
    icon?: JSX.Element;
    children: JSX.Element;
    label?: string;
};

export const ActionButton: FC<ActionButtonProps> = ({
    icon,
    label,
    children,
}) => {
    const { refs, floatingStyles, context } = useFloating({
        placement: 'bottom-start',
        open: false,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(10),
            flip({
                fallbackAxisSideDirection: 'start',
                padding: 5,
            }),
            shift({ padding: 5 }),
        ],
    });

    const focus = useFocus(context, {
        enabled: true,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        focus,
        dismiss,
        role,
    ]);

    return (
        <Popover className="relative">
            <Fragment>
                <Popover.Button
                    className="focus:outline-none flex flex-col items-center"
                    ref={refs.setReference}
                    {...getReferenceProps()}
                >
                    {icon}
                    {label && <span>{label}</span>}
                </Popover.Button>

                <FloatingPortal>
                    <Popover.Panel
                        className={classNames(
                            'absolute w-80 bg-neutral-100 shadow-md p-5 left-0 rounded-md z-20',
                        )}
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        <div tabIndex={0} className="focus:outline-none">
                            {children}
                        </div>
                    </Popover.Panel>
                </FloatingPortal>
            </Fragment>
        </Popover>
    );
};
