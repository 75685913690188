import { Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline, Select, StyledField } from '_atoms';
import { DynamicFormValues } from './DynamicFormValues';
import { DynamicFormValue, DynamicFormValueStatus } from '@indicium/common';
import { fields } from './fields';
import { noop, uniqueId } from 'lodash';
import { useKeywords } from './use-keywords';
import {
    EXCLUDE_KEYWORDS_LIMIT,
    INCLUDE_KEYWORDS_LIMIT,
    KEYWORD_LENGTH_LIMIT,
} from './config';
import { DynamicFormButton } from './DynamicFormButton';

interface DynamicFormProps {
    values: any;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => void;
    disabled?: boolean;
}

const getFormValues = (values: any) =>
    Object.keys(fields).reduce((acc, key) => {
        acc[key] = values[key] || [];
        return acc;
    }, {} as Record<string, any>);

export const DynamicForm: FC<DynamicFormProps> = ({
    values,
    setFieldValue,
    disabled = false,
}) => {
    const [field, setField] = useState<string | null>(null);
    const { t } = useTranslation();

    const fieldLabels: Record<string, string> = t('dynamicForm.fields', {
        returnObjects: true,
    });

    const formValues = getFormValues(values);
    const activeField = field ? fields[field] : null;

    const handleValueRemove = (
        field: string,
        value: DynamicFormValue,
    ): void => {
        const fieldValues = formValues[field];
        setFieldValue(
            field,
            fieldValues.filter(
                (item: DynamicFormValue) => item.id !== value.id,
            ),
        );
    };

    const handleValueAdd = (
        field: string,
        value: string,
        status: DynamicFormValueStatus,
    ): void => {
        const fieldValues = formValues[field];
        const fieldValue: DynamicFormValue = {
            id: uniqueId(),
            value,
            status,
        };
        setFieldValue(field, [...fieldValues, fieldValue]);
    };

    const handleButtonClicked = (
        values: any,
        status: DynamicFormValueStatus,
        { resetForm = noop, setFieldValue = noop }: Partial<FormikHelpers<any>>,
    ): void => {
        if (!field) {
            return;
        }
        handleValueAdd(field, values[field], status);
        resetForm();
        setFieldValue(field, '');
    };

    const handleFormReset = (
        _: any,
        { setFieldValue }: FormikHelpers<any>,
    ): void => {
        if (!field) {
            return;
        }
        setFieldValue(field, '');
    };

    const keywords = useKeywords(formValues);

    return (
        <div className="bg-neutral-100 rounded-md">
            <Headline Level="h4">{t('dynamicForm.title')}</Headline>
            <div className="pt-3 text-gray-600 text-sm leading-5">
                {t('dynamicForm.description')}
            </div>
            <DynamicFormValues
                includedKeywords={keywords.included}
                excludedKeywords={keywords.excluded}
                totalIncluded={keywords.totalIncluded}
                totalExcluded={keywords.totalExcluded}
                onRemove={handleValueRemove}
            />
            <div className="grid grid-cols-6 gap-6 items-end">
                <div className="col-span-1">
                    <Select
                        label={t('dynamicForm.selectField')}
                        options={Object.keys(fields).map((field) => ({
                            id: field,
                            value: field,
                            label: fieldLabels[field],
                        }))}
                        onChange={(field) => setField(field)}
                        addEmptyOption={true}
                        disabled={disabled}
                    />
                </div>
                <Formik
                    initialValues={{
                        ...(activeField?.initialValues || { value: '' }),
                    }}
                    validationSchema={activeField?.validationSchema}
                    onSubmit={noop}
                    onReset={handleFormReset}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnMount={true}
                >
                    {({ resetForm, setFieldValue, isValid, values }) => (
                        <>
                            <div className="col-span-3">
                                <StyledField
                                    type="text"
                                    name={field || 'value'}
                                    label={field ? fieldLabels[field] : ' '}
                                    required={true}
                                    disabled={!field}
                                    maxLength={KEYWORD_LENGTH_LIMIT}
                                />
                            </div>
                            <div className="col-span-2 flex w-full gap-6 h-14">
                                <DynamicFormButton
                                    isDisabled={
                                        disabled ||
                                        !field ||
                                        !isValid ||
                                        keywords.totalIncluded ===
                                            INCLUDE_KEYWORDS_LIMIT
                                    }
                                    onClick={() =>
                                        handleButtonClicked(
                                            values,
                                            DynamicFormValueStatus.Confirmed,
                                            {
                                                resetForm,
                                                setFieldValue,
                                            },
                                        )
                                    }
                                >
                                    {t('dynamicForm.buttons.include')}
                                </DynamicFormButton>
                                <DynamicFormButton
                                    level="error"
                                    isDisabled={
                                        disabled ||
                                        !field ||
                                        !isValid ||
                                        keywords.totalExcluded ===
                                            EXCLUDE_KEYWORDS_LIMIT
                                    }
                                    onClick={() =>
                                        handleButtonClicked(
                                            values,
                                            DynamicFormValueStatus.Ignored,
                                            {
                                                resetForm,
                                                setFieldValue,
                                            },
                                        )
                                    }
                                >
                                    {t('dynamicForm.buttons.exclude')}
                                </DynamicFormButton>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};
