import { FC } from 'react';
import classnames from 'classnames';
import {
    headerActionClassname,
    PageLayoutHeader,
    PageLayoutProps,
} from './DefaultPageLayout';
import { SiteWideAnnouncements } from './SiteWideAnnouncements';

export const StickyHeaderPageLayout: FC<PageLayoutProps> = (props) => {
    const { title, headerActions, headerSubActions, className, children } =
        props;
    return (
        <div className={className}>
            <SiteWideAnnouncements />
            <div className="bg-neutral-250 sticky left-0 top-0 z-8">
                <div
                    className={classnames(
                        'container-fluid xl:max-w-max-content px-9 pt-9 mx-auto relative',
                        title && 'pb-8',
                        title &&
                            headerActions &&
                            !headerSubActions &&
                            headerActionClassname,
                        headerSubActions && 'space-y-7',
                    )}
                >
                    <PageLayoutHeader {...props} />
                </div>
            </div>
            <div
                className={classnames(
                    'container-fluid xl:max-w-max-content relative pb-9 px-9',
                )}
            >
                {children}
            </div>
        </div>
    );
};
