import { FC, HTMLAttributes, useState } from 'react';
import { SearchEngineArticle } from 'src/services/searchService';
import { BaseCard, CollapsibleCardWithBar } from '_organisms';
import { CaseData, Target } from '../../../services/dataService';
import { EntitySuspicion } from '_types';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';
import {
    Button,
    ColoredBar,
    ConfidenceBar,
    Highlight,
    Image,
    Modal,
    Paragraph,
} from '_atoms';
import { getCdnUrl } from '_utils';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ConfidenceScoreMatches } from '_organisms/SearchCards/ConfidenceScore/ConfidenceScoreMatches';
import { IndicatorsWithTooltip } from '../../_atoms/Highlight/types';

interface SearchResultCardProps extends HTMLAttributes<HTMLDivElement> {
    data: SearchEngineArticle;
    query?: string;
    hideArticleType?: boolean;
    action?: JSX.Element;
    score?: number;
    status?: string;
    suspicionItemId?: string;
    targetData?: Target;
    caseData?: CaseData;
    suspicionItems?: Array<EntitySuspicion>;
    indicators: IndicatorsWithTooltip[];
}

export const SearchResultCard: FC<SearchResultCardProps> = ({
    data,
    query,
    hideArticleType = false,
    suspicionItems,
    indicators,
    caseData,
    ...props
}) => {
    const { t } = useTranslation();
    const { targetData } = props;

    const [showModal, setShowModal] = useState(false);
    const isNotProduction = process.env.REACT_APP_STAGE !== 'production';

    const highlightChunks = buildHighlightChunks({
        target: targetData,
        indicators,
        searchQuery: query,
        caseKeywords: caseData?.keywords,
    });

    const indicatorTooltips = new Map(
        indicators.map((i) => [i.highlightText, i.tooltipForText]),
    );

    const sourceName: Record<string, string> = t('sourceName', {
        returnObjects: true,
    });

    const expanded = !!query?.length;

    const confidenceScore =
        String(
            (
                (Math.max(Math.min(data.confidenceScore ?? 0, 500), -500) /
                    500 /
                    2 +
                    0.5) *
                100
            ).toPrecision(3),
        ) + '%';
    const fingerprintScore =
        ((data.fingerprintScore ?? 0) * 100).toPrecision(3)?.toString() + '%';
    const unifiedScore =
        ((data.unifiedScore ?? 0) * 100).toPrecision(3)?.toString() + '%';

    const collapsibleCard = (
        <CollapsibleCardWithBar
            borderColor="border-transparent"
            expanded={expanded}
        >
            <div className="flex flex-col spacing-y-3 w-full">
                <Paragraph size="default" color="dark" className="pb-4">
                    <Highlight
                        searchWords={highlightChunks}
                        textToHighlight={data.plaintext?.replace(/\n/g, ' ')}
                        tooltips={indicatorTooltips}
                    />
                </Paragraph>
                {Boolean(data.screenshot) && (
                    <Image
                        src={getCdnUrl(data.screenshot)}
                        alt={data.url}
                        aspectRatio="none"
                    />
                )}
            </div>
        </CollapsibleCardWithBar>
    );

    return (
        <BaseCard
            entityType="webpage"
            title={data.title}
            titleLink={data.url}
            item={data}
            hideArticleType={hideArticleType}
            suspicionItems={suspicionItems}
            highlight={highlightChunks}
            lastChild={collapsibleCard}
            {...props}
        >
            <ColoredBar
                className={classnames(
                    'pb-5 px-6 flex-col transition duration-500 ease bg-neutral-100',
                )}
            >
                <div className="w-full">
                    <div className="grid grid-cols-2">
                        <div>
                            <Paragraph size="label" color="light" weight="bold">
                                {t('source')}
                            </Paragraph>
                            <Paragraph
                                size="default"
                                color="dark"
                                weight="bold"
                                className="pt-0.5"
                            >
                                {!data.sources?.length
                                    ? t('noData')
                                    : data.sources.map((source, i) => (
                                          <span
                                              key={`${source}_${i}`}
                                              className="block"
                                          >
                                              <Highlight
                                                  searchWords={highlightChunks}
                                                  textToHighlight={
                                                      sourceName[
                                                          source.toLowerCase()
                                                      ]
                                                  }
                                              />
                                          </span>
                                      ))}
                            </Paragraph>
                        </div>

                        <div>
                            <Paragraph size="label" color="light" weight="bold">
                                {t('retrievalDate')}
                            </Paragraph>
                            <Paragraph
                                size="default"
                                color="dark"
                                weight="bold"
                                className="pt-0.5"
                            >
                                {data.createdDate}
                            </Paragraph>
                        </div>
                    </div>

                    {isNotProduction && (
                        <div className="grid grid-cols-3 pt-4">
                            <div>
                                <Paragraph
                                    size="label"
                                    color="light"
                                    weight="bold"
                                >
                                    {'Confidence Score'}
                                </Paragraph>
                                <Paragraph
                                    size="default"
                                    color="dark"
                                    weight="normal"
                                    className="pt-0.5"
                                >
                                    {confidenceScore}
                                </Paragraph>
                            </div>

                            <div>
                                <Paragraph
                                    size="label"
                                    color="light"
                                    weight="bold"
                                >
                                    {'Fingerprint Score'}
                                </Paragraph>
                                <Paragraph
                                    size="default"
                                    color="dark"
                                    weight="normal"
                                    className="pt-0.5"
                                >
                                    {fingerprintScore ?? ''}
                                </Paragraph>
                            </div>

                            <div>
                                <Paragraph
                                    size="label"
                                    color="light"
                                    weight="bold"
                                >
                                    {'Unified Score'}
                                </Paragraph>
                                <Paragraph
                                    size="default"
                                    color="dark"
                                    weight="normal"
                                    className="pt-0.5"
                                >
                                    {unifiedScore ?? ''}
                                </Paragraph>
                            </div>
                        </div>
                    )}

                    {Boolean(data.description?.length) && (
                        <div>
                            <Paragraph
                                size="label"
                                color="light"
                                weight="bold"
                                className="pt-5"
                            >
                                {t('preview')}
                            </Paragraph>
                            <Paragraph
                                size="default"
                                color="dark"
                                className="pt-0.5 wrapper"
                            >
                                <Highlight
                                    searchWords={highlightChunks}
                                    textToHighlight={data.description}
                                />
                            </Paragraph>
                        </div>
                    )}
                    {typeof data.confidenceScore === 'number' ? (
                        <>
                            <ConfidenceBar
                                confidenceScore={data.confidenceScore}
                                className="mt-5"
                                onClick={() => setShowModal(true)}
                            />
                            <Modal
                                isOpen={showModal}
                                title={t('confidenceScore.matchesLabel')}
                                onClose={() => setShowModal(false)}
                                maxWidth="max-w-4xl"
                                className="text-center"
                            >
                                <div className="text-left space-y-3">
                                    <div className="bg-success-1/15 p-3 rounded-xl">
                                        <ConfidenceScoreMatches
                                            data={
                                                data.confidenceScoreMatches
                                                    ?.positive ?? {}
                                            }
                                            isPositive={true}
                                        />
                                    </div>
                                    <div className="bg-error-1/15 p-3 rounded-xl">
                                        <ConfidenceScoreMatches
                                            data={
                                                data.confidenceScoreMatches
                                                    ?.negative ?? {}
                                            }
                                            isPositive={false}
                                        />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Button
                                        level="primaryGhost"
                                        onClick={() => setShowModal(false)}
                                        className="min-w-48"
                                    >
                                        {t('closeButton')}
                                    </Button>
                                </div>
                            </Modal>
                        </>
                    ) : null}
                </div>
            </ColoredBar>
        </BaseCard>
    );
};
