import {
    BeneficialOwner,
    Company,
    Director,
    LinkedEntity,
    Shareholder,
    Subsidiary,
} from '../../types';
import React from 'react';
import { EntitiesContext } from '../Graph';
import { formatDate, formatShareholderEntityType } from '../../logic/utils';
import { LinkedEntityListing } from '../LinkedEntityListing';
import { ComplianceFlags } from '../ComplianceFlags';

export const CompanyBeneficialOwners: React.FC<{
    beneficialOwners: BeneficialOwner[];
    onEntityClick?: (id: string) => void;
}> = ({ beneficialOwners, onEntityClick }) => {
    const formattedBeneficialOwners: [
        string,
        {
            name: string;
            items: {
                id: string;
                title: string;
                details: { label: string; value: string }[];
            }[];
            markInactive: boolean;
        },
    ][] = beneficialOwners.map((bo) => {
        return [
            bo.id,
            {
                name: bo.name,
                items: [
                    {
                        id: bo.id,
                        title: formatShareholderEntityType(bo.entity_type),
                        details: [
                            {
                                label: 'Address',
                                value: [
                                    bo.address,
                                    bo.city,
                                    bo.postcode,
                                    bo.country_iso_code,
                                ]
                                    .filter(Boolean)
                                    .join(', '),
                            },
                            {
                                label: 'Gender',
                                value: bo.gender || 'Not specified',
                            },
                            {
                                label: 'UCI',
                                value: bo.uci || 'Not available',
                            },
                        ].filter((detail) => detail.value !== ''),
                    },
                ],
                markInactive: false,
            },
        ];
    });

    return (
        <LinkedEntityListing
            label="Beneficial Owners"
            linkedEntities={formattedBeneficialOwners}
            onEntityClick={onEntityClick}
            formatDate={(dateString: string) => {
                // Format date if needed
                return new Date(dateString).toLocaleDateString();
            }}
        />
    );
};

export const CompanyDirectors: React.FC<{
    directors: Director[];
    onEntityClick?: (id: string) => void;
}> = ({ directors, onEntityClick }) => {
    const formattedDirectors: LinkedEntity[] = directors.reduce(
        (acc: LinkedEntity[], director) => {
            const existingDirector = acc.find(([id]) => id === director.id);

            const function_tag = [director.id, director.function.function].join(
                '_',
            );

            if (existingDirector) {
                const existingFunction = existingDirector[1].items.find(
                    (item) => item.title === director.function.function,
                );

                if (
                    !existingFunction ||
                    (existingFunction &&
                        director.function.status !== 'Previous')
                ) {
                    if (existingFunction) {
                        const index =
                            existingDirector[1].items.indexOf(existingFunction);
                        existingDirector[1].items.splice(index, 1);
                    }

                    existingDirector[1].items.push({
                        id: function_tag,
                        title: `${director.function.function}`,
                        details: [],
                    });

                    existingDirector[1].markInactive =
                        director.function.status === 'Previous';
                }
            } else {
                acc.push([
                    director.id,
                    {
                        name: director.full_name,
                        items: [
                            {
                                id: function_tag,
                                title: `${director.function.function}`,
                                details: [],
                            },
                        ],
                        markInactive: director.function.status === 'Previous',
                    },
                ]);
            }
            return acc;
        },
        [],
    );

    return (
        <LinkedEntityListing
            label="Directors"
            linkedEntities={formattedDirectors}
            onEntityClick={onEntityClick}
            formatDate={(dateString: string) => {
                // Format date if needed
                return new Date(dateString).toLocaleDateString();
            }}
        />
    );
};

export const CompanyShareholders: React.FC<{
    shareholders: Shareholder[];
    onEntityClick?: (id: string) => void;
}> = ({ shareholders, onEntityClick }) => {
    const formattedShareholders: LinkedEntity[] = shareholders.map(
        (shareholder) => {
            // console.log(shareholder.id, entities.get(shareholder.id));

            return [
                shareholder.id,
                {
                    name: shareholder.name,
                    items: [
                        {
                            id: shareholder.id,
                            title: `${formatShareholderEntityType(
                                shareholder.entity_type,
                            )}`,
                            details: [
                                {
                                    label: 'Name',
                                    value: [
                                        shareholder.salutation,
                                        shareholder.first_name,
                                        shareholder.last_name,
                                    ]
                                        .filter(Boolean)
                                        .join(' '),
                                },
                                {
                                    label: 'Address',
                                    value: [
                                        shareholder.address_line1,
                                        shareholder.address_line2,
                                        shareholder.address_line3,
                                        shareholder.address_line4,
                                        shareholder.city,
                                        shareholder.postcode,
                                        shareholder.country_iso_code,
                                    ]
                                        .filter(Boolean)
                                        .join(', '),
                                },
                            ].filter((detail) => detail.value !== ''),
                        },
                    ],
                    markInactive: false,
                },
            ];
        },
    );

    return (
        <LinkedEntityListing
            label="Shareholders"
            linkedEntities={formattedShareholders}
            onEntityClick={onEntityClick}
            formatDate={formatDate}
        />
    );
};
export const CompanySubsidiaries: React.FC<{
    subsidiaries: Subsidiary[];
    onEntityClick?: (id: string) => void;
}> = ({ subsidiaries, onEntityClick }) => {
    const entities = React.useContext(EntitiesContext);

    const formattedSubsidiaries: [
        string,
        {
            name: string;
            items: {
                id: string;
                title: string;
                details: { label: string; value: string }[];
            }[];
            markInactive: boolean;
        },
    ][] = subsidiaries
        .map((subsidiary) => {
            const entity = entities.get(subsidiary.id);
            if (entity?.type !== 'Company') {
                return null;
            }

            return [
                subsidiary.id,
                {
                    name: entity.name,
                    items: [
                        {
                            id: subsidiary.id,
                            title: `Level: ${subsidiary.level}, Total: ${
                                subsidiary.total_pct
                            }%${
                                subsidiary.direct_pct
                                    ? `, Direct: ${subsidiary.direct_pct}%`
                                    : ''
                            }`,
                            details: [
                                {
                                    label: 'Location',
                                    value: [
                                        subsidiary.city,
                                        subsidiary.state_province,
                                        subsidiary.country_iso_code,
                                    ]
                                        .filter(Boolean)
                                        .join(', '),
                                },
                                ...(subsidiary.nace_core_label
                                    ? [
                                          {
                                              label: 'Industry',
                                              value: subsidiary.nace_core_label,
                                          },
                                      ]
                                    : []),
                                {
                                    label: 'Source',
                                    value: subsidiary.information_source,
                                },
                                {
                                    label: 'Date',
                                    value: formatDate(
                                        subsidiary.information_date,
                                    ),
                                },
                            ],
                        },
                    ],
                    markInactive: false,
                },
            ];
        })
        .filter(Boolean) as [
        string,
        {
            name: string;
            items: {
                id: string;
                title: string;
                details: { label: string; value: string }[];
            }[];
            markInactive: boolean;
        },
    ][];

    return (
        <LinkedEntityListing
            label="Subsidiaries"
            linkedEntities={formattedSubsidiaries}
            onEntityClick={onEntityClick}
            formatDate={formatDate}
        />
    );
};

export const CompanyDetails: React.FC<{
    entity: Company;
    renderField: (label: string, value: string | number) => JSX.Element;
    onEntityClick: (id: string) => void;
    renderHeader: (title: string) => JSX.Element;
}> = ({ entity, renderField, onEntityClick, renderHeader }) => {
    return (
        <>
            {renderHeader(entity.name)}
            {entity.trade_register_number &&
                renderField(
                    'Trade Register Number',
                    entity.trade_register_number.join(', '),
                )}
            {entity.incorporation_date &&
                renderField(
                    'Incorporation Date',
                    formatDate(entity.incorporation_date),
                )}
            {entity.trade_description_en &&
                renderField('Trade Description', entity.trade_description_en)}
            {[
                entity.address_line1,
                entity.address_line2,
                entity.address_line3,
                entity.address_line4,
                entity.city,
                entity.postcode,
            ].filter(Boolean).length > 0 &&
                renderField(
                    'Address',
                    [
                        entity.address_line1,
                        entity.address_line2,
                        entity.address_line3,
                        entity.address_line4,
                        entity.city,
                        entity.postcode,
                    ]
                        .filter(Boolean)
                        .join(', '),
                )}
            {entity.phone_number &&
                renderField('Phone', entity.phone_number.join(', '))}
            {entity.email && renderField('Email', entity.email.join(', '))}
            {entity.website && (
                <div style={{ marginBottom: '15px' }}>
                    <h3
                        style={{
                            fontSize: '18px',
                            color: '#495057',
                            marginBottom: '5px',
                        }}
                    >
                        Website(s)
                    </h3>
                    {entity.website.map((website, index) => (
                        <a
                            key={index}
                            href={website}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                fontSize: '14px',
                                color: '#007bff',
                                textDecoration: 'none',
                                display: 'block',
                            }}
                        >
                            {website}
                        </a>
                    ))}
                </div>
            )}
            {entity.listing_status &&
                renderField('Listing Status', entity.listing_status)}
            <ComplianceFlags entity={entity} onEntityClick={onEntityClick} />
            <CompanyBeneficialOwners
                beneficialOwners={entity.bos ?? []}
                onEntityClick={onEntityClick}
            />
            <CompanyDirectors
                directors={entity.directors ?? []}
                onEntityClick={onEntityClick}
            />
            <CompanyShareholders
                shareholders={entity.shareholders ?? []}
                onEntityClick={onEntityClick}
            />
            <CompanySubsidiaries
                subsidiaries={entity.subsidiaries ?? []}
                onEntityClick={onEntityClick}
            />
        </>
    );
};
