import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { customers } from '../../services/nestApiService';
import { ErrorResponse, ResourceDeleteResponse } from 'src/types/NestAPI';

export const useDeleteCustomerMutation = (): UseMutationResult<
    ResourceDeleteResponse,
    ErrorResponse,
    Record<string, string>
> => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ customerId }) => customers.delete(customerId).then((r) => r.data),
        {
            onSuccess: () =>
                queryClient.invalidateQueries({
                    predicate: (query) => {
                        return query.queryKey.includes('customers');
                    },
                }),
        },
    );
};
