import { FC, Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Headline } from '_atoms';
import { noop } from 'lodash';
import { nonProdDataTestId } from '_utils';
import classnames from 'classnames';
import { RxCross2 } from 'react-icons/rx';

type ModalProps = {
    children: ReactNode;
    isOpen: boolean;
    title: string;
    onClose?: (value: boolean) => void;
    className?: string;
    maxWidth?: string;
    maxHeight?: string;
    noOverflow?: boolean;
    hideCloseButton?: boolean;
    static?: boolean;
};

export const Modal: FC<ModalProps> = ({
    children,
    isOpen,
    title,
    onClose = noop,
    className,
    maxWidth,
    maxHeight,
    noOverflow,
    hideCloseButton = false,
    static: _static,
}: ModalProps) => (
    <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={onClose}
            data-testid={nonProdDataTestId('popup')}
            static={_static}
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay
                        className="fixed inset-0 bg-neutral-500/75 transition-opacity"
                        style={{
                            pointerEvents: _static ? 'none' : 'auto',
                        }}
                    />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        className={classnames(
                            'inline-block align-bottom bg-neutral-100 rounded-2xl p-4 text-left overflow-hidden shadow-card transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6',
                            maxWidth ? maxWidth : 'sm:max-w-lg',
                            maxHeight ? maxHeight : 'sm:max-h-[80vh]',
                            noOverflow ? 'overflow-visible' : 'overflow-y-auto',
                        )}
                    >
                        <div
                            className={classnames(
                                'flex flex-col space-y-5',
                                className,
                            )}
                        >
                            <div className="flex justify-between items-center">
                                <Dialog.Title as={Headline} Level="h4">
                                    {title}
                                </Dialog.Title>
                                {Boolean(onClose) && !hideCloseButton && (
                                    <Button
                                        level="custom"
                                        className="text-primary-4"
                                        onClick={() => onClose(false)}
                                        icon={RxCross2}
                                    />
                                )}
                            </div>
                            {children}
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition.Root>
);
