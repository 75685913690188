import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Badge } from '_atoms';
import { DynamicFormValue, DynamicFormValueStatus } from '@indicium/common';

interface DynamicFormValueItemProps {
    field: string;
    item: DynamicFormValue;
    onRemove: (key: string, item: DynamicFormValue) => void;
}

export const DynamicFormValueItem: FC<DynamicFormValueItemProps> = ({
    field,
    item,
    onRemove,
}) => {
    const { t } = useTranslation();
    const { status, value } = item;

    const fields: Record<string, string> = t('dynamicForm.fields', {
        returnObjects: true,
    });

    return (
        <Badge
            background={
                status === DynamicFormValueStatus.Confirmed ? 'blue' : 'red'
            }
        >
            <div className="flex flex-row items-center gap-1 text-font-light">
                <div className="font-bold">{value}</div>
                <div>
                    <strong>({fields[field] || fields.default})</strong>
                </div>
                <button
                    type="button"
                    className="focus:outline-none"
                    onClick={() => onRemove(field, item)}
                >
                    <IoCloseCircleOutline />
                </button>
            </div>
        </Badge>
    );
};
