import { FC, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flags, { FlagComponent } from 'country-flag-icons/react/1x1';
import { ProfileData as ProfileDataType } from '../../../services/dataService';
import {
    mapSourceToText,
    replaceUmlauts,
    transformBirthdayRangeToString,
    transformResidenceToString,
} from '_utils';
import { LocalizationService, TargetReportSourceType } from '@indicium/common';
import { InfoBlock, InfoItem } from './InfoBlock';
import { uniqBy } from 'lodash';
import { Locale } from '@indicium/common/src/types/Locale/Locale';
import { UserInputTarget } from '@indicium/common';
import { Button, Paragraph } from '_atoms';
import { Modal } from '_atoms';
import { ContentType } from '_molecules';
import { TiDocumentText } from 'react-icons/ti';

export interface ProfileDataProps extends HTMLAttributes<HTMLDivElement> {
    data?: ProfileDataType;
    isOverview?: boolean;
    jobs: ReadonlyArray<InfoItem>;
    education?: UserInputTarget['education'];
}

interface MergedName {
    sources: TargetReportSourceType[];
    value: string;
}

const educationToString = (
    education: Required<UserInputTarget>['education'][number],
) => {
    const { title = '', type = '', date = '' } = education;
    const realDate =
        typeof date === 'string' ? date : `${date.start}-${date.end}`;
    return `${[type, title].join(' ')}${date ? ` (${realDate})` : ''}`;
};

const mergeNames = (
    nameData: ProfileDataType['name'],
    accessor: (nameData: {
        first: string;
        last: string;
        middle?: string;
    }) => string | undefined,
): MergedName[] => {
    const normalizedData = nameData
        .map((name) => ({
            value: replaceUmlauts(accessor(name.value) || ''),
            sources: name.sources,
        }))
        .filter((name) => Boolean(name.value));

    return uniqBy(normalizedData, 'value')
        .map((name, index) => {
            const duplicateSources = normalizedData
                .filter((n, i) => n.value === name.value && i !== index)
                .flatMap((n) => n.sources);

            return {
                ...name,
                sources: [...new Set([...name.sources, ...duplicateSources])],
            };
        })
        .sort((a, b) => b.sources.length - a.sources.length);
};

const transformNationalities = (
    nationalities: ProfileDataType['nationality'] = [],
    locale: string,
) =>
    nationalities.map(({ value, sources }) => {
        const country = LocalizationService.getCountry(value, locale as Locale);
        const Flag =
            country &&
            ((Flags as any)[country.iso2] as FlagComponent | undefined);

        return {
            value: country?.nationality ?? value,
            sources,
            icon: Flag ? <Flag className="w-5 h-5 rounded-full" /> : undefined,
        };
    });

export const ProfileData: FC<ProfileDataProps> = ({
    className,
    data,
    isOverview = false,
    jobs,
    education = [],
}: ProfileDataProps) => {
    const { t, i18n } = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const [modalContents, setModalContents] =
        useState<{
            headline: string;
            content: ContentType[];
        } | null>(null);

    if (!data) {
        return null;
    }

    const { name, birthday, age, residence, birthPlace } = data;

    const ages = (age || []).map((age) => ({
        ...age,
        value: t('yearsOld', { age: age.value }),
    }));

    const names = mergeNames(
        name,
        (nd) => `${nd.first} ${nd.middle ? nd.middle + ' ' : ''}${nd.last}`,
    );

    const birthdays = transformBirthdayRangeToString(birthday);
    const residences = transformResidenceToString(residence);
    const nationalities = transformNationalities(
        data.nationality,
        i18n.resolvedLanguage,
    );

    const educations =
        data.educations?.map((education) => ({
            ...education,
            value: education.value.display,
        })) ?? [];
    educations.push(
        ...education.map((e) => ({
            value: educationToString(e),
            sources: ['userinput' as TargetReportSourceType],
        })),
    );

    const deduplicatedEducation: Record<string, TargetReportSourceType[]> = {};

    for (const education of educations) {
        if (!deduplicatedEducation[education.value]) {
            deduplicatedEducation[education.value] = [];
        }
        deduplicatedEducation[education.value].push(...education.sources);
    }

    const educationContent = Object.entries(deduplicatedEducation).map(
        ([value, sources]) => ({ value, sources }),
    );

    const jobsContent = jobs.map((j) => ({
        value: j.value,
        additionalValue: j.additionalValue,
        sources: j.sources?.map((s) => s as TargetReportSourceType),
    }));

    const CUT_OFF = 6;
    const jobsNeedsToBeCutOff = jobs.length > CUT_OFF;
    const educationNeedsToBeCutOff = educationContent.length > CUT_OFF;

    return isOverview ? (
        <div className={className}>
            <div className="row space-y-3 py-3">
                <InfoBlock
                    title={t('infoBlockItem.birthday')}
                    infoList={birthdays}
                />
                <InfoBlock
                    title={t('infoBlockItem.nationality')}
                    infoList={nationalities}
                />
                <InfoBlock
                    title={t('infoBlockItem.birthPlace')}
                    infoList={birthPlace ?? []}
                />
            </div>
        </div>
    ) : (
        <div className={className}>
            <Modal
                isOpen={showModal}
                title={modalContents?.headline ?? ''}
                onClose={() => setShowModal(false)}
                maxWidth="max-w-4xl"
                maxHeight="max-h-[95vh]"
                className="text-center"
            >
                <div className="text-left max-h-[75vh] overflow-y-auto">
                    {modalContents?.content.map((content, index) => (
                        <Paragraph
                            key={index}
                            className="even:bg-neutral-200 p-2 rounded-md hyphens-auto break-words"
                        >
                            {`${content.value} - ${content.sources
                                ?.map(mapSourceToText)
                                .map(t)
                                .join(', ')}`}
                        </Paragraph>
                    ))}
                </div>
                <div className="text-center">
                    <Button
                        level="primaryGhost"
                        onClick={() => setShowModal(false)}
                        className="min-w-48"
                    >
                        {t('closeButton')}
                    </Button>
                </div>
            </Modal>
            <div className="row">
                <div className="col print:hidden">
                    <InfoBlock title={t('name')} infoList={names} />
                    {birthdays?.length ? (
                        <InfoBlock
                            title={t('infoBlockItem.birthday')}
                            infoList={birthdays}
                            containerClassName="py-1"
                        />
                    ) : (
                        <InfoBlock
                            title={t('infoBlockItem.age')}
                            infoList={ages}
                        />
                    )}
                    <InfoBlock
                        title={t('infoBlockItem.nationality')}
                        infoList={nationalities}
                        containerClassName="py-1"
                    />
                    <InfoBlock
                        title={t('infoBlockItem.placeOfBirth')}
                        infoList={birthPlace ?? []}
                        containerClassName="py-1"
                    />
                    <InfoBlock
                        title={t('infoBlockItem.placesLived')}
                        infoList={residences}
                        containerClassName="py-1"
                    />
                </div>
                <div className="col">
                    <InfoBlock
                        title={t('infoBlockItem.occupation')}
                        infoList={jobs.slice(0, CUT_OFF)}
                    />
                    {jobsNeedsToBeCutOff && (
                        <div
                            className="text-primary-4 font-bold mt-1.5 flex items-center cursor-pointer pl-1"
                            onClick={
                                jobsNeedsToBeCutOff
                                    ? () => {
                                          setModalContents({
                                              headline: t(
                                                  'infoBlockItem.occupation',
                                              ),
                                              content: jobsContent,
                                          });
                                          setShowModal(true);
                                      }
                                    : undefined
                            }
                        >
                            {t('seeXMore', { x: jobs.length - CUT_OFF })}
                            <TiDocumentText size={20} className="ml-1" />
                        </div>
                    )}
                </div>
                <div className="col">
                    <InfoBlock
                        title={t('infoBlockItem.education')}
                        infoList={educationContent.slice(0, CUT_OFF)}
                    />
                    {educationNeedsToBeCutOff && (
                        <div
                            className="text-primary-4 font-bold mt-1.5 flex items-center cursor-pointer pl-1"
                            onClick={
                                educationNeedsToBeCutOff
                                    ? () => {
                                          setModalContents({
                                              headline: t(
                                                  'infoBlockItem.education',
                                              ),
                                              content: educationContent,
                                          });
                                          setShowModal(true);
                                      }
                                    : undefined
                            }
                        >
                            {t('seeXMore', {
                                x: educationContent.length - CUT_OFF,
                            })}
                            <TiDocumentText size={20} className="ml-1" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
