import { FC, HTMLAttributes } from 'react';
import { CustomData, Highlight } from '_atoms';
import { HighlightChunks } from '../Highlight/types';

export const normalizeUrl = (url: string | undefined): string =>
    url ? (url.slice(0, 4) === 'http' ? url : `http://${url}`) : '';

interface SingleLinkDataProps extends HTMLAttributes<HTMLDivElement> {
    headline: string;
    href: string;
    hrefs?: Array<string>;
    width?: string;
    highlight?: HighlightChunks;
}

interface MultipleLinkDataProps extends HTMLAttributes<HTMLDivElement> {
    headline: string;
    href?: string;
    hrefs: Array<string> | undefined;
    width?: string;
    highlight?: HighlightChunks;
}

export const LinkData: FC<SingleLinkDataProps | MultipleLinkDataProps> = ({
    headline,
    href,
    hrefs = [href],
    width = 'w-1/5',
    className = '',
    highlight,
    ...props
}) => (
    <CustomData
        headline={headline}
        width={width}
        className={className}
        {...props}
    >
        {hrefs?.length && hrefs[0]
            ? hrefs
                  .filter((href): href is string => !!href)
                  .map((href) => (
                      <a
                          key={href}
                          href={normalizeUrl(href)}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="text-primary-4 block overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                          {highlight ? (
                              <Highlight
                                  searchWords={highlight}
                                  textToHighlight={href}
                              />
                          ) : (
                              href
                          )}
                      </a>
                  ))
            : null}
    </CustomData>
);
