import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Headline,
    Highlight,
    ImageData,
    SocialProfilePictureBubble,
    TextData,
} from '_atoms';
import { BaseCard } from '_organisms';
import { trimConnectionUserName } from '_organisms/SocialMedia/SocialMediaContacts';
import { formatDateTime, getCdnUrl } from '_utils';
import {
    SocialAccountConnectionArticle,
    SocialAccountPostArticle,
} from '../../../services/searchService';
import { CaseData, Target } from '../../../services/dataService';
import { EntitySuspicion } from '_types';
import {
    HighlightChunks,
    IndicatorsWithTooltip,
} from '../../_atoms/Highlight/types';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';

type SocialMediaData =
    | SocialAccountPostArticle
    | SocialAccountConnectionArticle;

interface SocialMediaCardProps extends HTMLAttributes<HTMLDivElement> {
    data: SocialMediaData;
    query?: string;
    accessToken: string;
    hideArticleType?: boolean;
    score?: number;
    status?: string;
    suspicionItemId?: string;
    targetData?: Target;
    caseData?: CaseData;
    suspicionItems?: Array<EntitySuspicion>;
    highlight?: HighlightChunks;
    indicators: IndicatorsWithTooltip[];
}

const SocialMediaContent: FC<SocialMediaCardProps> = ({
    data,
    highlight,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <div className="p-4" {...props}>
            {data.type === 'SocialAccountPost' ? (
                <>
                    {data.postImageCdnUrl ? (
                        <ImageData
                            headline={t('socialMedia.media')}
                            src={getCdnUrl(data.postImageCdnUrl)}
                            width="w-1/5"
                            enableZoom={false}
                        />
                    ) : null}
                    <div className="w-4/5 inline-block align-top">
                        <TextData
                            headline={t('socialMedia.datePublished')}
                            text={
                                data.postDatePublished
                                    ? formatDateTime(data.postDatePublished)
                                    : t('noData')
                            }
                            highlight={highlight}
                            width="w-1/5"
                        />
                        <TextData
                            headline={t('socialMedia.likeCount')}
                            text={
                                data.postLikeCount >= 0
                                    ? data.postLikeCount?.toLocaleString()
                                    : t('noData')
                            }
                            highlight={highlight}
                            width="w-1/5"
                        />
                        <p
                            className="mx-6 py-7 line-clamp-3 overflow-auto"
                            style={{ display: 'block' }}
                        >
                            <pre className="whitespace-pre-wrap break-words">
                                {highlight ? (
                                    <Highlight
                                        searchWords={highlight}
                                        textToHighlight={data.postBody || ''}
                                    />
                                ) : (
                                    data.postBody
                                )}
                            </pre>
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div className="pb-3 px-2 inline-block align-top w-1/6">
                        <Headline
                            className="uppercase"
                            Level="h6"
                            weight="bold"
                            color="light"
                        >
                            {t('socialMedia.profile')}
                        </Headline>
                        <SocialProfilePictureBubble
                            data={{
                                imageUrl: getCdnUrl(
                                    data.connectionProfileImageCdnUrl,
                                ),
                                network: data.accountType?.toLowerCase(),
                            }}
                            size="small"
                            className="w-16 h-20 ml-2"
                        />
                    </div>
                    <TextData
                        headline={t('socialMedia.connectionType')}
                        text={data.connectionType}
                        highlight={highlight}
                        width="w-1/5"
                    />
                    <TextData
                        headline={t('socialMedia.username')}
                        text={trimConnectionUserName(data.connectionUsername)}
                        highlight={highlight}
                        width="w-1/5"
                    />
                </>
            )}
        </div>
    );
};

export const SocialMediaCard: FC<SocialMediaCardProps> = ({
    data,
    query,
    accessToken,
    hideArticleType = false,
    suspicionItems,
    indicators,
    caseData,
    ...props
}) => {
    const { targetData } = props;

    const highlight = buildHighlightChunks({
        searchQuery: query,
        indicators,
        target: targetData,
        caseKeywords: caseData?.keywords,
    });

    return (
        <BaseCard
            entityType={
                data.type === 'SocialAccountPost'
                    ? 'socialPost'
                    : 'socialConnection'
            }
            title={
                data.type === 'SocialAccountPost'
                    ? data.postProfileName
                    : data.connectionProfileName
            }
            item={data}
            titleLink={
                data.type === 'SocialAccountPost'
                    ? data.postUrl
                    : data.connectionProfileUrl
            }
            hideArticleType={hideArticleType}
            suspicionItems={suspicionItems}
            highlight={highlight}
            {...props}
        >
            <SocialMediaContent
                data={data}
                accessToken={accessToken}
                highlight={highlight}
                indicators={indicators}
            />
        </BaseCard>
    );
};
