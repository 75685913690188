import classNames from 'classnames';
import { FC } from 'react';
import { Paragraph } from '_atoms';
import { ParagraphProps } from '../Paragraph/Paragraph';

export const Tag: FC<ParagraphProps> = (props) => {
    const { children, className, weight, color, ...remainingProps } = props;

    return (
        <Paragraph
            weight={weight ? weight : 'semibold'}
            color={color ? color : 'dark'}
            className={classNames(
                'px-2.5 py-1 rounded-full',
                className,
                !className?.includes('bg-') && 'bg-gray-100',
            )}
            HtmlTag="span"
            {...remainingProps}
        >
            {children}
        </Paragraph>
    );
};
