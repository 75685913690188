import React, {
    FC,
    PropsWithoutRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { get, noop } from 'lodash';
import { baseURL } from '../../../services/nestApiService';
import { TargetFormSchema } from '../../../schemas/targetFormSchema';
import { targetFormInitialValues } from '../../../features/targets/TargetForm/TargetForm';
import {
    Button,
    Modal,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { sanitizeInput } from '@indicium/common/src/utils';
import { UserInputTarget } from '@indicium/common';
import axios, { CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '../../_atoms/ProgressBar/ProgressBar';
import { IoCloudUploadSharp } from 'react-icons/io5';
import { MdDeleteOutline } from 'react-icons/md';
import classNames from 'classnames';
import { createTarget } from '../../../hooks/queries/useCreateTargetMutation';
import { useParams } from 'react-router-dom';
import type { Target } from '../../../services/dataService';
import { CiCircleAlert, CiCircleCheck } from 'react-icons/ci';
import { FaSpinner } from 'react-icons/fa';
import { useToast } from '../../../context/Toast';

type CVUploaderProps = PropsWithoutRef<{
    onUpload?: (
        data?: Required<FileState>['data']['target'],
        images?: Required<FileState>['data']['images'],
    ) => void;
    onCreate?: (createdTargets: Target[]) => void;
    onError?: (message: unknown) => void;
    customView: {
        openElement: (open: () => void) => JSX.Element;
    };
    multi?: boolean;
    maxFileCount?: number;
}>;

function readFileAsString(file: File): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject(new Error('result is not a string'));
            }
        };
        reader.readAsDataURL(file);
    });
}

type FileState = {
    uploadProgress?: number;
    creationProgress?: number;
    error?: string;
    data?: { target: UserInputTarget; images: string[] };
    source?: CancelTokenSource;
};

function getPossibleDataDescription(data: unknown): string | undefined {
    if (get(data, 'target')) {
        const title = get(data, 'target.title', '');
        const firstname = get(data, 'target.firstname', '');
        const middlename = get(data, 'target.middlename', '');
        const lastname = get(data, 'target.lastname', '');
        const res = [title, firstname, middlename, lastname].join(' ').trim();
        return `(${res})`;
    }
}

declare global {
    interface Window {
        cvUploader: {
            lastTarget: EventTarget | null;
        };
    }
}

export const CVUploader: FC<CVUploaderProps> = ({
    onUpload = noop,
    onCreate = noop,
    // onError = noop,
    customView,
    multi = false,
    maxFileCount = 1,
}: CVUploaderProps): JSX.Element => {
    const { t } = useTranslation();
    const { caseId } = useParams<{ caseId: string }>();

    const cvUploader = useRef<HTMLDivElement>(null);

    const toast = useToast();

    const listener = useCallback(
        (e: BeforeUnloadEvent) => {
            e.preventDefault();
            e.returnValue = t('bulkUpload.closeMessage');
            return t('bulkUpload.closeMessage');
        },
        [t],
    );

    const [isOver, setIsOver] = useState(false);
    const [isOpen, setIsOpen] = useState<'manual' | 'automatic' | false>(false);
    const [uploadedFiles, setUploadedFiles] = useState<
        Record<string, FileState>
    >({});

    const fileUploadsLeft = useMemo(
        () => maxFileCount - Object.keys(uploadedFiles).length,
        [maxFileCount, uploadedFiles],
    );
    const fileUploads = useMemo(
        () => Object.keys(uploadedFiles).length,
        [uploadedFiles],
    );

    /* deletes a file, in case of an upload, cancels the upload */
    const deleteFile = useCallback(
        (file: string) => {
            const state = uploadedFiles[file];
            if (state) {
                if (state.source) {
                    state.source.cancel();
                }
                setUploadedFiles((f) => {
                    const copy = { ...f };
                    delete copy[file];
                    return copy;
                });
            }
        },
        [uploadedFiles],
    );

    const cancelEverything = useCallback(() => {
        for (const file of Object.keys(uploadedFiles)) {
            deleteFile(file);
        }
        setUploadedFiles({});
        setIsOpen(false);
        return null;
    }, [deleteFile, uploadedFiles]);

    const uploadFile = useCallback(
        async (file: File) => {
            setIsOpen('automatic'); // open the modal in outside drag-and drop case
            if (uploadedFiles[file.name]) {
                return;
            }
            setUploadedFiles((f) => ({
                ...f,
                [file.name]: { uploadProgress: 0 },
            }));
            const rawData = await readFileAsString(file);
            const source = axios.CancelToken.source();
            setUploadedFiles((f) => ({
                ...f,
                [file.name]: { uploadProgress: 33, source },
            }));
            let data: Required<FileState>['data'];
            let error: string;
            try {
                data = await axios
                    .post(
                        `${baseURL}/targets/processcv`,
                        {
                            data: rawData,
                        },
                        {
                            cancelToken: source.token,
                        },
                    )
                    .then((res) => res.data.data)
                    .then(({ target, images }) => ({
                        target: Object.assign(
                            {},
                            targetFormInitialValues,
                            target,
                        ),
                        images,
                    }))
                    .then(({ target, images }) => ({
                        target: TargetFormSchema.validateSync(target),
                        images,
                    }))
                    .then(({ target, images }) => ({
                        target: sanitizeInput(target) as UserInputTarget,
                        images,
                    }));
                if (!multi) {
                    // if not multi we just want to upload one file and then close the modal
                    onUpload(data.target, data.images);
                    cancelEverything();
                }
            } catch (e) {
                if (e instanceof Error) {
                    error = e.message;
                }
                error = String(e);
            }
            setUploadedFiles((f) => {
                if (!f[file.name]) {
                    return f;
                }
                return {
                    ...f,
                    [file.name]: {
                        ...f[file.name],
                        uploadProgress: 100,
                        error,
                        data,
                    },
                };
            });
        },
        [cancelEverything, multi, onUpload, uploadedFiles],
    );

    const processFile = useCallback(
        async (file: string) => {
            const state = uploadedFiles[file];
            if (!state) {
                return;
            }
            if (state.uploadProgress !== 100) {
                return;
            }
            if (state.error || !state.data) {
                return;
            }
            setUploadedFiles((f) => ({
                ...f,
                [file]: {
                    ...f[file],
                    creationProgress: 0,
                },
            }));
            const result = await createTarget({
                caseId,
                cancelToken: state.source?.token,
                cvImages: state.data?.images,
                data: state.data?.target,
            });
            setUploadedFiles((f) => ({
                ...f,
                [file]: {
                    ...f[file],
                    creationProgress: 100,
                },
            }));
            return result;
        },
        [caseId, uploadedFiles],
    );

    const processFiles = useCallback(async () => {
        const createdTargets = [];
        for (const file of Object.keys(uploadedFiles)) {
            const createdTarget = await processFile(file);
            if (createdTarget) {
                createdTargets.push(createdTarget);
            }
        }
        cancelEverything();
        onCreate(createdTargets);
        toast({
            text: t('toasts.createdTargets', {
                count: createdTargets.length,
            }),
            style: 'confirmation',
            ms: 5000,
        });
        return createdTargets;
    }, [cancelEverything, onCreate, processFile, t, toast, uploadedFiles]);

    const onDropAccepted = useCallback(
        (files: File[]) => {
            setIsOpen('automatic');
            for (const file of files) {
                uploadFile(file);
            }
        },
        [uploadFile],
    );

    const validator = useCallback(
        (file: File) => {
            // max 25MB
            if (file.size > 25 * 1024 * 1024) {
                return {
                    code: 'file-too-large',
                    message: t('bulkUpload.fileTooLarge'),
                };
            }
            // if file with the same name is already uploaded
            if (uploadedFiles[file.name]) {
                return {
                    code: 'file-already-uploaded',
                    message: t('bulkUpload.fileAlreadyUploaded'),
                };
            }
            return null;
        },
        [t, uploadedFiles],
    );

    const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
        disabled:
            fileUploadsLeft <= 0 ||
            Object.values(uploadedFiles).some(
                (f) => f.creationProgress !== undefined,
            ),
        accept: {
            'application/pdf': ['.pdf'],
        },
        onDrop: () => {
            cvUploader.current?.style.setProperty('visibility', 'hidden');
            setIsOver(false);
        },
        onDropAccepted,
        validator,
        multiple: multi,
        maxFiles: multi ? fileUploadsLeft : undefined,
        noClick: true,
        noKeyboard: true,
        preventDropOnDocument: false,
    });

    useEffect(() => {
        if (isOpen && fileUploads > 0) {
            window.addEventListener('beforeunload', listener);
        } else {
            window.removeEventListener('beforeunload', listener);
        }
        return () => window.removeEventListener('beforeunload', listener);
    }, [fileUploads, isOpen, listener]);

    // #region Feat: drag and drop on the whole screen https://stackoverflow.com/questions/28226021/entire-page-as-a-dropzone-for-drag-and-drop */

    const enterListener = useCallback((e: DragEvent) => {
        if (e.dataTransfer?.types.indexOf('Files') === -1) {
            return;
        }
        window.cvUploader = { lastTarget: e.target };
        if (cvUploader.current) {
            if (!isOpen) {
                setIsOpen('automatic');
            }
            setIsOver(true);
            cvUploader.current.style.visibility = 'visible';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const leaveListener = useCallback(
        (e: DragEvent) => {
            if (
                cvUploader.current &&
                (e.target === window.cvUploader?.lastTarget ||
                    e.target === document)
            ) {
                cvUploader.current.style.visibility = 'hidden';
                setIsOver(false);
                if (isOpen === 'automatic' && fileUploads === 0) {
                    setIsOpen(false);
                }
            }
        },
        [fileUploads, isOpen],
    );

    useEffect(() => {
        window.addEventListener('dragenter', enterListener);
        window.addEventListener('dragleave', leaveListener);
        return () => {
            window.removeEventListener('dragenter', enterListener);
            window.removeEventListener('dragleave', leaveListener);
        };
    }, [enterListener, leaveListener, cvUploader]);

    // #endregion

    const isUploadingFile = useMemo(() => {
        return Object.values(uploadedFiles).some(
            (f) => f.uploadProgress !== undefined && f.uploadProgress < 100,
        );
    }, [uploadedFiles]);

    const isProcessingFile = useMemo(() => {
        return Object.values(uploadedFiles).some(
            (f) => f.creationProgress !== undefined && f.creationProgress < 100,
        );
    }, [uploadedFiles]);

    const isError = useMemo(() => {
        return Object.values(uploadedFiles).some((f) => f.error);
    }, [uploadedFiles]);

    return (
        <>
            <div
                {...getRootProps({
                    style: {
                        display: 'flex',
                        visibility: 'hidden',
                        opacity: 0,
                        backgroundColor: 'gray',
                        position: 'fixed',
                        top: 0,
                        left: '-15px',
                        width: '100vw',
                        height: '100vh',
                        zIndex: 50,
                        transition:
                            'opacity 0.25s ease-in-out, visiblity 0.25s ease-in-out',
                    },
                    ref: cvUploader,
                })}
            >
                <Modal
                    isOpen={isOpen !== false}
                    title={t('bulkUpload.title')}
                    maxWidth="max-w-4xl"
                    onClose={cancelEverything}
                    hideCloseButton
                    static
                >
                    {fileUploadsLeft === 0 ? (
                        <p className="text-neutral-500">
                            {t('bulkUpload.noFilesLeft')}
                        </p>
                    ) : (
                        <p className="text-neutral-500">
                            {t('bulkUpload.subtitle', {
                                count: fileUploadsLeft,
                                max: maxFileCount,
                            })}
                        </p>
                    )}
                    <div
                        className={classNames(
                            'w-full flex flex-col gap-4 items-center justify-center border-dashed border-2 border-neutral-600 rounded py-8',
                            isOver
                                ? 'bg-primary-4/10 border-primary-4'
                                : 'bg-neutral-200 border-neutral-600',
                        )}
                    >
                        <IoCloudUploadSharp
                            className="text-neutral-500"
                            size={70}
                        />
                        <p className="text-center text-neutral-500">
                            {t('bulkUpload.dragAndDrop1')}{' '}
                            <a
                                className="text-primary-4 underline font-semibold cursor-pointer"
                                onClick={open}
                            >
                                {t('bulkUpload.dragAndDrop2')}
                            </a>
                        </p>
                        <p className="text-error-1 italic">
                            {fileRejections.at(0)?.errors[0]?.message}
                        </p>
                    </div>
                    <div className="my-2">
                        {Object.entries(uploadedFiles).map(
                            ([filename, data]) => (
                                <div
                                    className="grid grid-cols-[24px_1fr_1fr_24px] gap-2 mt-2 items-center"
                                    key={filename}
                                >
                                    <MdDeleteOutline
                                        size={24}
                                        className={classNames(
                                            'text-neutral-500 mb-0.5 pointer-events-none',
                                            !isProcessingFile &&
                                                'hover:text-error-1 pointer-events-auto cursor-pointer ',
                                        )}
                                        onClick={() => deleteFile(filename)}
                                    />
                                    <div className="flex flex-row gap-1 overflow-hidden text-ellipsis whitespace-nowrap">
                                        {filename}
                                        <TooltipV2>
                                            <TooltipContentV2>
                                                {getPossibleDataDescription(
                                                    data.data,
                                                )}
                                            </TooltipContentV2>
                                            <TooltipTriggerV2 asChild>
                                                <p className="italic text-ellipsis overflow-hidden whitespace-nowrap">
                                                    {getPossibleDataDescription(
                                                        data.data,
                                                    )}
                                                </p>
                                            </TooltipTriggerV2>
                                        </TooltipV2>
                                    </div>
                                    <div className="-translate-y-[9px]">
                                        <p className="text-right pr-1">
                                            {data.error
                                                ? t('bulkUpload.error')
                                                : data.creationProgress === 100
                                                ? t('bulkUpload.processed')
                                                : data.creationProgress !==
                                                  undefined
                                                ? t('bulkUpload.submitting')
                                                : data.uploadProgress !== 100
                                                ? t('bulkUpload.uploading')
                                                : t('bulkUpload.success')}
                                        </p>
                                        <ProgressBar
                                            value={
                                                // data.creationProgress ??
                                                data.uploadProgress ?? 0
                                            }
                                            color={
                                                data.error
                                                    ? 'error'
                                                    : data.creationProgress ===
                                                      100
                                                    ? 'green'
                                                    : 'blue'
                                            }
                                        />
                                    </div>
                                    <div className="flex items-center justify-center">
                                        {data.error ? (
                                            <CiCircleAlert
                                                size={24}
                                                className="text-error-1"
                                            />
                                        ) : data.creationProgress === 100 ? (
                                            <CiCircleCheck
                                                size={24}
                                                className="text-success-1"
                                            />
                                        ) : data.creationProgress !==
                                          undefined ? (
                                            <FaSpinner
                                                size={20}
                                                className="animate-spin"
                                            />
                                        ) : data.uploadProgress !== 100 ? (
                                            <FaSpinner
                                                size={20}
                                                className="animate-spin"
                                            />
                                        ) : (
                                            <CiCircleCheck
                                                size={24}
                                                className="text-primary-4"
                                            />
                                        )}
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                    <div className="ml-auto mr-0 flex flex-row gap-4">
                        <Button
                            level="primaryGhost"
                            onClick={cancelEverything}
                            disabled={isProcessingFile}
                        >
                            {t('bulkUpload.cancel')}
                        </Button>
                        <Button
                            disabled={
                                fileUploads === 0 ||
                                isUploadingFile ||
                                isProcessingFile ||
                                isError
                            }
                            onClick={processFiles}
                        >
                            {t('bulkUpload.create')}
                        </Button>
                    </div>
                </Modal>
                <input {...getInputProps()} />
            </div>
            {customView?.openElement(() => setIsOpen('manual'))}
        </>
    );
};
