import { noop } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';
import { Suspicion, SuspicionItemEntity } from '_types';
import { suspicions } from '../../services/nestApiService';

export const useSuspicionItemEntityQuery = (
    entityId: string,
    targetId: string,
): UseQueryResult<SuspicionItemEntity & { suspicions: Suspicion[] }> =>
    useQuery(
        ['suspicions', 'items', 'entities', entityId],
        () => suspicions.items.entities.find(entityId, targetId).catch(noop),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );
