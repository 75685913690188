import React, { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useUserState } from '../../context/User';
import { useCustomerQuery } from '_queries';
import moment from 'moment/moment';
import { formatDateTime } from '_utils';
import { Card } from '_atoms';

const AnnouncementBanner: FC<{
    color: 'warning' | 'error';
    addWrapper?: boolean;
}> = ({ addWrapper, color, children }) => {
    const banner = (
        <Card
            className={classnames(
                'border-2 p-5 text-center',
                color === 'warning' ? 'border-warning-1' : 'border-error-1',
                !addWrapper && 'mb-8',
            )}
            backgroundColor={
                color === 'warning' ? 'bg-warning-1/15' : 'bg-error-1/15'
            }
        >
            {children}
        </Card>
    );

    if (!addWrapper) {
        return banner;
    }

    return (
        <div className="w-full text-center pt-8 px-9 xl:max-w-max-content mx-auto">
            {banner}
        </div>
    );
};

export const SiteWideAnnouncements: FC<{ addWrapper?: boolean }> = ({
    addWrapper, // if the announcements are positioned outside the content, we need a wrapper
}) => {
    const { t } = useTranslation();

    const [{ user, customerId }] = useUserState();

    const { data: customer } = useCustomerQuery(customerId, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const subscriptionAboutToExpireMessage =
        customer?.expiresAt &&
        moment(customer.expiresAt).isBetween(moment(), moment().add(1, 'week'))
            ? t('subscriptionAboutToExpire', {
                  expiration_date: formatDateTime(customer.expiresAt),
              })
            : '';

    const subscriptionExpiredMessage =
        customer?.expiresAt && moment(customer.expiresAt).isBefore(moment())
            ? t('subscriptionExpired', {
                  expiration_date: formatDateTime(customer.expiresAt),
              })
            : '';

    if (
        !user ||
        (!subscriptionAboutToExpireMessage && !subscriptionExpiredMessage)
    ) {
        return null;
    }

    return (
        <>
            {(subscriptionAboutToExpireMessage ||
                subscriptionExpiredMessage) && (
                // if the announcements are positioned outside the content, we need a wrapper
                <AnnouncementBanner
                    color={
                        subscriptionAboutToExpireMessage ? 'warning' : 'error'
                    }
                    addWrapper={addWrapper}
                >
                    {subscriptionAboutToExpireMessage ||
                        subscriptionExpiredMessage}
                </AnnouncementBanner>
            )}
        </>
    );
};
