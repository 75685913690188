import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, Headline, Paragraph } from '_atoms';
import { useUserState } from './../../../context/User';
import { ContactSupportButton } from '_organisms/SomethingWentWrongMessage/SomethingWentWrongMessage';

export const PageNotFound: FC = () => {
    const { t } = useTranslation();
    const [{ initialRoute }] = useUserState();

    return (
        <div className="flex items-center justify-center h-full w-full">
            <div className="flex flex-col items-center max-w-3xl">
                <Headline color="dark" Level="h3">
                    {t('pageNotFound.title')}
                </Headline>
                <Card className="mt-6 p-6">
                    <Headline color="dark" Level="h5">
                        {t('pageNotFound.subtitle')}
                    </Headline>
                    <Paragraph className="mt-2">
                        {t('pageNotFound.description')}
                    </Paragraph>
                    <div className="flex gap-2 mt-5">
                        <Link to={initialRoute}>
                            <Button>{t('pageNotFound.button')}</Button>
                        </Link>
                        <ContactSupportButton />
                    </div>
                </Card>
            </div>
        </div>
    );
};
