import { GoogleCandidate } from '_types';
import { normalizeCaseless, normalizeDedupStrings } from './utils';
import { NormalizedReferenceInput } from './scoreCandidate';
import { scoreCompany } from './scoreCompany';

enum Scores {
    Highest = 4,
    High = 2,
    Medium = 1,
    Low = 0.5,
}
interface ExtendedGoogleCandidate extends GoogleCandidate {
    groupId?: string;
    score?: number;
    extraKeywords?: string[];
    info?: { [key: string]: any }[];
    targetId?: string;
}

const scoreGoogleCandidate = (
    candidate: ExtendedGoogleCandidate,
    referenceInput: NormalizedReferenceInput,
): number => {
    const titleAndDescription = normalizeCaseless(
        `${candidate.title ?? ''} ${candidate.description}`,
    );

    const keywords = normalizeDedupStrings([
        ...(candidate.extraKeywords ?? []),
        ...(candidate.organizations ?? []),
    ]);

    const highScoresInputData = [
        ...referenceInput.usernames,
        ...referenceInput.phones,
        ...referenceInput.emails,
        ...referenceInput.urls,
    ];

    const highScores = highScoresInputData
        .filter(
            (value) =>
                titleAndDescription.includes(value) || keywords.includes(value),
        )
        .map(() => Scores.High);

    const lowScoresInputData = [
        ...referenceInput.locations,
        ...referenceInput.jobs,
        ...referenceInput.extras,
    ];

    const lowScores = lowScoresInputData
        .filter((value) => {
            return (
                titleAndDescription.includes(value) || keywords.includes(value)
            );
        })
        .map(() => Scores.Low);

    const companiesScore = referenceInput.companies.reduce(
        (total, company) => total + scoreCompany(titleAndDescription, company),
        0,
    );

    const dobScores = referenceInput.dobs
        .filter((dob) => {
            const startDate = dob.start;
            const endDate = dob.end;

            if (startDate !== endDate) {
                return false;
            }

            return (
                titleAndDescription.includes(startDate) ||
                keywords.includes(startDate)
            );
        })
        .map(() => Scores.High);

    return highScores
        .concat(lowScores)
        .concat(companiesScore)
        .concat(dobScores)
        .reduce((a, b) => a + b, 0);
};

/**
 * Calculates the score for each GoogleCandidate based on the given user input.
 *
 * @param {GoogleCandidate[]} candidates - The list of GoogleCandidates to score.
 * @param {SuperProcessTarget} userInput - The user input to calculate the score.
 * @returns {GoogleCandidate[]} - The list of GoogleCandidates with their calculated scores.
 */
export const scoreGoogleCandidates = (
    candidates: ExtendedGoogleCandidate[],
    positiveReferenceInput: NormalizedReferenceInput,
    negativeReferenceInput: NormalizedReferenceInput,
): number => {
    return (
        candidates
            .map((candidate) => {
                const positiveScore = scoreGoogleCandidate(
                    candidate,
                    positiveReferenceInput,
                );

                const negativeScore = scoreGoogleCandidate(
                    candidate,
                    negativeReferenceInput,
                );

                return positiveScore - negativeScore;
            })
            .reduce((a, b) => a + b, 0) / candidates.length
    );
};
