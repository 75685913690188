import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { CaseData } from '../../services/dataService';
import { CaseType } from 'src/features/cases/CaseNew';
import { Keyword } from 'src/features/cases/CaseNew/CaseNew.types';
import { baseURL } from '../../services/nestApiService';

type Payload = Pick<CaseData, 'title' | 'userIds'> & {
    option: {
        value: CaseType;
        props: {
            backgroundCheck?: boolean;
            publicInfo?: boolean;
            privateSocialMedia?: boolean;
            creditRating?: boolean;
            customSearchTerms?: boolean;
            keywords?: Keyword[];
        };
    };
};

export const useCreateCaseMutation = (): UseMutationResult<
    CaseData,
    unknown,
    Payload
> => {
    const queryClient = useQueryClient();

    return useMutation(
        async (payload) =>
            await (
                await axios.post(`${baseURL}/cases`, payload)
            ).data?.data,
        {
            onSuccess: () => queryClient.invalidateQueries('cases'),
        },
    );
};
