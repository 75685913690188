import { Fingerprint } from '../dataService';

import {
    calculateV2EmbeddingSimilarity,
    checkIsV2Embedding,
    unpackV2Embedding,
} from './embeddings/v2_text_embeddings';
import { calculateFingerprintScoreV1 } from './embeddings/v1_cortical';

export function calculateFingerprintScore(
    referenceFingerprint: Fingerprint,
    positiveFingerprints: Fingerprint[] = [],
    negativeFingerprints: Fingerprint[] = [],
): number {
    const isV2RefFp = checkIsV2Embedding(referenceFingerprint);

    if (isV2RefFp) {
        const positiveFingerprintsF32 = positiveFingerprints
            .filter((fp) => checkIsV2Embedding(fp))
            .map((fp) => unpackV2Embedding(fp));

        const negativeFingerprintsF32 = negativeFingerprints
            .filter((fp) => checkIsV2Embedding(fp))
            .map((fp) => unpackV2Embedding(fp));

        return calculateV2EmbeddingSimilarity(
            unpackV2Embedding(referenceFingerprint),
            positiveFingerprintsF32,
            negativeFingerprintsF32,
        );
    }

    return calculateFingerprintScoreV1(
        referenceFingerprint,
        positiveFingerprints,
        negativeFingerprints,
    );
}
