import { LocalizationService } from '@indicium/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CompanyAddress,
    RegisterDataArticle,
} from 'src/services/searchService';
import { CustomData, Highlight, LinkData, Paragraph } from '_atoms';
import { CollapsibleCard } from '_molecules';
import { formatDate, normalizeName } from '_utils';
import { HighlightChunks } from '../../../_atoms/Highlight/types';

export type BasicDataProps = {
    data: RegisterDataArticle;
    highlight?: HighlightChunks;
    initiallyExpanded?: boolean;
};

export const BasicData: FC<BasicDataProps> = ({
    data,
    highlight = {},
    initiallyExpanded = false,
}) => {
    const { t } = useTranslation();

    const sourceName: Record<string, string> = t('sourceName', {
        returnObjects: true,
    });

    const companyActivity: Array<string> = [
        ...(data.companyMainActivity || []),
        ...(data.companySecondaryActivity || []),
    ];

    const transformCompanyAddress = ({
        street,
        city,
        zip,
        country: isoCountry,
    }: CompanyAddress): string => {
        const country = isoCountry
            ? LocalizationService.getCountry(isoCountry)?.name
            : undefined;
        return normalizeName(
            [street, city, zip, country].filter(Boolean).join(', '),
        );
    };

    return (
        <CollapsibleCard
            className="py-4"
            borderColor="border-transparent"
            header={
                <div className="flex flex-col gap-4">
                    {/* Row 1  */}
                    <div className="grid grid-cols-3 gap-2">
                        {/* Column 1  */}
                        <div className="flex gap-2">
                            {data.companyWebsites?.length ? (
                                <LinkData
                                    headline={t('websites')}
                                    hrefs={data.companyWebsites}
                                    width="w-full"
                                    className="mb-1 text-sm"
                                />
                            ) : (
                                <CustomData
                                    headline={t('websites')}
                                    width="w-full"
                                />
                            )}
                        </div>
                        {/* Column 2  */}
                        <CustomData
                            headline={t('companyCard.dateOfIncorporation')}
                            width="w-full"
                        >
                            {data.companyIncorporationDate?.length
                                ? data.companyIncorporationDate?.map(
                                      (date, index) => (
                                          <Paragraph
                                              color="dark"
                                              key={[
                                                  data.id,
                                                  date,
                                                  index,
                                              ].join()}
                                              className="mb-1"
                                          >
                                              <Highlight
                                                  searchWords={highlight}
                                                  textToHighlight={formatDate(
                                                      date,
                                                  )}
                                              />
                                          </Paragraph>
                                      ),
                                  )
                                : null}
                        </CustomData>
                        {/* Column 3  */}
                        <div className="flex">
                            <CustomData
                                headline={t('companyCard.companyActivity')}
                                width="w-full"
                            >
                                {companyActivity.length ? (
                                    <Highlight
                                        searchWords={highlight}
                                        textToHighlight={companyActivity.join(
                                            ', ',
                                        )}
                                    />
                                ) : null}
                            </CustomData>
                        </div>
                    </div>

                    {/* Row 2 */}
                    <div className="grid grid-cols-3 gap-2 h-fit">
                        {/* Column 1  */}
                        <CustomData headline={t('phone')} width="w-full">
                            {data.companyPhones?.length
                                ? data.companyPhones?.map((phone, index) => (
                                      <Paragraph
                                          color="dark"
                                          key={[data.id, phone, index].join()}
                                          className="mb-1"
                                      >
                                          <Highlight
                                              searchWords={highlight}
                                              textToHighlight={phone}
                                          />
                                      </Paragraph>
                                  ))
                                : null}
                        </CustomData>
                        {/* Column 2  */}
                        <CustomData
                            headline={t('companyCard.previousNames')}
                            width="w-full"
                        >
                            {data.companyNamePrevious?.length
                                ? data.companyNamePrevious.map(
                                      ({ name, date }, index) => (
                                          <Paragraph
                                              color="dark"
                                              key={[name, index].join()}
                                              className="mb-1"
                                          >
                                              <Highlight
                                                  searchWords={highlight}
                                                  textToHighlight={name}
                                              />
                                              {date ? (
                                                  <Paragraph
                                                      color="light"
                                                      HtmlTag="span"
                                                  >
                                                      {' '}
                                                      ({formatDate(date)})
                                                  </Paragraph>
                                              ) : null}
                                          </Paragraph>
                                      ),
                                  )
                                : null}
                        </CustomData>
                        {/* Column 3  */}
                        <CustomData headline={t('address')} width="w-full">
                            {data.companyAddresses?.length
                                ? data.companyAddresses.map(
                                      (address, index) => {
                                          const transformedAddress =
                                              transformCompanyAddress(address);
                                          return (
                                              <Paragraph
                                                  color="dark"
                                                  key={[
                                                      data.id,
                                                      transformedAddress,
                                                      index,
                                                  ].join()}
                                                  className="mb-1"
                                              >
                                                  <Highlight
                                                      searchWords={highlight}
                                                      textToHighlight={transformCompanyAddress(
                                                          address,
                                                      )}
                                                  />
                                              </Paragraph>
                                          );
                                      },
                                  )
                                : null}
                        </CustomData>
                    </div>
                </div>
            }
            initiallyExpanded={initiallyExpanded}
        >
            {/* Collapsed state */}
            <div className="pt-4 pr-9 border-t border-t-neutral-300 flex flex-col gap-2">
                {/* Row 1 */}
                <div className="grid grid-cols-3 gap-2 h-fit">
                    <CustomData
                        headline={t('companyCard.identifiers')}
                        width="w-full"
                        className="col-span-2"
                    >
                        <table className="border-0 w-full">
                            <tbody>
                                <tr>
                                    <td className="w-1/2 pb-1 text-sm">
                                        {t('companyCard.tradeRegisterNumber')}
                                    </td>
                                    <td>
                                        {data.companyRegistrationNumber
                                            ?.length && (
                                            <Paragraph color="dark">
                                                <Highlight
                                                    searchWords={highlight}
                                                    textToHighlight={
                                                        data.companyRegistrationNumber
                                                    }
                                                />
                                            </Paragraph>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-1/2 pb-1 text-sm">
                                        {t('companyCard.vatTaxNumber')}
                                    </td>
                                    <td>
                                        {data.companyVatNumber?.length && (
                                            <Paragraph color="dark">
                                                <Highlight
                                                    searchWords={highlight}
                                                    textToHighlight={data.companyVatNumber?.join(
                                                        ', ',
                                                    )}
                                                />
                                            </Paragraph>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-1/2 pb-1 text-sm">
                                        {t('companyCard.leiCompanyId')}
                                    </td>
                                    <td>
                                        {data.companyLegalEntityIdentifier
                                            ?.length && (
                                            <Paragraph color="dark">
                                                <Highlight
                                                    searchWords={highlight}
                                                    textToHighlight={data.companyLegalEntityIdentifier?.join(
                                                        ', ',
                                                    )}
                                                />
                                            </Paragraph>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-1/2  text-sm">
                                        {t('companyCard.europeanVatNumber')}
                                    </td>
                                    <td>
                                        {data.companyEuropeanVatNumber
                                            ?.length && (
                                            <Paragraph color="dark">
                                                <Highlight
                                                    searchWords={highlight}
                                                    textToHighlight={data.companyEuropeanVatNumber?.join(
                                                        ', ',
                                                    )}
                                                />
                                            </Paragraph>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CustomData>
                    <CustomData headline={t('sources')} width="w-fit">
                        {data.sources?.length
                            ? data.sources.map((source, index) => (
                                  <Paragraph
                                      color="dark"
                                      key={[data.id, source, index].join()}
                                  >
                                      <Highlight
                                          searchWords={highlight}
                                          textToHighlight={
                                              sourceName[source.toLowerCase()]
                                          }
                                      />
                                  </Paragraph>
                              ))
                            : null}
                    </CustomData>
                </div>
                {/* Row 2 */}
                <CustomData
                    headline={t('companyCard.subjectText')}
                    width="w-full"
                >
                    {data.companySubjects?.length
                        ? data.companySubjects.map((subject, index) => (
                              <Paragraph
                                  color="dark"
                                  key={[subject.text, index].join()}
                                  className="mb-2 block"
                              >
                                  <Highlight
                                      textToHighlight={subject.text}
                                      searchWords={highlight}
                                  />
                              </Paragraph>
                          ))
                        : null}
                </CustomData>
            </div>
        </CollapsibleCard>
    );
};
