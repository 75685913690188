import { FC } from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import classnames from 'classnames';
import { FallbackImage } from '../../../components/_molecules/FallbackImage/FallbackImage';

export type TargetCandidateImagesProps = {
    images: string[];
    onClick: () => void;
    className?: string;
    sizeOverride?: string;
};

export const TargetCandidateImages: FC<TargetCandidateImagesProps> = ({
    images,
    onClick,
    className,
    sizeOverride,
}) => {
    if (!images.length) {
        return (
            <div className={classnames('relative', className)}>
                <div
                    className={classnames(
                        'object-cover rounded-full overflow-hidden border',
                        sizeOverride ? sizeOverride : 'w-32 h-32',
                    )}
                >
                    <FallbackImage aspectRatio="square" />
                </div>
            </div>
        );
    }

    const [primaryImage] = images.slice(0, 1);
    const otherImages = images.slice(1, 3);

    return (
        <div className={classnames('relative', className)}>
            <img
                src={primaryImage}
                className={classnames(
                    'object-cover rounded-full cursor-pointer',
                    sizeOverride ? sizeOverride : 'w-32 h-32',
                )}
                onClick={() => onClick()}
            />
            {otherImages.length > 0 && (
                <div className="absolute -bottom-3 flex gap-1 w-full justify-center">
                    {otherImages.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            className="object-cover w-8 h-8 rounded-full border-2 border-white cursor-pointer"
                            onClick={() => onClick()}
                        />
                    ))}
                    <div className="w-8 h-8 flex justify-center items-center rounded-full bg-blue-500 text-font-light border-2 border-white cursor-pointer p-1">
                        <HiOutlineArrowRight onClick={() => onClick()} />
                    </div>
                </div>
            )}
        </div>
    );
};
