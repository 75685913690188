import * as Yup from 'yup';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import axios from 'axios';
import { baseURL } from '../../services/nestApiService';
import { useEffect, useState } from 'react';

const caseSearchResultDtoItemSchema = Yup.object({
    caseId: Yup.string().required(),
    caseTitle: Yup.string().required(),
    targetId: Yup.string().nullable(),
    targetStatus: Yup.string().nullable(),
    targetFullname: Yup.string().nullable(),
    targetFirstname: Yup.string().nullable(),
    targetLastname: Yup.string().nullable(),
    targetMiddlename: Yup.string().nullable(),
    caseOption: Yup.string().required(),
    createdAt: Yup.string().required(),
    matchType: Yup.string().oneOf(['target', 'case']).required(),
    caseAuthorFirstName: Yup.string().nullable(),
    caseAuthorLastName: Yup.string().nullable(),
    caseAuthorEmail: Yup.string().nullable(),
    targetAuthorFirstName: Yup.string().nullable(),
    targetAuthorLastName: Yup.string().nullable(),
    targetAuthorEmail: Yup.string().nullable(),
}).required();

const caseSearchResultDtoSchema = Yup.object({
    items: Yup.array(caseSearchResultDtoItemSchema).required(),
    total: Yup.number().required(),
}).required();

export type CaseSearchResultDto = Yup.InferType<
    typeof caseSearchResultDtoSchema
>;

export type CaseSearchResultItemDto = Yup.InferType<
    typeof caseSearchResultDtoItemSchema
>;

const fetchGlobalSearchQuery = async (
    page: number,
    pageSize: number,
    query: string,
): Promise<CaseSearchResultDto> => {
    // TODO we can require 3 characters or more
    if (query.length === 0) {
        return {
            items: [],
            total: 0,
        };
    }

    const urlSearchParams = new URLSearchParams([
        ['page', page.toString()],
        ['pageSize', pageSize.toString()],
        ['query', query],
    ]);

    const response = await axios.get(
        `${baseURL}/cases/search?${urlSearchParams.toString()}`,
    );

    try {
        return caseSearchResultDtoSchema.validateSync(response.data.data) ?? [];
    } catch (error) {
        console.error(error);

        return {
            items: [],
            total: 0,
        };
    }
};

const MIN_PAGE = 1;
const PAGE_SIZE = 10;

export const useGlobalSearchQueryResult = (
    query: string,
    onSettled: () => void,
): UseInfiniteQueryResult<CaseSearchResultDto, string> => {
    const [debouncedQuery, setDebouncedQuery] = useState<string>(query);

    useEffect(() => {
        if (debouncedQuery === query) {
            return;
        }

        const timeout = setTimeout(() => setDebouncedQuery(query), 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [debouncedQuery, query]);

    return useInfiniteQuery(
        ['globalSearch', debouncedQuery],
        ({ pageParam }) =>
            fetchGlobalSearchQuery(
                Number(pageParam ?? MIN_PAGE),
                PAGE_SIZE,
                debouncedQuery,
            ),
        {
            getPreviousPageParam: (_, allPages) => {
                const previousPageParam = allPages.length - 1;

                if (previousPageParam < MIN_PAGE) {
                    return undefined;
                }

                return previousPageParam;
            },
            getNextPageParam: (_, allPages) => {
                const nextPageParam = allPages.length + 1;

                const total = allPages
                    .map(({ total }) => total)
                    .reduce((acc, curr) => Math.max(acc, curr), 0);

                const currentItemCount = allPages
                    .map(({ items }) => items.length)
                    .reduce((acc, curr) => acc + curr, 0);

                if (currentItemCount >= total) {
                    return undefined;
                }

                return nextPageParam;
            },
            cacheTime: 1000 * 60,
            refetchOnWindowFocus: false,
            onSettled,
        },
    );
};
