import { useQuery, UseQueryResult } from 'react-query';
import { TargetCandidates } from '../../services/dataService';
import { cases } from '../../services/nestApiService';

export const useTargetPreselectedCandidatesQuery = (
    caseId: string,
    targetId: string,
    enabled = true,
): UseQueryResult<TargetCandidates['preSelectedCandidates'], string> =>
    useQuery(
        ['targetPreselectedCandidates', caseId, targetId],
        () => cases.getPreselectedCandidates(caseId, targetId),
        {
            retry: 50,
            retryDelay: (iteration) =>
                Math.min(1000 * Math.pow(2, iteration), 60_000),
            enabled,
        },
    );
