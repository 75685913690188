import React from 'react';

interface ImageProps {
    url: string;
    alt: string;
}

export const Image = ({ url, alt }: ImageProps): React.ReactElement => (
    <div className="flex justify-center pb-4 box-border">
        <img src={url} alt={alt} className="object-cover h-72" />
    </div>
);
