import React from 'react';
import { CaseSettings, CaseType } from '../CaseNew.types';
import { CaseKeywords } from './CaseKeywords';

interface CaseSettingsFormProps {
    readOnly?: boolean;
    settings: CaseSettings;
    setSettings?: React.Dispatch<React.SetStateAction<CaseSettings>>;
    caseType?: CaseType;
}

export const CaseSettingsForm = (
    props: CaseSettingsFormProps,
): React.ReactElement | null => {
    if (props.caseType === CaseType.Complete) {
        return <CaseKeywords {...props} />;
    }

    return null;
};
