import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSocialMediaErrorsQuery } from '../../../hooks/queries/useSocialMediaErrorsQuery';
import {
    SocialMediaErrorPath,
    SocialMediaErrorType,
} from '../../../services/dataService';

export enum Platform {
    Facebook = 'facebook',
    Instagram = 'instagram',
    Twitter = 'twitter',
    LinkedIn = 'linkedIn',
    Xing = 'xing',
}

const ExternalErrors = [
    SocialMediaErrorType.HttpError,
    SocialMediaErrorType.SDError,
];

enum ErrorCategory {
    InternalError = 'internalError',
    ExternalError = 'externalError',
    Warning = 'warning',
}

export type SocialMediaErrors = Map<
    ErrorCategory,
    Map<Platform, Set<SocialMediaErrorPath>>
>;

const pathToPlatform = (path: SocialMediaErrorPath): Platform => {
    if (path.includes(Platform.Facebook)) {
        return Platform.Facebook;
    }
    if (path.includes(Platform.Instagram)) {
        return Platform.Instagram;
    }
    if (path.includes(Platform.Twitter)) {
        return Platform.Twitter;
    }
    if (path.includes(Platform.Xing)) {
        return Platform.Xing;
    }
    return Platform.LinkedIn;
};

const errorTypeToCategory = (
    errorType: SocialMediaErrorType,
): ErrorCategory => {
    if (ExternalErrors.includes(errorType)) {
        return ErrorCategory.ExternalError;
    }

    if (errorType === SocialMediaErrorType.SDWarning) {
        return ErrorCategory.Warning;
    }

    return ErrorCategory.InternalError;
};

export const useSocialMediaErrors = (): SocialMediaErrors => {
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();
    const { data } = useSocialMediaErrorsQuery(caseId, targetId);

    return useMemo(() => {
        const categorizedErrors = new Map<
            ErrorCategory,
            Map<Platform, Set<SocialMediaErrorPath>>
        >();

        data?.forEach(({ errorPath, type }) => {
            const platform = pathToPlatform(errorPath);
            const category = errorTypeToCategory(type);

            const errorsByCategory =
                categorizedErrors.get(category) ??
                new Map<Platform, Set<SocialMediaErrorPath>>();

            const errorsByPlatform =
                errorsByCategory.get(platform) ??
                new Set<SocialMediaErrorPath>();

            errorsByPlatform.add(errorPath);

            errorsByCategory.set(platform, errorsByPlatform);

            categorizedErrors.set(category, errorsByCategory);
        });

        return categorizedErrors;
    }, [data]);
};
