import { FC } from 'react';
import {
    Alert,
    InfiniteScroller,
    Pagination,
    SearchInput,
    WorkflowError,
} from '_molecules';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import { SearchResultCard } from '_organisms';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SearchFilterValue } from '_types';
import { useElasticSearch } from '../../../hooks/useElasticSearch';
import { SearchEngineArticle } from 'src/services/searchService';

import searchEngineFilters from './filters.json';
import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

const initialValues = {
    items: [],
};

const pagingEnabled = false;

type SearchEngineFilters = {
    source: SearchFilterValue;
    createdDate: SearchFilterValue;
};

export type SearchEnginesProps = ResultsProps & { accessToken: string };

// This is the component rendering the contents of the Search Engines tab in the raw data section of a report
export const SearchEngines: FC<SearchEnginesProps> = ({
    targetData,
    caseData,
    entityIndicators,
}: SearchEnginesProps) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();
    const {
        searchInputProps: { renderedFilters, ...searchInputProps },
        data: { items, paging } = initialValues,
        isLoading,
        isError,
        handlePageChange,
    } = useElasticSearch<SearchEngineFilters, SearchEngineArticle[]>({
        caseId,
        targetId,
        entityType: 'WebPage',
        searchFilters: searchEngineFilters,
        pagingEnabled: pagingEnabled,
    });

    return (
        <DefaultPageLayout title={t('rawData.searchEngines')}>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
            <WorkflowError errors={targetData?.workflowErrors} path="profile" />

            {targetData?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}

            <div
                className="relative mt-6 z-10 flex flex-col gap-4"
                data-testid={nonProdDataTestId('search-engines')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full py-20">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {items.length ? (
                    <>
                        {items?.map((item: SearchEngineArticle) => (
                            <SearchResultCard
                                key={item.url}
                                data={item}
                                query={searchInputProps.value}
                                hideArticleType={true}
                                targetData={targetData}
                                caseData={caseData}
                                indicators={entityIndicators
                                    .filter((s) => s.entityId === item.id)
                                    .flatMap(({ indicators }) => indicators)}
                            />
                        ))}
                        {paging?.pagesCount > 1 &&
                            (pagingEnabled ? (
                                <div className="px-5">
                                    <Pagination
                                        paging={paging}
                                        setPageNumber={(page) =>
                                            handlePageChange(page)
                                        }
                                    />
                                </div>
                            ) : (
                                <InfiniteScroller
                                    paging={paging}
                                    isLoading={isLoading}
                                    setPageNumber={(page) =>
                                        handlePageChange(page)
                                    }
                                />
                            ))}
                    </>
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
