import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuspicionStatusIcon } from '../../../../features/dashboard/Overview/SuspicionStatusIcon';
import { SuspicionItemStatus } from '_enums';
import { LoadingSpinner } from '_atoms';

export type UpdateItemsStatusProps = {
    itemIds: string[];
    status?: SuspicionItemStatus;
    differentStatuses?: boolean;
    onStatusUpdate: (
        itemIds: string[],
        status: SuspicionItemStatus,
    ) => Promise<unknown>;
    size?: number;
    padding?: number;
};

export const UpdateItemsStatus: FC<UpdateItemsStatusProps> = ({
    itemIds,
    status,
    differentStatuses,
    onStatusUpdate,
    size,
    padding,
}) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const statuses = differentStatuses
        ? Object.values(SuspicionItemStatus)
        : Object.values(SuspicionItemStatus).filter((s) => s !== status);

    const handleStatusClicked = (status: SuspicionItemStatus) => {
        setLoading(true);
        onStatusUpdate(itemIds, status).finally(() => setLoading(false));
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className="flex flex-col gap-3 text-sm">
            {statuses.map((status) => (
                <div
                    className="flex items-center gap-3 cursor-pointer"
                    key={status}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleStatusClicked(status);
                    }}
                >
                    <SuspicionStatusIcon
                        status={status}
                        size={size}
                        padding={padding}
                    />
                    <span>{t(['suspicionItemStatus', status].join('.'))}</span>
                </div>
            ))}
        </div>
    );
};
