import React, { FC } from 'react';
import { Card, OverlayWithSpinner } from '_atoms';
import { CaseData, Target } from '../../../services/dataService';
import { SearchResultsRow } from './SearchResultsRow';
import { TableEmpty } from '_organisms/Table/TableEmpty/TableEmpty';
import { useTranslation } from 'react-i18next';

type ResultsListProps = {
    items: ReadonlyArray<Target | CaseData>;
    title: string;
    isLoading: boolean;
};

const ResultRow = (props: Target | CaseData): React.ReactElement => {
    const sharedProps = {
        creatorName: `${props.creator?.firstname} ${props.creator?.lastname}`,
        creatorEmail: props.creator?.email ?? '',
    };
    if ('caseId' in props) {
        const targetFullname = [
            props.firstname,
            props.middlename,
            props.lastname,
        ]
            .filter(Boolean)
            .join(' ');

        return (
            <SearchResultsRow
                {...sharedProps}
                key={`${props.caseId}-${props.id}`}
                caseId={props.caseId ?? ''}
                targetId={props.id}
                name={targetFullname}
                createdAt={props.createdAt}
                targetStatus={props.status}
                type="target"
            />
        );
    }
    return (
        <SearchResultsRow
            {...sharedProps}
            key={props.id ?? ''}
            caseId={props.id ?? ''}
            name={props.title ?? ''}
            createdAt={props.createdAt}
            caseType={props?.option?.option}
            type="case"
        />
    );
};

export const ResultList: FC<ResultsListProps> = (props: ResultsListProps) => {
    const { t } = useTranslation();
    const { items, title, isLoading } = props;

    return (
        <Card className="flex flex-col p-5 text-center w-full bg-neutral-50 shadow-card">
            <div className="flex mb-1 justify-between">
                <div className="text-lg font-semibold text-primary-1">
                    {title}
                </div>
            </div>
            <OverlayWithSpinner
                showOverlay={isLoading}
                className="flex-1 bg-transparent min-h-12"
            >
                {!isLoading && (
                    <>
                        {items.length > 0 ? (
                            <div className="w-full max-w-screen-lg mx-auto flex flex-col">
                                {items?.map((item) => (
                                    <ResultRow key={item.id ?? ''} {...item} />
                                ))}
                            </div>
                        ) : (
                            <TableEmpty
                                message={
                                    title === t('landingPage.recentTargets')
                                        ? t('landingPage.noTargetsFound')
                                        : t('landingPage.noCasesFound')
                                }
                            />
                        )}
                    </>
                )}
            </OverlayWithSpinner>
        </Card>
    );
};
