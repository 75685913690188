import { withTranslation, WithTranslation } from 'react-i18next';
import { DatePicker } from '../../DatePicker/DatePicker';
import { Paragraph } from '../../Paragraph/Paragraph';
import Filter from '../Filter';
import { format } from 'date-fns';
import { BaseFilterProps } from '_types';

type DateRangeFilterState = {
    from: Date | null;
    to: Date | null;
};

class DateRangeFilter extends Filter<
    BaseFilterProps & WithTranslation,
    DateRangeFilterState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            from: null,
            to: null,
        };
    }

    componentDidMount() {
        const { searchState, field } = this.props;
        if (searchState.filters && searchState.filters[field]) {
            const value = searchState.filters[field].value;
            const updatedState: any = {};
            if (value.gte) {
                updatedState['from'] = new Date(value.gte);
            }
            if (value.lte) {
                updatedState['to'] = new Date(value.lte);
            }
            this.setState(updatedState);
            this.forceUpdate();
        }
    }

    handleDatePickerChange(
        stateKey: keyof DateRangeFilterState,
        value: Date | null,
    ): void {
        const { field } = this.props;
        const updatedState = {
            ...this.state,
            [stateKey]: value,
        };
        const filterValue: any = {};
        if (updatedState.from) {
            filterValue['gte'] = format(updatedState.from, 'yyyy-MM-dd');
        }
        if (updatedState.to) {
            filterValue['lte'] = format(updatedState.to, 'yyyy-MM-dd');
        }

        const valueJson = this.getFilterValueJson(
            filterValue,
            'filter',
            'range',
        );
        this.handleFilterChange(field, valueJson);
    }

    render() {
        const { t, item } = this.props;

        return (
            <div className="py-2">
                {item.title && (
                    <Paragraph
                        color="light"
                        size="small"
                        className="uppercase mb-1"
                    >
                        {t(`filters.${item.title}`)}
                    </Paragraph>
                )}
                <div className="flex gap-5">
                    <div className="date-from w-40">
                        <Paragraph
                            color="light"
                            size="small"
                            className="uppercase mb-1"
                        >
                            {t('filters.dateFrom')}
                        </Paragraph>
                        <DatePicker
                            selected={this.state.from}
                            isClearable
                            dateFormat="dd.MM.yyyy"
                            onChange={(value: Date | null) =>
                                this.handleDatePickerChange('from', value)
                            }
                        />
                    </div>
                    <div className="date-to w-40">
                        <Paragraph
                            color="light"
                            size="small"
                            className="uppercase mb-1"
                        >
                            {t('filters.dateTo')}
                        </Paragraph>
                        <DatePicker
                            selected={this.state.to}
                            isClearable
                            dateFormat="dd.MM.yyyy"
                            onChange={(value: Date | null) =>
                                this.handleDatePickerChange('to', value)
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DateRangeFilter);
