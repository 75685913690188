import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from '_assets/icons/cross.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';
import {
    PASSWORD_MIN_LENGTH,
    PasswordValidationRules,
} from './use-password-validation';

interface PasswordValidationsProps {
    validations: PasswordValidationRules;
}

const checkIcon = <CheckIcon className="w-5 h-5 text-green-500" />;
const crossIcon = <CrossIcon className="w-5 h-5 text-gray-600" />;

export const PasswordValidations = (
    props: PasswordValidationsProps,
): React.ReactElement => {
    const { validations } = props;
    const { t } = useTranslation();

    return (
        <div className="text-sm absolute pl-4 top-0 right-0 translate-x-full transform">
            <div
                className={classNames(
                    'flex items-center mb-1',
                    validations.minLength ? 'text-green-500' : 'text-gray-600',
                )}
            >
                <div className="mr-2 w-5 h-5">
                    {validations.minLength ? checkIcon : crossIcon}
                </div>
                {t('passwordValidation.min', {
                    minLength: PASSWORD_MIN_LENGTH,
                })}
            </div>
            <div
                className={classNames(
                    'flex items-center mb-1',
                    validations.oneNumber ? 'text-green-500' : 'text-gray-600',
                )}
            >
                <div className="mr-2 w-5 h-5">
                    {validations.oneNumber ? checkIcon : crossIcon}
                </div>
                {t('passwordValidation.oneNumber')}
            </div>
            <div
                className={classNames(
                    'flex items-center mb-1',
                    validations.oneSpecialChar
                        ? 'text-green-500'
                        : 'text-gray-600',
                )}
            >
                <div className="mr-2 w-5 h-5">
                    {validations.oneSpecialChar ? checkIcon : crossIcon}
                </div>
                {t('passwordValidation.oneSpecialChar')}
            </div>
            <div
                className={classNames(
                    'flex items-center mb-1',
                    validations.oneUppercase
                        ? 'text-green-500'
                        : 'text-gray-600',
                )}
            >
                <div className="mr-2 w-5 h-5">
                    {validations.oneUppercase ? checkIcon : crossIcon}
                </div>
                {t('passwordValidation.oneUppercase')}
            </div>
            <div
                className={classNames(
                    'flex items-center mb-1',
                    validations.oneLowercase
                        ? 'text-green-500'
                        : 'text-gray-600',
                )}
            >
                <div className="mr-2 w-5 h-5">
                    {validations.oneLowercase ? checkIcon : crossIcon}
                </div>
                {t('passwordValidation.oneLowercase')}
            </div>
            <div
                className={classNames(
                    'flex items-center mb-1',
                    validations.passwordMatch
                        ? 'text-green-500'
                        : 'text-gray-600',
                )}
            >
                <div className="mr-2 w-5 h-5">
                    {validations.passwordMatch ? checkIcon : crossIcon}
                </div>
                {t('passwordValidation.passwordMatch')}
            </div>
        </div>
    );
};
