import countriesEN from 'i18n-iso-countries/langs/en.json';

export const LOWERCASE_COUNTRY_CODES = Object.keys(countriesEN.countries).map(
    (code) => code.toLowerCase(),
);
export const LOWERCASE_COUNTRY_NAMES = Object.values(
    countriesEN.countries,
).flatMap((name) =>
    Array.isArray(name)
        ? name.map((nameType) => nameType.toLowerCase())
        : [name.toLowerCase()],
);

export const COMMON_COMPANY_LEGAL_POSTFIXES = [
    /^ab$/,
    /^ag$/,
    /^aktiengesellschaft$/,
    /^as$/,
    /^aÃ¶r$/,
    /^b?\\.v?\\.$/,
    /^beschrÃ¤nkter$/,
    /^co$/,
    /^co?\\.$/,
    /^e?\\.v?\\.$/,
    /^eg$/,
    /^ewiv$/,
    /^gag$/,
    /^gbr$/,
    /^gesellschaft$/,
    /^ggmbh$/,
    /^gmbh$/,
    /^haftung$/,
    /^haftungsbeschrÃ¤nkt$/,
    /^handelsgesellschaft$/,
    /^inc?\\.$/,
    /^kg$/,
    /^kgaa$/,
    /^kommanditgesellschaft$/,
    /^kÃ¶r$/,
    /^limited$/,
    /^llc$/,
    /^ltd$/,
    /^ltd\\.$/,
    /^mbb$/,
    /^mit$/,
    /^nv$/,
    /^offene$/,
    /^ohg$/,
    /^ohg$/,
    /^partenreederei$/,
    /^partg$/,
    /^partg$/,
    /^reit$/,
    /^reit-ag$/,
    /^s?\\.a?\\.$/,
    /^sarl$/,
    /^sce$/,
    /^se$/,
    /^ug$/,
    /^ugmbh$/,
    /^unternehmergesellschaft$/,
    /^vvag$/,
];

export const COMMON_COMPANY_PHRASE_REGEXPS = [
    /^automobil$/,
    /^bau$/,
    /^beratung$/,
    /^commercial$/,
    /^dienstleistungen$/,
    /^eigenbetrieb$/,
    /^einzelunternehmen$/,
    /^elektronik$/,
    /^enterprises$/,
    /^eu$/,
    /^financial$/,
    /^finanz$/,
    /^global$/,
    /^group$/,
    /^handel$/,
    /^holding$/,
    /^holdings$/,
    /^immobilien$/,
    /^industrie$/,
    /^industries$/,
    /^international$/,
    /^invag$/,
    /^investments$/,
    /^lebensmittel$/,
    /^maschinenbau$/,
    /^medizin$/,
    /^mini$/,
    /^mit$/,
    /^office$/,
    /^regiebetrieb$/,
    /^services$/,
    /^small$/,
    /^software$/,
    /^stiftung$/,
    /^stille$/,
    /^technologie$/,
    /^technologies$/,
    /^technology$/,
    /^versicherung$/,
    /^vertrieb$/,
];
