import { ComponentType, FC, Fragment } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useUserState } from './context/User';
import { fallbackPath, RouteProperties, routes } from './routes';
import { PageNotFound } from '_organisms';
import { useCustomerQuery } from '_queries';

const getRoute = (
    condition: boolean,
    path: string,
    Component: ComponentType,
    fallback?: string,
    props?: Record<string, unknown>,
    originalPath?: string,
) => {
    if (condition) {
        return <Route {...{ path }} render={() => <Component {...props} />} />;
    }
    return fallback ? (
        <Redirect
            to={{
                pathname: fallback,
                state: {
                    redirectUrl:
                        fallback === fallbackPath ? originalPath : undefined,
                },
            }}
        />
    ) : (
        <Fragment>403</Fragment>
    );
};

export const ProtectedRoute: FC<RouteProperties & { originalPath?: string }> =
    ({ path, component, condition, fallbackPath, props, originalPath }) => {
        const [{ user, userRoles, customerId }] = useUserState();
        const { data: customer } = useCustomerQuery(customerId, {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        });
        const Component = component;

        if (!condition) {
            return (
                <Route {...{ path }} render={() => <Component {...props} />} />
            );
        }

        const isRouteEnabled = condition({
            user,
            userRoles,
            customer,
            props,
        });

        return getRoute(
            isRouteEnabled,
            path,
            component,
            fallbackPath,
            props,
            originalPath,
        );
    };

export const Router: FC = () => {
    const location = useLocation();
    const { pathname } = location;
    const [{ initialRoute }] = useUserState();

    return pathname === '/' && pathname !== initialRoute ? (
        initialRoute ? (
            <Redirect to={initialRoute} />
        ) : (
            <Redirect to="/signin" />
        )
    ) : (
        <Switch>
            {Object.values(routes)
                .sort((a, b) => b.weight - a.weight)
                .map((route, index) => (
                    <ProtectedRoute
                        key={index}
                        {...route}
                        originalPath={pathname}
                    />
                ))}
            <Route component={PageNotFound} />
        </Switch>
    );
};
