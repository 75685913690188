import { GraphLink, GraphNode } from '_molecules';
import { FilterOptions } from './NetworkGraph';
import { isNeitherNullNorUndefined } from '_utils';

export enum CompanyStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    UNKNOWN = 'unknown',
}

export const getCompanyStatus = (companyStatus: string): CompanyStatus => {
    if (
        ['active', 'insolvent', 'liquidation'].some(
            (item) => companyStatus.trim().toLowerCase() == item,
        )
    ) {
        return CompanyStatus.ACTIVE;
    } else if (
        ['suspended', 'dissolved'].some(
            (item) => companyStatus.trim().toLowerCase() == item,
        )
    ) {
        return CompanyStatus.INACTIVE;
    }
    return CompanyStatus.UNKNOWN;
};

export const buildAdjacencyList = (
    edges: GraphLink[],
): Record<string, string[]> => {
    const adjacencyList: Record<string, string[]> = {};

    for (const edge of edges) {
        const { from, to } = edge;

        if (!adjacencyList[from]) {
            adjacencyList[from] = [];
        }
        adjacencyList[from].push(to);

        // Handle undirected edges by adding the reverse direction as well
        if (!adjacencyList[to]) {
            adjacencyList[to] = [];
        }
        adjacencyList[to].push(from);
    }

    return adjacencyList;
};

export const filterNodesByOptions = (
    nodes: GraphNode[],
    filterOptions: FilterOptions,
    adjacencyList: Record<string, string[]>,
): GraphNode[] => {
    const filteredNodeIds = new Set(
        nodes
            .filter((node) => {
                const isLevelMatch = node.lvl <= filterOptions.graphLevel;
                const isRoleMatch =
                    ['target', 'person'].includes(node.type) ||
                    filterOptions.candidateRole.length === 0 ||
                    filterOptions.candidateRole.some(
                        (role) => role.value === node.type,
                    );
                const isStatusMatch =
                    ['target', 'person'].includes(node.type) ||
                    filterOptions.companyStatus.length === 0 ||
                    filterOptions.companyStatus.some(
                        (status) =>
                            status.value ===
                            getCompanyStatus(node.status ?? ''),
                    );
                return isLevelMatch && isRoleMatch && isStatusMatch;
            })
            .map((n) => n.id),
    );

    const reachableNodes: Set<string> = new Set();
    const explore = (nodeId: string) => {
        if (reachableNodes.has(nodeId) || !filteredNodeIds.has(nodeId)) {
            return;
        }
        reachableNodes.add(nodeId);

        (adjacencyList[nodeId] ?? []).forEach((neighbor: string) => {
            if (filteredNodeIds.has(neighbor)) {
                explore(neighbor);
            }
        });
    };

    if (filteredNodeIds.has('0')) {
        explore('0');
    }

    const result: GraphNode[] = Array.from(reachableNodes)
        .map((id) => nodes.find((n) => n.id === id))
        .filter(isNeitherNullNorUndefined);

    return result;
};
