import { Headline, Paragraph } from '_atoms';
import classNames from 'classnames';
import React from 'react';
import { IconType } from 'react-icons';
import { ReactComponent as CrossIcon } from '_assets/icons/cross.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';

interface CaseTypeBaseButtonProps {
    isSelected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    title: string;
    Icon: IconType;
    infoItems: string[];
    sourceUsageInfo: {
        included: string[];
        excluded: string[];
    };
    isHidden?: boolean;
}

export const CaseTypeBaseButton = (
    props: CaseTypeBaseButtonProps,
): React.ReactElement => {
    const {
        isSelected = false,
        disabled = false,
        title,
        Icon,
        onClick,
        infoItems,
        sourceUsageInfo,
        isHidden,
    } = props;

    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={classNames(
                'flex flex-col p-6 min-h-full rounded-md border-solid border-white hover:bg-primary-4 group focus:outline-none disabled:bg-neutral-300 disabled:cursor-default transition-all opacity-100 space-y-5',
                isSelected
                    ? 'cursor-auto bg-primary-4 pointer-events-none'
                    : 'cursor-pointer bg-neutral-100',
                isHidden && 'invisible pointer-events-none',
            )}
        >
            <div className="flex items-center">
                <div>
                    <Icon
                        className={classNames(
                            'w-10 h-10 self-center mr-4 group-hover:text-font-light transition',
                            isSelected ? 'text-font-light' : 'text-primary-4',
                        )}
                    />
                </div>
                <Headline
                    Level="h3"
                    weight="bold"
                    color={isSelected ? 'white' : 'dark'}
                    className="group-hover:text-font-light transition"
                >
                    {title}
                </Headline>
            </div>
            <ul className="list-disc ml-4.5 text-left flex-grow">
                {infoItems.map((item) => (
                    <li
                        key={item}
                        className={classNames(
                            'group-hover:text-font-light transition ',
                            isSelected ? 'text-font-light' : 'text-font-dark',
                        )}
                    >
                        <Paragraph
                            key={item}
                            weight="normal"
                            color={isSelected ? 'white' : 'default'}
                            className={classNames(
                                'group-hover:text-font-light transition',
                                isSelected && 'text-font-light',
                            )}
                        >
                            {item}
                        </Paragraph>
                    </li>
                ))}
            </ul>
            <div className="text-left space-y-2">
                {sourceUsageInfo.included.map((item) => (
                    <div
                        key={item}
                        className={classNames(
                            'group-hover:text-font-light transition flex items-center space-x-2',
                            isSelected ? 'text-font-light' : 'text-font-dark',
                        )}
                    >
                        <CheckIcon className="w-5 h-5 text-green-500" />
                        <Paragraph
                            key={item}
                            weight="normal"
                            color={isSelected ? 'white' : 'default'}
                            className={classNames(
                                'group-hover:text-font-light transition',
                                isSelected && 'text-font-light',
                            )}
                        >
                            {item}
                        </Paragraph>
                    </div>
                ))}
                {sourceUsageInfo.excluded.map((item) => (
                    <div
                        key={item}
                        className={classNames(
                            'group-hover:text-font-light transition flex items-center space-x-2',
                            isSelected ? 'text-font-light' : 'text-font-dark',
                        )}
                    >
                        <CrossIcon className="w-5 h-5 text-gray-600" />
                        <Paragraph
                            key={item}
                            weight="normal"
                            color={isSelected ? 'white' : 'default'}
                            className={classNames(
                                'group-hover:text-font-light transition',
                                isSelected && 'text-font-light',
                            )}
                        >
                            {item}
                        </Paragraph>
                    </div>
                ))}
            </div>
        </button>
    );
};
