import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Paragraph } from '_atoms';
import {
    MdCancel,
    MdCheckCircleOutline,
    MdClose,
    MdInfo,
    MdOutlineReportProblem,
} from 'react-icons/md';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

type NotificationLevel = 'error' | 'warning' | 'success' | 'info';

export type NotificationProps = {
    headline: string;
    message?: string;
    level: NotificationLevel;
    className?: string;
    link?: {
        text: string;
        to: string;
    };
};

export const Notification: FC<NotificationProps> = ({
    headline,
    message,
    level,
    className,
    link,
}: NotificationProps) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        setShow(true);
    }, [headline, message]);

    const getIcon = useMemo(() => {
        switch (level) {
            case 'error':
                return <MdCancel />;

            case 'warning':
                return <MdOutlineReportProblem />;

            case 'success':
                return <MdCheckCircleOutline />;

            case 'info':
                return <MdInfo />;

            default:
                break;
        }
    }, [level]);

    return (
        <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div
                className={classnames(
                    className,
                    'max-w-sm w-full bg-neutral-100 flex items-start p-4 rounded-md border-1 border-neutral-400 fixed top-6 right-4 shadow-lg z-20',
                )}
            >
                <div
                    className={classnames(
                        'mr-3.5',
                        level === 'error' && 'text-error-2',
                        level === 'warning' && 'text-yellow-500',
                        level === 'success' && 'text-green-500',
                        level === 'info' && 'text-blue-500',
                    )}
                >
                    {getIcon}
                </div>

                <div className="w-10/12 pt-0.5">
                    {headline && (
                        <Paragraph
                            size="default"
                            weight="bold"
                            lineHeight="default"
                            className="text-gray-900 mt-0.5 mb-1"
                        >
                            {headline}
                        </Paragraph>
                    )}

                    {message && (
                        <Paragraph
                            size="default"
                            weight="normal"
                            lineHeight="default"
                            className="mb-1"
                        >
                            {message}
                        </Paragraph>
                    )}
                    {link && (
                        <Link
                            className={'text-blue-500 hover:text-primary-3'}
                            to={link.to}
                        >
                            {link.text}
                        </Link>
                    )}
                </div>

                <button
                    onClick={() => {
                        setShow(false);
                    }}
                    className="text-neutral-400 hover:text-neutral-500 cursor-pointer ml-4 focus:outline-none"
                >
                    <MdClose />
                </button>
            </div>
        </Transition>
    );
};
