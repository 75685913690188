import { Dispatch, useReducer } from 'react';

type Params = Record<string, string>;

type State = {
    enableConfirm: boolean;
    showModal: boolean;
    params: Params;
};

type Action =
    | { type: 'CONFIRM_DELETION'; mutation: (params: Params) => void }
    | { type: 'ENABLE_CONFIRM_BUTTON' }
    | { type: 'RESET' }
    | { type: 'SET_DELETE_PARAMS'; params: Params };

const initialState: State = {
    enableConfirm: false,
    showModal: false,
    params: {},
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'CONFIRM_DELETION':
            action.mutation({ ...state.params });
            return { ...initialState };
        case 'ENABLE_CONFIRM_BUTTON':
            return {
                ...state,
                enableConfirm: true,
            };
        case 'RESET':
            return { ...initialState };
        case 'SET_DELETE_PARAMS':
            return {
                ...state,
                params: { ...action.params },
                showModal: true,
            };
    }
};

export const useDeleteMutation = (): [State, Dispatch<Action>] => {
    return useReducer(reducer, initialState);
};
