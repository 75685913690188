import { ReactNode, forwardRef } from 'react';
import classnames from 'classnames';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { noop } from 'lodash';

type CardProps = {
    statusColor?: string;
    backgroundColor?: string;
    children: ReactNode;
    className?: string;
    onClick?: () => void;
    id?: string;
    additionalHtmlAttributes?: Record<string, string | undefined>;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(
    (
        {
            id,
            statusColor,
            backgroundColor = 'bg-neutral-100',
            children,
            className,
            onClick,
            additionalHtmlAttributes,
        },
        ref,
    ) => {
        const cardClassNames = classnames(
            backgroundColor,
            statusColor,
            className,
            statusColor &&
                className?.indexOf('border-') === -1 &&
                'border-solid border-l-6',
            className?.indexOf('overflow-') === -1 && 'overflow-hidden',
            className?.indexOf('rounded-') === -1 && 'rounded-md',
            onClick && 'cursor-pointer',
        );

        return (
            <div
                id={id}
                ref={ref}
                className={cardClassNames}
                onClick={onClick || noop}
                {...additionalHtmlAttributes}
            >
                {children}
            </div>
        );
    },
);

Card.displayName = 'Card';
