import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Checkbox, Headline, Image, Paragraph, SourceInfo } from '_atoms';
import { GoogleCandidate, GoogleCandidateStatus, GoogleImage } from '_types';
import { TargetPersonSelectionState } from '_enums';
import { ImageGalleryModal } from '_molecules';
import classnames from 'classnames';
import classNames from 'classnames';
import { getCdnUrl, parseRem } from '_utils';
import { TbExternalLink } from 'react-icons/tb';
import { GoogleCandidateImageSelectionStatus } from '../../../types/TargetPersonSelection';
import { PendingCandidateIterator } from './TargetCandidates';
import { FallbackImage } from '../../../components/_molecules/FallbackImage/FallbackImage';
import { Swiper, SwiperSlide } from 'swiper/react';
import { theme } from '../../../tailwind.config';
import { FiChevronDown } from 'react-icons/fi';

type UnstructuredCandidateProps = {
    groupId: string;
    name: string;
    organizations: string[];
    candidates: GoogleCandidate[];
    groupSelectionState: GoogleCandidateStatus[];
    updateSelectionState: (
        groupId: string,
        selectionState: GoogleCandidateStatus[],
    ) => void;
    disabled?: boolean;
    score?: number;
    fingerprintScore?: number;
    unifiedScore?: number;
    iterator: PendingCandidateIterator;
    isListView?: boolean;
};

export const ShittyListViewUnstructuredCandidate: FC<UnstructuredCandidateProps> =
    ({
        groupId,
        name,
        candidates = [],
        groupSelectionState,
        organizations,
        score,
        fingerprintScore,
        unifiedScore,
        disabled = false,
        updateSelectionState,
        isListView = false,
    }) => {
        const { t } = useTranslation();

        const [isGalleryOpened, setIsGalleryOpened] = useState(false);
        const [galleryImages, setGalleryImages] = useState<string[]>([]);

        const [showGroupTopics, setShowGroupTopics] = useState(false);

        const currentGroupStatus = useMemo(() => {
            if (
                groupSelectionState.every(
                    (value) =>
                        value.status === TargetPersonSelectionState.Confirmed,
                )
            ) {
                return TargetPersonSelectionState.Confirmed;
            }
            if (
                groupSelectionState.every(
                    (value) =>
                        value.status === TargetPersonSelectionState.Ignored,
                )
            ) {
                return TargetPersonSelectionState.Ignored;
            }
            return undefined;
        }, [groupSelectionState]);

        const handleImageGalleryOpen = ({ images }: { images: string[] }) => {
            setGalleryImages(images);
            setIsGalleryOpened(true);
        };

        const handleImageGalleryClose = () => {
            setIsGalleryOpened(false);
        };

        const wipeAllImagesIfIgnored = (
            imageSelectionStatus: GoogleCandidateImageSelectionStatus[],
            status?: TargetPersonSelectionState,
        ) => {
            return status === TargetPersonSelectionState.Confirmed
                ? imageSelectionStatus
                : imageSelectionStatus.map(({ imageId }) => ({
                      imageId,
                      imageStatus: undefined,
                  }));
        };

        const handleSetAll = (
            isSelected: boolean,
            status?: TargetPersonSelectionState,
        ) => {
            updateSelectionState(
                groupId,
                groupSelectionState.map(({ id, imageSelectionStatus }) => ({
                    id,
                    status: isSelected ? status : undefined,
                    imageSelectionStatus: wipeAllImagesIfIgnored(
                        imageSelectionStatus,
                        status,
                    ),
                })),
            );
        };

        const handleChangeImageSelection = (
            candidateId: string,
            imageIndex: number,
        ) => {
            const candidate = groupSelectionState.find(
                (candidate) => candidate.id === candidateId,
            );

            if (!candidate) {
                return;
            }

            const currentImageStatus =
                candidate.imageSelectionStatus[imageIndex]?.imageStatus;

            updateSelectionState(groupId, [
                ...groupSelectionState.filter(
                    (candidate) => candidate.id !== candidateId,
                ),
                {
                    ...candidate,
                    status:
                        currentImageStatus === undefined
                            ? TargetPersonSelectionState.Confirmed
                            : candidate.status,
                    imageSelectionStatus: candidate.imageSelectionStatus.map(
                        (image, i) => {
                            if (i !== imageIndex) {
                                return image;
                            }

                            return {
                                ...image,
                                imageStatus:
                                    image.imageStatus ===
                                    TargetPersonSelectionState.Confirmed
                                        ? undefined
                                        : TargetPersonSelectionState.Confirmed,
                            };
                        },
                    ),
                },
            ]);
        };

        const handleChangeSubCandidateSelection = (
            id: string,
            isSelected: boolean,
            status?: TargetPersonSelectionState,
        ) => {
            const candidate = groupSelectionState.find(
                (candidate) => candidate.id === id,
            );

            const newStatus = isSelected ? status : undefined;

            if (candidate) {
                updateSelectionState(groupId, [
                    ...groupSelectionState.filter(
                        (candidate) => candidate.id !== id,
                    ),
                    {
                        ...candidate,
                        status: newStatus,
                        imageSelectionStatus: wipeAllImagesIfIgnored(
                            candidate.imageSelectionStatus,
                            newStatus,
                        ),
                    },
                ]);
            }
        };

        return (
            <>
                <Card
                    className={classnames(
                        'border-2 rounded-2xl p-4 flex space-x-4 relative pr-14 flex-grow',
                        currentGroupStatus ===
                            TargetPersonSelectionState.Confirmed &&
                            'border-primary-4',
                        currentGroupStatus ===
                            TargetPersonSelectionState.Ignored &&
                            'border-error-2',
                        isListView && 'max-h-150',
                    )}
                >
                    <div className="object-cover border rounded-full w-20 h-20 flex-shrink-0 overflow-hidden">
                        <FallbackImage aspectRatio="square" />
                    </div>
                    <div className="overflow-hidden flex flex-col flex-grow">
                        <div className="border-b-2">
                            <div className="flex justify-between space-x-5 items-center pb-4">
                                <div>
                                    <Headline
                                        Level="h4"
                                        className="flex gap-x-1 items-center"
                                    >
                                        <span className="font-jost">
                                            {name}
                                        </span>
                                    </Headline>
                                    <SourceInfo
                                        sources={['google']}
                                        size="small"
                                    />
                                </div>
                                <div className="flex justify-end items-center space-x-5">
                                    <Checkbox
                                        label={t('excludeCandidate')}
                                        onChange={(isSelected) =>
                                            handleSetAll(
                                                isSelected,
                                                TargetPersonSelectionState.Ignored,
                                            )
                                        }
                                        initialIsSelected={
                                            currentGroupStatus ===
                                            TargetPersonSelectionState.Ignored
                                        }
                                        labelPosition="append"
                                        labelColor="red"
                                        color="red"
                                        disabled={disabled}
                                    />
                                    <Checkbox
                                        label={t('includeCandidate')}
                                        onChange={(isSelected) =>
                                            handleSetAll(
                                                isSelected,
                                                TargetPersonSelectionState.Confirmed,
                                            )
                                        }
                                        initialIsSelected={
                                            currentGroupStatus ===
                                            TargetPersonSelectionState.Confirmed
                                        }
                                        labelColor="blue"
                                        labelPosition="append"
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            {organizations.length > 0 && candidates.length > 1 && (
                                <div className="py-4 border-t-2">
                                    <div
                                        className="font-jost font-bold text-primary-4 cursor-pointer flex items-center"
                                        onClick={() =>
                                            setShowGroupTopics(!showGroupTopics)
                                        }
                                    >
                                        {showGroupTopics ? (
                                            <>
                                                {t(
                                                    'googleCandidates.hideGroupTopics',
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {t(
                                                    'googleCandidates.showGroupTopics',
                                                )}
                                            </>
                                        )}
                                        <FiChevronDown
                                            className={classnames(
                                                'transition',
                                                showGroupTopics
                                                    ? 'rotate-180'
                                                    : 'rotate-0',
                                            )}
                                        />
                                    </div>
                                    {showGroupTopics && (
                                        <div className="grid grid-cols-6 gap-x-6 gap-y-1 font-jost pt-2 ">
                                            {organizations.map(
                                                (organization, index) => (
                                                    <div key={index}>
                                                        {organization}
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {process.env.REACT_APP_STAGE !== 'production' && (
                            <div className="absolute right-full top-0 w-48 p-2 mr-2 bg-neutral-100">
                                <div className="text-sm text-neutral-500 leading-5">
                                    {`Candidate group score: ${score}`}
                                </div>
                                <div className="text-sm text-neutral-500 leading-5">
                                    {`Unified score: ${parseFloat(
                                        (unifiedScore ?? 0).toFixed(5),
                                    )}`}
                                </div>
                                <div className="text-sm text-neutral-500 leading-5">
                                    {`Fingerprint score: ${parseFloat(
                                        (fingerprintScore ?? 0).toFixed(5),
                                    )}`}
                                </div>
                            </div>
                        )}

                        <ImageGalleryModal
                            isOpen={isGalleryOpened}
                            onClose={handleImageGalleryClose}
                            images={galleryImages}
                            aspectRatio="none"
                        />

                        <div className="mt-4 flex flex-col space-y-4 overflow-y-auto">
                            {candidates.length > 0 && (
                                <>
                                    {candidates.map(
                                        ({
                                            id,
                                            title,
                                            description,
                                            url,
                                            googleImages,
                                        }) => {
                                            let domain = url;

                                            try {
                                                domain = new URL(url).hostname;
                                            } catch (e) {
                                                console.warn(
                                                    `invalid URL ${url}`,
                                                    e,
                                                );
                                            }

                                            const candidateStatus =
                                                groupSelectionState.find(
                                                    (candidate) =>
                                                        candidate.id === id,
                                                );

                                            const status =
                                                candidateStatus?.status;

                                            return (
                                                <div
                                                    key={id}
                                                    className="flex flex-col space-y-2 relative font-jost"
                                                >
                                                    <div
                                                        className={classNames(
                                                            'px-4 transition border-l-6 flex justify-between space-x-4',
                                                            status ===
                                                                TargetPersonSelectionState.Ignored &&
                                                                'border-error-1',
                                                            status ===
                                                                TargetPersonSelectionState.Confirmed &&
                                                                'border-primary-4',
                                                        )}
                                                    >
                                                        <div
                                                            className={classnames(
                                                                'transition',
                                                                status ===
                                                                    TargetPersonSelectionState.Ignored
                                                                    ? 'opacity-50'
                                                                    : 'opacity-100',
                                                            )}
                                                        >
                                                            <Headline
                                                                Level="h5"
                                                                lineHeight="default"
                                                            >
                                                                {title}
                                                            </Headline>
                                                            <Paragraph color="light">
                                                                {description}
                                                            </Paragraph>
                                                            <a
                                                                target="_blank"
                                                                href={url}
                                                                rel="noopener noreferrer"
                                                                className="break-all inline-block text-primary-4 hover:text-primary-3 transition disabled:opacity-50 pt-1"
                                                            >
                                                                <div className="flex items-center space-x-1">
                                                                    <span>
                                                                        {domain}
                                                                    </span>
                                                                    <TbExternalLink className="text-lg" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="flex justify-end items-center space-x-5">
                                                            <Checkbox
                                                                label={t(
                                                                    'exclude',
                                                                )}
                                                                onChange={(
                                                                    isSelected,
                                                                ) =>
                                                                    handleChangeSubCandidateSelection(
                                                                        id,
                                                                        isSelected,
                                                                        TargetPersonSelectionState.Ignored,
                                                                    )
                                                                }
                                                                initialIsSelected={
                                                                    status ===
                                                                    TargetPersonSelectionState.Ignored
                                                                }
                                                                labelPosition="append"
                                                                labelColor="red"
                                                                color="red"
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                            <Checkbox
                                                                label={t(
                                                                    'include',
                                                                )}
                                                                onChange={(
                                                                    isSelected,
                                                                ) =>
                                                                    handleChangeSubCandidateSelection(
                                                                        id,
                                                                        isSelected,
                                                                        TargetPersonSelectionState.Confirmed,
                                                                    )
                                                                }
                                                                initialIsSelected={
                                                                    status ===
                                                                    TargetPersonSelectionState.Confirmed
                                                                }
                                                                labelColor="blue"
                                                                labelPosition="append"
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {googleImages &&
                                                        googleImages.length >
                                                            0 && (
                                                            <div
                                                                className={classnames(
                                                                    'w-full rounded pt-2 relative border-l-6 px-4 border-transparent transition',
                                                                    status ===
                                                                        TargetPersonSelectionState.Ignored
                                                                        ? 'opacity-50'
                                                                        : 'opacity-100',
                                                                )}
                                                            >
                                                                <Swiper
                                                                    className=""
                                                                    spaceBetween={parseRem(
                                                                        theme
                                                                            .spacing[1.5],
                                                                    )}
                                                                    slidesPerView={
                                                                        12.3
                                                                    }
                                                                    preventInteractionOnTransition={
                                                                        true
                                                                    }
                                                                    lazy={{
                                                                        loadOnTransitionStart:
                                                                            true,
                                                                    }}
                                                                >
                                                                    {googleImages?.map(
                                                                        (
                                                                            image: GoogleImage,
                                                                            imageIndex,
                                                                        ) => (
                                                                            <SwiperSlide
                                                                                className="cursor-pointer group"
                                                                                key={
                                                                                    imageIndex
                                                                                }
                                                                            >
                                                                                <Image
                                                                                    aspectRatio="portrait"
                                                                                    alt={`${image.title}`}
                                                                                    className="rounded-md"
                                                                                    src={getCdnUrl(
                                                                                        image.url,
                                                                                    )}
                                                                                    onClick={() => {
                                                                                        handleImageGalleryOpen(
                                                                                            {
                                                                                                images: [
                                                                                                    getCdnUrl(
                                                                                                        image.url,
                                                                                                    ),
                                                                                                ],
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <Checkbox
                                                                                    className="pt-2 inline-block"
                                                                                    onChange={() =>
                                                                                        handleChangeImageSelection(
                                                                                            id,
                                                                                            imageIndex,
                                                                                        )
                                                                                    }
                                                                                    initialIsSelected={
                                                                                        candidateStatus
                                                                                            ?.imageSelectionStatus?.[
                                                                                            imageIndex
                                                                                        ]
                                                                                            ?.imageStatus ===
                                                                                        TargetPersonSelectionState.Confirmed
                                                                                    }
                                                                                    labelPosition="before"
                                                                                    disabled={
                                                                                        disabled ||
                                                                                        status ===
                                                                                            TargetPersonSelectionState.Ignored
                                                                                    }
                                                                                />
                                                                            </SwiperSlide>
                                                                        ),
                                                                    )}
                                                                </Swiper>
                                                            </div>
                                                        )}
                                                </div>
                                            );
                                        },
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Card>
            </>
        );
    };
