import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageArticle } from 'src/services/searchService';
import { Badge, Headline, Highlight, ImageData, TextData } from '_atoms';
import { BaseCard } from '_organisms';
import { EntitySuspicion } from '_types';
import { formatDateTime, getCdnUrl } from '_utils';
import { CaseData, Target } from '../../../services/dataService';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';
import { IndicatorsWithTooltip } from '../../_atoms/Highlight/types';

interface PictureCardProps extends HTMLAttributes<HTMLDivElement> {
    data: ImageArticle;
    action?: JSX.Element;
    score?: number;
    status?: string;
    suspicionItemId?: string;
    caseData?: CaseData;
    targetData?: Target;
    suspicionItems?: Array<EntitySuspicion>;
    indicators: IndicatorsWithTooltip[];
    query?: string;
}

export const PictureCard: FC<PictureCardProps> = ({
    data,
    query,
    suspicionItems,
    indicators,
    caseData,
    ...props
}) => {
    const { targetData } = props;
    const { t } = useTranslation();

    const sourceName: Record<string, string> = t('sourceName', {
        returnObjects: true,
    });

    const highlight = buildHighlightChunks({
        searchQuery: query,
        indicators,
        target: targetData,
        caseKeywords: caseData?.keywords,
    });

    return (
        <BaseCard
            entityType="image"
            title={data.imageSources
                .map(
                    (imageSource) =>
                        sourceName[imageSource.toLowerCase()] ?? imageSource,
                )
                .join(', ')}
            titleLink={data.imageUrl}
            item={data}
            highlight={highlight}
            suspicionItems={suspicionItems}
            {...props}
        >
            <div className="p-3 pt-0 flex gap-3">
                <ImageData
                    headline={t('image')}
                    src={getCdnUrl(data.imageMediaCdnUrl)}
                    width="w-2/4"
                    className="inline-block align-top pl-5"
                    enableZoom={false}
                />
                <div className="w-2/4 inline-block align-top">
                    <div className="grid grid-cols-2 w-full">
                        <TextData
                            headline={t('source')}
                            text={data.imageSources
                                .map(
                                    (imageSource) =>
                                        sourceName[imageSource.toLowerCase()] ??
                                        imageSource,
                                )
                                .join(', ')}
                            highlight={highlight}
                            width="w-full"
                            className="text-sm"
                        />
                        <TextData
                            headline={t('date')}
                            text={
                                formatDateTime(data.imageDatePublished) ??
                                t('noData')
                            }
                            highlight={highlight}
                            width="w-full"
                            className="text-sm"
                        />
                    </div>
                    {data.imageAnalsisTags &&
                        data.imageAnalsisTags?.length > 0 && (
                            <>
                                <Headline
                                    className="pl-2 mt-1 uppercase"
                                    Level="h6"
                                    weight="bold"
                                    color="light"
                                >
                                    {t('tags')}
                                </Headline>
                                <div className="w-full px-2 space-x-4 mb-2 text-sm">
                                    {data.imageAnalsisTags.map((tag) => (
                                        <Badge
                                            key={tag}
                                            background="light"
                                            paddingClassName="px-2 py-1"
                                            marginClassName="my-1"
                                            className="border-primary-4 border-solid border font-semibold"
                                        >
                                            <Highlight
                                                searchWords={highlight}
                                                textToHighlight={tag}
                                            />
                                        </Badge>
                                    ))}
                                </div>
                            </>
                        )}
                </div>
            </div>
        </BaseCard>
    );
};
