import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '_atoms';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Navigation } from 'swiper';
import { NavigationMethods } from 'swiper/types';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { FallbackImage } from '../FallbackImage/FallbackImage';

SwiperCore.use([Navigation, Keyboard]);

type ImageSliderProps = {
    images: string[];
};

export const ImageSlider: FC<ImageSliderProps> = ({
    images,
}: ImageSliderProps) => {
    const { t } = useTranslation();

    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

    const prevRef = useRef<HTMLDivElement | null>(null);
    const nextRef = useRef<HTMLDivElement | null>(null);
    const sliderRef = useRef<HTMLDivElement | null>(null);

    const handleBeforeSliderInit = (swiper: SwiperCore) => {
        const navigation = swiper.params.navigation as NavigationMethods;
        navigation.prevEl = prevRef.current as HTMLElement;
        navigation.nextEl = nextRef.current as HTMLElement;
    };

    const handleSlideChange = (swiper: SwiperCore) =>
        setActiveSlideIndex(swiper.activeIndex);

    if (!images.length) {
        return <FallbackImage />;
    }

    return (
        <div className="image-slider relative" ref={sliderRef}>
            <Swiper
                className="overflow-y-auto rounded-md"
                onBeforeInit={handleBeforeSliderInit}
                onActiveIndexChange={handleSlideChange}
                observer={true}
                observeParents={true}
                lazy={{
                    loadOnTransitionStart: true,
                }}
                loopedSlides={5}
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                    disabledClass: 'opacity-0',
                }}
                keyboard={{ enabled: true }}
            >
                {images.map((src, index) => (
                    <SwiperSlide key={index}>
                        <Image
                            aspectRatio="portrait"
                            alt={`${t('profileImage')} ${index + 1}`}
                            src={src}
                        />
                    </SwiperSlide>
                ))}

                <div className="absolute w-full inset-0 flex justify-between align-middle z-10 opacity-0 hover:pointer-events-none hover:opacity-100">
                    <div
                        className="pointer-events-auto my-auto transition duration-500 opacity-0"
                        ref={prevRef}
                    >
                        <button
                            className="text-primary-4 p-2 focus:outline-none"
                            disabled={activeSlideIndex === 0}
                        >
                            <MdChevronLeft />
                        </button>
                    </div>
                    <div
                        className="pointer-events-auto my-auto transition duration-500"
                        ref={nextRef}
                    >
                        <button
                            className="text-primary-4 p-2 focus:outline-none"
                            disabled={activeSlideIndex === images.length - 1}
                        >
                            <MdChevronRight />
                        </button>
                    </div>
                </div>
            </Swiper>
        </div>
    );
};
