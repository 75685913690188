import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { routes } from '../../../routes';
import { useCaseQuery, useTargetSelectedCandidatesQuery } from '_queries';
import { Card, Error, Headline, LoadingSpinner, Paragraph } from '_atoms';
import { Alert, ImageGalleryModal } from '_molecules';
import { LegacyStructuredCandidate } from '../../targets/TargetCandidates/LegacyStructuredCandidate';
import { LegacyUnstructuredCandidate } from '../../targets/TargetCandidates/LegacyUnstructuredCandidate';
import { CaseType } from '../../cases/CaseNew';
import { TargetPersonSelectionState } from '_enums';
import { AllCandidates } from '../../../services/dataService';
import { GoogleCandidateStatus } from '_types';

type GoogleCandidateStatusMap = Record<string, GoogleCandidateStatus[]>;

const mapCandidateStatusToScore = (
    candidate: AllCandidates,
    selectedGoogleCandidates: GoogleCandidateStatusMap,
): number => {
    const TOP_SCORE = 3;
    const HIGH_SCORE = 2;
    const MID_SCORE = 1;
    const LOW_SCORE = 0;

    if ('info' in candidate) {
        if (candidate.status === TargetPersonSelectionState.Confirmed) {
            return TOP_SCORE;
        }

        if (candidate.status === TargetPersonSelectionState.Ignored) {
            return LOW_SCORE;
        }

        if (!candidate.status) {
            return MID_SCORE;
        }
    }

    if (candidate.groupId in selectedGoogleCandidates) {
        const allConfirmed = selectedGoogleCandidates[candidate.groupId].every(
            ({ status }) => status === TargetPersonSelectionState.Confirmed,
        );

        if (allConfirmed) {
            return TOP_SCORE;
        }

        const someConfirmed = selectedGoogleCandidates[candidate.groupId]?.some(
            ({ status }) => status === TargetPersonSelectionState.Confirmed,
        );

        if (someConfirmed) {
            return HIGH_SCORE;
        }

        const allIgnored = selectedGoogleCandidates[candidate.groupId]?.every(
            ({ status }) => status === TargetPersonSelectionState.Ignored,
        );

        if (allIgnored) {
            return LOW_SCORE;
        }

        return MID_SCORE;
    }

    return LOW_SCORE;
};

export const Candidates: FC = () => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const [isGalleryOpened, setIsGalleryOpened] = useState(false);
    const [galleryImages, setGalleryImages] = useState<string[]>([]);

    const caseQuery = useCaseQuery(caseId);

    const {
        isError: candidatesError,
        data: { candidates, googleCandidates, allCandidates } = {
            candidates: [],
            googleCandidates: [],
            allCandidates: [],
        },
    } = useTargetSelectedCandidatesQuery(
        caseId,
        targetId,
        caseQuery.data?.option?.option ?? CaseType.Essential,
    );

    const hasTradeRegisterData = (() => {
        return candidates?.some(
            (group) =>
                group.sources.includes('orbis') ||
                group.sources.includes('companyhouse'),
        );
    })();

    const hasCandidates = allCandidates.length > 0;

    const selectedGoogleCandidates = useMemo(
        (): GoogleCandidateStatusMap =>
            Object.fromEntries(
                (googleCandidates ?? []).map((group) => [
                    group.groupId,
                    group.candidates.map((candidate) => ({
                        id: candidate.id,
                        status: candidate.status,
                        imageSelectionStatus: [],
                    })),
                ]),
            ),
        [googleCandidates],
    );

    const handleImageGalleryOpen = ({ images }: { images: string[] }) => {
        setGalleryImages(images);
        setIsGalleryOpened(true);
    };

    const handleImageGalleryClose = () => {
        setIsGalleryOpened(false);
    };

    const sortedCandidates = useMemo(
        () =>
            allCandidates.sort(
                (a, b) =>
                    mapCandidateStatusToScore(b, selectedGoogleCandidates) -
                    mapCandidateStatusToScore(a, selectedGoogleCandidates),
            ),
        [allCandidates, selectedGoogleCandidates],
    );

    return (
        <div className="container-fluid p-9 pb-0 h-full flex flex-col xl:max-w-max-content">
            <Alert
                alertType="disclamer"
                message={t('inputDataDisclamer')}
                className="mt-4"
            />
            <div className="row">
                <div className="col-12">
                    <div className="row mb-6">
                        <Headline className="mt-6" Level="h3" color="dark">
                            {t('resolveTargetCandidatesHeadline')}
                        </Headline>
                    </div>
                    {candidatesError && (
                        <Error
                            headline={t('noResultHeadline')}
                            message={t('noResultText')}
                            target={{
                                link: `${routes.casesList.path}`,
                                text: t('cancelCreateTarget'),
                            }}
                        />
                    )}
                    {hasCandidates && !hasTradeRegisterData && (
                        <Card className="border-2 p-5 mb-6 text-center border-gray-200">
                            <Paragraph>
                                {t('noTradeRegisterDataText')}
                            </Paragraph>
                        </Card>
                    )}
                    {hasCandidates ? (
                        <div className="grid grid-cols-1 4xl:grid-cols-2 gap-5">
                            {sortedCandidates.map((candidate) =>
                                'info' in candidate ? (
                                    <LegacyStructuredCandidate
                                        key={candidate.groupId}
                                        {...candidate}
                                        onStateChange={() => null}
                                        onGalleryOpen={handleImageGalleryOpen}
                                        disabled={true}
                                    />
                                ) : (
                                    <LegacyUnstructuredCandidate
                                        key={candidate.groupId}
                                        {...candidate}
                                        selectedCandidates={
                                            selectedGoogleCandidates
                                        }
                                        selectCandidates={() => null}
                                        disabled={true}
                                    />
                                ),
                            )}
                        </div>
                    ) : (
                        <LoadingSpinner
                            message={<span className="invisible">...</span>}
                        />
                    )}
                </div>
            </div>
            <ImageGalleryModal
                isOpen={isGalleryOpened}
                onClose={handleImageGalleryClose}
                images={galleryImages}
            />
        </div>
    );
};
