import { TargetReportSourceType } from '@indicium/common';
import { CaseType } from '../features/cases/CaseNew/CaseNew.types';

export const allowedSourcesByCaseType = (
    option: CaseType,
): Array<TargetReportSourceType | string> => {
    /*
     * base for when caseType === 'essential'.
     * Lexisnexis is also allowed for essential... but it is not a pure source type.
     */
    const allowedSources: TargetReportSourceType[] = [
        'userInput',
        'google',
        'pipl',
        'shadowdragon',
    ];

    if (option === CaseType.Plus) {
        allowedSources.push('complianceCatalyst');
    }

    if (option === CaseType.Complete) {
        allowedSources.push('orbis', 'icijLeaks', 'companyhouse', 'zefix');
    }
    return allowedSources;
};
