import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseData, Target } from 'src/services/dataService';
import { DynamicSVG, LoadingSpinner, Paragraph, Tag } from '_atoms';
import { CollapsibleCard, Pagination } from '_molecules';
import {
    CompanyCard,
    PictureCard,
    PressCard,
    SearchResultCard,
    SocialMediaCard,
} from '_organisms';
import { useSuspicionsReportItemBySuspicionQuery } from '_queries';
import { ReportFilters } from '../RiskAnalysis/ReportCard';
import { EntityBySuspicion } from '_types';
import { useReportTailoring } from '../../../hooks/useReportTailoring';
import { withinSpanOfDays } from '_utils';
import { SuspicionIndicator } from '../../../types/SuspicionItemEntity';

const PAGE_SIZE = 10;

interface ArticlesBySuspicionProps {
    id: string;
    name: string;
    label: string;
    targetId: string;
    filters?: ReportFilters;
    targetData?: Target;
    caseData?: CaseData;
    accessToken: string;
    entityCount?: number;
    confirmedCount?: number;
}

const buildIndicators = <T extends unknown>(
    suspicionIndicators: SuspicionIndicator[],
): T[] => suspicionIndicators.map(({ indicator }) => indicator.value as T);

export const ArticlesBySuspicion: FC<ArticlesBySuspicionProps> = ({
    id,
    name,
    label,
    targetId,
    filters,
    targetData,
    caseData,
    accessToken,
    entityCount,
    confirmedCount,
}) => {
    const { t } = useTranslation();
    const { ReportTailoringActions } = useReportTailoring({
        targetId: targetId,
    });
    const [page, setPage] = useState<number>(1);

    const { data, isLoading, isError } =
        useSuspicionsReportItemBySuspicionQuery(targetId, id, {
            ...filters,
            page,
            pageSize: PAGE_SIZE,
        });

    const uniqueEntities: EntityBySuspicion[] = useMemo(() => {
        const map = new Map(data?.entities?.map((e) => [e.id, e]));

        return Array.from(map.values());
    }, [data?.entities]);

    const uniformStatus: string | undefined = useMemo(() => {
        const set = new Set(uniqueEntities?.map(({ status }) => status));
        const keys = Array.from(set.keys());

        return keys.length === 1 ? keys[0] : undefined;
    }, [uniqueEntities]);

    // if older than 30 days, don't allow editing the report anymore
    const reportIsTooOld =
        !!targetData?.updatedAt &&
        !withinSpanOfDays(targetData.updatedAt) &&
        process.env.REACT_APP_STAGE === 'production';

    const allActions =
        uniqueEntities?.length && !reportIsTooOld
            ? {
                  action: ReportTailoringActions.UpdateItemsStatus(
                      uniqueEntities.map((item) => item.suspicionItemId),
                      uniformStatus ?? 'pending',
                      !uniformStatus,
                  ),
              }
            : null;

    useEffect(() => {
        setPage(1);
    }, [filters]);

    return (
        <CollapsibleCard
            key={['report', 'suspicion', id].join('-')}
            header={
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full bg-warning-1 p-3 text-yellow-700">
                            <DynamicSVG
                                src={`icons/suspicions/${label}`}
                                width={16}
                            />
                        </div>
                        <div className="flex items-baseline gap-4">
                            <Paragraph color="dark" size="medium" weight="bold">
                                {name}
                            </Paragraph>
                            <Paragraph color="mediumDark" size="small">
                                {entityCount &&
                                    entityCount > 0 &&
                                    `${t('confirmedSuspicionsCount', {
                                        hits: confirmedCount,
                                    })} / `}
                                {t('reports.resultsCount', {
                                    count: entityCount,
                                })}
                            </Paragraph>
                        </div>
                    </div>
                    {allActions?.action}
                </div>
            }
            headerColor="bg-gray-200"
            bodyClasses="bg-gray-200"
            className="border-0"
            initiallyExpanded={filters?.suspicionItemId === id}
        >
            <div className="flex flex-col space-y-5">
                {isLoading ? (
                    <LoadingSpinner
                        message={<span className="invisible">...</span>}
                    />
                ) : isError ? (
                    <div>Error</div>
                ) : null}

                {uniqueEntities?.length ? (
                    <>
                        {uniqueEntities.map(
                            ({
                                value,
                                suspicionItemId: itemId,
                                score,
                                status,
                                indicators,
                            }) => {
                                switch (value.type) {
                                    case 'press':
                                        return (
                                            <PressCard
                                                key={`${value.id}, status: ${status}`}
                                                data={value}
                                                score={score}
                                                status={status}
                                                suspicionItemId={itemId}
                                                targetData={targetData}
                                                caseData={caseData}
                                                indicators={buildIndicators(
                                                    indicators,
                                                )}
                                            />
                                        );
                                    case 'SocialAccountConnection':
                                    case 'SocialAccountPost':
                                        return (
                                            <SocialMediaCard
                                                key={`${value.id}, status: ${status}`}
                                                data={value}
                                                accessToken={accessToken}
                                                score={score}
                                                status={status}
                                                suspicionItemId={itemId}
                                                targetData={targetData}
                                                caseData={caseData}
                                                indicators={buildIndicators(
                                                    indicators,
                                                )}
                                            />
                                        );
                                    case 'Image':
                                        return (
                                            <PictureCard
                                                key={`${value.id}, status: ${status}`}
                                                data={value}
                                                score={score}
                                                status={status}
                                                suspicionItemId={itemId}
                                                targetData={targetData}
                                                caseData={caseData}
                                                indicators={buildIndicators(
                                                    indicators,
                                                )}
                                            />
                                        );
                                    case 'WebPage':
                                        return (
                                            <SearchResultCard
                                                key={`${value.id}, status: ${status}`}
                                                data={value}
                                                score={score}
                                                status={status}
                                                suspicionItemId={itemId}
                                                targetData={targetData}
                                                caseData={caseData}
                                                indicators={buildIndicators(
                                                    indicators,
                                                )}
                                            />
                                        );
                                    case 'TargetCompany':
                                        return (
                                            <CompanyCard
                                                key={`${value.id}, status: ${status}`}
                                                data={value}
                                                score={score}
                                                status={status}
                                                suspicionItemId={itemId}
                                                targetData={targetData}
                                                caseData={caseData}
                                            />
                                        );
                                }
                            },
                        )}

                        {data?.paging?.pagesCount &&
                        data?.paging?.pagesCount > 1 ? (
                            <div className="px-5">
                                <Pagination
                                    paging={data.paging}
                                    setPageNumber={setPage}
                                />
                            </div>
                        ) : null}
                    </>
                ) : (
                    <Tag className="self-center">{t('noData')}</Tag>
                )}
            </div>
        </CollapsibleCard>
    );
};
