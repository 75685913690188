import React from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';

export interface ConditionalSpotlightProps {
    targetName: string;
    condition: boolean;
}

export const ConditionalSpotlight: React.FC<
    React.PropsWithChildren<ConditionalSpotlightProps>
> = ({ targetName, children, condition }) => {
    return condition ? (
        <SpotlightTarget name={targetName}>{children}</SpotlightTarget>
    ) : (
        <>{children}</>
    );
};
