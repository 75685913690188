import {
    GROUPING_OPTIONS,
    ReportGroupingOption,
    ReportQueryParams,
    ReportsQuery,
} from './ReportCard';
import { SuspicionItemEntityType, SuspicionItemStatus } from '_enums';
import { ReportSuspicion, SummaryReport } from '_types';
import { TFunction } from 'react-i18next';
import {
    INVOLVEMENT_OPTIONS,
    SuspicionItemSource,
} from '../../../types/SuspicionItem';

export const getTestIdForReportCard = (
    reportType: string,
): string | undefined => {
    switch (reportType) {
        case 'compliance_violations':
            return 'analysis overview notice of compliance violations';
        case 'general_crime':
            return 'analysis overview indications of crime';
        case 'own_keywords':
            return 'analysis overview own keywords';
        case 'general_anomalies':
            return 'analysis overview general abnormalities';
        case 'problematic_content_detected':
            return 'analysis overview problematic content detected';
        default:
            return undefined;
    }
};

export const extractParams = (
    initialFilters: ReportsQuery,
    queryParams: ReportsQuery,
    summaryReportData?: SummaryReport[],
): ReportsQuery => {
    const _filters: ReportsQuery = { ...initialFilters };

    summaryReportData?.forEach((report) => {
        const reportLabel = report.label;
        const reportFilters: ReportQueryParams = {};
        if (
            queryParams[reportLabel] &&
            typeof queryParams[reportLabel] === 'object' &&
            Object.keys(queryParams[reportLabel] as ReportQueryParams).length >
                0
        ) {
            const searchParams = queryParams[reportLabel] as ReportQueryParams;

            if (searchParams.suspicionItemId) {
                reportFilters.suspicionItemId =
                    searchParams.suspicionItemId.toString();
            }
            if (
                searchParams.entityType &&
                Object.values(SuspicionItemEntityType).includes(
                    searchParams.entityType.toString() as SuspicionItemEntityType,
                )
            ) {
                reportFilters.entityType =
                    searchParams.entityType.toString() as SuspicionItemEntityType;
            }
            if (
                searchParams.status &&
                Object.values(SuspicionItemStatus).includes(
                    searchParams.status.toString() as SuspicionItemStatus,
                )
            ) {
                reportFilters.status =
                    searchParams.status.toString() as SuspicionItemStatus;
            }
            if (
                searchParams.involvement &&
                INVOLVEMENT_OPTIONS.includes(searchParams.involvement)
            ) {
                reportFilters.involvement = searchParams.involvement;
            }
            if (
                searchParams.groupBy &&
                GROUPING_OPTIONS.includes(searchParams.groupBy)
            ) {
                reportFilters.groupBy =
                    searchParams.groupBy.toString() as ReportGroupingOption;
            }
            if (searchParams.expanded) {
                reportFilters.expanded = searchParams.expanded;
            }
            if (searchParams.page) {
                reportFilters.page = searchParams.page;
            }
            if (
                searchParams.source &&
                Object.values(SuspicionItemSource).includes(
                    searchParams.source.toString() as SuspicionItemSource,
                )
            ) {
                reportFilters.source =
                    searchParams.source.toString() as SuspicionItemSource;
            }
        }
        if (Object.keys(reportFilters).length > 0) {
            _filters[reportLabel] = reportFilters;
        }
    });

    return _filters;
};

export function cleanFilters(filters: ReportsQuery): ReportsQuery {
    Object.keys(filters).forEach((report: string) => {
        const reportFilters = filters[report];
        Object.entries(reportFilters).forEach(([key, value]) => {
            if (
                (key === 'expanded' && value.toString() === 'false') ||
                (key === 'page' && value <= 1) ||
                value === ''
            ) {
                delete reportFilters[key as keyof ReportQueryParams];
            }
        });
        if (Object.keys(reportFilters).length === 0) {
            delete filters[report];
        }
    });

    return filters;
}

export function translateAndSortSuspicions(
    suspicions: ReportSuspicion[],
    t: TFunction<'translation'>,
): ReportSuspicion[] {
    return suspicions
        .map((suspicion) => {
            suspicion.name = t(`suspicionKeywords.${suspicion.label}`, {
                defaultValue: suspicion.name,
            });
            return suspicion;
        })
        .sort(
            (a, b) => b.itemCount - a.itemCount || a.name.localeCompare(b.name),
        );
}
