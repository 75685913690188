import { DetailedHTMLProps, forwardRef, SelectHTMLAttributes } from 'react';
import classnames from 'classnames';
import { SelectOption } from '../../../features/targets/TargetNew/wizard/form.interface';
import { useTranslation } from 'react-i18next';

interface WizardFormSelectProps
    extends DetailedHTMLProps<
        SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    label?: string;
    errorMsg?: string;
    hintMsg?: string;
    options: SelectOption[];
}

export const WizardSelect = forwardRef<
    HTMLSelectElement,
    WizardFormSelectProps
>((props, ref) => {
    const { t } = useTranslation();
    const {
        className,
        label,
        errorMsg,
        required,
        children,
        options,
        value,
        hintMsg,
        ...rest
    } = props;

    const select = (
        <select
            className={classnames(
                className,
                'bg-neutral-50 px-4 py-3.5 text-base focus:ring-0 border-neutral-400 focus:border-primary-4 hover:border-primary-4 block w-full rounded-md transition-all border-2',
                rest.disabled && 'opacity-50',
            )}
            value={value}
            ref={ref}
            {...rest}
        >
            <option value="">-</option>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {t(option.labelTranslationKey)}
                </option>
            ))}
        </select>
    );

    return (
        <div className="w-full max-w-flow-text-base mx-auto relative">
            {label ? (
                <label>
                    <span className="block text-sm font-bold font-jost text-neutral-500">
                        {t(label)}
                        {required && <span className="text-error-1"> *</span>}
                    </span>
                    {select}
                </label>
            ) : (
                select
            )}
            {hintMsg && (
                <span className="text-neutral-500 block text-sm">
                    {t(hintMsg)}
                </span>
            )}
            {errorMsg && (
                <div className="text-error-1 text-sm">{t(errorMsg)}</div>
            )}
            {children}
        </div>
    );
});
WizardSelect.displayName = 'WizardSelect';
