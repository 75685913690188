import {
    Spotlight,
    SpotlightManager,
    SpotlightTransition,
} from '@atlaskit/onboarding';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from '@atlaskit/onboarding/dist/types/types';

export interface SpotlightContext {
    activeSpotlight: null | number;
    start: () => void;
    next: () => void;
    back: () => void;
    end: () => void;
    goto: (index: number) => void;
}

export const spotlightContext = React.createContext<SpotlightContext>({
    activeSpotlight: null,
    start: () => {
        /* do nothing */
    },
    next: () => {
        /* do nothing */
    },
    back: () => {
        /* do nothing */
    },
    end: () => {
        /* do nothing */
    },
    goto: () => {
        /* do nothing */
    },
});

export const SpotlightProvider: React.FC = ({ children }) => {
    const [activeSpotlight, setActiveSpotlight] = useState<null | number>(null);
    const { t } = useTranslation();

    const start = () => setActiveSpotlight(0);
    const next = () => setActiveSpotlight((activeSpotlight || 0) + 1);
    const back = () => setActiveSpotlight((activeSpotlight || 1) - 1);
    const end = () => setActiveSpotlight(null);
    const goto = (index: number) => setActiveSpotlight(index);

    const firstActions = [
        {
            onClick: () => next(),
            text: t('next'),
        },
        {
            onClick: () => end(),
            text: t('dismiss'),
            appearance: 'subtle',
        },
    ] as Actions;

    const finalActions = [
        { onClick: () => next(), text: 'Ok' },
        {
            onClick: () => back(),
            text: t('back'),
            appearance: 'subtle',
        },
    ] as Actions;

    const middleActions = [
        { onClick: () => next(), text: t('next') },
        {
            onClick: () => back(),
            text: t('back'),
            appearance: 'subtle',
        },
    ] as Actions;

    const renderActiveSpotlight = () => {
        const spotlights = [
            <Spotlight
                dialogWidth={800}
                actions={firstActions}
                heading={t('spotlight.onboardingLeftMenuLogoHeading')}
                target="onboardingLeftMenuLogo"
                key="onboardingLeftMenuLogo"
                targetRadius={3}
            >
                {t('spotlight.onboardingLeftMenuLogoDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t('spotlight.onboardingTopMenuBreadcrumsHeading')}
                target="onboardingTopMenuBreadcrums"
                key="onboardingTopMenuBreadcrums"
                targetRadius={3}
                targetBgColor="#F9F9F9"
            >
                {t('spotlight.onboardingTopMenuBreadcrumsDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t('spotlight.onboardingTopMenuLanguageHeading')}
                target="onboardingTopMenuLanguage"
                key="onboardingTopMenuLanguage"
                targetRadius={3}
                targetBgColor="#F9F9F9"
            >
                {t('spotlight.onboardingTopMenuLanguageDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t('spotlight.onboardingTopMenuProfileHeading')}
                target="onboardingTopMenuProfile"
                key="onboardingTopMenuProfile"
                targetRadius={3}
            >
                {t('spotlight.onboardingTopMenuProfileDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t('spotlight.onboardingLandingPageSearchHeading')}
                target="onboardingLandingPageSearch"
                key="onboardingLandingPageSearch"
                targetRadius={3}
                targetBgColor="#DDDDDD"
            >
                {t('spotlight.onboardingLandingPageSearchDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={finalActions}
                heading={t('spotlight.onboardingLeftMenuCasesHeading')}
                target="onboardingLeftMenuCases"
                key="onboardingLeftMenuCases"
                targetRadius={3}
            >
                {t('spotlight.onboardingLeftMenuCasesDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={firstActions}
                heading={t('spotlight.onboardingCaseListCreatePositionHeading')}
                target="onboardingCaseListCreatePosition"
                key="onboardingCaseListCreatePosition"
                targetRadius={3}
            >
                {t('spotlight.onboardingCaseListCreatePositionDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={firstActions}
                heading={t('spotlight.onboardingCaseNewOptionsHeading')}
                target="onboardingCaseNewOptions"
                key="onboardingCaseNewOptions"
                targetRadius={3}
            >
                {t('spotlight.onboardingCaseNewOptionsDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t('spotlight.onboardingCaseNewContinueHeading')}
                target="onboardingCaseNewContinue"
                key="onboardingCaseNewContinue"
                targetRadius={3}
            >
                {t('spotlight.onboardingCaseNewContinueDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t('spotlight.onboardingCaseNewTitleHeading')}
                target="onboardingCaseNewTitle"
                key="onboardingCaseNewTitle"
                targetRadius={3}
                targetBgColor="#DDDDDD"
            >
                {t('spotlight.onboardingCaseNewTitleDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={finalActions}
                heading={t('spotlight.onboardingCaseNewContinueHeading')}
                target="onboardingCaseNewContinue"
                key="onboardingCaseNewContinue"
                targetRadius={3}
            >
                {t('spotlight.onboardingCaseNewContinueDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={firstActions}
                heading={t('spotlight.onboardingTargetFormUploadPdfHeading')}
                target="onboardingTargetFormUploadPdf"
                key="onboardingTargetFormUploadPdf"
                targetRadius={3}
            >
                {t('spotlight.onboardingTargetFormUploadPdfDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t('spotlight.onboardingTargetFormBasicDataHeading')}
                target="onboardingTargetFormBasicData"
                key="onboardingTargetFormBasicData"
                targetRadius={3}
                targetBgColor="#DDDDDD"
            >
                {t('spotlight.onboardingTargetFormBasicDataDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t('spotlight.onboardingTargetFormCompanyDataHeading')}
                target="onboardingTargetFormCompanyData"
                key="onboardingTargetFormCompanyData"
                targetBgColor="#DDDDDD"
                targetRadius={3}
            >
                {t('spotlight.onboardingTargetFormCompanyDataDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={finalActions}
                heading={t('spotlight.onboardingTargetFormNextHeading')}
                target="onboardingTargetFormNext"
                key="onboardingTargetFormNext"
                targetRadius={3}
            >
                {t('spotlight.onboardingTargetFormNextDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={firstActions}
                heading={t(
                    'spotlight.onboardingTargetCandidatesunselectedCandidatesHeading',
                )}
                target="onboardingTargetCandidatesunselectedCandidates"
                key="onboardingTargetCandidatesunselectedCandidates"
                targetRadius={3}
            >
                {t(
                    'spotlight.onboardingTargetCandidatesunselectedCandidatesDescription',
                )}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t(
                    'spotlight.onboardingTargetCandidatesdecidedCandidatesHeading',
                )}
                target="onboardingTargetCandidatesdecidedCandidates"
                key="onboardingTargetCandidatesdecidedCandidates"
                targetRadius={3}
            >
                {t(
                    'spotlight.onboardingTargetCandidatesdecidedCandidatesDescription',
                )}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t(
                    'spotlight.onboardingTargetCandidatesExcludeHeading',
                )}
                target="onboardingTargetCandidatesExclude"
                key="onboardingTargetCandidatesExclude"
                targetRadius={3}
            >
                {t('spotlight.onboardingTargetCandidatesExcludeDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={middleActions}
                heading={t(
                    'spotlight.onboardingTargetCandidatesIncludeHeading',
                )}
                target="onboardingTargetCandidatesInclude"
                key="onboardingTargetCandidatesInclude"
                targetRadius={3}
            >
                {t('spotlight.onboardingTargetCandidatesIncludeDescription')}
            </Spotlight>,
            <Spotlight
                dialogWidth={800}
                actions={finalActions}
                heading={t(
                    'spotlight.onboardingTargetCandidatesRequestHeading',
                )}
                target="onboardingTargetCandidatesRequest"
                key="onboardingTargetCandidatesRequest"
                targetRadius={3}
            >
                {t('spotlight.onboardingTargetCandidatesRequestDescription')}
            </Spotlight>,
            // <Spotlight
            //     dialogWidth={800}
            //     actions={[]}
            //     heading={t('spotlight.onboarding{&}Heading')}
            //     target="onboarding{&}"
            //     key="onboarding{&}"
            //     targetRadius={3}
            // >
            //     {t('spotlight.onboarding{&}Description')}
            // </Spotlight>,
        ];

        if (activeSpotlight === null) {
            return null;
        }

        return spotlights[activeSpotlight];
    };

    return (
        <spotlightContext.Provider
            value={{ activeSpotlight, start, next, back, end, goto }}
        >
            <SpotlightManager>
                {children}
                <SpotlightTransition>
                    {renderActiveSpotlight()}
                </SpotlightTransition>
            </SpotlightManager>
        </spotlightContext.Provider>
    );
};

export const useSpotlightContext: () => SpotlightContext = () =>
    useContext(spotlightContext);
