import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import { CustomData, Headline, Highlight, Paragraph } from '_atoms';
import { HighlightChunks } from '../../../_atoms/Highlight/types';

export type CompanyCardTableColumn = {
    key: string;
    label: string;
    render?: (value: any) => string;
    props?: HTMLAttributes<HTMLTableCellElement>;
};

export type CompanyCardTableProps = {
    data: Array<any>;
    highlight?: HighlightChunks;
    columns: CompanyCardTableColumn[];
};

export const CompanyCardTable: FC<CompanyCardTableProps> = ({
    data,
    columns,
    highlight,
}) => {
    const defaultColumnRender = (value: any) => value;

    return data?.length ? (
        <table className="w-full">
            <thead>
                <tr>
                    {columns.map(({ key, label, props = {} }, index) => (
                        <th
                            key={[key, index].join()}
                            {...{
                                ...props,
                                className: classNames(
                                    'text-left uppercase align-bottom',
                                    props.className,
                                ),
                            }}
                        >
                            <Headline Level="h6" weight="normal" color="light">
                                {label}
                            </Headline>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="mt-4">
                {data.map((item, i) => (
                    <tr
                        key={i}
                        className={classNames(
                            i == 0 && 'border-t-2',
                            'border-t',
                        )}
                    >
                        {columns.map(
                            (
                                { key, render = defaultColumnRender, props },
                                index,
                            ) => (
                                <td
                                    key={[i, key, index].join()}
                                    className={classNames(
                                        'py-1',
                                        props?.className,
                                    )}
                                >
                                    <Paragraph color="dark">
                                        <Highlight
                                            textToHighlight={
                                                item[key]
                                                    ? render(item[key])
                                                    : '-'
                                            }
                                            searchWords={highlight}
                                        />
                                    </Paragraph>
                                </td>
                            ),
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <div className="h-28 w-full flex items-center justify-center bg-neutral-50 pt-2">
            <CustomData headline="" width="w-fit" />
        </div>
    );
};
