import { FC, HTMLAttributes } from 'react';
import { LoadingSpinner } from '_atoms';
import { useApiVersionQuery } from '_queries';
import { MaintenanceMessage } from '_organisms';

export const Maintenance: FC<HTMLAttributes<HTMLDivElement>> = ({
    children,
}) => {
    const { isError, refetch, isLoading } = useApiVersionQuery();

    return isLoading ? (
        <div className="h-full w-full flex items-center justify-center">
            <LoadingSpinner />
        </div>
    ) : isError ? (
        <MaintenanceMessage reload={refetch} />
    ) : (
        <>{children}</>
    );
};
