import classnames from 'classnames';
import { FC, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { Card } from '_atoms';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { VscFoldDown } from 'react-icons/vsc';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { resultsRoutes } from '../../../routes';

type CollapsibleCardWithBarProps = {
    children: ReactNode;
    borderColor: string;
    expanded: boolean;
};

export const CollapsibleCardWithBar: FC<CollapsibleCardWithBarProps> = ({
    borderColor,
    children,
    expanded,
}: CollapsibleCardWithBarProps) => {
    const { t } = useTranslation();
    const isPrint = useRouteMatch(resultsRoutes.printVersion.path);
    const [isOpen, setIsOpen] = useState<boolean>(isPrint ? true : expanded);
    const toggleHeightRef = useRef<HTMLDivElement | null>(null);
    const [containerHeight, setContainerHeight] =
        useState<number | undefined | null>(null);
    const [windowWidth] = useWindowSize();

    useLayoutEffect(() => {
        setContainerHeight(toggleHeightRef.current?.clientHeight);

        const layoutTimeout = window.setTimeout(() => {
            setContainerHeight(toggleHeightRef.current?.clientHeight);
        }, 1000);

        return () => {
            window.clearTimeout(layoutTimeout);
        };
    }, [toggleHeightRef, windowWidth]);

    return (
        <Card statusColor={borderColor} backgroundColor="bg-neutral-50">
            <div className={classnames('flex space-x-2 py-1 justify-center')}>
                <button
                    className="text-neutral-400 cursor-pointer focus:outline-none text-sm"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span
                        className={classnames(
                            'inline-block transform transition duration-500 ease-in-out px-3',
                            isOpen && 'rotate-180',
                        )}
                    >
                        <VscFoldDown size={13} />
                    </span>
                    {isOpen ? t('showLess') : t('showMore')}
                    <span
                        className={classnames(
                            'inline-block transform transition duration-500 ease-in-out px-3',
                            isOpen && 'rotate-180',
                        )}
                    >
                        <VscFoldDown size={13} />
                    </span>
                </button>
            </div>
            <div
                style={{
                    height: `${isOpen ? `${containerHeight}px` : '0px'}`,
                    transition: 'ease 500ms',
                }}
            >
                <div
                    className="py-6 px-8 max-w-flow-text-lg mx-auto hyphens-auto break-words"
                    ref={toggleHeightRef}
                    style={{
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {children}
                </div>
            </div>
        </Card>
    );
};
