import React, { useEffect, useRef, useState } from 'react';
import { LoadingSpinner } from '_atoms';
import type { Paging } from '../../../services/dataService';

interface InfiniteScrollV2Props {
    paging: Paging;
    isLoading: boolean;
    setPageNumber: (pageNumber: number) => void;
}

export const InfiniteScrollV2 = (
    props: InfiniteScrollV2Props,
): React.ReactElement | null => {
    const { paging, isLoading, setPageNumber } = props;
    const [localPage, setLocalPage] = useState<number>(paging.currentPage);

    const loadingRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isLoading) {
            setLocalPage(paging.currentPage);
        }
    }, [isLoading, paging]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px', // Adjust the rootMargin based on your layout
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                if (
                    paging.unknownPageCount &&
                    localPage === paging.currentPage
                ) {
                    setLocalPage(paging.currentPage);
                    setPageNumber(paging.currentPage + 1);
                } else if (paging.currentPage < paging.pagesCount) {
                    setPageNumber(paging.currentPage + 1);
                }
            }
        }, options);

        if (loadingRef.current) {
            observer.observe(loadingRef.current);
        }

        return () => {
            if (loadingRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(loadingRef.current);
            }
        };
    }, [paging, setPageNumber, localPage]);

    // remove loading once we run out of pages
    return paging.currentPage < paging.pagesCount ? (
        <LoadingSpinner ref={loadingRef} />
    ) : null;
};
