import React from 'react';
import { CaseSearchResultDto } from '../../../../hooks/queries/useGlobalSearchQueryResult';
import { SearchResultsRow } from '../SearchResultsRow';

interface ResultsTableProps {
    items: CaseSearchResultDto['items'];
}

export const GlobalSearchResults = (
    props: ResultsTableProps,
): React.ReactElement => {
    return (
        <>
            {props.items.map((item) => {
                const sharedProps = {
                    caseId: item.caseId ?? '',
                    creatorEmail: item.targetAuthorEmail ?? '',
                    createdAt: item.createdAt,
                    showTypeIcon: true,
                };
                if (item.targetId) {
                    const targetFullname = [
                        item.targetFirstname,
                        item.targetMiddlename,
                        item.targetLastname,
                    ]
                        .filter(Boolean)
                        .join(' ');

                    return (
                        <SearchResultsRow
                            {...sharedProps}
                            key={`${item.caseId}-${item.targetId}`}
                            targetId={item.targetId}
                            creatorName={`${item.targetAuthorFirstName} ${item.targetAuthorLastName}`}
                            creatorEmail={item.targetAuthorEmail ?? ''}
                            name={targetFullname}
                            targetStatus={item.targetStatus ?? ''}
                            type="target"
                        />
                    );
                }

                return (
                    <SearchResultsRow
                        {...sharedProps}
                        key={item.caseId}
                        creatorName={`${item.caseAuthorFirstName} ${item.caseAuthorLastName}`}
                        creatorEmail={item.caseAuthorEmail ?? ''}
                        name={item.caseTitle ?? ''}
                        caseType={item.caseOption}
                        type="case"
                    />
                );
            })}
        </>
    );
};
