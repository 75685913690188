// NOTE: Setting first- and lastname is currently commented out as we would need
// to save those values to the database as well as to cognito.
// As it is we could run into inconsistencies between cognito and db.
// This will be dealt with as soon as the process of creating accounts is getting improved.
// It is expected that first- and lastname are being set manually in aws.

import React, {
    FormEvent,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    changePasswordOnFirstSgnin,
    User,
} from '../../services/authenticationService';
import { PasswordValidations } from './PasswordValidations';
import { AuthSteps, NEW_PASSWORD_REQUIRED, ResetExceptions } from './types';
import { usePasswordValidation } from './use-password-validation';

interface PasswordResetProps {
    localUser: User | null;
    setLocalUser: (user: User) => void;
    onError: (code: ResetExceptions) => void;
    onChangeStep: (step: AuthSteps) => void;
    onSuccess: (user: User) => void;
}

export const PasswordResetRequired = ({
    localUser,
    onError,
    onChangeStep,
    onSuccess,
}: PasswordResetProps): React.ReactElement => {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState<string | null>(null);
    const [newPasswordConfirmation, setNewPasswordConfirmation] =
        useState<string | null>(null);

    const newPasswordRef = useRef<HTMLInputElement | null>(null);
    const challengeName = localUser?.challengeName;

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            try {
                if (
                    localUser?.challengeName === NEW_PASSWORD_REQUIRED &&
                    newPassword
                ) {
                    const newUser = await changePasswordOnFirstSgnin(
                        newPassword,
                        localUser,
                    );

                    // setLocalUser(newUser);
                    onSuccess(newUser);
                } else {
                    onChangeStep(AuthSteps.ResetCode);
                }
            } catch (error) {
                onError(error as ResetExceptions);
            }
        },
        [localUser, newPassword, onSuccess, onChangeStep, onError],
    );

    useEffect(() => {
        if (challengeName && challengeName !== NEW_PASSWORD_REQUIRED) {
            onChangeStep(AuthSteps.ResetCode);
        }
    }, [challengeName, onChangeStep]);

    useEffect(() => {
        newPasswordRef.current?.focus();
    }, []);

    const validations = usePasswordValidation({
        password: newPassword,
        confirmedPassword: newPasswordConfirmation,
    });

    return (
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            {/* TODO: Check classes for commonalities and extract them into a variable -> see IND-252 */}
            <div className="rounded-md shadow-sm">
                <div>
                    <div className="relative">
                        <label htmlFor="email-address" className="sr-only">
                            {t('passwordReset.password')}
                        </label>
                        <input
                            name="password"
                            type="password"
                            autoComplete="password"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-neutral-300 placeholder-neutral-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-4 focus:border-primary-4 focus:z-10 sm:text-sm"
                            placeholder={t('passwordReset.newPassword')}
                            onChange={(e) => setNewPassword(e.target.value)}
                            ref={newPasswordRef}
                        />
                        <PasswordValidations validations={validations} />
                    </div>
                    <div>
                        <label htmlFor="email-address" className="sr-only">
                            {t('passwordReset.password')}
                        </label>
                        <input
                            name="passwordConfirm"
                            type="password"
                            autoComplete="password"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-neutral-300 placeholder-neutral-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-4 focus:border-primary-4 focus:z-10 sm:text-sm"
                            placeholder={t('passwordReset.newPasswordRepeat')}
                            onChange={(e) =>
                                setNewPasswordConfirmation(e.target.value)
                            }
                        />
                    </div>
                </div>
            </div>

            <button
                disabled={!validations.isValid}
                type="submit"
                className={classNames(
                    !validations.isValid &&
                        'opacity-30 disabled:bg-primary-4 disabled:cursor-not-allowed',
                    'group relative w-full flex justify-center py-2 px-4 mt-12 border border-transparent text-sm font-medium rounded-md text-font-light bg-primary-4 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-4',
                )}
            >
                {t('passwordReset.newPasswordConfirm')}
            </button>
        </form>
    );
};
