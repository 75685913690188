import { FC, HTMLAttributes } from 'react';
import { Headline, Paragraph, Tag } from '_atoms';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface CustomDataProps extends HTMLAttributes<HTMLDivElement> {
    headline?: string;
    width?: string;
}

export const CustomData: FC<CustomDataProps> = ({
    headline,
    width = 'w-1/5',
    className = '',
    children,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={classNames(
                'pb-3 px-2 inline-block align-top',
                width,
                className,
            )}
            {...props}
        >
            {Boolean(headline?.length) && (
                <Headline
                    className="pb-2 uppercase text-ellipsis overflow-hidden"
                    Level="h6"
                    weight="bold"
                    color="light"
                >
                    {headline}
                </Headline>
            )}
            {children ? (
                <Paragraph
                    weight="bold"
                    color="dark"
                    className="break-normal"
                    size="medium"
                    HtmlTag="div"
                >
                    {children}
                </Paragraph>
            ) : (
                <Tag>{t('noData')}</Tag>
            )}
        </div>
    );
};
