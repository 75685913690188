/*
Next steps:
- trigger relayouting when opening a cluster
- handle clusters when filtering by maxLevelShown
- add maxLevelShown select to clusters
- remove old d3 network graph
*/
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Network } from 'vis-network/peer';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, MultiSelect, Select, SelectOption } from '_atoms';
import { options } from './options';
import { nonProdDataTestId } from '_utils';
import NetworkGraphLegend from '../../../features/dashboard/NetworkGraph/NetworkGraphLegend';
import { buildAdjacencyList, filterNodesByOptions } from './helpers';

export type GraphNode = {
    id: string;
    label: string;
    type: string;
    group?: string;
    lvl: number;
    hidden?: boolean;
    shape?: string;
    size?: number;
    status?: string;
};

export type GraphLink = {
    to: string;
    from: string;
    label: string;
};

type NetworkGraphData = {
    nodes: GraphNode[];
    edges: GraphLink[];
};

type NetworkGraphProps = {
    data: NetworkGraphData;
};

export interface FilterOptions {
    graphLevel: number;
    candidateRole: SelectOption[];
    companyStatus: SelectOption[];
}

export const NetworkGraph: FC<NetworkGraphProps> = ({
    data,
}: NetworkGraphProps) => {
    const { t } = useTranslation();

    const capturedCanvasRef = useRef<HTMLImageElement | null>(null);

    const visJsRef = useRef<HTMLDivElement | null>(null);

    const [isLayouting, setIsLayouting] = useState(true);

    const [layoutingProgress, setLayoutingProgress] =
        useState<number | null>(null);

    const [network, setNetwork] = useState<Network | null>(null);

    const adjacencyList = useMemo(
        () => buildAdjacencyList(data.edges),
        [data.edges],
    );

    const [filterOptions, setFilterOptions] = useState<FilterOptions>({
        graphLevel: 1,
        candidateRole: [],
        companyStatus: [],
    });

    // ProductLevel 0 => 'Target/Zielperson'
    // ProductLevel 1=1A+1B => 'immediate network of Target/direktes Netzwerk der Zielperson` ~ UI selection level 1
    // ProductLevel  0 ~ DataLevel === 0
    // ProductLevel 1A ~ DataLevel === 1
    // ProductLevel 1B ~ DataLevel === 2
    // ProductLevel  2 ~ DataLevel === 3
    // ... and so on
    // max(ProductLevel)  ~ max(DataLevel - 1)
    const availableSelectLevelOptions = useMemo(() => {
        if (data.nodes.length === 0) {
            return [];
        }
        const maxAvailableLevel = Math.max(...data.nodes.map(({ lvl }) => lvl));

        return [...Array(maxAvailableLevel).keys()].map((value) => ({
            id: ++value,
            label: `${value}. ${t('networkGraph.graphLevels')}`,
            value: `${value}`,
        }));
    }, [data.nodes, t]);

    const candidateRoleOptions: SelectOption[] = [
        {
            id: 'company',
            label: t('networkGraph.executiveRole'),
            value: 'company',
        },
        {
            id: 'employeePosition',
            label: t('networkGraph.employeeRole'),
            value: 'employeePosition',
        },
    ];

    const companyStatusOptions: SelectOption[] = [
        {
            id: 'active',
            label: t('networkGraph.companyStatusActive'),
            value: 'active',
        },
        {
            id: 'inactive',
            label: t('networkGraph.companyStatusInactive'),
            value: 'inactive',
        },
        {
            id: 'unknown',
            label: t('networkGraph.companyStatusUnknown'),
            value: 'unknown',
        },
    ];

    const handleFilterChange = (
        filterName: keyof typeof filterOptions,
        selectedOptions: number | SelectOption[],
    ) => {
        setFilterOptions((prevState) => ({
            ...prevState,
            [filterName]: selectedOptions,
        }));
    };

    const captureCanvas = useCallback(() => {
        if (
            visJsRef.current &&
            capturedCanvasRef.current &&
            capturedCanvasRef.current.src.length < 5
        ) {
            const canvas = visJsRef.current.querySelector('canvas');
            if (canvas) {
                const data = canvas.toDataURL('image/png');
                capturedCanvasRef.current.src = `${data}`;
            }
        }
    }, []);

    useEffect(() => {
        const updatedNodes = filterNodesByOptions(
            data.nodes,
            filterOptions,
            adjacencyList,
        );
        // NOTE: Something's wrong with the vis js type definitions.
        // Creating a dataset from our GraphLink[] type should work according to the documentation
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        network?.setData({
            nodes: updatedNodes,
            edges: data.edges,
        });
        network?.stabilize(100);

        // TODO: Hide clusters based on maxShownLevel
        // We lose all node positions after opening and closing a cluster
        // The clusters root node isn't on the canvas anymore and needs to be added if maxSownLevel === cluster.level
        // The
        // Object.entries(clusterRootNodes).map(([clusterId, clusterRootNode]) => {
        //   if (network?.isCluster(clusterId)) network?.openCluster(clusterId);
        //   if (clusterRootNode.level === maxShownLevel) nodes.add(clusterRootNode);
        // });
        // clusterNodesByHubsize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.nodes, data.edges, filterOptions]);

    useEffect(() => {
        if (visJsRef.current) {
            // NOTE: Something's wrong with the vis js types.
            // Creating to network with dataset types should work according to the documentation
            // but the type definitions don't allow for it
            const visNetwork = new Network(
                visJsRef.current,
                {
                    nodes: filterNodesByOptions(
                        data.nodes,
                        filterOptions,
                        adjacencyList,
                    ),
                    edges: data.edges,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                },
                options,
            );

            visNetwork.setSize('100%', '100%');
            visNetwork.stabilize(100);

            visNetwork.on('startStabilizing', () => {
                setLayoutingProgress(0);
                setIsLayouting(true);
            });
            visNetwork.on('stabilizationProgress', (e) => {
                setLayoutingProgress(
                    Math.floor((e.iterations / e.total) * 100),
                );
            });
            visNetwork.on('stabilizationIterationsDone', () => {
                // Intentional timeout to ensure user sees the progress indicator and avoid flashing
                setTimeout(() => {
                    setLayoutingProgress(null);
                    setIsLayouting(false);
                    captureCanvas();
                }, 400);
            });

            visNetwork.on('hoverNode', function (params) {
                // NOTE: Something's wrong with the vis js types. Canvas is actually accessible in visNetwork
                if (visNetwork.isCluster(params.node)) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (visNetwork as any).canvas.body.container.style.cursor =
                        'pointer';
                }
            });

            visNetwork.on('blurNode', function () {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (visNetwork as any).canvas.body.container.style.cursor =
                    'default';
            });

            setNetwork(visNetwork);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.nodes, data.edges, visJsRef, filterOptions, adjacencyList]);

    return (
        <>
            <div
                // spacer
                style={{ height: 'calc(100vh - 13.75rem)' }}
            />
            <div className="flex justify-end items-end space-x-4 absolute right-9 top-4 print:hidden">
                <NetworkGraphLegend />
                {/* only show level select outside clusters because filter functionality inside clusters doesn't exist yet
        {openClusters.length === 1 && ( */}
                <Select
                    label={t('networkGraph.graphDepth')}
                    initialSelection={{
                        id: 1,
                        label: `${filterOptions.graphLevel}. ${t(
                            'networkGraph.graphLevels',
                        )}`,
                        value: `${filterOptions.graphLevel}`,
                    }}
                    options={availableSelectLevelOptions}
                    onChange={(value) => {
                        handleFilterChange('graphLevel', value);
                    }}
                    dataTestId="open levels"
                />
                {/* )} */}
                <MultiSelect
                    className="h-min"
                    label={t('networkGraph.candidateRole')}
                    options={candidateRoleOptions}
                    onChange={(value) => {
                        handleFilterChange('candidateRole', value);
                    }}
                    selected={filterOptions.candidateRole}
                />
                <MultiSelect
                    className="h-min"
                    label={t('networkGraph.companyStatus')}
                    options={companyStatusOptions}
                    onChange={(value) => {
                        handleFilterChange('companyStatus', value);
                    }}
                    selected={filterOptions.companyStatus}
                />
            </div>
            <div
                className="absolute w-max-content overflow-always-hidden left-9 bottom-9 rounded-md"
                style={{
                    // 13.75 is the height of the top nav bar + the header + spacing
                    height: 'calc(100vh - 13.75rem)',
                    // 4.5rem is px-9
                    width: 'calc(100% - 4.5rem)',
                }}
            >
                <div
                    ref={visJsRef}
                    className="h-full bg-neutral-100 print:hidden"
                    data-testid={nonProdDataTestId('network graph')}
                ></div>
                {isLayouting && (
                    <div className="z-8 inset-0 absolute bg-neutral-200/70 flex justify-center items-center text-neutral-400 pointer-events-none print:hidden">
                        <LoadingSpinner
                            message={
                                layoutingProgress ? (
                                    `${layoutingProgress} %`
                                ) : (
                                    <span className="invisible">...</span>
                                )
                            }
                        />
                    </div>
                )}
                <img
                    className="hidden print:block"
                    ref={capturedCanvasRef}
                ></img>
            </div>
        </>
    );
};
