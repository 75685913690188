import { useCallback } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { AllDataArticle } from 'src/services/searchService';
import { ActionButton } from '_atoms';
import { AddToReport, UpdateItemsStatus } from '_organisms';
import { SuspicionItemEntity } from '_types';
import { SuspicionItemStatus } from '_enums';
import { useAddSuspicionMutation } from './queries/useAddSuspicionMutation';
import { SuspicionStatusIcon } from '../features/dashboard/Overview/SuspicionStatusIcon';
import { useUpdateSuspicionItemsMutation } from './queries/useUpdateSuspicionItemMutation';
import { SuspicionItemSource } from '../types/SuspicionItem';

type ReportTailoringActionButton = 'AddToReport' | 'UpdateItemsStatus';

type ReportTailoringActions = Record<
    ReportTailoringActionButton,
    (...args: any[]) => JSX.Element
>;

type ReportTailoringProps = {
    targetId: string;
};

type ReportTailoringExport = {
    ReportTailoringActions: ReportTailoringActions;
};

export const useReportTailoring = ({
    targetId,
}: ReportTailoringProps): ReportTailoringExport => {
    const addSuspicionMutation = useAddSuspicionMutation();
    const updateSuspicionItemsMutation = useUpdateSuspicionItemsMutation();

    const handleAddToReport = useCallback(
        async (
            reportId: string,
            suspicionIds: string[],
            entity: SuspicionItemEntity,
        ): Promise<unknown> => {
            return addSuspicionMutation.mutateAsync({
                targetId,
                suspicionIds,
                reportId,
                entity,
                source: SuspicionItemSource.User,
            });
        },
        [targetId, addSuspicionMutation],
    );

    const getAddToReportActionButton = useCallback(
        (entity: AllDataArticle): JSX.Element => (
            <ActionButton
                icon={
                    <div className="bg-neutral-100 rounded-lg px-2 py-0">
                        <HiDotsHorizontal />
                    </div>
                }
            >
                <AddToReport
                    entityId={entity.id}
                    entity={entity}
                    onAddToReport={handleAddToReport}
                />
            </ActionButton>
        ),
        [handleAddToReport],
    );

    const handleStatusesUpdate = useCallback(
        async (
            itemIds: string[],
            status: SuspicionItemStatus,
        ): Promise<unknown> => {
            return updateSuspicionItemsMutation.mutate({
                itemIds,
                status,
            });
        },
        [updateSuspicionItemsMutation],
    );

    const getUpdateItemsStatusActionButton = useCallback(
        (
            itemIds: string[],
            status: SuspicionItemStatus,
            differentStatuses: boolean,
            size,
            padding,
        ): JSX.Element => (
            <ActionButton
                icon={
                    <SuspicionStatusIcon
                        status={status}
                        size={size}
                        padding={padding}
                    />
                }
            >
                <UpdateItemsStatus
                    itemIds={itemIds}
                    status={status}
                    differentStatuses={differentStatuses}
                    onStatusUpdate={handleStatusesUpdate}
                    size={size}
                    padding={padding}
                />
            </ActionButton>
        ),
        [handleStatusesUpdate],
    );

    const ReportTailoringActions: ReportTailoringActions = {
        AddToReport: getAddToReportActionButton,
        UpdateItemsStatus: getUpdateItemsStatusActionButton,
    };

    return {
        ReportTailoringActions,
    };
};
