import { useQuery, UseQueryResult } from 'react-query';
import { CaseData } from '../../services/dataService';
import { cases } from '../../services/nestApiService';
import { Paginated } from '../../types/NestAPI';

export interface CasesQueryParams {
    page?: string;
    pageSize?: number;
    query?: string;
    creatorId?: string;
}

export const useCasesQuery = (
    queryParams?: CasesQueryParams,
): UseQueryResult<Paginated<CaseData>> =>
    useQuery(['cases', queryParams], async () => cases.getAll(queryParams), {
        refetchOnWindowFocus: false,
    });
