import React from 'react';
import { ReactComponent as CompletedWithIrregularitiesIcon } from '_assets/icons/target-status/completed-with-irregularities.svg';
import { ReactComponent as CompletedIcon } from '_assets/icons/target-status/completed.svg';
import { ReactComponent as FetchCandidatesIcon } from '_assets/icons/target-status/fetch-candidates.svg';
import { ReactComponent as CreatedIcon } from '_assets/icons/target-status/created.svg';
import { ReactComponent as FetchingDetailsIcon } from '_assets/icons/target-status/fetching-details.svg';
import { ReactComponent as HasInitialProfileIcon } from '_assets/icons/target-status/has-initial-profile.svg';
import { ReactComponent as SelectionCompletedIcon } from '_assets/icons/target-status/selection-completed.svg';
import { ReactComponent as SelectionPendingIcon } from '_assets/icons/target-status/selection-pending.svg';
import { ReactComponent as InsightCompleteIcon } from '_assets/icons/case-type/insight-complete.svg';
import { ReactComponent as InsightPlusIcon } from '_assets/icons/case-type/insight-plus.svg';
import { ReactComponent as InsightEssentialIcon } from '_assets/icons/case-type/insight-essential.svg';
import { TargetStatus } from '../../../services/dataService';
import { CaseType } from '../../cases/CaseNew';
import { SearchResultsRowProps } from './SearchResultsRow';

type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export type StatusIconData = { icon: IconType; color: string };

export const propsToStatusIcon = (
    props: SearchResultsRowProps,
): StatusIconData | null => {
    if (props.type === 'case') {
        return caseTypeToIcon(props.caseType);
    }
    return targetStatusToIcon(props.targetStatus);
};

const targetStatusToIcon = (
    status: TargetStatus | string,
): StatusIconData | null => {
    switch (status) {
        case TargetStatus.Created:
            return { icon: CreatedIcon, color: 'text-current' };
        case TargetStatus.CandidateSelectionCompleted:
            return { icon: SelectionCompletedIcon, color: 'text-current' };
        case TargetStatus.FetchingDetails:
            return { icon: FetchingDetailsIcon, color: 'text-current' };
        case TargetStatus.CandidateSelectionPending:
            return { icon: SelectionPendingIcon, color: 'text-warning-1' };
        case TargetStatus.HasInitialProfile:
            return { icon: HasInitialProfileIcon, color: 'text-current' };
        case TargetStatus.Completed:
            return { icon: CompletedIcon, color: 'text-success-1' };
        case TargetStatus.CompletedWithIrregularities:
            return {
                icon: CompletedWithIrregularitiesIcon,
                color: 'text-error-1',
            };
        case TargetStatus.FetchingCandidates:
            return { icon: FetchCandidatesIcon, color: 'text-current' };
        default:
            return null;
    }
};
const caseTypeToIcon = (
    caseType?: string | CaseType | null,
): StatusIconData | null => {
    switch (caseType) {
        case CaseType.Essential:
            return { icon: InsightEssentialIcon, color: 'text-contrast-1' };
        case CaseType.Plus:
            return { icon: InsightPlusIcon, color: 'text-contrast-2' };
        case CaseType.Complete:
            return { icon: InsightCompleteIcon, color: 'text-contrast-3' };
        default:
            return null;
    }
};
