import { BaseTarget } from '@indicium/common/src/types/Target/TargetTypes';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { ProfileData, SimpleEntry } from '../../services/dataService';
import { TargetReportSourceType, UserInputTarget } from '@indicium/common';

export type ProfileCompany = {
    name: SimpleEntry;
    role: {
        value: {
            from: string;
            isRegisterRelevant: boolean;
            role: string;
            to?: string | null | undefined;
        };
        sources: TargetReportSourceType[];
    }[];
    address: any[];
    managers: any[];
    shareholders: any[];
    country: any[];
};

export type Profile = {
    targetPerson: BaseTarget & ProfileData;
    companies: ProfileCompany[];
};

export type Summary = {
    en: string;
    de: string;
};

export type TargetResults = {
    profile: Profile | null;
    ignoredProfile: Profile | null;
    summarization: Summary | null;
    networkUrl: string;
    rawUserInput: UserInputTarget;
};

const fetchTargetResults = async (
    caseId: string,
    targetId: string,
): Promise<TargetResults> => {
    const { data, ignored_data, summarization, network_url, raw_user_input } = (
        await axios.get(
            `https://${backendBaseUrl}/v2/cases/${caseId}/targets/${targetId}`,
        )
    ).data;

    return {
        profile: data,
        ignoredProfile: ignored_data,
        summarization,
        networkUrl: network_url,
        rawUserInput: raw_user_input,
    };
};

export const useTargetResultsQuery = (
    caseId: string,
    targetId: string,
    onSuccess?: () => void,
): UseQueryResult<TargetResults> =>
    useQuery(
        ['target', caseId, targetId, 'results'],
        () => fetchTargetResults(caseId, targetId),
        { staleTime: 10 * 60 * 1000, onSuccess }, // 10 minutes
    );
