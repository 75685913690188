import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { UserData } from 'src/types/UserData';

export const useDeleteUserMutation = (): UseMutationResult<
    UserData,
    unknown,
    Record<string, string>
> => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ userId }) =>
            axios.delete(`https://${backendBaseUrl}/users/${userId}`),
        {
            onSuccess: () =>
                ['users', 'customerUsage'].forEach((key) =>
                    queryClient.invalidateQueries(key),
                ),
        },
    );
};
