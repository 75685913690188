import { useQuery, UseQueryResult } from 'react-query';
import { targets } from '../../services/nestApiService';
import { SuspicionsByCategory } from '_types';
import { SuspicionFilters } from '../../features/dashboard/RiskAnalysisV2/useFilters';

export const useSuspicionsByCategory = (
    targetId: string,
    filters: SuspicionFilters,
    shouldRefetch?: boolean,
): UseQueryResult<SuspicionsByCategory> =>
    useQuery(
        [
            'target',
            targetId,
            filters.contentTypes?.sort().join(','),
            filters.reviewStatuses?.sort().join(','),
            filters.sources?.sort().join(','),
            filters.suspicionIds?.sort().join(','),
            'suspicions-by-category',
        ],
        () => targets.getSuspicionsByCategory(targetId, filters),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            refetchInterval: shouldRefetch ? 60000 * 3 : undefined, // refetch every three minutes
        },
    );
