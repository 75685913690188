import {
    FC,
    FormEvent,
    Fragment,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { SuspicionItemEntity } from '_types';
import {
    DynamicSVG,
    LoadingSpinner,
    MultiSelect,
    Select,
    SelectOption,
} from '_atoms';
import { useTranslation } from 'react-i18next';
import { useReportsQuery } from '../../../../hooks/queries/useReportsQuery';
import { useSuspicionsQuery } from '_queries';
import { useSuspicionItemEntityQuery } from '../../../../hooks/queries/useSuspicionItemEntityQuery';
import { useParams } from 'react-router-dom';

export type AddToReportProps = {
    entityId: string;
    entity: any;
    onAddToReport: (
        reportId: string,
        suspicionIds: string[],
        entity: SuspicionItemEntity,
    ) => Promise<unknown>;
};

export const AddToReport: FC<AddToReportProps> = ({
    entityId,
    entity,
    onAddToReport,
}) => {
    const { t } = useTranslation();

    const [selectedReportOption, setSelectedReportOption] =
        useState<SelectOption | undefined>();
    const [selectedSuspicionOptions, setSelectedSuspicionOptions] = useState<
        SelectOption[]
    >([]);

    const { targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { data: suspicionItemEntity, isFetching: isFetchingEntity } =
        useSuspicionItemEntityQuery(entityId, targetId);
    const { data: reports } = useReportsQuery();
    const { data: suspicions } = useSuspicionsQuery(
        selectedReportOption?.value,
    );

    useEffect(() => {
        if (selectedReportOption?.id) {
            setSelectedSuspicionOptions(
                suspicions
                    ? suspicions
                          .map(({ id, name: label }) => ({
                              id,
                              label,
                              value: id,
                          }))
                          .filter((suspicion) =>
                              suspicionItemEntity?.suspicions?.find(
                                  (es) => es.id === suspicion.id,
                              ),
                          )
                    : [],
            );
        }
    }, [suspicions, suspicionItemEntity, selectedReportOption]);

    const handleReportChange = useCallback(
        (value?: string) => {
            const report = reports?.find((r) => r.id === value);
            const option: SelectOption = {
                id: report?.id || 0,
                value: report?.label,
                label: report?.name || '-',
            };
            setSelectedReportOption(option);
            setSelectedSuspicionOptions([]);
        },
        [reports],
    );

    const handleSuspicionChange = (selected: SelectOption[]) => {
        setSelectedSuspicionOptions(selected);
    };

    const handleFormSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            if (!selectedReportOption?.id) {
                return;
            }
            const _entity = { id: entityId, type: entity.type, value: entity };
            onAddToReport(
                selectedReportOption.id as string,
                selectedSuspicionOptions.map((sso) => `${sso.id}`),
                _entity,
            ).then(() => {
                setSelectedReportOption(undefined);
                setSelectedSuspicionOptions([]);
            });
        },
        [
            entity,
            entityId,
            onAddToReport,
            selectedSuspicionOptions,
            selectedReportOption,
        ],
    );

    return isFetchingEntity ? (
        <LoadingSpinner />
    ) : (
        <Fragment>
            {suspicionItemEntity &&
            suspicionItemEntity.suspicions.length > 0 ? (
                <Fragment>
                    <div className="text-sm font-medium text-neutral-500 mb-1">
                        {t('linkedSuspicions')}
                    </div>
                    <div className="grid grid-cols-6 gap-2 mb-3">
                        {suspicionItemEntity.suspicions.map(
                            ({ id, label, name }) => (
                                <div
                                    key={id}
                                    className="flex justify-center items-center w-10 h-10 rounded-full bg-warning-1 p-3 text-yellow-700"
                                    title={name}
                                >
                                    <DynamicSVG
                                        src={`icons/suspicions/${label}`}
                                        width={16}
                                    />
                                </div>
                            ),
                        )}
                    </div>
                </Fragment>
            ) : null}
            <form className="flex flex-col gap-3" onSubmit={handleFormSubmit}>
                <Select
                    label={t('changeSuspicion')}
                    addEmptyOption={true}
                    options={
                        reports
                            ? reports.map(({ id, label, name }) => ({
                                  id,
                                  label: t(`reports.sections.${label}`, {
                                      defaultValue: name,
                                  }),
                                  value: id,
                              }))
                            : []
                    }
                    onChange={handleReportChange}
                    initialSelection={selectedReportOption}
                />
                <MultiSelect
                    className="h-min"
                    label={t('selectSuspicion')}
                    disabled={!selectedReportOption?.value}
                    options={
                        suspicions
                            ? suspicions
                                  .map(({ id, label, name }) => ({
                                      id,
                                      label: t(`suspicionKeywords.${label}`, {
                                          defaultValue: name,
                                      }),
                                      value: id,
                                  }))
                                  .sort((a, b) =>
                                      a.label.localeCompare(b.label),
                                  )
                            : []
                    }
                    onChange={handleSuspicionChange}
                    selected={selectedSuspicionOptions}
                    floatingOptions={false}
                />
                <button
                    className="text-font-light bg-primary-4 hover:bg-primary-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-4 disabled:bg-neutral-300 disabled:cursor-default rounded-md py-3"
                    disabled={!selectedReportOption?.value}
                >
                    {t('changeSuspicionButton')}
                </button>
            </form>
        </Fragment>
    );
};
