import React from 'react';
import { ArticlePreviewCard } from './components/ArticlePreviewCard';
import { PressArticle } from '../../../../../services/searchService';

import { SharedArticlePreviewProps } from './types';

interface PressProps extends SharedArticlePreviewProps {
    article: PressArticle;
}

export const Press = (props: PressProps): React.ReactElement => {
    const {
        onClick,
        onSuspicionClick,
        isActive,
        article,
        indicators = [],
        suspicionsCount,
    } = props;

    return (
        <ArticlePreviewCard
            onClick={onClick}
            isActive={isActive}
            title={article.title}
            type="press"
            suspicionsCount={suspicionsCount}
            onSuspicionClick={onSuspicionClick}
        >
            <div className="text-sm line-clamp-3">
                {indicators.map(({ indicator }) => indicator.value).join('\n')}
            </div>
        </ArticlePreviewCard>
    );
};
