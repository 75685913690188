import { SuspicionArticle } from '_types';
import React, { FC, ReactElement } from 'react';
import { Press } from './Press';
import { SuspicionItemEntityType } from '_enums';
import { SearchEngine } from './SearchEngine';
import { Company } from './Company';
import { SocialMediaPost } from './SocialMediaPost';
import { SharedArticlePreviewProps } from './types';

interface ArticlePreviewProps {
    isActive: boolean;
    onClick: () => void;
    article: SuspicionArticle;
    onSuspicionClick?: () => void;
    isSuspicionSidebarCollapsed?: boolean;
}

type ComponentMap = {
    [key in SuspicionItemEntityType]: FC<
        SharedArticlePreviewProps & { article: any }
    > | null;
};

const componentMap: ComponentMap = {
    [SuspicionItemEntityType.Press]: Press,
    [SuspicionItemEntityType.GoogleResult]: SearchEngine,
    [SuspicionItemEntityType.TargetCompany]: Company,
    [SuspicionItemEntityType.SocialMediaPost]: SocialMediaPost,
    [SuspicionItemEntityType.SocialMediaConnection]: null,
    [SuspicionItemEntityType.Image]: null,
};

export const ArticlePreview: FC<ArticlePreviewProps> = ({
    article,
    isActive,
    onClick,
    onSuspicionClick,
    isSuspicionSidebarCollapsed,
}): ReactElement | null => {
    const suspicionCount = article.entity.suspicionItems?.length ?? 0;

    const Component = componentMap[article.entity.type];

    if (!Component) {
        return null;
    }

    return (
        <Component
            article={article.entity.value}
            indicators={article.indicators}
            suspicionsCount={suspicionCount}
            isActive={isActive}
            onClick={onClick}
            onSuspicionClick={
                suspicionCount && isSuspicionSidebarCollapsed
                    ? onSuspicionClick
                    : undefined
            }
        />
    );
};
