import { Component, Dispatch } from 'react';
import {
    SearchState,
    SearchAction,
    SearchActionTypes,
} from '../../../hooks/useSearch';
import { SearchFilterValue } from '_types';
import { clone } from 'lodash';

class Filter<P, S> extends Component<P, S> {
    protected type: string;
    protected item: any;
    protected searchState: SearchState<any>;
    protected dispatcher: Dispatch<SearchAction<any>>;

    constructor(props: any) {
        super(props);
        this.type = props.type;
        this.item = props.item;
        this.searchState = props.searchState;
        this.dispatcher = props.dispatcher;
    }

    shouldComponentUpdate(nextProps: any) {
        return nextProps.searchState.filters !== this.searchState.filters;
    }

    handleFilterChange(field: string, valueJson: any) {
        const filters = this.mergeFilters(
            this.searchState.filters,
            field,
            valueJson,
        );

        this.dispatcher({
            type: SearchActionTypes.SetFilter,
            filters,
        });
    }

    protected mergeFilters(
        filters: any,
        field: string,
        valueJson: SearchFilterValue,
    ) {
        const clonedFilters = filters ? clone(filters) : {};
        return {
            ...clonedFilters,
            [field]: valueJson.value ? valueJson : undefined,
        };
    }

    protected getFilterValueJson(
        value?: any,
        type = 'query',
        query = 'terms',
    ): SearchFilterValue {
        return {
            type,
            query,
            value,
        };
    }
}

export default Filter;
