import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

export const Stats: React.FC<{
    end: number;
    duration: number;
    countText: string;
    text: string;
    decimals?: number;
}> = ({ end, duration, text, countText, decimals }) => {
    const { t } = useTranslation();
    return (
        <div className="bg-white p-4 rounded border-neutral-200 flex-shrink-0 basis-1/5 flex-grow flex flex-col items-center justify-center">
            <p className="text-sm text-neutral-500">{t('stats.over')}</p>
            <h2 className="font-bold text-2xl text-primary-4">
                <CountUp
                    end={end}
                    duration={duration}
                    delay={0.5}
                    decimals={decimals}
                    className="font-jost"
                />
                {countText}
            </h2>
            <p className="font-medium text-font-dark">{text}</p>
        </div>
    );
};
