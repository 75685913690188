import { useQuery, UseQueryResult } from 'react-query';
import { Customer } from '@indicium/common';
import { customers } from '../../services/nestApiService';
import { ErrorResponse } from 'src/types/NestAPI';

export const useCustomerQuery = (
    customerId: string,
    options: Record<string, any> = {},
): UseQueryResult<Customer, ErrorResponse> =>
    useQuery(
        ['customers', customerId],
        () => customers.findOne(customerId).then((r) => r.data),
        {
            enabled: !!customerId,
            ...options,
        },
    );
