import { FC } from 'react';
import { Paragraph, SourceInfo } from '_atoms';
import { SocialMediaImage } from '_molecules';
import { TargetReportSourceType } from '@indicium/common';
import { SocialIcon } from 'react-social-icons';

export type SocialMediaAccountProps = {
    imageUrl?: string;
    name: string;
    site?: string;
    profileUrl?: string;
    sources: TargetReportSourceType[];
};

export const SocialMediaAccount: FC<SocialMediaAccountProps> = ({
    imageUrl,
    name,
    profileUrl,
    sources,
}) => {
    return (
        <div className="flex py-4 items-start">
            <div className="relative">
                <SocialMediaImage src={imageUrl} />
                {profileUrl && (
                    <SocialIcon
                        as="div"
                        url={profileUrl}
                        fgColor="white"
                        style={{
                            position: 'absolute',
                            width: '1.5rem',
                            height: '1.5rem',
                            bottom: 0,
                            right: '1rem',
                            backgroundColor: 'white',
                            borderRadius: '100%',
                        }}
                    />
                )}
            </div>
            <div className="overflow-auto flex-1">
                <Paragraph weight="bold" color="dark" className="break-normal">
                    {name}
                </Paragraph>

                {profileUrl && (
                    <a href={profileUrl} target="_blank" rel="noreferrer">
                        <Paragraph
                            weight="bold"
                            color="dark"
                            className="break-words"
                        >
                            {profileUrl}
                        </Paragraph>
                    </a>
                )}

                <SourceInfo sources={sources} />
            </div>
        </div>
    );
};
