import { Schemas } from '@indicium/common/src/utils';
import { Form, Formik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useParams } from 'react-router-dom';
import { Button, Card, LoadingSpinner, StyledField } from '_atoms';
import { useCaseQuery, useUpdateCaseMutation } from '_queries';
import { routes } from '../../../../routes';
import * as Yup from 'yup';
import { DefaultPageLayout } from '../../../../components/Layout/DefaultPageLayout';

export const EditCase: FC = () => {
    const { caseId } = useParams<{ caseId: string }>();
    const { t } = useTranslation();

    const {
        data: caseData,
        isError: isCaseError,
        error: caseError,
    } = useCaseQuery(caseId);

    const validationSchema = Yup.object().shape({
        title: Schemas.requiredString().test({
            name: 'caseTitleChanged',
            message: 'editCase.titleHasNotChanged',
            test: (value) => {
                return value !== caseData?.title;
            },
        }),
    });

    if (isCaseError) {
        console.error(`Could not fetch case: ${caseError}`);
    }

    const { mutate, error, isError, isLoading, isSuccess } =
        useUpdateCaseMutation();

    const handleSubmit = async ({ title }: { title: string }) => {
        mutate({ caseId, title });
    };

    if (isSuccess) {
        return <Redirect to={routes.casesList.path} />;
    }

    if (isError) {
        console.error(`Something went wrong, could not edit Case: ${error}`);
    }

    const title = caseData?.title ?? '';

    if (!title || isLoading) {
        return (
            <div className="mt-20">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <DefaultPageLayout title={`${t('editCaseAction')}: ${title}`}>
            <Formik
                initialValues={{ title }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnMount
                enableReinitialize
            >
                {({ isValid }) => (
                    <Form>
                        <Card className="mb-6 rounded-2xl p-9">
                            {/*<div className="flex justify-between mb-10">*/}
                            {/*    <Headline Level="h4" color="dark" weight="bold">*/}
                            {/*        {t('editCaseFormHeadline')}*/}
                            {/*    </Headline>*/}
                            {/*</div>*/}

                            <div className="max-w-150 mx-auto">
                                <StyledField
                                    type="text"
                                    name="title"
                                    label={t('title')}
                                />
                            </div>
                        </Card>

                        <div className="flex justify-end space-x-6">
                            <Link to={routes.casesList.path}>
                                <Button level="primaryGhost" type="button">
                                    {t('back')}
                                </Button>
                            </Link>
                            <Button
                                disabled={isLoading || !isValid}
                                level="primary"
                                type="submit"
                            >
                                {t('continue')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </DefaultPageLayout>
    );
};
