import { RedFlagItem } from '_types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RedFlagCompanyRole } from './RedFlagCompanyRole';

interface RedFlagCompanyRolesProps {
    roles: RedFlagItem['roles'];
}

export const RedFlagCompanyRoles = (
    props: RedFlagCompanyRolesProps,
): React.ReactElement => {
    const { roles } = props;
    const { t } = useTranslation();

    if (!roles?.length) {
        return <>{t('redFlags.roleNotAvailable')}</>;
    }

    return (
        <ul className="list-disc pl-4">
            {roles.map((role, index) => (
                <li key={index}>
                    <RedFlagCompanyRole role={role} />
                </li>
            ))}
        </ul>
    );
};
