import { ProcessTarget } from '@indicium/common';
import { isValid, parse } from 'date-fns';
import { scoreCompany } from './scoreCompany';

type DateRange = Record<'start' | 'end', Date>;

export function parseDateWithMultipleFormats(
    dateString: string,
    formats: string[],
    referenceDate: Date,
): Date {
    for (const format of formats) {
        const tempDate = parse(dateString, format, referenceDate);
        if (isValid(tempDate)) {
            return tempDate;
        }
    }

    return referenceDate;
}

const DATE_FORMATS = ['dd.MM.yyyy', 'yyyy-MM-dd'];

export function toDate(dateString: string, dateFormats = DATE_FORMATS): Date {
    return parseDateWithMultipleFormats(dateString, dateFormats, new Date());
}

export function datesTimesAreEqual(date1: Date, date2: Date): boolean {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return date1.getTime() === date2.getTime();
}

export function normalizeCaseless(text: string | undefined): string {
    return text ? text.normalize('NFKD').toLowerCase().trim() : '';
}

export function normalizeDedupStrings(strings: string[]): string[] {
    // Placeholder for the actual implementation
    // This function should normalize and remove duplicates from the array of strings.
    return Array.from(new Set(strings.map((s) => normalizeCaseless(s))));
}

export function dateRangesOverlap(
    innerRange: DateRange,
    outterRange: DateRange,
): boolean {
    // Placeholder implementation: Check if the two date ranges overlap
    return (
        innerRange.start <= outterRange.end &&
        outterRange.start <= innerRange.end
    );
}

export function toNameVariations(userInput: ProcessTarget): string[] {
    const nameVariations: string[] = [];

    const { firstname, middlename, lastname, title } = userInput;

    // Generate basic name variation
    nameVariations.push(`${firstname} ${lastname}`.trim());

    // Add variations with middle name
    if (middlename) {
        nameVariations.push(`${firstname} ${middlename} ${lastname}`.trim());
    }

    // Add variations with title
    if (title) {
        nameVariations.push(`${title} ${firstname} ${lastname}`.trim());
    }

    // Add variations with both title and middle name
    if (title && middlename) {
        nameVariations.push(
            `${title} ${firstname} ${middlename} ${lastname}`.trim(),
        );
    }

    return nameVariations;
}

export const findCompanyMatches = (
    referenceCompanies: string[],
    candidateCompanies: string[],
): number => {
    if (!referenceCompanies.length || !candidateCompanies.length) {
        return 0;
    }

    const deduplicatedReferenceCompanies = new Set<string>(
        referenceCompanies.filter((company) => company),
    );

    const deduplicatedCandidateCompanies = new Set<string>(
        candidateCompanies.filter((company) => company),
    );

    let totalScore = 0;

    deduplicatedReferenceCompanies.forEach((referenceCompany) => {
        deduplicatedCandidateCompanies.forEach((candidateCompany) => {
            totalScore += scoreCompany(referenceCompany, candidateCompany);
        });
    });

    return totalScore;
};

function normalizeScoreBetweenOneAndZero(
    score: number,
    minScore: number,
    maxScore: number,
): number {
    if (score > maxScore) {
        return 1;
    }

    if (score < minScore) {
        return 0;
    }

    return (score - minScore) / (maxScore - minScore);
}

export function normalizeExactMatchScore(score: number): number {
    return normalizeScoreBetweenOneAndZero(score, 0, 120);
}

export function normalizeGoogleExactMatchScore(score: number): number {
    return normalizeScoreBetweenOneAndZero(score, 0, 20);
}

export function parseUrl(url: string): string {
    try {
        const baseUrl = url.split(/[&?]/)[0];
        return new URL(baseUrl).pathname;
    } catch (error) {
        return url;
    }
}
