import React from 'react';
import { ArticlePreviewCard } from './components/ArticlePreviewCard';
import { RegisterDataArticle } from '../../../../../services/searchService';
import { Badge } from '_atoms';
import { useTranslation } from 'react-i18next';
import { RoleType } from '../../../../dashboard/Registerdaten/RoleType';

import { SharedArticlePreviewProps } from './types';

interface CompanyProps extends SharedArticlePreviewProps {
    article: RegisterDataArticle;
}

export const Company = (props: CompanyProps): React.ReactElement => {
    const { onClick, onSuspicionClick, isActive, article, suspicionsCount } =
        props;

    const { t } = useTranslation();

    const hasManagerRole = article.companyTargetRoles.some(
        (role) => role.roleType === RoleType.Manager,
    );

    return (
        <ArticlePreviewCard
            onClick={onClick}
            isActive={isActive}
            title={article.companyName}
            subtitle={article.companyNationalLegalForm?.[0]}
            type="targetcompany"
            country={article.companyAddresses?.[0]?.country}
            suspicionsCount={suspicionsCount}
            onSuspicionClick={onSuspicionClick}
        >
            <div className="text-right">
                <Badge
                    background="natural"
                    size="default"
                    paddingClassName="pl-2.5 pr-2 py-1"
                >
                    {t(
                        `riskAnalysis.employmentRole.${
                            hasManagerRole ? 'manager' : 'employee'
                        }`,
                    )}
                </Badge>
            </div>
        </ArticlePreviewCard>
    );
};
