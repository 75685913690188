import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '_atoms';

interface SearchResultsTooltipContentProps {
    name: string;
    creatorName: string;
    creatorEmail: string;
    type: 'case' | 'target';
    status?: string | null;
    typeIcon?: React.ReactNode;
    statusIcon: React.ReactNode;
}

const Label: FC = ({ children }) => (
    <Paragraph size="label" weight="bold" color="white" className="mb-1">
        {children}
    </Paragraph>
);

const IconWithLabel: FC<{ icon: React.ReactNode }> = ({ children, icon }) => (
    <div className="flex items-center">
        <div className="mr-1.5">{icon}</div>
        <span>{children}</span>
    </div>
);

export const SearchResultsTooltipContent = (
    props: SearchResultsTooltipContentProps,
): React.ReactElement => {
    const { creatorEmail, creatorName, status, statusIcon, type, typeIcon } =
        props;
    const { t } = useTranslation();

    return (
        <div className="space-y-3">
            {typeIcon && (
                <div>
                    <Label>{t('globalSearch.tooltip.type')}</Label>
                    <IconWithLabel icon={typeIcon}>
                        {t(`globalSearch.type.${type}`)}
                    </IconWithLabel>
                </div>
            )}
            <div>
                <Label>
                    {t(
                        `globalSearch.tooltip.${
                            type === 'target' ? 'status' : 'reportType'
                        }`,
                    )}
                </Label>
                <IconWithLabel icon={statusIcon}>{status}</IconWithLabel>
            </div>
            <div>
                <Label>{t('globalSearch.tooltip.creator')}</Label>
                <div>{creatorName}</div>
                <i className="break-words text-sm">{creatorEmail}</i>
            </div>
        </div>
    );
};
