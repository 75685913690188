/* istanbul ignore file */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as resources from './translations';

export const fallBackLanguage = 'de';

// initialize i18next to use language set in localStorage if set otherwise use browser language
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        detection: {
            order: [
                'navigator',
                'localStorage',
                'cookie',
                'querystring',
                'sessionStorage',
                'htmlTag',
                'path',
                'subdomain',
            ],
        },
        lng: localStorage.getItem('i18nextLng') ?? undefined,
        fallbackLng: fallBackLanguage,
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
