import { useUserState } from '../context/User';
import { useUserQuotaQuery } from './queries/useUserQuotaQuery';

type Quota = {
    used: number;
    total: number;
    remaining: number;
};

type UserQuota = {
    quota: Quota | null;
};

export const useUserQuota: () => UserQuota = () => {
    const [{ customerId }] = useUserState();
    const { data: userQuota } = useUserQuotaQuery(customerId);
    const quota: Quota | null = userQuota
        ? {
              used: userQuota.total.totalTargets,
              total: userQuota.limitOfReports,
              remaining:
                  userQuota.limitOfReports - userQuota.total.totalTargets,
          }
        : null;

    return {
        quota,
    };
};
