import React, {
    DetailedHTMLProps,
    ElementType,
    FC,
    forwardRef,
    InputHTMLAttributes,
} from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '_atoms';
import { SwitchOption } from '../../../features/targets/TargetNew/wizard/form.interface';

interface WizardSwitchProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label?: string;
    left: SwitchOption;
    right: SwitchOption;
    hintMsg?: string;
    errorMsg?: string;
}

const OptionLabel: FC<{
    label: string;
    selected: boolean;
    icon?: ElementType;
}> = ({ label, icon: Icon, selected }) => {
    return (
        <div
            className={classnames(
                'flex items-center gap-1 transition-opacity',
                selected ? 'opacity-100' : 'opacity-50',
            )}
        >
            {Icon && <Icon size={15} />}
            <Paragraph size="small" weight="medium" lineHeight="none">
                {label}
            </Paragraph>
        </div>
    );
};

export const WizardSwitch = forwardRef<HTMLInputElement, WizardSwitchProps>(
    (props, ref) => {
        const { t } = useTranslation();
        const { label, left, right, errorMsg, children, hintMsg, ...rest } =
            props;

        const defaultValue =
            typeof rest.defaultValue === 'boolean' ? rest.defaultValue : true;

        const isLeft =
            typeof rest.value === 'boolean' ? rest.value : defaultValue;

        const switchElement = (
            <div className="flex justify-center items-center gap-2 py-4">
                <OptionLabel
                    label={t(left.labelTranslationKey)}
                    icon={left.icon}
                    selected={isLeft}
                />
                <div className="shrink-0 border-neutral-400 border-2 focus:border-primary-4 hover:border-primary-4 bg-white rounded-full h-5 w-16 relative">
                    <input
                        type="checkbox"
                        className="hidden"
                        checked={!!rest.value}
                        ref={ref}
                        {...rest}
                    />
                    <span
                        className={classnames(
                            'w-8 h-full inline-block border-2 border-white bg-primary-1 rounded-full transition-all absolute top-0',
                            !isLeft
                                ? '-translate-x-full left-full'
                                : 'translate-x-0 left-0',
                        )}
                    />
                </div>
                <OptionLabel
                    label={t(right.labelTranslationKey)}
                    icon={right.icon}
                    selected={!isLeft}
                />
            </div>
        );

        return (
            <div className="w-full max-w-flow-text-base mx-auto relative">
                <label>
                    {label && (
                        <span className="block text-sm font-bold font-jost text-neutral-500">
                            {t(label)}
                        </span>
                    )}
                    {switchElement}
                </label>
                {hintMsg && (
                    <span className="text-neutral-500 block text-sm">
                        {t(hintMsg)}
                    </span>
                )}
                {errorMsg && (
                    <div className="text-error-1 text-sm">{t(errorMsg)}</div>
                )}
                {children}
            </div>
        );
    },
);
WizardSwitch.displayName = 'WizardSwitch';
