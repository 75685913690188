import { FC, HTMLAttributes } from 'react';
import { CustomData, Highlight, Paragraph } from '_atoms';
import { HighlightChunks } from '../Highlight/types';

interface TextDataProps extends HTMLAttributes<HTMLDivElement> {
    headline: string;
    text: string[] | string;
    width?: string;
    highlight?: HighlightChunks;
}

export const TextData: FC<TextDataProps> = ({
    headline,
    text,
    width = 'w-1/5',
    className = '',
    highlight,
    ...props
}) => (
    <CustomData
        headline={headline}
        width={width}
        className={className}
        {...props}
    >
        {Array.isArray(text) ? (
            text.map((word) => (
                <Paragraph
                    key={word}
                    weight="bold"
                    color="dark"
                    className="break-normal text-ellipsis overflow-hidden"
                    size="medium"
                >
                    {highlight ? (
                        <Highlight
                            searchWords={highlight}
                            textToHighlight={word}
                        />
                    ) : (
                        word
                    )}
                </Paragraph>
            ))
        ) : text?.length > 0 ? (
            <Paragraph
                weight="bold"
                color="dark"
                className="break-normal text-ellipsis overflow-hidden"
                size="medium"
            >
                {highlight ? (
                    <Highlight searchWords={highlight} textToHighlight={text} />
                ) : (
                    text
                )}
            </Paragraph>
        ) : null}
    </CustomData>
);
