import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useCaseKeywordsQuery, useCaseQuery } from '_queries';
import { LoadingSpinner } from '_atoms';
import { CaseType, Keyword } from '../../cases/CaseNew/CaseNew.types';
import { CaseTypeButtons } from '../../cases/CaseNew/CaseTypeButtons';
import { CaseSettingsForm } from '../../cases/CaseNew/CaseSettingsForm';
import { SiteWideAnnouncements } from '../../../components/Layout/SiteWideAnnouncements';

export const CaseData: FC = () => {
    const { caseId } = useParams<{ caseId: string }>();

    const { data: caseData, ...caseQuery } = useCaseQuery(caseId);
    const { data: caseKeywordsData, ...caseKeywordsQuery } =
        useCaseKeywordsQuery(caseId);

    const keywords: Keyword[] = (caseKeywordsData ?? [])?.map(
        (keyword: any) => ({
            value: keyword.suspicion.name,
            engTranslations: keyword.translations.map(
                (translation: any) => translation.value,
            ),
        }),
    );

    const caseType = caseData?.option?.option ?? CaseType.Essential;

    const settings = {
        ...caseData?.option,
        keywords,
    };

    return (
        <>
            <SiteWideAnnouncements addWrapper />
            <div className="container-fluid mb-20 px-10">
                {caseQuery?.isLoading || caseKeywordsQuery?.isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <CaseTypeButtons caseType={caseType} />
                        <CaseSettingsForm
                            caseType={caseType}
                            settings={settings}
                            readOnly={true}
                        />
                    </>
                )}
            </div>
        </>
    );
};
