import React, { useCallback } from 'react';
import { KeywordsInput } from '_molecules';
import { CaseSettings } from '../CaseNew.types';

const KEYWORD_TYPE_OPTIONS = ['noun', 'verb', 'adjective', 'phrase'];

interface CaseKeywordsProps {
    readOnly?: boolean;
    settings: CaseSettings;
    setSettings?: React.Dispatch<React.SetStateAction<CaseSettings>>;
}

export const CaseKeywords = (props: CaseKeywordsProps): React.ReactElement => {
    const { readOnly, settings, setSettings } = props;

    const onKeywordChange = useCallback(
        (newKeyword, index) =>
            setSettings?.((prevSettings) => ({
                ...prevSettings,
                keywords: prevSettings.keywords.map((keyword, j) =>
                    j === index ? { ...keyword, ...newKeyword } : keyword,
                ),
            })),
        [setSettings],
    );

    const onAddKeyword = (keyword = ''): void => {
        setSettings?.((prevSettings) =>
            prevSettings.keywords.length < 5
                ? {
                      ...prevSettings,
                      keywords: [...prevSettings.keywords, { value: keyword }],
                  }
                : prevSettings,
        );
    };

    const onRemoveKeyword = (index: number): void => {
        setSettings?.((prevSettings) => ({
            ...prevSettings,
            keywords: prevSettings.keywords.filter(
                (_v, keywordIndex) => keywordIndex !== index,
            ),
        }));
    };

    return (
        <KeywordsInput
            margins="mt-6"
            keywords={settings.keywords}
            options={KEYWORD_TYPE_OPTIONS}
            onChangeValue={onKeywordChange}
            onAddKeyword={onAddKeyword}
            onRemoveKeyword={onRemoveKeyword}
            readOnly={readOnly}
        />
    );
};
