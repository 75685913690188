import { Badge, Card, Paragraph } from '_atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RedFlagCompanyRoles } from './RedFlagCompanyRoles';
import { VscFilePdf } from 'react-icons/vsc';
import { RedFlagItem } from '_types';

interface RedFlagsProps {
    redFlag: RedFlagItem;
}
export const RedFlags = (props: RedFlagsProps): React.ReactElement => {
    const { redFlag } = props;
    const { t } = useTranslation();

    const redFlagHeaders = Object.values(
        t<string, Record<string, string>>('redFlags.headers', {
            returnObjects: true,
        }),
    );

    const sourceNames: Record<string, string> = t('sourceName', {
        returnObjects: true,
    });

    return (
        <Card className="p-4 border-2 rounded-lg">
            <div className="space-y-3 divide-y">
                <div className="grid grid-cols-6 gap-3 items-center w-full">
                    {redFlagHeaders.map((header, index) => (
                        <Paragraph
                            key={index}
                            size="label"
                            weight="bold"
                            color="light"
                        >
                            {header}
                        </Paragraph>
                    ))}
                </div>
                <div className="divide-y">
                    <div className="grid grid-cols-6 gap-3 items-center py-3 break-words">
                        <Paragraph size="default" weight="bold" color="dark">
                            {redFlag.name}
                        </Paragraph>
                        <Paragraph size="default" color="dark" HtmlTag="div">
                            <RedFlagCompanyRoles roles={redFlag.roles} />
                        </Paragraph>
                        <Paragraph size="default" weight="bold" color="dark">
                            {t(
                                `redFlags.rawTypes.${redFlag.type}`,
                                redFlag.type,
                            )}
                        </Paragraph>
                        <Paragraph size="default" color="dark">
                            {redFlag.country}
                        </Paragraph>
                        <Paragraph size="default" color="dark">
                            {redFlag.sources
                                .map((source) => sourceNames[source] ?? source)
                                .join(', ')}
                        </Paragraph>
                        <Paragraph
                            size="default"
                            color="dark"
                            className="flex items-center"
                        >
                            {redFlag.description}
                        </Paragraph>
                        {redFlag.reportUrl ? (
                            <a
                                className="block text-sm text-primary-4 hover:text-primary-3 font-normal focus:outline-none mb-1"
                                href={redFlag.reportUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Badge className="gap-2">
                                    <VscFilePdf />
                                    {t('redFlags.reportsItem')}
                                </Badge>
                            </a>
                        ) : null}
                    </div>
                </div>
            </div>
        </Card>
    );
};
