import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayWithSpinner } from '_atoms';
import { ResultsProps } from '../Results';
import { ReportCard, ReportQueryParams, ReportsQuery } from './ReportCard';
import { useSummaryReportsQuery } from '_queries';
import { useLocation, useParams } from 'react-router-dom';
import { nonProdDataTestId } from '_utils';
import { getQueryParam, replaceHistoryState } from '../../../hooks/useSearch';
import { RedFlags } from './RedFlags';
import { cleanFilters, extractParams, getTestIdForReportCard } from './helpers';
import { TargetStatus } from '../../../services/dataService';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { noop } from 'lodash';

const FILTER_QUERY_PARAM = 'filters';
export const RiskAnalysis: FC<
    ResultsProps & {
        accessToken: string;
        onFinishLoading?: () => void;
    }
> = ({
    accessToken,
    targetData,
    caseData,
    redFlags = [],
    onFinishLoading = noop,
}) => {
    const { t } = useTranslation();
    const { search } = useLocation();

    const { targetId } =
        useParams<{
            targetId: string;
            caseId: string;
        }>();

    const queryParams = getQueryParam(FILTER_QUERY_PARAM, search);

    const [filterQueryParams, setFilterQueryParams] = useState<ReportsQuery>(
        () => {
            try {
                return JSON.parse(queryParams);
            } catch {
                return {};
            }
        },
    );

    const {
        data: summaryReportData,
        isSuccess: summaryReportIsSuccess,
        ...summaryReportQuery
    } = useSummaryReportsQuery(
        targetId,
        targetData?.status === TargetStatus.HasInitialProfile,
        onFinishLoading,
    );
    const filters: ReportsQuery = useMemo(() => {
        const _filters: ReportsQuery = {};

        const redFlagsFilters: ReportQueryParams =
            (filterQueryParams['red_flags'] as ReportQueryParams) || {};

        if (Object.keys(redFlagsFilters).length > 0) {
            _filters['red_flags'] = {
                ...redFlagsFilters,
            };
        }

        return extractParams(_filters, filterQueryParams, summaryReportData);
    }, [filterQueryParams, summaryReportData]);

    const updateFilters = (
        reportLabel: string,
        newFilters: ReportQueryParams,
        clear = false,
    ) => {
        const _reportFilters = clear
            ? newFilters
            : {
                  ...filters[reportLabel],
                  ...newFilters,
              };

        const filtersQuery = {
            ...filters,
            [reportLabel]: _reportFilters,
        } as ReportsQuery;

        setFilterQueryParams(cleanFilters(filtersQuery));

        replaceHistoryState(
            JSON.stringify({ [FILTER_QUERY_PARAM]: filtersQuery }),
        );
    };

    return (
        <DefaultPageLayout title={t('riskAnalysis.title')}>
            <div className="flex-1">
                <OverlayWithSpinner
                    className="space-y-5"
                    showOverlay={summaryReportQuery.isFetching}
                    hideLoader={true}
                >
                    <RedFlags
                        key="red_flags"
                        redFlags={redFlags}
                        filters={filters['red_flags']}
                        onFiltersChange={updateFilters}
                    />

                    {summaryReportIsSuccess &&
                        summaryReportData?.map((report) => (
                            <ReportCard
                                key={report.id}
                                report={report}
                                targetId={targetId}
                                accessToken={accessToken}
                                targetData={targetData}
                                caseData={caseData}
                                additionalHtmlAttributes={{
                                    'data-testid': nonProdDataTestId(
                                        getTestIdForReportCard(report.label) +
                                            ' card',
                                    ),
                                }}
                                filters={filters[report.label]}
                                onFiltersChange={updateFilters}
                            />
                        ))}
                </OverlayWithSpinner>
            </div>
        </DefaultPageLayout>
    );
};
