export enum Step {
    CaseSettings = 'caseSettings',
    CaseAccessSettings = 'caseAccessSettings',
}

export enum CaseType {
    Essential = 'option1',
    Plus = 'option2',
    Complete = 'option3',
}

export interface Keyword {
    value: string;
    engTranslations?: string[];
}

export interface CaseSettings {
    creditRating?: boolean;
    backgroundCheck?: boolean;
    publicInfo?: boolean;
    icijLeaks?: boolean;
    privateSocialMedia?: boolean;
    customSearchTerms?: boolean;
    keywords: Keyword[];
}
