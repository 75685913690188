import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline, Paragraph, Select, SelectV2 } from '_atoms';
import classnames from 'classnames';
import { SummaryReport } from '_types';
import { CollapsibleCard } from '_molecules';
import { ReportCardBySuspicion } from './ReportCardBySuspicion';
import { ReportCardByEntity } from './ReportCardByEntity';
import { SuspicionItemEntityType, SuspicionItemStatus } from '_enums';
import { CaseData, Target } from '../../../services/dataService';
import { nonProdDataTestId } from '_utils';
import { Keywords } from './Keywords';
import {
    INVOLVEMENT_OPTIONS,
    SuspicionItemInvolvement,
    SuspicionItemSource,
} from '../../../types/SuspicionItem';
import { useRouteMatch } from 'react-router-dom';
import { resultsRoutes } from '../../../routes';

type ReportCardProps = {
    report: SummaryReport;
    targetId: string;
    accessToken?: string;
    className?: string;
    targetData?: Target;
    caseData?: CaseData;
    additionalHtmlAttributes?: Record<string, string | undefined>;
    filters?: ReportQueryParams;
    onFiltersChange: (
        reportLabel: string,
        filter: ReportQueryParams,
        clear: boolean,
    ) => void;
    minConfidenceScore?: number;
};

export interface ReportFilters {
    suspicionItemId?: string;
    entityType?: SuspicionItemEntityType;
    status?: SuspicionItemStatus;
    involvement?: SuspicionItemInvolvement;
    minConfidenceScore?: number;
    page?: number;
    source?: SuspicionItemSource;
}

export interface ReportQueryParams extends ReportFilters {
    expanded?: boolean;
    groupBy?: ReportGroupingOption;
}

export interface ReportsQuery {
    [reportLabel: string]: ReportQueryParams;
}

export interface ReportFiltersWithPaging extends ReportFilters {
    page: number;
    pageSize: number;
}

export type ReportGroupingOption = 'entity' | 'suspicion';

export const GROUPING_OPTIONS: ReportGroupingOption[] = ['entity', 'suspicion'];

interface ReportOption {
    id: number;
    label: string;
    value: ReportGroupingOption;
}

const reportOptions: Array<ReportOption> = [
    {
        id: 1,
        label: 'entity',
        value: 'entity',
    },
    {
        id: 2,
        label: 'suspicion',
        value: 'suspicion',
    },
];

interface SelectOption {
    id: number;
    label: string;
    value: string;
}

const getInitialSelectValue = (list: SelectOption[], value: string) => {
    return list.find((item) => item.value === value);
};

export const ReportCard: FC<ReportCardProps> = ({
    report,
    targetId,
    accessToken = '',
    className,
    targetData,
    caseData,
    additionalHtmlAttributes,
    filters,
    onFiltersChange,
    minConfidenceScore,
}) => {
    const { confirmedSuspicionCount, suspicionsCount, suspicions, id, label } =
        report;

    const { t } = useTranslation();

    const isPrint = useRouteMatch(resultsRoutes.printVersion.path);

    const sourceOptions = useMemo(
        () =>
            Object.values(SuspicionItemSource)
                .filter((source) => source !== SuspicionItemSource.Cortical)
                .map((source, index) => ({
                    id: index,
                    label: t(`suspicionItemSource.${source}`),
                    value: source,
                })),
        [t],
    );

    const involvementOptions = useMemo(
        () =>
            INVOLVEMENT_OPTIONS.map((involvement, index) => ({
                id: index,
                label: t(`suspicionItemInvolvement.${involvement}`),
                value: involvement,
            })),
        [t],
    );

    const statusOptions = [
        SuspicionItemStatus.Problem,
        SuspicionItemStatus.Pending,
    ].map((status, index) => ({
        // + 1 is required to avoid duplicate keys when passing the options
        // to Select component with addEmptyOption as true, because
        // addEmptyOption pushes a new option with key 0 to the Select;
        id: index + 1,
        label: t(`suspicionItemStatus.${status}`),
        value: status,
    }));

    const entityOptions = (
        Object.keys(SuspicionItemEntityType) as Array<
            keyof typeof SuspicionItemEntityType
        >
    ).map((key, index) => ({
        id: index + 1,
        label: t(`suspicionItemEntityType.${SuspicionItemEntityType[key]}`),
        value: SuspicionItemEntityType[key],
    }));

    const [suspicionOptions, setSuspicionOptions] = useState<
        { id: string; label: string; value: string }[]
    >([]);

    useEffect(() => {
        setSuspicionOptions(
            suspicions.map(({ id, name, label }) => ({
                id,
                value: id,
                label: t(`suspicionKeywords.${label}`, {
                    defaultValue: name,
                }),
            })),
        );
    }, [suspicions, t]);

    const reportGroupingOptions = reportOptions.map((option) => ({
        ...option,
        label: t(option.label),
    }));

    return (
        <CollapsibleCard
            id={id}
            headerBorder={
                suspicionsCount > 0 ? 'border-warning-1' : 'border-success-1'
            }
            headerColor={
                suspicionsCount > 0 ? 'bg-warning-1/30' : 'bg-neutral-100'
            }
            header={
                <>
                    <div
                        className={classnames('flex items-baseline space-x-3')}
                    >
                        <Headline Level="h5">
                            {t(`reports.sections.${report.label}`)}
                        </Headline>
                        <Paragraph
                            color="mediumDark"
                            size="small"
                            className="font-jost"
                        >
                            {suspicionsCount > 0 &&
                                `${t('confirmedSuspicionsCount', {
                                    hits: confirmedSuspicionCount,
                                })} / `}
                            {t('reports.resultsCount', {
                                count: suspicionsCount,
                            })}
                        </Paragraph>
                    </div>
                    <Keywords
                        className="mt-2"
                        keywords={suspicions.map(
                            ({
                                id: suspicionItemId,
                                name,
                                label,
                                itemCount,
                                involvements,
                            }) => ({
                                name,
                                label,
                                suspicionItemId,
                                count: itemCount,
                                involvements,
                            }),
                        )}
                        onClick={(label, suspicionItemId) => {
                            onFiltersChange(
                                label,
                                {
                                    expanded: true,
                                    suspicionItemId: suspicionItemId,
                                    groupBy: filters?.groupBy,
                                    page: 1,
                                },
                                true,
                            );
                        }}
                        reportLabel={label}
                        t={t}
                    />
                </>
            }
            headerWrapperClasses="items-center"
            initiallyExpanded={isPrint ? true : filters?.expanded ?? false}
            toggleEnabled={suspicionsCount > 0}
            onToggleChange={(value) =>
                onFiltersChange(label, { expanded: value }, false)
            }
            className={classnames(
                'shadow-md',
                'border-0',
                'overflow-visible',
                className,
            )}
            additionalHtmlAttributes={additionalHtmlAttributes}
        >
            {suspicionsCount > 0 ? (
                <>
                    <div className="flex justify-between space-x-4 print:hidden">
                        <div className="max-w-[20%] w-full">
                            <SelectV2
                                label={t('suspicion')}
                                options={(suspicionOptions ?? []).sort((a, b) =>
                                    a.label.localeCompare(b.label),
                                )}
                                selected={suspicionOptions?.find(
                                    (item) =>
                                        item.id === filters?.suspicionItemId ||
                                        '',
                                )}
                                addEmptyOption={true}
                                onChange={(suspicionItemId) =>
                                    onFiltersChange(
                                        label,
                                        {
                                            suspicionItemId: suspicionItemId,
                                            expanded: true,
                                            page: 1,
                                        },
                                        false,
                                    )
                                }
                                doNotTruncate={true}
                            />
                        </div>
                        <SelectV2
                            className="max-w-[20%] w-full"
                            label={t('source')}
                            options={sourceOptions}
                            addEmptyOption={true}
                            selected={getInitialSelectValue(
                                sourceOptions,
                                filters?.source || '',
                            )}
                            onChange={(source) =>
                                onFiltersChange(
                                    label,
                                    { source, expanded: true, page: 1 },
                                    false,
                                )
                            }
                            doNotTruncate={true}
                        />
                        <Select
                            className="max-w-[20%] w-full"
                            label={t('entity')}
                            options={entityOptions}
                            initialSelection={getInitialSelectValue(
                                entityOptions,
                                filters?.entityType || '',
                            )}
                            onChange={(entityType: SuspicionItemEntityType) =>
                                onFiltersChange(
                                    label,
                                    {
                                        entityType: entityType,
                                        expanded: true,
                                        page: 1,
                                    },
                                    false,
                                )
                            }
                            addEmptyOption={true}
                            doNotTruncate={true}
                        />
                        <Select
                            className="max-w-[20%] w-full"
                            label={t('filters.involvement')}
                            options={involvementOptions}
                            initialSelection={getInitialSelectValue(
                                involvementOptions,
                                filters?.status || '',
                            )}
                            onChange={(involvement: SuspicionItemInvolvement) =>
                                onFiltersChange(
                                    label,
                                    {
                                        involvement: involvement,
                                        expanded: true,
                                        page: 1,
                                    },
                                    false,
                                )
                            }
                            addEmptyOption={true}
                            doNotTruncate={true}
                        />
                        <Select
                            className="max-w-[20%] w-full"
                            label={t('filters.status')}
                            options={statusOptions}
                            initialSelection={getInitialSelectValue(
                                statusOptions,
                                filters?.status || '',
                            )}
                            onChange={(status: SuspicionItemStatus) =>
                                onFiltersChange(
                                    label,
                                    { status: status, expanded: true, page: 1 },
                                    false,
                                )
                            }
                            addEmptyOption={true}
                            doNotTruncate={true}
                        />
                        <Select
                            className="max-w-[20%] w-full"
                            label={t('sortBy')}
                            options={reportGroupingOptions}
                            initialSelection={getInitialSelectValue(
                                reportGroupingOptions,
                                filters?.groupBy ??
                                    reportGroupingOptions[0].label.toLowerCase(),
                            )}
                            onChange={(reportGrouping) =>
                                onFiltersChange(
                                    label,
                                    {
                                        groupBy: reportGrouping.toLowerCase(),
                                        expanded: true,
                                        page: 1,
                                    },
                                    false,
                                )
                            }
                            dataTestId={nonProdDataTestId(
                                'see sort by options',
                            )}
                            doNotTruncate={true}
                        />
                    </div>

                    {filters?.groupBy === 'suspicion' ? (
                        <ReportCardBySuspicion
                            reportId={id}
                            targetId={targetId}
                            filters={filters}
                            accessToken={accessToken}
                            targetData={targetData}
                            caseData={caseData}
                            report={report}
                            minConfidenceScore={minConfidenceScore}
                        />
                    ) : (
                        <ReportCardByEntity
                            reportId={id}
                            filters={filters}
                            accessToken={accessToken}
                            targetData={targetData}
                            caseData={caseData}
                            minConfidenceScore={minConfidenceScore}
                            setPage={(page: number) => {
                                onFiltersChange(label, { page }, false);
                            }}
                        />
                    )}
                </>
            ) : null}
        </CollapsibleCard>
    );
};
