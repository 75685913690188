import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Image } from '_atoms';
import { theme } from '../../../tailwind.config';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { parseRem } from '_utils';

type SliderVerticalProps = { images: string[]; onClick?: () => void };
SwiperCore.use([Navigation, Pagination]);

export const SliderVertical: FC<SliderVerticalProps> = ({
    images,
    onClick,
}: SliderVerticalProps) => {
    const { t } = useTranslation();
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const prevButtonRef = useRef<HTMLDivElement | null>(null);
    const nextButtonRef = useRef<HTMLDivElement | null>(null);
    const paginationRef = useRef<HTMLDivElement | null>(null);
    const [windowWidth] = useWindowSize();

    const mainSliderRef = useRef<HTMLDivElement | null>(null);
    const [mainSlider, setMainSlider] = useState<SwiperCore | null>(null);
    const [smallSlider, setSmallSlider] = useState<SwiperCore | null>(null);

    const mainSliderHeight = useMemo(
        () =>
            mainSliderRef.current
                ? (mainSliderRef.current.clientWidth * 4) / 3
                : 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mainSliderRef, windowWidth],
    );

    useEffect(() => {
        mainSlider?.slideTo(currentSlide);
        smallSlider?.slideTo(currentSlide);
    }, [mainSlider, smallSlider, currentSlide]);

    return (
        <Fragment>
            <div ref={mainSliderRef} className="space-y-1" onClick={onClick}>
                <Swiper
                    onActiveIndexChange={(swiper) =>
                        setCurrentSlide(swiper.activeIndex)
                    }
                    className="overflow-y-always-hidden"
                    onSwiper={setMainSlider}
                    observer={true}
                    observeParents={true}
                    lazy={{
                        loadOnTransitionStart: true,
                    }}
                    onSlideChange={(swiper) => {
                        setIsBeginning(swiper.isBeginning);
                        setIsEnd(swiper.isEnd);
                    }}
                    navigation={{
                        nextEl: nextButtonRef.current,
                        prevEl: prevButtonRef.current,
                    }}
                    pagination={{
                        el: paginationRef.current,
                        clickable: true,
                        dynamicBullets: true,
                    }}
                >
                    {images.map((src, index) => (
                        <SwiperSlide
                            key={index}
                            onClick={() => {
                                setCurrentSlide(index);
                            }}
                            style={{
                                height: mainSliderHeight,
                            }}
                        >
                            <Image
                                aspectRatio="portrait"
                                alt={`${t('profileImage')} ${index + 1}`}
                                className="rounded-md"
                                src={src}
                            />
                        </SwiperSlide>
                    ))}
                    <div
                        className="pointer-events-none absolute w-full inset-0 flex justify-between align-middle z-10"
                        style={{ height: `${mainSliderHeight}px` }}
                    >
                        <div
                            className={`pointer-events-auto my-auto transition duration-500 opacity-1 ${
                                isBeginning && 'opacity-0'
                            }`}
                            style={{ width: '35px' }}
                            ref={prevButtonRef}
                        >
                            <button
                                className="text-gray-200 p-2"
                                disabled={isBeginning}
                            >
                                <MdChevronLeft />
                            </button>
                        </div>
                        <div
                            className={`pointer-events-auto my-auto transition duration-500 opacity-1 ${
                                isEnd && 'opacity-0'
                            }`}
                            ref={nextButtonRef}
                        >
                            <button
                                className="text-gray-200 p-2"
                                disabled={isEnd}
                            >
                                <MdChevronRight />
                            </button>
                        </div>
                    </div>

                    <style>
                        {`.swiper-pagination-bullet-active {
                          background: ${theme.extend.colors.gray[200]};
                        }`}
                    </style>
                </Swiper>
                <Swiper
                    style={{
                        height: mainSliderHeight / 5,
                    }}
                    className="relative bottom-0 left-0 right-0 z-10"
                    onActiveIndexChange={(swiper) =>
                        setCurrentSlide(swiper.activeIndex)
                    }
                    onSwiper={setSmallSlider}
                    spaceBetween={parseRem(theme.spacing[1.5])}
                    slidesPerView={5.2}
                    preventInteractionOnTransition={true}
                    lazy={{
                        loadOnTransitionStart: true,
                    }}
                >
                    {images.map((src, index) => (
                        <SwiperSlide
                            className="cursor-pointer group"
                            key={index}
                            onClick={() => setCurrentSlide(index)}
                        >
                            <div
                                className={`absolute z-1 w-full h-full inset-0 border-primary-4 border-solid group-hover:border-b-4 ${
                                    currentSlide === index &&
                                    `border-b-4 border-primary-4`
                                }`}
                            />
                            <Image
                                aspectRatio="portrait"
                                alt={`${t('profileImage')} ${index + 1}`}
                                className="rounded-md"
                                src={src}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </Fragment>
    );
};
