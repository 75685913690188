import { useMemo } from 'react';
import {
    CandidateFingerprint,
    Fingerprint,
    MixedTargetCandidateGroup,
    UserInputFingerprints,
} from '../../../services/dataService';
import { calculateFingerprintScore } from '../../../services/fingerprints';
import { GoogleCandidateStatus } from '_types';
import { TargetPersonSelectionState } from '_enums';
import { ProcessTarget } from '@indicium/common';
import {
    candidateToReferenceInput,
    groupReferenceInput,
    negativeUserInputToNormalizedReferenceInput,
    NormalizedReferenceInput,
    scoreCandidate,
    userInputToNormalizedReferenceInput,
} from './candidateScoring/scoreCandidate';
import {
    normalizeExactMatchScore,
    normalizeGoogleExactMatchScore,
} from './candidateScoring/utils';
import { scoreGoogleCandidates } from './candidateScoring/scoreGoogleCandidate';
import { isNeitherNullNorUndefined } from '_utils';

interface CandidateSortingInput {
    allCandidates: MixedTargetCandidateGroup[];
    candidateFingerprints?: CandidateFingerprint[];
    userInputFingerprints?: UserInputFingerprints;
    selectedGoogleCandidates: Record<string, GoogleCandidateStatus[]>;
    selectedCandidates:
        | Record<string, TargetPersonSelectionState | undefined>
        | undefined;
    userInput?: ProcessTarget;
    sortByHardCriteriaScore?: boolean;
    skippedCandidates: MixedTargetCandidateGroup[];
}

export type PendingCandidate = MixedTargetCandidateGroup & {
    hardCriteriaScore: number;
    unifiedScore: number;
    fingerprintScore: number;
};

type SortedCandidates = {
    pending: PendingCandidate[];
    decided: MixedTargetCandidateGroup[];
    included: MixedTargetCandidateGroup[];
    excluded: MixedTargetCandidateGroup[];
};

export const useCandidateSorting = ({
    allCandidates,
    candidateFingerprints,
    userInputFingerprints,
    selectedGoogleCandidates,
    selectedCandidates,
    userInput,
    sortByHardCriteriaScore,
    skippedCandidates,
}: CandidateSortingInput): SortedCandidates => {
    const candidateFingerprintMap = useMemo(() => {
        const _candidateFingerprintMap = new Map<string, Fingerprint>();

        (candidateFingerprints ?? []).forEach((candidateFingerprint) => {
            const candidateFingerprintSet =
                Object.entries(candidateFingerprint)[0];
            const candidateId = candidateFingerprintSet?.[0];
            const referenceFingerprint = candidateFingerprintSet?.[1];

            if (
                referenceFingerprint &&
                candidateId &&
                Array.isArray(referenceFingerprint)
            ) {
                _candidateFingerprintMap.set(candidateId, referenceFingerprint);
            }
        });
        return _candidateFingerprintMap;
    }, [candidateFingerprints]);

    const {
        pendingCandidates,
        includedCandidates,
        excludedCandidates,
        decidedCandidates,
        positiveFingerprints,
        negativeFingerprints,
    } = useMemo(() => {
        const positiveFingerprints: Fingerprint[] = [
            userInputFingerprints?.positive ?? [],
        ];
        const negativeFingerprints: Fingerprint[] = [
            userInputFingerprints?.negative ?? [],
        ];
        const pendingCandidates: MixedTargetCandidateGroup[] = [];
        const includedCandidates: MixedTargetCandidateGroup[] = [];
        const excludedCandidates: MixedTargetCandidateGroup[] = [];
        const decidedCandidates: MixedTargetCandidateGroup[] = [];

        allCandidates.forEach((candidateGroup) => {
            if ('isGoogleCandidate' in candidateGroup) {
                let includedCandidateCount = 0;
                let excludedCandidateCount = 0;

                const googleCandidates =
                    selectedGoogleCandidates[candidateGroup.groupId] ?? [];

                googleCandidates.forEach((googleCandidate) => {
                    const candidateFingerprint =
                        candidateFingerprintMap?.get(googleCandidate.id) ?? [];

                    if (
                        googleCandidate.status ===
                        TargetPersonSelectionState.Confirmed
                    ) {
                        includedCandidateCount += 1;
                        positiveFingerprints.push(candidateFingerprint);
                    }

                    if (
                        googleCandidate.status ===
                        TargetPersonSelectionState.Ignored
                    ) {
                        excludedCandidateCount += 1;
                        negativeFingerprints.push(candidateFingerprint);
                    }
                });

                if (includedCandidateCount > 0 || excludedCandidateCount > 0) {
                    decidedCandidates.push(candidateGroup);
                } else {
                    pendingCandidates.push(candidateGroup);
                }

                if (includedCandidateCount === googleCandidates.length) {
                    includedCandidates.push(candidateGroup);
                }

                if (excludedCandidateCount === googleCandidates.length) {
                    excludedCandidates.push(candidateGroup);
                }
            } else {
                const candidateFingerprint = candidateGroup.candidateIds
                    .map((id) => candidateFingerprintMap?.get(id) ?? [])
                    .flat();

                if (
                    selectedCandidates?.[candidateGroup.groupId] ===
                    TargetPersonSelectionState.Confirmed
                ) {
                    positiveFingerprints.push(candidateFingerprint);
                    includedCandidates.push(candidateGroup);
                    decidedCandidates.push(candidateGroup);
                } else if (
                    selectedCandidates?.[candidateGroup.groupId] ===
                    TargetPersonSelectionState.Ignored
                ) {
                    negativeFingerprints.push(candidateFingerprint);
                    excludedCandidates.push(candidateGroup);
                    decidedCandidates.push(candidateGroup);
                } else {
                    pendingCandidates.push(candidateGroup);
                }
            }
        });

        const filteredPendingCandidates = pendingCandidates.filter(
            (candidate) => {
                return !skippedCandidates.some(
                    (skippedCandidate) =>
                        skippedCandidate.groupId === candidate.groupId,
                );
            },
        );

        return {
            positiveFingerprints,
            negativeFingerprints,
            pendingCandidates: filteredPendingCandidates,
            includedCandidates,
            excludedCandidates,
            decidedCandidates,
        };
    }, [
        userInputFingerprints?.positive,
        userInputFingerprints?.negative,
        allCandidates,
        selectedGoogleCandidates,
        candidateFingerprintMap,
        selectedCandidates,
        skippedCandidates,
    ]);

    const scoredFingerprints = useMemo(
        () =>
            new Map<string, number>(
                Array.from(candidateFingerprintMap?.entries() ?? []).map(
                    ([candidateId, candidateFingerprint]) => {
                        const score = calculateFingerprintScore(
                            candidateFingerprint,
                            positiveFingerprints,
                            negativeFingerprints,
                        );

                        return [candidateId, score] as const;
                    },
                ),
            ),
        [positiveFingerprints, negativeFingerprints, candidateFingerprintMap],
    );

    const positiveReferenceInput = useMemo(() => {
        const referenceInput: NormalizedReferenceInput[] = [
            userInputToNormalizedReferenceInput(userInput),
            ...includedCandidates
                .map((candidate) =>
                    'isGoogleCandidate' in candidate
                        ? null
                        : candidateToReferenceInput(candidate),
                )
                .filter(isNeitherNullNorUndefined),
        ];

        return groupReferenceInput(referenceInput);
    }, [userInput, includedCandidates]);

    const negativeReferenceInput = useMemo(() => {
        const referenceInput: NormalizedReferenceInput[] = [
            negativeUserInputToNormalizedReferenceInput(userInput),
            ...excludedCandidates
                .map((candidate) =>
                    'isGoogleCandidate' in candidate
                        ? null
                        : candidateToReferenceInput(candidate),
                )
                .filter(isNeitherNullNorUndefined),
        ];

        return groupReferenceInput(referenceInput);
    }, [userInput, excludedCandidates]);

    const sortedPendingCandidates = useMemo(
        () =>
            pendingCandidates
                .map((candidateGroup) => {
                    if ('isGoogleCandidate' in candidateGroup) {
                        const totalFingerprintScore =
                            candidateGroup.candidates.reduce(
                                (total, googleCandidate) =>
                                    total +
                                    (scoredFingerprints.get(
                                        googleCandidate.id,
                                    ) ?? 0),
                                0,
                            );

                        const fingerprintScore =
                            totalFingerprintScore /
                            candidateGroup.candidates.length;

                        const hardCriteriaScore =
                            normalizeGoogleExactMatchScore(
                                scoreGoogleCandidates(
                                    candidateGroup.candidates,
                                    positiveReferenceInput,
                                    negativeReferenceInput,
                                ),
                            );

                        return {
                            ...candidateGroup,
                            hardCriteriaScore,
                            fingerprintScore,
                            unifiedScore:
                                (hardCriteriaScore + fingerprintScore) / 2,
                        };
                    }
                    const totalFingerprintScore =
                        candidateGroup.candidateIds.reduce(
                            (total, id) =>
                                total + (scoredFingerprints.get(id) ?? 0),
                            0,
                        );

                    const fingerprintScore =
                        totalFingerprintScore /
                        candidateGroup.candidateIds.length;

                    const hardCriteriaScore = normalizeExactMatchScore(
                        scoreCandidate(
                            candidateGroup,
                            positiveReferenceInput,
                            negativeReferenceInput,
                        ),
                    );

                    return {
                        ...candidateGroup,
                        hardCriteriaScore,
                        fingerprintScore,
                        unifiedScore:
                            (hardCriteriaScore + fingerprintScore) / 2,
                    };
                })
                .sort((a, b) =>
                    sortByHardCriteriaScore
                        ? b.hardCriteriaScore - a.hardCriteriaScore
                        : b.unifiedScore - a.unifiedScore,
                ),
        [
            pendingCandidates,
            scoredFingerprints,
            positiveReferenceInput,
            negativeReferenceInput,
            sortByHardCriteriaScore,
        ],
    );

    return {
        pending: sortedPendingCandidates,
        included: includedCandidates, // likely correctly sorted
        excluded: excludedCandidates, // likely correctly sorted
        decided: decidedCandidates,
    };
};
