import { LoadingSpinner } from '_atoms';
import classNames from 'classnames';
import React, { FC, FormEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { confirmTOTPSetup, User } from '../../services/authenticationService';
import { AuthSteps, SignInExceptions } from './types';

interface SigninProps {
    localUser: User | null;
    onSuccess: (user: User) => void;
    onError: (code: SignInExceptions) => void;
    onChangeStep: (step: AuthSteps) => void;
}

export const ConfirmCode: FC<SigninProps> = ({
    onSuccess,
    onError,
    localUser,
    onChangeStep,
}: SigninProps) => {
    const { t } = useTranslation();
    const [confirmationCode, setConfirmationCode] =
        useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setLoading(true);

            if (!localUser || !confirmationCode) {
                return;
            }

            try {
                const authenticatedUser = await confirmTOTPSetup(
                    localUser,
                    confirmationCode,
                );

                onSuccess(authenticatedUser);
            } catch (error: any) {
                if (error?.code) {
                    onError(error.code as SignInExceptions);
                }
            } finally {
                setLoading(false);
            }
        },
        [localUser, confirmationCode, onSuccess, onError],
    );

    if (!localUser) {
        onChangeStep(AuthSteps.SignIn);

        return null;
    }

    const mfaValid = confirmationCode?.length === 6;

    return (
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <p>{t('authentication.description')}</p>

            <input type="hidden" name="remember" defaultValue="true" />

            <div className="rounded-md shadow-sm -space-y-px">
                <div>
                    <label htmlFor="confirmationCode" className="sr-only">
                        MFA Code:
                    </label>
                    <input
                        id="confirmationCode"
                        name="confirmationCode"
                        type="number"
                        required
                        className="appearance-none relative block w-full px-3 py-2 border border-neutral-300 placeholder-neutral-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-4 focus:border-primary-4 focus:z-10 sm:text-sm"
                        onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                </div>
            </div>

            <div>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <button
                        disabled={!mfaValid}
                        type="submit"
                        className={classNames(
                            !mfaValid &&
                                'opacity-30 disabled:bg-primary-4 disabled:cursor-not-allowed',
                            'group relative w-full flex justify-center py-2 px-4 mt-12 border border-transparent text-sm font-medium rounded-md text-font-light bg-primary-4 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-4',
                        )}
                    >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <HiOutlineLockClosed
                                className="h-5 w-5 text-blue-500 group-hover:text-primary-4"
                                aria-hidden="true"
                            />
                        </span>
                        {t('authentication.submitButton')}
                    </button>
                )}
            </div>
        </form>
    );
};
