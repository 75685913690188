import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterDataArticle } from 'src/services/searchService';
import { Headline } from '_atoms';
import classNames from 'classnames';
import { formatDate } from '_utils';

export type PublicationsProps = {
    data: RegisterDataArticle;
};

export const Publications: FC<PublicationsProps> = ({ data }) => {
    const { t } = useTranslation();

    const companyPublications = data?.companyPublications?.map((item) => ({
        date: formatDate(item.date),
        type: item.type,
        message: item.message
            .replace(/<FT TYPE="(.+?)">/g, '<span className="$1">')
            .replace(/<\/FT>/g, '</span>'),
    }));

    return (
        <div className="p-8">
            <div className="flex flex-col gap-4">
                {companyPublications?.map((item, index) => (
                    <div
                        key={`pub_${index}`}
                        className={classNames(
                            'flex flex-col gap-2 ',
                            index === companyPublications.length - 1
                                ? 'border-none pb-0'
                                : 'border-b pb-6',
                        )}
                    >
                        <div className="flex flex-row gap-2">
                            <Headline Level="h3">
                                {item.date}:{' '}
                                {t(`companyMutations.${item.type}`, item.type)}
                            </Headline>
                        </div>
                        <div
                            dangerouslySetInnerHTML={{ __html: item.message }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
