import {
    COMMON_COMPANY_LEGAL_POSTFIXES,
    COMMON_COMPANY_PHRASE_REGEXPS,
    LOWERCASE_COUNTRY_CODES,
    LOWERCASE_COUNTRY_NAMES,
} from './constants';

export const normalizeCompanyName = (name: string): string => {
    return name
        .trim()
        .toLocaleLowerCase()
        .replace(/[^a-zäöüß0-9&+]/g, ' ')
        .replace(/ {2,}/g, ' ');
};

const removeTrailingCompanyNameParts = (
    regExps: ReadonlyArray<RegExp>,
    name: string,
): string => {
    const lastSpaceIndex = name.lastIndexOf(' ');
    const lastWord = name.substring(lastSpaceIndex + 1);

    const matched =
        lastWord === '&' || regExps.some((regExp) => regExp.test(lastWord));

    if (!matched) {
        return name;
    }

    if (lastSpaceIndex === -1) {
        return normalizeCompanyName('');
    }

    return removeTrailingCompanyNameParts(
        regExps,
        normalizeCompanyName(name.substring(0, lastSpaceIndex)),
    );
};

const removeCountryCodes = (companyName: string): string => {
    return LOWERCASE_COUNTRY_CODES.reduce((acc, code) => {
        return acc.replace(new RegExp(`\\b${code}\\b`, 'gi'), '');
    }, companyName.toLowerCase().trim()).trim();
};

const removeCountryNames = (companyName: string): string => {
    return LOWERCASE_COUNTRY_NAMES.reduce((acc, name) => {
        return acc.replace(new RegExp(`\\b${name}\\b`, 'gi'), '');
    }, companyName.toLowerCase().trim()).trim();
};

const isCompanyMentionedInText = (text: string, company: string): boolean =>
    text.includes(company) ||
    text.toLowerCase().includes(company.toLowerCase());

export const scoreCompany = (text: string, companyName: string): number => {
    if (!companyName) {
        return 0;
    }

    if (isCompanyMentionedInText(text, companyName)) {
        return 15;
    }

    const companyNameNoLegalWords = removeTrailingCompanyNameParts(
        COMMON_COMPANY_LEGAL_POSTFIXES,
        normalizeCompanyName(companyName),
    );

    if (isCompanyMentionedInText(text, companyNameNoLegalWords)) {
        return 12;
    }

    const companyNameNoLegalNoPhraseWords = removeTrailingCompanyNameParts(
        COMMON_COMPANY_PHRASE_REGEXPS,
        companyNameNoLegalWords,
    );

    if (isCompanyMentionedInText(text, companyNameNoLegalNoPhraseWords)) {
        return 10;
    }

    const companyNameWithoutCode = removeCountryCodes(companyNameNoLegalWords);

    if (isCompanyMentionedInText(text, companyNameWithoutCode)) {
        return 8;
    }

    const companyNameWithoutCountryName = removeCountryNames(
        companyNameWithoutCode,
    );

    if (isCompanyMentionedInText(text, companyNameWithoutCountryName)) {
        return 5;
    }

    return 0;
};
