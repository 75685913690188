import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DynamicSVG, Headline } from '_atoms';

interface MaintenanceMessageProps {
    reload: () => void;
}

export const MaintenanceMessage = ({
    reload,
}: MaintenanceMessageProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="h-content-height max-w-max-content flex flex-col items-center justify-center gap-4 mx-auto p-9">
            <Headline Level="h3">{t('maintenance.title')}</Headline>
            <Headline Level="h4">{t('maintenance.subtitle')}</Headline>
            <DynamicSVG src="icons/error-page-icon" />
            <div className="flex gap-4">
                <Button
                    className="w-52"
                    type="submit"
                    level="primary"
                    disabled={false}
                    onClick={() => {
                        reload();
                    }}
                >
                    {t('maintenance.reload')}
                </Button>
            </div>
        </div>
    );
};
