import { useMutation, UseMutationResult } from 'react-query';
import { SuspicionItemStatus } from '_enums';
import { suspicions } from '../../services/nestApiService';

type Payload = {
    itemIds: string[];
    status: SuspicionItemStatus;
};

export const useUpdateSuspicionItemsMutation = (): UseMutationResult<
    unknown,
    unknown,
    Payload
> => {
    return useMutation(
        async ({ itemIds, status }: Payload) =>
            suspicions.items.updateMany(itemIds, status),
        {
            retry: (attempt) => attempt < 3,
            retryDelay: (attempt) => 2000 * Math.pow(2, attempt - 1),
        },
    );
};
