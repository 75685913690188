import { AllDataArticle } from 'src/services/searchService';
import { SuspicionIndicator, SuspicionItemEntity } from './SuspicionItemEntity';

export type SuspicionItem = {
    id: string;
    targetId: string;
    suspicionId: string;
    userId?: string;
    entity: SuspicionItemEntity;
    status: string;
    score: number;
    createdAt: Date;
    updatedAt: Date;
};

export type EntityBySuspicion = {
    id: string;
    value: AllDataArticle;
    createdAt: Date;
    updatedAt: Date;
    type: string;
    suspicionItemId: string;
    involvement: SuspicionItemInvolvement | null;
    indicators: Array<SuspicionIndicator>;
    score: number;
    status: string;
};

export const INVOLVEMENT_OPTIONS = [
    'perpetrator',
    'victim',
    'suspect',
] as const;

export type SuspicionItemInvolvement = typeof INVOLVEMENT_OPTIONS[number];

export enum SuspicionItemSource {
    User = 'User',
    Cortical = 'Cortical',
    LogosLLM = 'LogosLLM',
    ML = 'ML',
    OwnKeyword = 'OwnKeyword',
    KeywordList = 'KeywordList',
}

export interface LinkSuspicionItemParams {
    entityId: string;
    suspicionId: string;
    targetId: string;
    involvement: SuspicionItemInvolvement;
}

export enum SuspicionItemInvolvementDecision {
    Inferred = 'inferred', // Involvement inferred from context
    Explicit = 'explicit', // Explicitly stated involvement
    Assumed = 'assumed', // Assumed involvement based on available information
    Unknown = 'unknown', // Involvement is unknown
}
