import { useQuery, UseQueryResult } from 'react-query';
import { Target } from '../../services/dataService';
import { cases } from '../../services/nestApiService';
import { Paginated } from '../../types/NestAPI';

export interface TargetsQueryParams {
    page?: string;
    pageSize?: number;
    query?: string;
    creatorId?: string;
}

export const useTargetsQuery = (
    caseId: string,
    queryParams?: TargetsQueryParams,
): UseQueryResult<Paginated<Target>> =>
    useQuery(
        ['targets', caseId, queryParams],
        async () => cases.getTargets(caseId, queryParams),
        {
            refetchOnWindowFocus: false,
        },
    );

export const useFiveLatestTargetsQuery = (
    queryParams?: TargetsQueryParams,
): UseQueryResult<Paginated<Target>> =>
    useQuery(
        ['targets', queryParams],
        async () => cases.getLatestTargetList(queryParams),
        {
            refetchOnWindowFocus: false,
        },
    );
