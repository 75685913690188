import { clone } from 'lodash';
import { BaseFilterProps } from '_types';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Paragraph } from '../../Paragraph/Paragraph';
import { withTranslation, WithTranslation } from 'react-i18next';
import Filter from '../Filter';

type OptionsRangeFilterState = {
    value: {
        lte?: string;
        gte?: string;
    };
    values: (string | number)[];
};

type OptionsRangeItemOption = {
    id?: string | number;
    label: string;
    value: string | number;
    operator: keyof OptionsRangeFilterState['value'];
};

type OptionsRangeFilterProps = {
    item: {
        title?: string;
        name: string;
        options: OptionsRangeItemOption[];
    };
};

class OptionsFilter extends Filter<
    BaseFilterProps & OptionsRangeFilterProps & WithTranslation,
    OptionsRangeFilterState
> {
    constructor(props: any) {
        super(props);
        if (props.item.options.length > 2) {
            throw new Error(
                '[OptionsRangeFilter] Maximum number of options exceeded. Limit: 2',
            );
        }
        this.state = {
            value: {},
            values: [],
        };
    }

    componentDidMount() {
        const { searchState, field } = this.props;
        if (searchState.filters && searchState.filters[field]) {
            const value = searchState.filters[field].value;
            this.setState({
                value,
            });
            this.forceUpdate();
        }
    }

    handleValueChange(option: OptionsRangeItemOption, checked: boolean) {
        const { value } = this.state;
        const { field } = this.props;
        const valueClone = clone(value);

        if (valueClone[option.operator] && !checked) {
            valueClone[option.operator] = undefined;
        } else {
            valueClone[option.operator] = option.value.toString();
        }

        const transformedValue = this.getFilterValueJson(
            valueClone,
            'filter',
            'range',
        );
        super.handleFilterChange(field, transformedValue);
    }

    render() {
        const { item, t } = this.props;
        const { value } = this.state;

        return (
            <div className="py-2">
                {item.title && (
                    <Paragraph
                        color="light"
                        size="small"
                        className="uppercase mb-1"
                    >
                        {t(`filters.${item.title}`)}
                    </Paragraph>
                )}
                <div className="flex gap-5">
                    {item.options.map((option: any, index: number) => (
                        <Checkbox
                            key={index}
                            onChange={(checked: boolean) =>
                                this.handleValueChange(option, checked)
                            }
                            initialIsSelected={Boolean(
                                value[
                                    option.operator as keyof OptionsRangeFilterState['value']
                                ],
                            )}
                            label={t(`filters.${option.label}`)}
                            labelPosition="append"
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default withTranslation()(OptionsFilter);
