import { FC, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { MdClose, MdFilterList, MdSearch } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { debounce, noop } from 'lodash';
import { SearchType } from '_types';
import { Checkbox } from '_atoms';

export type AutoCompleteResult = {
    title: string;
    value: string;
};

export type SearchInputProps = {
    onSearchInput: (value: string, searchType: SearchType) => void;
    value?: string;
    placeholder?: string;
    className?: string;
    children?: JSX.Element[];
    autocomplete?: (value: string) => Promise<AutoCompleteResult[]>;
    debounceTimeout?: number;
    hideFilters?: boolean;
};

export const SearchInput: FC<SearchInputProps> = ({
    onSearchInput,
    placeholder,
    value = '',
    className = '',
    children = [],
    autocomplete,
    debounceTimeout = 200,
    hideFilters = false,
}: SearchInputProps) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState<string>(value);
    const [searchType, setSearchType] = useState<SearchType>('fuzzy');
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false);
    const [autoCompleteResults, setAutoCompleteResults] = useState<
        AutoCompleteResult[]
    >([]);

    useEffect(() => setQuery(value), [value]);

    const containerRef = useRef<HTMLDivElement>(null);

    function handleClickOutside(event: MouseEvent) {
        /*
         * We use event.composedPath() instead of containerRef.current.contains(event.target) because some child
         * components are injected into the DOM and therefore the event.target is not contained in the containerRef.
         * I do not also think it is worth it to have that bubble up to this component and update containerRef on each
         * node injection.
         */
        if (
            containerRef.current &&
            !event.composedPath().includes(containerRef.current)
        ) {
            setFiltersExpanded(false);
        }
    }

    useEffect(() => {
        if (filtersExpanded) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            if (filtersExpanded) {
                document.removeEventListener('click', handleClickOutside);
            }
        };
    }, [containerRef, filtersExpanded]);

    const debounceInput = useMemo(
        () => debounce(onSearchInput, debounceTimeout),
        [onSearchInput, debounceTimeout],
    );

    const handleQueryChange = (e: FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        filtersExpanded && setFiltersExpanded(false);
        setQuery(value);
        debounceInput(value, searchType);
    };

    const handleSearchTypeChange = (isSelected: boolean) => {
        const value = isSelected ? 'exact' : 'fuzzy';
        filtersExpanded && setFiltersExpanded(false);
        setSearchType(value);
        debounceInput(query, value);
    };

    useEffect(() => {
        autocomplete && autocomplete(value).then(setAutoCompleteResults);
    }, [autocomplete, value]);

    return (
        <div
            className={classnames('flex-1 relative', className)}
            ref={containerRef}
        >
            {!filtersExpanded && (
                <span className="absolute h-full w-8 z-10 p-6">
                    <MdSearch />
                </span>
            )}
            <input
                type="text"
                placeholder={placeholder || t('searchAndFilter')}
                className={classnames(
                    'py-6 w-full border-none focus:ring-0',
                    children.length > 0 ? 'pr-16' : '',
                    !filtersExpanded ? 'pl-16' : 'pl-6',
                )}
                value={query}
                onInput={handleQueryChange}
                onFocus={() =>
                    children.length > 0 && !hideFilters
                        ? setFiltersExpanded(true)
                        : noop()
                }
            />
            <span
                className="absolute h-full w-8 z-10 p-6 right-0 cursor-pointer mr-3"
                onClick={() => setFiltersExpanded(!filtersExpanded)}
            >
                {filtersExpanded ? <MdClose /> : <MdFilterList />}
            </span>
            {filtersExpanded ? (
                <div className="flex flex-col absolute w-full z-20 px-5 bg-neutral-100 border-t-2 border-gray-100 divide-y text-sm shadow shadow-md py-3">
                    {autocomplete && autoCompleteResults.length > 0 && (
                        <div className="flex flex-col">
                            {autoCompleteResults.map((result, index) => (
                                <div
                                    className="py-2 flex items-center gap-2 cursor-pointer hover:bg-gray-100"
                                    key={index}
                                    onClick={() =>
                                        onSearchInput(result.value, searchType)
                                    }
                                >
                                    <span className="w-5">
                                        <MdSearch />
                                    </span>
                                    <span>{result.title}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <Checkbox
                        onChange={handleSearchTypeChange}
                        initialIsSelected={searchType === 'exact'}
                        label={t('exactSearch')}
                        centered={false}
                        className="my-2"
                        labelPosition="append"
                    />
                    {!hideFilters ? children : null}
                </div>
            ) : null}
        </div>
    );
};
