import React from 'react';
import { ArticlePreviewCard } from './components/ArticlePreviewCard';
import { SearchEngineArticle } from '../../../../../services/searchService';

import { SharedArticlePreviewProps } from './types';

interface SearchEngineProps extends SharedArticlePreviewProps {
    article: SearchEngineArticle;
}

export const SearchEngine = (props: SearchEngineProps): React.ReactElement => {
    const { onClick, onSuspicionClick, isActive, article, suspicionsCount } =
        props;

    return (
        <ArticlePreviewCard
            onClick={onClick}
            isActive={isActive}
            title={article.title}
            type="webpage"
            suspicionsCount={suspicionsCount}
            onSuspicionClick={onSuspicionClick}
        >
            <div className="text-sm line-clamp-3">{article.description}</div>
        </ArticlePreviewCard>
    );
};
