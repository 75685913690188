import React from 'react';
import classNames from 'classnames';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

interface OverlayWithSpinnerProps {
    showOverlay: boolean;
    children: React.ReactNode;
    className?: string;
    hideLoader?: boolean;
}

export const OverlayWithSpinner = ({
    showOverlay,
    children,
    className,
    hideLoader = false,
}: OverlayWithSpinnerProps): React.ReactElement => (
    <div className={classNames('relative', className)}>
        <div
            className={classNames(
                'flex justify-center items-center w-full h-full absolute transition-opacity opacity-0 pointer-events-none duration-500 z-10',
                showOverlay && 'opacity-100 pointer-events-auto',
                !className?.includes('bg-') && 'bg-neutral-200/70',
            )}
        >
            {!hideLoader && <LoadingSpinner />}
        </div>
        {children}
    </div>
);
