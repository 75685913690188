import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, Tag } from '_atoms';
import { useSuspicionsReportEntityQuery } from '_queries';
import {
    CompanyCard,
    PictureCard,
    PressCard,
    SearchResultCard,
    SocialMediaCard,
} from '_organisms';
import { SuspicionItemEntityType, SuspicionItemStatus } from '_enums';
import { useParams } from 'react-router-dom';
import { CaseData, Target } from '../../../services/dataService';
import { Pagination } from '_molecules';
import { EntitySuspicion } from '_types';
import {
    IndicatorsWithTooltip,
    IndicatorTooltip,
} from '../../../components/_atoms/Highlight/types';

const PAGE_SIZE = 10;

type ReportCardProps = {
    reportId: string;
    filters?: ReportFilters;
    accessToken?: string;
    className?: string;
    targetData?: Target;
    caseData?: CaseData;
    minConfidenceScore?: number;
    setPage: (page: number) => void;
};

type ReportFilters = {
    suspicionItemId?: string;
    score?: string;
    entityType?: SuspicionItemEntityType;
    status?: SuspicionItemStatus;
    page?: number;
};

export const buildIndicators = (
    suspicionItems: EntitySuspicion[],
): IndicatorsWithTooltip[] => {
    const indicators: IndicatorTooltip = new Map();
    suspicionItems.forEach((s) =>
        s.indicators.forEach((i) => {
            if (!i.indicator.value) {
                return;
            }

            const tooltips = indicators.get(i.indicator.value) ?? [];
            tooltips.push({
                suspicionLabel: s.suspicion.label,
                source: s.sources.join('/'),
            });

            indicators.set(i.indicator.value, tooltips);
        }),
    );
    return Array.from(indicators.entries()).map(([highlightText, tooltip]) => ({
        highlightText,
        tooltipForText: Array.from(new Set(tooltip)),
    }));
};

export const ReportCardByEntity: FC<ReportCardProps> = ({
    filters,
    accessToken = '',
    targetData,
    caseData,
    reportId,
    minConfidenceScore,
    setPage,
}) => {
    const { t } = useTranslation();
    const { targetId } = useParams<{ caseId: string; targetId: string }>();

    const {
        data,
        isError,
        isLoading: isLoading,
    } = useSuspicionsReportEntityQuery(targetId, reportId, {
        ...filters,
        page: filters?.page || 1,
        pageSize: PAGE_SIZE,
        minConfidenceScore,
    });

    useEffect(() => {
        if (
            data?.paging?.pagesCount &&
            data?.paging?.currentPage &&
            data.paging.pagesCount < data.paging.currentPage
        ) {
            setPage(data.paging.pagesCount);
        }
    }, [data?.paging, setPage]);

    const entities = data?.entities;

    return (
        <div className="flex flex-col gap-4 pb-0 pt-4">
            {isLoading ? (
                <LoadingSpinner
                    message={<span className="invisible">...</span>}
                />
            ) : isError ? (
                <div>Error</div>
            ) : null}

            {!entities?.length && !isLoading && (
                <Tag className="self-center">{t('noData')}</Tag>
            )}

            {entities && entities.length > 0 && (
                <>
                    {entities?.map((entity) => {
                        switch (entity.value.type) {
                            case 'press':
                                return (
                                    <PressCard
                                        data={entity.value}
                                        key={entity.id}
                                        targetData={targetData}
                                        caseData={caseData}
                                        suspicionItems={entity.suspicionItems}
                                        indicators={buildIndicators(
                                            entity.suspicionItems ?? [],
                                        )}
                                    />
                                );
                            case 'SocialAccountConnection':
                            case 'SocialAccountPost':
                                return (
                                    <SocialMediaCard
                                        data={entity.value}
                                        key={entity.id}
                                        accessToken={accessToken}
                                        targetData={targetData}
                                        caseData={caseData}
                                        suspicionItems={entity.suspicionItems}
                                        indicators={buildIndicators(
                                            entity.suspicionItems ?? [],
                                        )}
                                    />
                                );
                            case 'Image':
                                return (
                                    <PictureCard
                                        data={entity.value}
                                        key={entity.id}
                                        targetData={targetData}
                                        caseData={caseData}
                                        suspicionItems={entity.suspicionItems}
                                        indicators={buildIndicators(
                                            entity.suspicionItems ?? [],
                                        )}
                                    />
                                );
                            case 'WebPage':
                                return (
                                    <SearchResultCard
                                        data={entity.value}
                                        key={entity.id}
                                        targetData={targetData}
                                        caseData={caseData}
                                        suspicionItems={entity.suspicionItems}
                                        indicators={buildIndicators(
                                            entity.suspicionItems ?? [],
                                        )}
                                    />
                                );
                            case 'TargetCompany':
                                return (
                                    <CompanyCard
                                        data={entity.value}
                                        key={entity.id}
                                        targetData={targetData}
                                        caseData={caseData}
                                        suspicionItems={entity.suspicionItems}
                                    />
                                );
                        }
                    })}

                    {data?.paging?.pagesCount && data?.paging?.pagesCount > 1 && (
                        <div className="px-5 print:hidden">
                            <Pagination
                                paging={data.paging}
                                setPageNumber={setPage}
                            />
                        </div>
                    )}
                    <div className="hidden print:block">
                        {t('printHiddenElements')}
                    </div>
                </>
            )}
        </div>
    );
};
