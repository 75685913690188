import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image, Paragraph } from '_atoms';
import { Alert, WorkflowError } from '_molecules';
import { SocialMediaContacts, SocialMediaPosts } from '_organisms';
import { ResultsProps } from '../Results';
import ShadowDragonLogo from '../../../images/shadow-dragon-logo.png';
import { useSocialMediaErrors } from './useSocialMediaErrors';
import { SocialMediaErrors } from './SocialMediaErrors';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

export const SocialMedia: FC<ResultsProps> = ({
    accessToken,
    targetData,
    caseData,
    entityIndicators,
}: ResultsProps) => {
    const { t } = useTranslation();
    const [selectedData, setSelectedData] =
        useState<'posts' | 'connections'>('posts');
    const socialMediaErrors = useSocialMediaErrors();

    return (
        <DefaultPageLayout title={t('rawData.socialMedia')}>
            <div className="flex gap-4">
                <div className="flex-grow">
                    {targetData?.workflowErrors?.length ? (
                        <WorkflowError
                            className="my-8"
                            errors={targetData?.workflowErrors}
                            path="socialmedia"
                        />
                    ) : null}
                    {targetData?.status === 'HasInitialProfile' && (
                        <Alert
                            alertType="warning"
                            headline={t('profileDataNotFinal')}
                            className="my-8"
                        />
                    )}
                    {Boolean(socialMediaErrors.size) && (
                        <SocialMediaErrors errors={socialMediaErrors} />
                    )}
                    <div className="flex-grow h-13 flex justify-begin space-x-3 my-4">
                        <Button
                            disabled={selectedData === 'posts'}
                            className="py-4 uppercase"
                            onClick={() => setSelectedData('posts')}
                        >
                            {t('socialMedia.posts')}
                        </Button>

                        <Button
                            disabled={selectedData === 'connections'}
                            className="py-4 uppercase"
                            onClick={() => setSelectedData('connections')}
                        >
                            {t('socialMedia.friendsAndInteractants')}
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col justify-center gap-1 text-center mr-1 basis-28 self-center">
                    <Paragraph size="small" color="default">
                        {t('socialMedia.attribution')}
                    </Paragraph>
                    <Image src={ShadowDragonLogo} alt="shadow dragon logo" />
                </div>
            </div>

            {selectedData === 'connections' ? (
                <SocialMediaContacts targetData={targetData} />
            ) : selectedData === 'posts' ? (
                <SocialMediaPosts
                    accessToken={accessToken}
                    targetData={targetData}
                    caseData={caseData}
                    entityIndicators={entityIndicators}
                />
            ) : null}
        </DefaultPageLayout>
    );
};
