import { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoCompleteResult } from '_molecules';
import { SearchActionTypes, useSearch } from './useSearch';
import { EntityType, useSearchQuery } from './queries/useSearchQuery';
import FiltersFactory from '../factories/filters.factory';

import socialMediaPostsFilters from '../components/_organisms/SocialMedia/SocialMediaPostsFilters.json';
import { SearchType } from '_types';

interface GeneratedSearchInput {
    searchInputProps: {
        value: string;
        onSearchInput: any;
        autocomplete: any;
        onFilterChange: any;
        renderedFilters: any;
    };
    data: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    handlePageChange: (pageNumber: number) => void;
}

interface useElasticSearchProps {
    caseId: string;
    targetId: string;
    entityType?: EntityType;
    searchFilters?: Array<any>;
    staticFilters?: Record<string, Record<string, any>>;
    pagingEnabled?: boolean;
    pageSize?: number;
}

const mockAutocompleteData: AutoCompleteResult[] = [
    // { title: 'Dejan Bozinoski', value: 'a56b33e0-b751-4f49-a13a-47b6841868b5' },
    // { title: 'Vasil Todorovski', value: '8e96f1f1-764f-4062-9f47-bb08587b763f' },
    // { title: 'Target Person', value: '81d65ab7-4887-476b-895f-2940aff68dee' },
];

const initialValues = {
    items: [],
    paging: {
        count: 0,
        pagesCount: 0,
        currentPage: 0,
    },
    count: null,
};

export const useElasticSearch = <T extends unknown, R>({
    caseId,
    targetId,
    entityType,
    searchFilters = socialMediaPostsFilters,
    staticFilters = {},
    pagingEnabled = true,
    pageSize = 12,
}: useElasticSearchProps): GeneratedSearchInput => {
    const filtersFactory = useMemo(() => new FiltersFactory(), []);

    // useSearch holds the state of the entered query, and the "fuzzy" search state
    const [searchState, dispatch] = useSearch<T>();
    const { page, query, filters, exactMatch } = searchState;
    const [dataPayload, setDataPayload] = useState<any>(initialValues);

    const renderedFilters = searchFilters.map((filter) =>
        filtersFactory.create(filter, searchState, dispatch),
    );

    const { data, isLoading, isError, isSuccess } = useSearchQuery<T, R>(
        {
            caseId,
            targetId,
            page,
            query,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            filters: filters ? { ...filters, ...staticFilters } : staticFilters,
            exactMatch,
        },
        entityType,
        pageSize,
    );

    useEffect(() => {
        setDataPayload(initialValues);
    }, [query, filters, exactMatch, pagingEnabled]);

    useEffect(() => {
        if (pagingEnabled) {
            setDataPayload(data);
        } else {
            if (data) {
                setDataPayload((dataPayload: any) => ({
                    paging: data?.paging,
                    items: [
                        ...dataPayload.items,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        ...(data?.items ? data?.items : []),
                    ],
                    count: data?.count,
                }));
            }
        }
    }, [data, pagingEnabled]);

    const handlePageChange = useCallback(
        (pageNumber: number) => {
            dispatch({
                type: SearchActionTypes.SetPage,
                page: pageNumber,
            });
        },
        [dispatch],
    );

    const handleQueryChange = useCallback(
        (query: string, exactMatch: SearchType) => {
            dispatch({
                type: SearchActionTypes.SetQuery,
                query,
                exactMatch: exactMatch === 'exact' ? 1 : 0,
            });
        },
        [dispatch],
    );

    const handleFilterChange = useCallback(
        (filters: T) => {
            dispatch({ type: SearchActionTypes.SetFilter, filters });
        },
        [dispatch],
    );

    const handleInputAutocomplete = useCallback(async (query: string): Promise<
        AutoCompleteResult[]
    > => {
        if (!query.length) {
            return Promise.resolve([]);
        }
        const normalizedQuery = query.toLowerCase().trim();
        const results = mockAutocompleteData.filter(
            (result) =>
                result.title.toLowerCase().indexOf(normalizedQuery) > -1,
        );
        return Promise.resolve(results);
    }, []);

    return {
        searchInputProps: {
            value: query || '',
            onSearchInput: handleQueryChange,
            autocomplete: handleInputAutocomplete,
            onFilterChange: handleFilterChange,
            renderedFilters,
        },
        data: dataPayload,
        isLoading,
        isError,
        isSuccess,
        handlePageChange,
    };
};
