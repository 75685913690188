import { TrellisEdge, TrellisNode } from './payload_types';
import { Entity } from './types';

export type Graph3FlagRaw = [number, [number, number][][]];

export enum GraphV3MetadataType {
    Depth = 0b0010,
    Type = 0b0011,
    Status = 0b0100,
    CoCaFlagCount = 0b0101,
    WoCoFlagCount = 0b0110,
    Centrality = 0b0111,
    Target = 0b1000,
    Unknown = 0b0000,
}

export enum GraphV3RelationType {
    SubsidaryOf = 0b0_00_10,
    ParentOf = 0b0_00_11,
    ShareholderOf = 0b0_01_00,
    SharesHeldBy = 0b0_01_01,
    BeneficialOwnerOf = 0b0_01_10,
    BeneficiallyOwnedBy = 0b0_01_11,
    DirectorOf = 0b0_10_00,
    DirectedBy = 0b0_10_01,
    BoardMemberOf = 0b0_10_10,
    BoardMemberBy = 0b0_10_11,
    AdvisorOf = 0b0_11_00,
    AdvisedBy = 0b0_11_01,
    StaffOf = 0b0_11_10,
    StaffedBy = 0b0_11_11,

    CustomRelation = 0b1_00_00,

    Unknown = 0b0_00_00,
}

export enum GraphV3CompanyStatus {
    Active = 0b0001,
    Inactive = 0b0010,
    Insolvent = 0b0011,
    Liquidation = 0b0100,
    Dissolved = 0b0101,
    Suspended = 0b0110,
    Unknown = 0b0000,
}

export enum GraphV3NodeType {
    Company = 0b001,
    Person = 0b010,
    UserProvided = 0b011,
}

export type GraphV3NodeRaw = [number, number, [number[], number[]]];

export type GraphV3EdgeRaw = [number, number, number, number | null];

export interface GraphV3Raw {
    nodes: GraphV3NodeRaw[];
    edges: GraphV3EdgeRaw[];
    flags: Graph3FlagRaw[];
    chunks: [number, number[]][];
    lowRankChunks: number[];
    text: string[];
}

export type GraphV3MetadataEntry = [
    GraphV3MetadataType,
    string | number | boolean,
];

export type GraphV3Node = {
    id: string;
    name: string;
    meta: Map<GraphV3MetadataType, string | number | boolean>;
};

export type GraphV3Edge = {
    source: string;
    target: string;
    name: string;
    relation: GraphV3RelationType;
};

export type GraphV3 = {
    nodes: GraphV3Node[];
    edges: GraphV3Edge[];
    chunkMap: Map<string, string>;
    lowRankChunks: string[];
    flags: Map<string, Entity['coca_entities']>;
};

const parseMetadataValue = (
    metadataType: GraphV3MetadataType,
    value: string,
): string | number | boolean => {
    switch (metadataType) {
        case GraphV3MetadataType.Depth:
            return parseInt(value, 10);
        case GraphV3MetadataType.Type:
            return parseInt(value, 10);
        case GraphV3MetadataType.Status:
            return parseInt(value, 10);
        case GraphV3MetadataType.CoCaFlagCount:
            return parseInt(value, 10);
        case GraphV3MetadataType.WoCoFlagCount:
            return parseInt(value, 10);
        case GraphV3MetadataType.Centrality:
            return parseInt(value, 10) / 10000;
        case GraphV3MetadataType.Target:
            return value === '1';
        default:
            return value;
    }
};

const deserializeNode = (
    node: GraphV3NodeRaw,
    textArray: string[],
): GraphV3Node => {
    const [idIndex, nameIndex, [md_keys, md_values]] = node;

    const meta = md_keys.map((k, i) => {
        const value = parseMetadataValue(k, textArray[md_values[i]]);

        return [k, value] as GraphV3MetadataEntry;
    });

    return {
        id: textArray[idIndex],
        name: textArray[nameIndex],
        meta: new Map(meta),
    };
};

const deserializeEdge = (
    edge: GraphV3EdgeRaw,
    textArray: string[],
): GraphV3Edge => {
    const [fromIndex, toIndex, relationType, customNameIndex] = edge;

    const name =
        relationType === GraphV3RelationType.CustomRelation
            ? textArray[customNameIndex!]
            : GraphV3RelationType[relationType] ?? GraphV3RelationType.Unknown;

    return {
        source: textArray[fromIndex],
        target: textArray[toIndex],
        name,
        relation: relationType,
    };
};

const deserializeFlags = (entityFlags: Graph3FlagRaw, textArray: string[]) => {
    const [entityId, flags] = entityFlags;

    const deserializedFlags = flags.map((flag) =>
        flag.reduce((acc, [k, v]) => {
            const key = textArray[k];
            const value = textArray[v];

            const match = key.match(/^(\w+)_(\d+)_(.+)$/);
            if (match) {
                const [, prefix, index, suffix] = match;
                if (!acc[prefix]) acc[prefix] = [];
                if (!acc[prefix][parseInt(index)])
                    acc[prefix][parseInt(index)] = {};
                acc[prefix][parseInt(index)][suffix] = value;
            } else {
                acc[key] = value;
            }

            return acc;
        }, {} as Record<string, any>),
    );

    return [entityId, deserializedFlags] as const;
};

export const deserializeGraphV3 = (graphV3: GraphV3Raw): GraphV3 => {
    const deserializedNodes = graphV3.nodes
        .map((node) => deserializeNode(node, graphV3.text))
        .filter(
            (node) =>
                ((node.meta.get(GraphV3MetadataType.Depth) ?? 10) as number) <
                3,
        );

    const deserializedEdges = graphV3.edges
        .map((edge) => {
            return deserializeEdge(edge, graphV3.text);
        })
        .filter((edge) => edge !== null);

    const deserializedFlags =
        graphV3.flags?.map((flags) => deserializeFlags(flags, graphV3.text)) ??
        [];

    const chunkMap = new Map<string, string>();

    for (const [chunk_idx, chunk] of graphV3.chunks ?? []) {
        for (const node_id of chunk) {
            chunkMap.set(graphV3.text[node_id], graphV3.text[chunk_idx]);
        }
    }

    const lowRankChunks = graphV3.lowRankChunks?.map(
        (chunk_idx) => graphV3.text[chunk_idx],
    );

    return {
        nodes: deserializedNodes as any,
        edges: deserializedEdges as any,
        chunkMap,
        lowRankChunks,
        flags: new Map<string, any>(
            deserializedFlags.map(([id, data]) => [graphV3.text[id], data]),
        ),
    };
};

function extractNodeMeta(
    node: GraphV3Node,
): Map<
    typeof GraphV3MetadataType[keyof typeof GraphV3MetadataType],
    string | number
> {
    const meta = new Map<
        typeof GraphV3MetadataType[keyof typeof GraphV3MetadataType],
        string | number
    >();

    for (const [k, v] of node.meta) {
        switch (k) {
            case GraphV3MetadataType.Depth:
                meta.set(GraphV3MetadataType.Depth, +v);
                break;
            case GraphV3MetadataType.Type:
                switch (v) {
                    case GraphV3NodeType.Company:
                        meta.set(GraphV3MetadataType.Type, 'Company');
                        break;
                    case GraphV3NodeType.Person:
                        meta.set(GraphV3MetadataType.Type, 'Person');
                        break;
                    case GraphV3NodeType.UserProvided:
                        meta.set(GraphV3MetadataType.Type, 'UserProvided');
                        break;
                    default:
                        meta.set(GraphV3MetadataType.Type, 'Unknown');
                }
                break;
            case GraphV3MetadataType.Status:
                switch (v) {
                    case GraphV3CompanyStatus.Active:
                        meta.set(GraphV3MetadataType.Status, 'Active');
                        break;
                    case GraphV3CompanyStatus.Inactive:
                        meta.set(GraphV3MetadataType.Status, 'Inactive');
                        break;
                    case GraphV3CompanyStatus.Insolvent:
                        meta.set(GraphV3MetadataType.Status, 'Insolvent');
                        break;
                    case GraphV3CompanyStatus.Liquidation:
                        meta.set(GraphV3MetadataType.Status, 'Liquidation');
                        break;
                    case GraphV3CompanyStatus.Dissolved:
                        meta.set(GraphV3MetadataType.Status, 'Dissolved');
                        break;
                    case GraphV3CompanyStatus.Suspended:
                        meta.set(GraphV3MetadataType.Status, 'Suspended');
                        break;
                    default:
                        meta.set(GraphV3MetadataType.Status, 'Unknown');
                }
                break;
            case GraphV3MetadataType.CoCaFlagCount:
                meta.set(GraphV3MetadataType.CoCaFlagCount, +v);
                break;
            case GraphV3MetadataType.WoCoFlagCount:
                meta.set(GraphV3MetadataType.WoCoFlagCount, +v);
                break;
            case GraphV3MetadataType.Centrality:
                meta.set(GraphV3MetadataType.Centrality, +v);
                break;
            case GraphV3MetadataType.Target:
                meta.set(GraphV3MetadataType.Target, +v);
                break;
            default:
                meta.set(GraphV3MetadataType.Unknown, +v);
        }
    }

    return meta;
}

const MIN_NODE_SIZE = 10; // Minimum size for nodes
const MAX_NODE_SIZE = 75; // Maximum size for nodes
const NODE_SIZE_SCALE = 5; // Scale factor for node size

// using the commented code below as a reference, implement a function that converts a GraphV3 object to a TrellisGraph object
export const graphV3ToTrellisGraph = (
    graphV3: GraphV3,
): {
    nodes: TrellisNode[];
    edges: TrellisEdge[];
} => {
    // Calculate the maximum/minimum number of connections any node has
    let maxConnections = 0;
    let minConnections = 1;

    const nodes = graphV3.nodes.map((node) => {
        const meta = extractNodeMeta(node);

        // find how many connections the node has
        const connectedNodes = new Set(
            graphV3.edges
                .filter(
                    (edge) =>
                        edge.source === node.id || edge.target === node.id,
                )
                .map((edge) =>
                    edge.source === node.id ? edge.target : edge.source,
                ),
        );

        const connectionCount = NODE_SIZE_SCALE * connectedNodes.size;

        if (maxConnections < connectionCount) {
            maxConnections = connectionCount;
        }

        if (minConnections > connectionCount) {
            minConnections = connectionCount;
        }

        const isRoot = meta.get(GraphV3MetadataType.Depth) === 0;

        const size = connectionCount + (isRoot ? 15 : 0);

        const type = meta.get(GraphV3MetadataType.Type) as string;

        const isPerson = type === 'Person';

        return {
            id: node.id,
            label: isPerson ? node.name.replace(/^(Ms |Mr )/, '') : node.name,
            type,
            depth: meta.get(GraphV3MetadataType.Depth) as number,
            radius: 30 + size,
            centrality: meta.get(GraphV3MetadataType.Centrality) as number,
            meta,
            size,
        };
    });

    const edges = graphV3.edges.map((edge) => ({
        id: `${edge.source}-${edge.target}-${edge.name}`,
        source: edge.source,
        target: edge.target,
        label: edge.name,
        original: edge.relation,
    }));

    // Dynamically calculate the maximum size for normalization
    const dynamicMaxSize = Math.min(maxConnections, MAX_NODE_SIZE);

    // Normalize the size between MIN_NODE_SIZE and dynamicMaxSize
    const normalizedNodes = nodes.map((node) => {
        const size =
            MIN_NODE_SIZE +
            (((node.size ?? 0) - minConnections) *
                (dynamicMaxSize - MIN_NODE_SIZE)) /
                (maxConnections - minConnections);

        return {
            ...node,
            radius: 30 + size,
            size,
        };
    });

    return {
        nodes: normalizedNodes,
        edges,
    };
};
