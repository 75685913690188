import React from 'react';
import { GoogleCandidateStatus, TargetGoogleCandidatesGroup } from '_types';
import { TargetGoogleCandidateGroup } from './TargetGoogleCandidateGroup';

type GoogleCandidateProps = TargetGoogleCandidatesGroup & {
    selectedCandidates: Record<string, GoogleCandidateStatus[]>;
    selectCandidates: React.Dispatch<
        React.SetStateAction<Record<string, GoogleCandidateStatus[]>>
    >;
    fingerprintScore?: number;
    unifiedScore?: number;
    hardCriteriaScore?: number;
    disabled?: boolean;
};

export const LegacyUnstructuredCandidate = (
    props: GoogleCandidateProps,
): React.ReactElement => (
    <TargetGoogleCandidateGroup
        key={props.groupId}
        groupId={props.groupId}
        candidates={props.candidates}
        name={props.name}
        organizations={props.organizations}
        groupSelectionState={props.selectedCandidates[props.groupId] || []}
        updateSelectionState={(groupId, state) => {
            props.selectCandidates({
                ...props.selectedCandidates,
                [groupId]: state,
            });
        }}
        score={props.score}
        unifiedScore={props.unifiedScore}
        fingerprintScore={props.fingerprintScore}
        hardCriteriaScore={props.hardCriteriaScore}
        disabled={props.disabled}
    />
);
