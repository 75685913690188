import { useQuery, UseQueryResult } from 'react-query';
import { ErrorResponse } from 'src/types/NestAPI';
import { TargetSelectedCandidates } from '../../services/dataService';
import { targets } from '../../services/nestApiService';
import { CaseType } from '../../features/cases/CaseNew';

export const useTargetSelectedCandidatesQuery = (
    caseId: string,
    targetId: string,
    caseType: CaseType,
): UseQueryResult<TargetSelectedCandidates, ErrorResponse> =>
    useQuery(
        ['cases', caseId, 'target', targetId, 'candidates', 'selection'],
        () => targets.getSelectedCandidates(caseId, targetId, caseType),
        {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
        },
    );
