import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { mapStringToStringSet } from '_utils';

type ContextValue = Readonly<{
    lowConfidenceTargetIds: ReadonlySet<string>;
    setLowConfidenceTargetIds: (set: ReadonlySet<string>) => void;
}>;

export const LocalStorageContext = createContext<ContextValue | null>(null);

const LOW_CONFIDENCE_TARGET_IDS = 'lowConfidenceTargetIds';

export const LocalStorageProvider: FC = (props) => {
    const [localLowConfidenceTargetIds, setLocalLowConfidenceTargetIds] =
        useState<ReadonlySet<string>>(new Set<string>());

    useEffect(() => {
        const value = localStorage.getItem(LOW_CONFIDENCE_TARGET_IDS);

        const set =
            value !== null ? mapStringToStringSet(value) : new Set<string>();

        setLocalLowConfidenceTargetIds(set);
    }, []);

    const setLowConfidenceTargetIds = useCallback(
        (value: ReadonlySet<string>) => {
            try {
                localStorage.setItem(
                    LOW_CONFIDENCE_TARGET_IDS,
                    JSON.stringify(Array.from(value)),
                );
                setLocalLowConfidenceTargetIds(value);
            } catch (error) {
                console.error(error);
            }
        },
        [],
    );

    return (
        <LocalStorageContext.Provider
            value={{
                lowConfidenceTargetIds: localLowConfidenceTargetIds,
                setLowConfidenceTargetIds,
            }}
        >
            {props.children}
        </LocalStorageContext.Provider>
    );
};
