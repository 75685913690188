import { FC, HTMLAttributes } from 'react';
import { SocialMediaImage } from '_molecules';
import { SocialIcon } from 'react-social-icons';

type bubbleSize = 'small' | 'medium';

interface SocialProfilePictureBubbleProps
    extends HTMLAttributes<HTMLDivElement> {
    data: any;
    size?: bubbleSize;
}

const leftForSize: Record<bubbleSize, string> = {
    small: '30px',
    medium: '38px',
};

const heightForSize: Record<bubbleSize, string> = {
    small: '18px',
    medium: '24px',
};

export const SocialProfilePictureBubble: FC<SocialProfilePictureBubbleProps> =
    ({ data, size = 'small', ...props }) => (
        <div {...props} className="relative">
            <SocialMediaImage size={size} src={data.imageUrl} />
            <SocialIcon
                as="div"
                network={data.network == 'twitter' ? 'x' : data.network}
                style={{
                    position: 'absolute',
                    width: heightForSize[size],
                    height: heightForSize[size],
                    bottom: '0',
                    left: leftForSize[size],
                    fontWeight: 'bold',
                    backgroundColor: 'white',
                    borderRadius: '100%',
                }}
            />
        </div>
    );
