import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Paging } from '../../../services/dataService';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
import classnames from 'classnames';

type PaginationProps = {
    paging: Paging;
    setPageNumber: (pageNumber: number) => void;
};

function getPagingRange(current = 1, min = 1, total = 20, length = 7) {
    if (length > total) {
        length = total;
    }

    let start = current - Math.floor(length / 2);
    start = Math.max(start, min);
    start = Math.min(start, min + total - length);

    return Array.from({ length: length }, (_, i) => start + i);
}

export const Pagination: FC<PaginationProps> = ({ paging, setPageNumber }) => {
    const [pageRange, setPageRange] = useState<number[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        const range = getPagingRange(paging.currentPage, 1, paging.pagesCount);
        setPageRange(range);
    }, [paging]);

    return (
        <div className="flex justify-between items-center text-sm">
            <button
                onClick={() => setPageNumber(paging.previousPage ?? 1)}
                disabled={!paging.previousPage}
                className={classnames(
                    'flex items-center bg-transparent border-none focus:outline-none',
                    !paging.previousPage
                        ? 'pointer-events-none text-neutral-500'
                        : '',
                )}
            >
                <HiArrowNarrowLeft />
                <span className="ml-3">{t('pagination.previous')}</span>
            </button>
            <div className="flex gap-3">
                {pageRange.map((pageNumber) => (
                    <button
                        key={pageNumber}
                        onClick={() =>
                            pageNumber !== paging.currentPage
                                ? setPageNumber(pageNumber)
                                : null
                        }
                        className={classnames(
                            'flex items-center bg-transparent border-none focus:outline-none',
                            pageNumber === paging.currentPage
                                ? 'text-blue-500'
                                : '',
                        )}
                    >
                        {pageNumber}
                    </button>
                ))}
            </div>
            <button
                onClick={() =>
                    setPageNumber(paging.nextPage ?? paging.pagesCount)
                }
                disabled={!paging.nextPage}
                className={classnames(
                    'flex items-center bg-transparent border-none focus:outline-none',
                    !paging.nextPage
                        ? 'pointer-events-none text-neutral-500'
                        : '',
                )}
            >
                <span className="mr-3">{t('pagination.next')}</span>
                <HiArrowNarrowRight />
            </button>
        </div>
    );
};
