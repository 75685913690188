import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface WizardFormCheckboxProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label?: string;
    hintMsg?: string;
    errorMsg?: string;
}

export const WizardFormCheckbox = forwardRef<
    HTMLInputElement,
    WizardFormCheckboxProps
>((props, ref) => {
    const { t } = useTranslation();
    const { label, errorMsg, required, children, hintMsg, ...rest } = props;

    const checkbox = (
        <div
            className={classnames(
                'relative rounded transition-all border-neutral-400 focus:border-primary-4 hover:border-primary-4 border-2',
                rest.disabled
                    ? 'bg-neutral-400'
                    : `cursor-pointer bg-neutral-50 hover:border-primary-4 group-hover:border-primary-4 hover:focus-within:border-primary-4 transition-colors`,
                `w-6 h-6 flex flex-shrink-0 justify-center items-center`,
                rest.value
                    ? `border-primary-4 focus-within:border-primary-4`
                    : 'border-neutral-400 focus-within:border-neutral-400',
            )}
        >
            <input
                type="checkbox"
                className="absolute cursor-pointer opacity-0 disabled:pointer-events-none disabled:cursor-default "
                checked={!!rest.value}
                ref={ref}
                {...rest}
            />
            <svg
                className={`fill-current hidden p-1 w-full h-full text-primary-4 pointer-events-none rounded`}
                viewBox="0 0 20 20"
            >
                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
            </svg>
        </div>
    );

    return (
        <div className="w-full max-w-flow-text-base mx-auto relative">
            {label ? (
                <label className="group">
                    <span className="flex-row items-center text-sm font-bold font-jost text-neutral-500">
                        {t(label)}
                        {required && <span className="text-error-1"> *</span>}
                    </span>
                    {checkbox}
                </label>
            ) : (
                checkbox
            )}
            {hintMsg && (
                <span className="text-neutral-500 block text-sm">
                    {t(hintMsg)}
                </span>
            )}
            {errorMsg && (
                <div className="text-error-1 text-sm">{t(errorMsg)}</div>
            )}
            {children}
        </div>
    );
});
WizardFormCheckbox.displayName = 'WizardFormCheckbox';
