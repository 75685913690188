import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { RegisterDataArticle } from '../../../services/searchService';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import {
    Alert,
    InfiniteScroller,
    Pagination,
    SearchInput,
    WorkflowError,
} from '_molecules';
import { CompanyCard } from '_organisms';
import { useElasticSearch } from '../../../hooks/useElasticSearch';
import { SearchFilterValue } from '_types';

import { companyFilters } from './filters';
import { nonProdDataTestId } from '_utils';
import { RoleType } from './RoleType';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

const initialValues = {
    items: [],
};

const pagingEnabled = false;

type CompanyFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

export type RegisterdatenProps = ResultsProps & { accessToken: string };

export const Registerdaten: FC<RegisterdatenProps> = ({
    targetData,
    caseData,
}) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const relevanceFilter = useMemo(
        () => ({
            type: 'Relevance',
            title: t('commercialRegister.filterTitle'),
            field: 'companyTargetRoles.roleType.keyword',
            options: [
                {
                    label: t('commercialRegister.relevantCheckbox'),
                    value: [RoleType.Manager, RoleType.Shareholder],
                    tooltipContent: t('commercialRegister.relevantTooltip'),
                },
            ],
        }),
        [t],
    );

    const {
        searchInputProps: { renderedFilters, ...searchInputProps },
        data: { items, paging } = initialValues,
        isLoading,
        isError,
        handlePageChange,
    } = useElasticSearch<CompanyFilters, RegisterDataArticle[]>({
        caseId,
        targetId,
        entityType: 'companies',
        searchFilters: [...companyFilters, relevanceFilter],
        pagingEnabled: pagingEnabled,
    });

    return (
        <DefaultPageLayout title={t('rawData.registerData')}>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
            <WorkflowError errors={targetData?.workflowErrors} path="profile" />
            {targetData?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}
            <div
                className="relative mt-6 z-10 flex flex-col gap-4"
                data-testid={nonProdDataTestId('registerdaten')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {items.length ? (
                    <Fragment>
                        {items.map((item: RegisterDataArticle) => (
                            <CompanyCard
                                key={item.id}
                                data={item}
                                hideArticleType={true}
                                query={searchInputProps.value}
                                targetData={targetData}
                                caseData={caseData}
                                initiallyExpanded={true}
                            />
                        ))}
                        {paging?.pagesCount > 1 && pagingEnabled ? (
                            <div className="px-5">
                                <Pagination
                                    paging={paging}
                                    setPageNumber={(page) =>
                                        handlePageChange(page)
                                    }
                                />
                            </div>
                        ) : (
                            <InfiniteScroller
                                paging={paging}
                                isLoading={isLoading}
                                setPageNumber={(page) => handlePageChange(page)}
                            />
                        )}
                    </Fragment>
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
