import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { noop, truncate } from 'lodash';
import { Error, LoadingSpinner } from '_atoms';
import {
    Alert,
    GraphLink,
    GraphNode,
    NetworkGraph,
    WorkflowError,
} from '_molecules';
import { useTargetResultsQuery } from '../../../hooks/queries/useTargetResultsQuery';
import { ResultsProps } from '../Results';
import { NodeEntry } from '@indicium/common';
import { useParams } from 'react-router-dom';
import { RedFlagItem } from 'src/types';
import { useNetworkS3Query } from '../../../hooks/queries/useNetworkQuery';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

export const transformEdges = (
    edges: GraphLink[],
    defaultLabel: string,
): GraphLink[] =>
    edges.map(({ label, ...edge }) => {
        const roles = [...new Set(label.split(','))]
            .filter((role) => role !== 'unknown role')
            .join(', ');

        if (roles.length === 0) {
            return {
                ...edge,
                label: defaultLabel,
            };
        }

        return {
            ...edge,
            label: truncate(roles, { length: 30, omission: '...' }),
            title: roles.length >= 28 ? roles : undefined,
        };
    });

const isNodeRedFlagged = (
    node: NodeEntry,
    redFlagIds: string[] | undefined,
): boolean =>
    (['company', 'target', 'person', 'family'].includes(node.type) &&
        redFlagIds?.includes(node.id)) ||
    false;

export const transformNodes = (
    nodes: NodeEntry[],
    redFlags: RedFlagItem[],
): GraphNode[] => {
    const redFlagIds: string[] = redFlags
        .filter((flag) => !!flag.apiId)
        .map((flag) => flag.apiId);

    return nodes
        .filter(({ id }) => Boolean(id))
        .map((node) => ({
            ...node,
            type: node.lvl === 0 ? 'target' : node.type,
        }))
        .map(({ type, ...node }) => ({
            ...node,
            group: isNodeRedFlagged({ ...node, type }, redFlagIds)
                ? `${type}Flagged`
                : type,
            type,
        }));
};

export const NetworkGraphSection: FC<
    ResultsProps & {
        onFinishLoading?: () => void;
    }
> = ({
    targetData,
    targetLoading,
    targetError,
    redFlags,
    onFinishLoading = noop,
}) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const targetResults = useTargetResultsQuery(caseId, targetId);
    const networkResult = useNetworkS3Query(
        caseId,
        targetId,
        targetResults?.data?.networkUrl,
        onFinishLoading,
    );

    const data = networkResult?.data;
    const isLoading =
        targetResults?.isLoading || networkResult?.isLoading || targetLoading;
    const hasError = targetError || networkResult.isError || targetError;

    const transformedData = useMemo(() => {
        if (data) {
            // Make sure the renderer only gets a maximum of 5000 nodes to handle
            const nodes = (data.nodes || [])
                .sort(({ lvl: aLvl }, { lvl: bLvl }) => aLvl - bLvl)
                .slice(0, 5000);
            // Make sure the renderer only get edges for which both ends still have a node after reducing them to a maximum of 5000
            const edges = (data.edges || []).filter(
                ({ from, to }) =>
                    nodes.some(({ id }) => id === from) &&
                    nodes.some(({ id }) => id === to),
            );
            return {
                edges: transformEdges(edges, t('networkGraph.unknownRole')),
                nodes: transformNodes(nodes, redFlags),
            };
        }
        return undefined;
    }, [data, t, redFlags]);

    const hasNodes = transformedData?.nodes.length;

    return (
        <DefaultPageLayout
            title={t('networkGraph.title')}
            className="flex flex-col"
        >
            <WorkflowError errors={targetData?.workflowErrors} path="network" />

            {isLoading && <LoadingSpinner message={t('profileLoading')} />}
            {!isLoading && hasError && (
                <Error
                    headline={t(
                        targetData?.status === 'HasInitialProfile'
                            ? 'profileErrorRetryHeadline'
                            : 'profileErrorHeadline',
                    )}
                    message={t(
                        targetData?.status === 'HasInitialProfile'
                            ? 'profileErrorRetryMessage'
                            : 'profileErrorMessage',
                    )}
                />
            )}
            {!!(!isLoading && !hasError && transformedData && targetData) && (
                <>
                    {targetData.status === 'HasInitialProfile' && (
                        <Alert
                            alertType="warning"
                            headline={t('profileDataNotFinal')}
                            className="mb-2"
                        />
                    )}
                    {hasNodes ? (
                        <NetworkGraph data={transformedData} />
                    ) : (
                        <Alert
                            className="items-center"
                            alertType="info"
                            message={t('noData')}
                        />
                    )}
                </>
            )}
        </DefaultPageLayout>
    );
};
