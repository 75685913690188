import React, { FC } from 'react';
import { ResultsProps } from '../Results';
import { ProfileInfo } from '../RiskAnalysisV2/ProfileInfo';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '_atoms';

export const CVAnalysis: FC<ResultsProps> = ({ targetData, targetImages }) => {
    const { t } = useTranslation();

    const targetFullName = [
        targetData?.firstname,
        targetData?.middlename,
        targetData?.lastname,
    ]
        .filter(Boolean)
        .join(' ');

    return (
        <DefaultPageLayout
            title={t('cVAnalysis.title')}
            headerActions={
                <ProfileInfo
                    name={targetFullName}
                    createdAt={targetData?.profile?.createdAt}
                    imageUrl={targetImages[0]}
                />
            }
        >
            {/*TODO: to be removed when there is other components to display*/}
            <div>
                <Paragraph color={'light'} className={'center'}>
                    {'We are working on statistics and the timeline'}
                </Paragraph>
                <img
                    src="https://i.pinimg.com/originals/2d/31/31/2d3131e5df7138931329c44e12485d5b.gif"
                    alt="Animated GIF"
                />
            </div>
        </DefaultPageLayout>
    );
};
