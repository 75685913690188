import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Error, LoadingSpinner } from '_atoms';
import { Alert, InfiniteScrollV2, SearchInput } from '_molecules';
import { ResultsProps } from '../../../features/dashboard/Results';
import type { SocialAccountPostArticle } from '../../../services/searchService';
import { SearchFilterValue } from '_types';
import { useElasticSearch } from '../../../hooks/useElasticSearch';
import socialMediaPostsFilters from './SocialMediaPostsFilters.json';
import { nonProdDataTestId } from '_utils';
import { SocialMediaSmallCard } from '_organisms/SocialMedia/SocialMediaSmallCard';
import { EntityIndicator } from '../../../hooks/queries/useTargetIndicatorsQuery';

const initialValues = {
    items: [],
};

const pagingEnabled = false;

type SocialMediaPostsFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

type SocialMediaPostsProps = Pick<
    ResultsProps,
    'accessToken' | 'targetData' | 'caseData' | 'entityIndicators'
>;

export const SocialMediaPosts: FC<SocialMediaPostsProps> = ({
    targetData,
    caseData,
    entityIndicators,
}) => {
    const { t } = useTranslation();

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const {
        searchInputProps: { renderedFilters, ...searchInputProps },
        data: { items, paging } = initialValues,
        isLoading,
        isError,
        handlePageChange,
    } = useElasticSearch<SocialMediaPostsFilters, SocialAccountPostArticle[]>({
        caseId,
        targetId,
        entityType: 'socialMediaPost',
        searchFilters: socialMediaPostsFilters,
        pagingEnabled: pagingEnabled,
    });

    return (
        <Fragment>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>

            <div
                className="relative mt-5 z-10"
                data-testid={nonProdDataTestId('socialmediaposts')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {items?.length ? (
                    <div>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 items-stretch my-3 w-full">
                            {items.map((item: SocialAccountPostArticle) => (
                                <SocialMediaSmallCard
                                    key={item.id}
                                    data={item}
                                    query={searchInputProps.value}
                                    hideArticleType={true}
                                    caseData={caseData}
                                    targetData={targetData}
                                    indicators={entityIndicators
                                        .filter(
                                            (e: EntityIndicator) =>
                                                e.entityId === item.id,
                                        )
                                        .flatMap(
                                            ({ indicators }) => indicators,
                                        )}
                                />
                            ))}
                        </div>
                        <InfiniteScrollV2
                            paging={paging}
                            isLoading={isLoading}
                            setPageNumber={handlePageChange}
                        />
                    </div>
                ) : isLoading ? null : (
                    <Alert alertType="info" message={t('noData')} />
                )}
            </div>
        </Fragment>
    );
};
