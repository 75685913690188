import { Dispatch, useCallback, useEffect, useReducer, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export enum SearchActionTypes {
    SetQuery = 'SET_QUERY',
    SetPage = 'SET_PAGE',
    SetFilter = 'SET_FILTER',
    RemoveFilter = 'REMOVE_FILTER',
}

export type SearchState<T> = {
    query?: string;
    page?: number;
    filters?: T;
    exactMatch?: number;
};

export type SearchAction<T> = {
    type: SearchActionTypes;
    query?: string;
    page?: number;
    filters?: T;
    exactMatch?: number;
};

const initialState = {
    query: '',
    page: 1,
    filters: undefined,
    exactMatch: 0,
};

const createSearchReducer =
    <T extends unknown>() =>
    (state: SearchState<T>, action: SearchAction<T>): SearchState<T> => {
        switch (action.type) {
            case SearchActionTypes.SetQuery:
                return {
                    ...state,
                    query: action.query || '',
                    exactMatch: action.exactMatch || 0,
                    page: 1,
                };
            case SearchActionTypes.SetPage:
                return { ...state, page: action.page || 1 };
            case SearchActionTypes.SetFilter:
                return { ...state, filters: action.filters, page: 1 };
            default:
                return state;
        }
    };

export const useSearch = <T extends unknown>(): [
    SearchState<T>,
    Dispatch<SearchAction<T>>,
] => {
    return useReducer(createSearchReducer<T>(), initialState);
};

export function useExtractQueryParams(
    queryKeyName = 'query',
    searchState: { query: string },
): () => Record<string, unknown> {
    const history = useHistory();
    const previousQueryParams = useRef<string>('');

    return useCallback(() => {
        const { query } = searchState;

        const params: Record<string, unknown> = {
            ...(query ? { [queryKeyName]: query } : {}),
        };

        const queryString = new URLSearchParams(
            params as Record<string, string>,
        ).toString();
        if (queryString !== previousQueryParams.current) {
            const newUrl = queryString
                ? `?${queryString}`
                : window.location.pathname;
            history.push(newUrl);
            previousQueryParams.current = queryString;
        }

        return params;
    }, [history, queryKeyName, searchState]);
}

export function useUpdateSearchState(
    queryKeyName = 'query',
    setSearchState: (searchState: { query: string }) => void,
): void {
    const location = useLocation();
    useEffect(() => {
        const searchState: { query: string } = { query: '' };

        searchState.query = getQueryParam(queryKeyName, location.search);

        setSearchState(searchState);
    }, [queryKeyName, setSearchState, location.search]);
}

export function getQueryParam(queryKeyName = 'query', search: string): string {
    const searchParams = new URLSearchParams(search);

    for (const key of searchParams.keys()) {
        if (key == queryKeyName && searchParams.get(key)) {
            return searchParams.get(key) as string;
        }
    }
    return '';
}

export function replaceHistoryState(newQuery: string): void {
    const searchParams = new URLSearchParams(window.location.search);
    const changes = JSON.parse(newQuery);

    for (const key in changes) {
        const value = changes[key];

        if (!value) {
            searchParams.delete(key);
        } else if (typeof value === 'object') {
            searchParams.delete(key);
            searchParams.set(key, JSON.stringify(value));
        } else {
            searchParams.set(key, value);
        }
    }

    const newParams = searchParams.toString();

    window.history.replaceState(
        {},
        '',
        newParams ? `?${newParams}` : window.location.pathname,
    );
}
