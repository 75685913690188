import React from 'react';
import { Button } from '_atoms';

interface DynamicFormButtonProps {
    children: React.ReactNode;
    isDisabled: boolean;
    onClick: () => void;
    level?: 'error';
}

export const DynamicFormButton = ({
    children,
    isDisabled,
    onClick,
    level,
}: DynamicFormButtonProps): React.ReactElement => {
    return (
        <Button
            className="w-full"
            type="button"
            disabled={isDisabled}
            level={level}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};
