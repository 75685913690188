import { useQuery, UseQueryResult } from 'react-query';
import { ErrorResponse } from 'src/types/NestAPI';
import { targets } from '../../services/nestApiService';
import { IndicatorsWithTooltip } from '../../components/_atoms/Highlight/types';

export type EntityIndicator = {
    entityId: string;
    indicators: IndicatorsWithTooltip[];
};

export const useTargetIndicatorsQuery = (
    targetId: string,
    entityId?: string,
): UseQueryResult<EntityIndicator[], ErrorResponse> =>
    useQuery(
        ['target', targetId, 'indicators', 'entityId', entityId],
        () => targets.getIndicators(targetId, entityId).then((r) => r.data),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );
