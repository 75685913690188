import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { Customer, UserInputForUpdateCustomer } from '@indicium/common';
import { customers } from '../../services/nestApiService';
import { ErrorResponse } from 'src/types/NestAPI';

export type UpdateCustomerMutationPayload = {
    name: string;
    limitOfReports: number;
    expiresAt: string | null;
};

export const useUpdateCustomerMutation = (): UseMutationResult<
    Customer,
    ErrorResponse,
    [string, UserInputForUpdateCustomer]
> => {
    const queryClient = useQueryClient();

    return useMutation(
        ([customerId, payload]) =>
            customers.update(customerId, payload).then((r) => r.data),
        {
            onSuccess: () =>
                queryClient.invalidateQueries({
                    predicate: (query) => {
                        return query.queryKey.includes('customers');
                    },
                }),
        },
    );
};
