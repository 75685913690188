import { Network } from '@indicium/common';
import { useQuery, UseQueryResult } from 'react-query';

const fetchNetworkFromS3 = async (url?: string): Promise<Network | null> => {
    if (!url) {
        return null;
    }
    const response = await fetch(url);
    return response.json();
};

export const useNetworkS3Query = (
    caseId: string,
    targetId: string,
    url?: string,
    onSuccess?: () => void,
): UseQueryResult<Network> =>
    useQuery(
        ['target.network.s3', caseId, targetId],
        () => fetchNetworkFromS3(url),
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: !!url,
            onSuccess,
        },
    );
