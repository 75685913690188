import React from 'react';
import { LinkedEntity } from '../types';
import { Headline, Paragraph, StyledLink } from '_atoms';
import { CollapsibleCard } from '_molecules';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

export const LinkedEntityListing = ({
    label,
    linkedEntities,
    onEntityClick,
}: {
    label: string;
    linkedEntities: Array<LinkedEntity>;
    onEntityClick?: (id: string) => void;
    formatDate: (dateString: string) => string;
}): JSX.Element | null => {
    const { t } = useTranslation();

    if (linkedEntities.length === 0) {
        return null;
    }

    const keyIndices = new Map<string, number>(
        linkedEntities.map(([id, _]) => [id, 0]),
    );

    const getKey = (id: string) => {
        const index = keyIndices.get(id);

        if (!index) {
            keyIndices.set(id, 1);
            return id;
        }

        keyIndices.set(id, index + 1);

        return `${id}-${index}`;
    };

    return (
        <div className="space-y-2">
            <Paragraph size="label" weight="bold" color="light">
                {label}
            </Paragraph>
            {linkedEntities.map(
                ([id, { name, items, flags, markInactive }]) => (
                    <CollapsibleCard
                        key={getKey(id)}
                        className={classnames(
                            'border-2 border-neutral-300',
                            markInactive ? 'bg-neutral-200' : '',
                        )}
                        headerColor={classnames(
                            markInactive ? 'bg-neutral-200' : '',
                            'p-4',
                        )}
                        borderColor="border-neutral-300"
                        bodyClasses="p-4 pt-0"
                        header={
                            <>
                                <Headline
                                    Level="h6"
                                    color={markInactive ? 'medium' : 'default'}
                                >
                                    {name}
                                </Headline>
                                {flags && (
                                    <div className="flex justify-start items-center gap-0.5 pt-1">
                                        {flags.map((flag, i) => (
                                            <Paragraph
                                                key={i}
                                                size="xSmall"
                                                color={
                                                    markInactive
                                                        ? 'light'
                                                        : 'mediumDark'
                                                }
                                                lineHeight="none"
                                                weight="bold"
                                            >
                                                {flag.displayText}
                                            </Paragraph>
                                        ))}
                                    </div>
                                )}
                            </>
                        }
                        initiallyExpanded={true}
                    >
                        {items
                            .filter((item) => item)
                            .map((item) => (
                                <div key={getKey(item.id)}>
                                    <Paragraph
                                        size="small"
                                        color={
                                            markInactive
                                                ? 'light'
                                                : 'mediumDark'
                                        }
                                    >
                                        {item.title}
                                    </Paragraph>
                                    {item.details
                                        .filter((detail) => detail)
                                        .map((detail, index) => (
                                            <p
                                                key={index}
                                                style={{
                                                    fontSize: '11px',
                                                    color: '#6c757d',
                                                    margin: '0',
                                                }}
                                            >
                                                {detail.label}: {detail.value}
                                            </p>
                                        ))}
                                </div>
                            ))}
                        {onEntityClick && (
                            <StyledLink
                                onClick={() => onEntityClick(id)}
                                className="text-sm font-jost font-bold"
                            >
                                {t('networkGraph.showInGraph')}
                            </StyledLink>
                        )}
                    </CollapsibleCard>
                ),
            )}
        </div>
    );
};
