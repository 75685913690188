import React, { useContext, useMemo } from 'react';
import { useMediaQuery } from '@react-hook/media-query';

interface MenuContext {
    collapsed: boolean;
    toggleCollapsed: () => void;
    setCollapsed: (collapsed: boolean) => void;
}

const menuContext = React.createContext<MenuContext>({
    collapsed: false,
    toggleCollapsed: () => {
        /* do nothing */
    },
    setCollapsed: () => {
        /* do nothing */
    },
});

const useSafeMediaQuery = (query: string) => {
    try {
        return useMediaQuery(query);
    } catch {
        return false;
    }
};

export const MenuProvider: React.FC = ({ children }) => {
    const [_collapsed, setCollapsed] = React.useState(false);
    const isPrint = useSafeMediaQuery('print');
    const collapsed = useMemo(
        () => (isPrint ? true : _collapsed),
        [_collapsed, isPrint],
    );

    const toggleCollapsed = () => setCollapsed((prev) => !prev);

    return (
        <menuContext.Provider
            value={{ collapsed, toggleCollapsed, setCollapsed }}
        >
            {children}
        </menuContext.Provider>
    );
};

export function useMenuContext(): MenuContext {
    return useContext(menuContext);
}
