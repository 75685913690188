import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import config from '../../backendConfig.json';
import { UserData } from 'src/types/UserData';

type CreateUserProps = {
    data: UserData;
    customerIdOverride?: string;
};

export const createUser = async ({
    data,
    customerIdOverride,
}: CreateUserProps): Promise<UserData> => {
    if (customerIdOverride) {
        return (
            await axios.post(
                `https://${config.backendBaseUrl}/customers/${customerIdOverride}/users`,
                data,
            )
        ).data as UserData;
    }

    return (await axios.post(`https://${config.backendBaseUrl}/users`, data))
        .data as UserData;
};

export const useCreateUserMutation = (): UseMutationResult<
    UserData,
    string,
    CreateUserProps
> => {
    const queryClient = useQueryClient();

    return useMutation(createUser, {
        onSuccess: () =>
            ['users', 'customerUsage'].forEach((key) =>
                queryClient.invalidateQueries(key),
            ),
    });
};
