import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { UserData } from 'src/types/UserData';

export const useDeleteTargetMutation = (): UseMutationResult<
    UserData,
    unknown,
    Record<string, string>
> => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ caseId, targetId }) =>
            axios.delete(
                `https://${backendBaseUrl}/cases/${caseId}/targets/${targetId}`,
            ),
        {
            onSuccess: () => queryClient.invalidateQueries('targets'),
        },
    );
};
