import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Highlight, Paragraph, SourceInfo, Tag } from '_atoms';
import { TargetReportSourceType } from '@indicium/common';
import { HighlightChunks } from '../../_atoms/Highlight/types';
import { hasFlag } from 'country-flag-icons';
import { getCode, getName } from 'country-list';
import Flags from 'country-flag-icons/react/1x1';
import { BirthdateRange, transformBirthdayRangeToString } from '_utils';
import { TiDocumentText } from 'react-icons/ti';
import { TbExternalLink } from 'react-icons/tb';

export type ContentType = {
    value: string;
    sources?: TargetReportSourceType[];
    additionalValue?: string;
    title?: string; // as in HTML hover title
};

const NationalityInfoBlockValues: FC<InfoBlockProps> = ({ content }) => (
    <>
        {content.map(({ value: originalValue, sources }, index) => {
            // special corner case (see https://en.wikipedia.org/wiki/ISO_3166-1#Coding for more info)
            const value =
                originalValue === 'United Kingdom' ? 'GB' : originalValue;
            const isoCode = hasFlag(value) ? value : getCode(value);
            const Flag = isoCode && (Flags as any)[isoCode];
            const country = isoCode ? getName(isoCode) : value;

            return (
                <li key={index} className="relative group list-none">
                    <Paragraph
                        weight="normal"
                        lineHeight="default"
                        color="dark"
                        title={value}
                        className="flex relative group"
                    >
                        <span className="w-6">
                            {Flag && <Flag className="w-5 h-5 rounded-full" />}
                        </span>
                        {country}
                        {sources && sources.length > 0 && (
                            <div className="p-1 absolute opacity-0 group-hover:opacity-100 bg-gray-100 top-5 w-full z-10 rounded-b-md">
                                <SourceInfo color="dark" sources={sources} />
                            </div>
                        )}
                    </Paragraph>
                </li>
            );
        })}
    </>
);

const InfoBlockValues: FC<InfoBlockProps> = ({
    highlightMatch,
    content,
    icon,
    className,
}) => (
    <>
        {content.map(
            ({ sources, value, additionalValue, title }, contentIndex) => {
                const textArray = Array.isArray(value) ? value : [value];
                return (
                    <div
                        key={contentIndex}
                        className="relative group flex flex-col mb-1"
                    >
                        {icon}
                        <Paragraph
                            size="default"
                            lineHeight="default"
                            // weight="medium"
                            color="dark"
                            className={classnames(
                                'break-words py-0.5 rounded-t-md',
                                {
                                    'ml-2.5': icon,
                                    'cursor-pointer group-hover:bg-neutral-50 ':
                                        sources,
                                },
                                className,
                            )}
                            title={title}
                        >
                            {textArray.map((text, textIndex) => {
                                const textToHighlight =
                                    text + (additionalValue ?? '');

                                return highlightMatch ? (
                                    <Highlight
                                        key={textIndex}
                                        searchWords={highlightMatch}
                                        textToHighlight={textToHighlight}
                                    />
                                ) : (
                                    <span
                                        key={textIndex}
                                        dangerouslySetInnerHTML={{
                                            __html: textToHighlight,
                                        }}
                                    />
                                );
                            })}
                        </Paragraph>
                        {sources && (
                            <div className="p-1 absolute opacity-0 group-hover:opacity-100 bg-gray-100 top-6 w-full z-10 rounded-b-md transition">
                                <SourceInfo color="dark" sources={sources} />
                            </div>
                        )}
                    </div>
                );
            },
        )}
    </>
);

const DateRangeOfBirthValues: FC<InfoBlockProps> = (props) => {
    // making sure it's a date range, since the types don't support it right now
    if (
        props.content.some(
            (item: any) => !item.value || !item.value.start || !item.value.end,
        )
    ) {
        return null;
    }

    return (
        <InfoBlockValues
            {...props}
            content={transformBirthdayRangeToString(
                props.content as unknown as BirthdateRange[],
            )}
        />
    );
};

const MAX_LENGTH = 85;

const TruncatedValues: FC<InfoBlockProps> = (props) => {
    const truncatedContent = props.content.map((item) => {
        const text = item.value;
        const isTruncated = text.length > MAX_LENGTH;
        const displayText = isTruncated
            ? `${text.slice(0, MAX_LENGTH)}...`
            : text;
        return {
            ...item,
            value: displayText,
            title: isTruncated ? text : undefined,
        };
    });

    return <InfoBlockValues {...props} content={truncatedContent} />;
};

const LeakSourceValues: FC<InfoBlockProps> = (props) => {
    const leakSource = props.content[0];
    const url = props.content?.[1]?.value;
    return (
        <div className="relative group flex flex-col mb-1">
            <Paragraph
                size="default"
                lineHeight="default"
                color="dark"
                className={classnames(
                    'break-words py-0.5 rounded-t-md',
                    props.className,
                )}
                title={leakSource.title}
            >
                {props.highlightMatch ? (
                    <Highlight
                        searchWords={props.highlightMatch}
                        textToHighlight={leakSource.value}
                    />
                ) : (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: leakSource.value,
                        }}
                    />
                )}
            </Paragraph>
            <a
                target="_blank"
                href={url}
                rel="noopener noreferrer"
                className="break-all inline-block text-primary-4 hover:text-primary-3 transition disabled:opacity-50 pt-1"
            >
                <div className="flex items-center space-x-1">
                    <span>{url}</span>
                    <TbExternalLink className="text-lg" />
                </div>
            </a>
        </div>
    );
};

export interface InfoBlockProps {
    content: ContentType[];
    headline: string;
    highlightMatch?: HighlightChunks;
    icon?: ReactElement;
    className?: string;
    containerClassName?: string;
    labelClassName?: string;
    renderComponent?: FC<InfoBlockProps>;
    type?: string | number;
    hiddenCount?: number;
    onClick?: () => void;
}

const customInfoBlockValueComponents: Record<string, FC<any>> = {
    nationality: NationalityInfoBlockValues,
    default: InfoBlockValues,
    dateRangeOfBirth: DateRangeOfBirthValues,
    url: TruncatedValues,
    leakSource: LeakSourceValues,
};

export const InfoBlock: FC<InfoBlockProps> = (props: InfoBlockProps) => {
    const {
        headline,
        content,
        containerClassName,
        labelClassName,
        type,
        hiddenCount,
        onClick,
    } = props;
    const { t } = useTranslation();

    const valuesComponentKey =
        !type || !customInfoBlockValueComponents[type] ? 'default' : type;
    const ValuesComponent = customInfoBlockValueComponents[valuesComponentKey];

    return (
        <div className={containerClassName} onClick={onClick}>
            <Paragraph
                size="label"
                weight="bold"
                color="light"
                className={classnames(
                    'py-1',
                    labelClassName,
                    content.length === 0 && 'opacity-30',
                )}
            >
                {headline}
            </Paragraph>
            {content.length === 0 ? (
                <Tag className="whitespace-nowrap my-1 inline-block opacity-30">
                    {t('noData')}
                </Tag>
            ) : (
                <>
                    <ValuesComponent {...props} />
                    {hiddenCount && hiddenCount > 0 ? (
                        <div className="text-primary-4 font-bold mt-1.5 flex items-center">
                            {t('seeXMore', { x: hiddenCount })}
                            <TiDocumentText size={20} className="ml-1" />
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};
