import { FC } from 'react';
import { TableRow } from './TableRow/TableRow';
import { TableRowAction } from './TableRowActionsMenu/TableRowActionsMenu';
import classnames from 'classnames';
import {
    EmptyTableCellWithRowBorderRadius,
    rowBorderRadiusClassNames,
} from '_organisms/Table/utils';

export type CaseInfo = Record<string, string | undefined>;

export type PersonInfo = Record<string, string | undefined>;

export type LinkOption = { name: string; href: string; param?: string };

export type TextAlign = { [k: string]: 'left' | 'center' | 'right' };

type TableProps = {
    headlines: string[];
    items: PersonInfo[] | CaseInfo[];
    tableRowActions?: TableRowAction[];
    linkOption?: LinkOption;
    additions?: {
        checkbox?: boolean;
        showNewFields?: number;
    };
    layoutOptions?: {
        highlightColumns?: {
            firstColBold?: boolean;
            secondColBold?: boolean;
        };
        textAlign?: TextAlign;
        squashLayout?: boolean;
    };
    selectedItems?: string[];
    setSelectedItems?: (selectedItems: string[]) => void;
};

export const Table: FC<TableProps> = ({
    headlines,
    items,
    tableRowActions,
    linkOption,
    additions,
    layoutOptions,
    selectedItems = [],
    setSelectedItems,
}: TableProps) => {
    const highlightColumns = layoutOptions?.highlightColumns;
    const textAlign = layoutOptions?.textAlign;

    const handleOnChangeRowSelection = ({
        id,
        isSelected,
    }: {
        id: string;
        isSelected: boolean;
    }) => {
        const items = isSelected
            ? // add selected id
              [...selectedItems, id].filter(
                  // but keep each id only once
                  (i, index, array) => array.indexOf(i) === index,
              )
            : [...selectedItems].filter((i) => i !== id); // remove id if not isSelected

        if (setSelectedItems) {
            setSelectedItems(items);
        }
    };

    return (
        <div>
            <table
                cellPadding="0"
                cellSpacing="0"
                className="w-full p-0 text-font-light rounded-md border-spacing-y-3 border-separate -mt-3"
            >
                <thead className="text-font-light bg-primary-1">
                    <tr className="*:px-7 *:py-5">
                        {additions?.checkbox && (
                            <EmptyTableCellWithRowBorderRadius />
                        )}
                        {headlines.map((header, index) => (
                            <th
                                key={header}
                                className={classnames(
                                    rowBorderRadiusClassNames,
                                    'text-base leading-6 font-jost font-medium uppercase',
                                    textAlign?.[index]
                                        ? `text-${textAlign[index]}`
                                        : 'text-left',
                                )}
                            >
                                {header}
                            </th>
                        ))}
                        {linkOption && <EmptyTableCellWithRowBorderRadius />}
                        {tableRowActions && (
                            <EmptyTableCellWithRowBorderRadius />
                        )}
                    </tr>
                </thead>
                <tbody>
                    {items.map((result, index) => (
                        <TableRow
                            firstColBold={highlightColumns?.firstColBold}
                            secondColBold={highlightColumns?.secondColBold}
                            backgroundColor={
                                index % 2 === 0
                                    ? 'bg-neutral-100'
                                    : 'bg-neutral-100'
                            }
                            key={result.id}
                            result={result}
                            tableRowActions={tableRowActions}
                            linkOption={linkOption}
                            checkbox={additions?.checkbox}
                            isSelected={
                                additions?.checkbox &&
                                selectedItems.indexOf(result.id ?? 'NOID') !==
                                    -1
                            }
                            squashLayout={layoutOptions?.squashLayout}
                            onChangeRowSelection={handleOnChangeRowSelection}
                            shouldAnimate={Boolean(result?.shouldAnimate)}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};
