import { FC, useRef } from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';

export type TabsProps = {
    tabTitles: Array<string | JSX.Element>;
    onChange?: (index: number) => void;
    selectedIndex?: number;
    defaultIndex?: number;
    className?: string;
    tabPanelClassName?: string;
};

export const Tabs: FC<TabsProps> = ({
    tabTitles,
    onChange,
    children,
    selectedIndex,
    defaultIndex,
    className,
    tabPanelClassName,
}) => {
    const panelParentRef = useRef<HTMLDivElement>(null);
    return (
        <div className={className}>
            <Tab.Group
                manual
                onChange={(index: number) => {
                    if (panelParentRef.current) {
                        panelParentRef.current.scrollTop = 0;
                    }
                    if (onChange) {
                        onChange(index);
                    }
                }}
                selectedIndex={selectedIndex}
                defaultIndex={defaultIndex}
            >
                <Tab.List className="flex flex-shrink-0 overflow-x-auto">
                    {tabTitles.map((title, index) => (
                        <Tab
                            key={index}
                            className={({ selected }) =>
                                classNames(
                                    'border-b-2 border-solid w-full py-4 px-6 min-h-full leading-5 font-semibold focus:outline-none transition-colors ease-in-out duration-200',
                                    selected
                                        ? 'border-primary-4 text-primary-4'
                                        : 'border-gray-200 text-neutral-500 hover:border-primary-4 hover:text-primary-4',
                                )
                            }
                        >
                            {title}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels
                    className={classNames('w-full', tabPanelClassName)}
                    ref={panelParentRef}
                >
                    {children}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};
