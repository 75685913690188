import {
    Checkbox,
    DynamicSVG,
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { EntitySuspicion } from '_types';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type SuspicionsProps = {
    className?: string;
    allActions?: {
        action: JSX.Element;
    } | null;
    actions?:
        | {
              suspicionItemId: string;
              action: JSX.Element;
          }[]
        | null;
    handleSelectAll: (isSelected: boolean) => void;
    handleSelectSuspicion: (id: string, isSelected: boolean) => void;
    selectedSuspicions: Set<string>;
    reportIsTooOld?: boolean;
    uniqueSuspicions?: EntitySuspicion[];
};
export const Suspicions: FC<SuspicionsProps> = ({
    className,
    reportIsTooOld,
    selectedSuspicions,
    uniqueSuspicions,
    handleSelectSuspicion,
    handleSelectAll,
    allActions,
    actions,
}) => {
    const { t } = useTranslation();

    if (!uniqueSuspicions) {
        return <></>;
    }

    return (
        <div className={classNames(className)}>
            <div className="p-3 font-semibold border-b border-neutral-300 items-center text-sm">
                {t('relatedSuspicion')}
            </div>
            {!reportIsTooOld && (
                <div className="items-center p-2 font-semibold flex space-x-2 print:hidden">
                    <Checkbox
                        onChange={handleSelectAll}
                        initialIsSelected={
                            selectedSuspicions.size === uniqueSuspicions.length
                        }
                        centered={true}
                        size={4}
                    />
                    <Paragraph
                        className="truncate light"
                        size="small"
                        weight="normal"
                    >
                        {t('filters.selectAll')}
                    </Paragraph>
                    {selectedSuspicions.size > 0 && allActions?.action}
                </div>
            )}
            <div className="px-2 pb-1">
                {uniqueSuspicions.map((item) => (
                    <TooltipV2
                        openDelay={500}
                        placement="right"
                        key={item.id}
                        withArrow={true}
                        disabled={item.sources.length === 0}
                    >
                        <TooltipContentV2 className="max-w-72 z-20">
                            <Paragraph size="label" weight="bold" color="white">
                                {t('source')}
                            </Paragraph>
                            {Array.from(
                                new Set(
                                    item.sources.map((source) =>
                                        t(`suspicionItemSource.${source}`),
                                    ),
                                ),
                            ).map((source) => (
                                <Paragraph
                                    key={source}
                                    size="label"
                                    weight="normal"
                                    color="white"
                                >
                                    {source}
                                </Paragraph>
                            ))}
                        </TooltipContentV2>
                        <TooltipTriggerV2 asChild={true}>
                            <div
                                className={classNames(
                                    'text-sm pb-2 flex gap-2 items-center',
                                )}
                            >
                                {!reportIsTooOld && (
                                    <Checkbox
                                        onChange={(isSelected) =>
                                            handleSelectSuspicion(
                                                item.id,
                                                isSelected,
                                            )
                                        }
                                        initialIsSelected={selectedSuspicions.has(
                                            item.id,
                                        )}
                                        centered={true}
                                        className="ml-0 pl-0 flex-shrink-0"
                                        size={4}
                                    />
                                )}
                                <DynamicSVG
                                    src={`icons/suspicions/${item.suspicion?.label}`}
                                    width={16}
                                    className="inline-block flex-shrink-0"
                                />
                                <div className="text-sm flex-grow">
                                    {t(
                                        `suspicionKeywords.${item.suspicion.label}`,
                                        {
                                            defaultValue: item.suspicion.name,
                                        },
                                    )}
                                    {item.involvement
                                        ? ` (${t(
                                              `suspicionItemInvolvement.${item.involvement}`,
                                          )})`
                                        : ''}
                                </div>
                                {item.score > 0 && (
                                    <div className="text-green-500 text-sm flex-shrink-0">{`${item.score} %`}</div>
                                )}
                                <div className="flex-shrink-0">
                                    {
                                        actions?.find?.(
                                            (action) =>
                                                action.suspicionItemId ===
                                                item.id,
                                        )?.action
                                    }
                                </div>
                            </div>
                        </TooltipTriggerV2>
                    </TooltipV2>
                ))}
            </div>
        </div>
    );
};
