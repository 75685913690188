import React, { FC, useState } from 'react';
import { ReactComponent as Company } from '../../../images/NetworkGraph/office-building.svg';
import { ReactComponent as CompanyFlagged } from '../../../images/NetworkGraph/office-building-redflag.svg';
import { ReactComponent as EmployeePosition } from '../../../images/NetworkGraph/employee-position.svg';
import { ReactComponent as Person } from '../../../images/NetworkGraph/user.svg';
import { ReactComponent as PersonFlagged } from '../../../images/NetworkGraph/user-redflag.svg';
import { ReactComponent as Candidate } from '../../../images/NetworkGraph/user-circle.svg';
import { Button, Modal, Paragraph } from '_atoms';
import { nonProdDataTestId } from '_utils';
import { useTranslation } from 'react-i18next';

const NetworkGraphLegend: FC = () => {
    const { t } = useTranslation();

    const [show, setShow] = useState<boolean>(false);

    const legendItems = [
        { SvgIcon: Candidate, groupName: t('networkGraphLegend.candidate') },
        { SvgIcon: Company, groupName: t('networkGraphLegend.company') },
        {
            SvgIcon: CompanyFlagged,
            groupName: t('networkGraphLegend.companyFlagged'),
        },
        {
            SvgIcon: EmployeePosition,
            groupName: t('networkGraphLegend.employeePosition'),
        },
        { SvgIcon: Person, groupName: t('networkGraphLegend.person') },
        {
            SvgIcon: PersonFlagged,
            groupName: t('networkGraphLegend.personFlagged'),
        },
    ];

    return (
        <>
            <Button level="primaryGhost" onClick={() => setShow(true)}>
                {t('networkGraphLegend.showLegend')}
            </Button>
            {show && (
                <Modal
                    isOpen={show}
                    title={t('networkGraphLegend.title')}
                    data-testid={nonProdDataTestId('popup2')}
                >
                    <Paragraph className="mt-4">
                        <div className="p-4 border border-black rounded mt-2">
                            <ul className="list-none p-0">
                                {legendItems.map(({ SvgIcon, groupName }) => (
                                    <li
                                        key={groupName}
                                        className="flex items-center"
                                    >
                                        <SvgIcon
                                            width="24"
                                            height="24"
                                            // className="h-7 w-7 mr-2"
                                            className="mr-2"
                                        />
                                        {groupName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Paragraph>
                    <div className="flex space-x-4 mt-6">
                        <Button
                            level="primaryGhost"
                            type="button"
                            onClick={() => setShow(false)}
                            data-testid={nonProdDataTestId(
                                'cancel delete button',
                            )}
                        >
                            {t('networkGraphLegend.closeButton')}
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default NetworkGraphLegend;
