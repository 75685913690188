import { theme } from '../../../tailwind.config';
import unknown from '../../../images/NetworkGraph/question-mark-circle.svg';
import company from '../../../images/NetworkGraph/office-building.svg';
import companyFlagged from '../../../images/NetworkGraph/office-building-redflag.svg';
import employeePosition from '../../../images/NetworkGraph/employee-position.svg';
import person from '../../../images/NetworkGraph/user.svg';
import personFlagged from '../../../images/NetworkGraph/user-redflag.svg';
import target from '../../../images/NetworkGraph/user-circle.svg';
import targetFlagged from '../../../images/NetworkGraph/user-circle-redflag.svg';

export const options = {
    groups: {
        unknown: {
            image: unknown,
            size: 18,
            shape: 'image',
        },
        company: {
            image: company,
            size: 24,
            shape: 'image',
        },
        companyFlagged: {
            image: companyFlagged,
            size: 24,
            shape: 'image',
        },
        employeePosition: {
            image: employeePosition,
            size: 24,
            shape: 'image',
        },
        person: {
            image: person,
            size: 24,
            shape: 'image',
        },
        personFlagged: {
            image: personFlagged,
            size: 24,
            shape: 'image',
        },
        family: {
            image: person,
            size: 24,
            shape: 'family',
        },
        familyFlagged: {
            image: personFlagged,
            size: 24,
            shape: 'family',
        },
        target: {
            image: target,
            size: 30,
            shape: 'image',
        },
        targetFlagged: {
            image: targetFlagged,
            size: 30,
            shape: 'image',
        },
    },
    edges: {
        color: {
            color: theme.extend.colors.blue[800],
            hover: theme.extend.colors.blue[900],
        },
        font: {
            color: theme.extend.colors.gray[900],
            background: theme.extend.colors.white,
            face: 'OpenSans',
            size: 12,
        },
        smooth: false,
    },
    nodes: {
        image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg==',
        borderWidth: 0,
        font: {
            color: theme.extend.colors.gray[900],
            background: theme.extend.colors.white,
            face: 'OpenSans',
            size: 14,
        },
    },
    interaction: {
        hover: true,
        zoomSpeed: 0.9,
    },
    physics: {
        enabled: false,
        stabilization: true,
        solver: 'repulsion',
        repulsion: {
            nodeDistance: 300,
        },
    },
    layout: {
        randomSeed: 2,
    },
};
