import { FC, SVGProps, useEffect, useState } from 'react';
import { MdManageSearch } from 'react-icons/md';

interface DynamicSVGProps extends SVGProps<SVGSVGElement> {
    src: string;
    fallback?: FC<SVGProps<SVGElement>>;
}

/**
 *
 * @param src - Always should be a path relative from "src/assets"
 * @param fallback - Fallback component when import fails (default: <MdManageSearch />)
 * @returns FC<DynamicSVG>
 */
export const DynamicSVG: FC<DynamicSVGProps> = ({
    src,
    fallback: Fallback = MdManageSearch,
    ...rest
}: DynamicSVGProps) => {
    const [SvgAsset, setSvgAsset] = useState<any>();

    useEffect(() => {
        (async () => {
            try {
                if (src) {
                    const module = await import(
                        `!!@svgr/webpack?-svgo,+titleProp,+ref!../../../assets/${src}.svg`
                    );
                    setSvgAsset(module.default);
                }
            } catch (e) {
                console.warn('[DynamicSVG] Failed to import asset:', src);
            }
        })();
    }, [src]);

    return SvgAsset ? <SvgAsset {...rest} /> : <Fallback />;
};
