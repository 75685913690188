import React, { useMemo } from 'react';
import { SocialAccountPostArticle } from '../../../../../services/searchService';
import { useTranslation } from 'react-i18next';
import { Meta } from './components/Meta';
import { Divider } from './components/Divider';
import { FullText } from './components/FullText';
import { Image } from './components/Image';
import { formatDate, getCdnUrl } from '_utils';

import { SharedArticleContentProps } from './types';

interface SocialMediaPostProps extends SharedArticleContentProps {
    article: SocialAccountPostArticle;
}

export const SocialMediaPost = (
    props: SocialMediaPostProps,
): React.ReactElement => {
    const { article, highlightChunks } = props;
    const { t } = useTranslation();

    const meta = useMemo(
        () => [
            {
                label: t('riskAnalysis.articleDetails.source'),
                list: article.postSource ? [article.postSource] : undefined,
            },
            {
                label: t('riskAnalysis.articleDetails.pubDate'),
                list: article.postDatePublished
                    ? [formatDate(article.postDatePublished)]
                    : undefined,
            },
            {
                label: t('riskAnalysis.articleDetails.likes'),
                list:
                    article.postLikeCount >= 0
                        ? [article.postLikeCount]
                        : undefined,
            },
        ],
        [article, t],
    );

    return (
        <>
            {Boolean(article.postImageCdnUrl) && (
                <Image
                    url={getCdnUrl(article.postImageCdnUrl)}
                    alt="Social post"
                />
            )}
            <Meta meta={meta} />
            <Divider className="my-5" />
            <FullText
                text={article.postBody ?? ''}
                highlight={highlightChunks}
            />
        </>
    );
};
