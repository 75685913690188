import React, { forwardRef } from 'react';
import { DynamicFormFieldProps } from './form.interface';
import {
    FieldErrors,
    RegisterOptions,
    useController,
    ValidationRule,
} from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import {
    WizardDateInput,
    WizardDateRangeInput,
    WizardFormCheckbox,
    WizardInput,
    WizardSelect,
} from '_atoms';
import { WizardSwitch } from '../../../../components/_atoms/Toggle/WizardSwitch';

export const getNestedErrorMessage = (
    errors: FieldErrors,
    key: string,
): string | undefined => {
    const parts = key.split('.');
    let current: any = errors;

    for (const part of parts) {
        if (part.includes('[') && part.includes(']')) {
            const [arrayName, indexStr] = part.split('[');
            const index = parseInt(indexStr.replace(']', ''), 10);
            current = current?.[arrayName]?.[index];
        } else {
            current = current?.[part];
        }

        if (!current) {
            return undefined;
        }
    }

    return current.message;
};

type ValidatorKey = keyof RegisterOptions;

export const translateValidationMessages = (
    validators: RegisterOptions,
    t: TFunction,
): RegisterOptions => {
    const translatedValidators: RegisterOptions = {};

    (Object.keys(validators) as ValidatorKey[]).forEach((key) => {
        const value = validators[key];

        if (
            typeof value === 'object' &&
            value !== null &&
            'value' in value &&
            'message' in value
        ) {
            translatedValidators[key] = {
                value: value.value,
                message: t(value.message as string),
            } as ValidationRule<boolean>;
        } else if (typeof value === 'string') {
            translatedValidators[key] = t(value) as ValidationRule<boolean>;
        } else {
            translatedValidators[key] = value;
        }
    });

    return translatedValidators;
};

export const DynamicFormField = forwardRef<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
    DynamicFormFieldProps
>(({ field: fieldProps, control }, ref) => {
    const { t } = useTranslation();
    const {
        key,
        labelTranslationKey,
        type,
        placeholder,
        validators,
        className = 'w-full',
        defaultValue = '',
        disabled,
        hintMsg,
        autoFocus,
    } = fieldProps;

    const {
        field,
        formState: { errors },
    } = useController({
        name: key,
        control,
        defaultValue,
        disabled,
        ...(validators
            ? { rules: translateValidationMessages(validators, t) }
            : {}),
    });

    const errorMessage = getNestedErrorMessage(errors, key);

    switch (fieldProps.type) {
        case 'text':
        case 'email':
        case 'password':
            return (
                <WizardInput
                    type={type}
                    label={labelTranslationKey}
                    placeholder={placeholder}
                    hintMsg={hintMsg}
                    errorMsg={errorMessage}
                    required={!!validators?.required}
                    autoFocus={autoFocus}
                    {...field}
                />
            );
        case 'date':
            return (
                <WizardDateInput
                    label={labelTranslationKey}
                    errorMsg={errorMessage}
                    hintMsg={hintMsg}
                    required={!!validators?.required}
                    autoFocus={autoFocus}
                    {...field}
                />
            );
        case 'dateRange':
            return 'start' in fieldProps && 'end' in fieldProps ? (
                <WizardDateRangeInput
                    label={labelTranslationKey}
                    errorMsg={errorMessage}
                    hintMsg={hintMsg}
                    required={!!validators?.required}
                    autoFocus={autoFocus}
                    {...field}
                />
            ) : null;
        case 'textarea':
            return (
                //TODO: create its own component
                <textarea
                    id={key}
                    placeholder={placeholder}
                    className={className}
                    required={!!validators?.required}
                    autoFocus={autoFocus}
                    {...field}
                    ref={ref as React.Ref<HTMLTextAreaElement>}
                />
            );
        case 'checkbox':
            return (
                <WizardFormCheckbox
                    type={type}
                    label={labelTranslationKey}
                    errorMsg={errorMessage}
                    hintMsg={hintMsg}
                    autoFocus={autoFocus}
                    {...field}
                />
            );
        case 'select':
            if ('options' in fieldProps && fieldProps.options) {
                return (
                    <WizardSelect
                        label={labelTranslationKey}
                        options={fieldProps.options}
                        errorMsg={errorMessage}
                        hintMsg={hintMsg}
                        required={!!validators?.required}
                        autoFocus={autoFocus}
                        {...field}
                    />
                );
            }
            return null;
        case 'switch':
            if ('options' in fieldProps && fieldProps.options.length === 2) {
                const [leftOption, rightOption] = fieldProps.options;
                if (leftOption && rightOption) {
                    return (
                        <WizardSwitch
                            label={labelTranslationKey}
                            left={leftOption}
                            right={rightOption}
                            hintMsg={hintMsg}
                            errorMsg={errorMessage}
                            autoFocus={autoFocus}
                            {...field}
                        />
                    );
                }
            }
            return null;
        default:
            return null;
    }
});
