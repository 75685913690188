import { FC, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ColoredBar,
    ConfidenceBar,
    Highlight,
    Modal,
    Paragraph,
} from '_atoms';
import { BaseCard, CollapsibleCardWithBar } from '_organisms';
import { formatDate, prepareInfoBlockContent } from '_utils';
import type { PressArticle } from '../../../services/searchService';
import { CaseData, Target } from '../../../services/dataService';
import { EntitySuspicion } from '_types';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';
import { InfoBlock } from '_molecules';
import { ConfidenceScoreMatches } from '_organisms/SearchCards/ConfidenceScore/ConfidenceScoreMatches';
import { IndicatorsWithTooltip } from '../../_atoms/Highlight/types';

interface PressCardProps extends HTMLAttributes<HTMLDivElement> {
    query?: string;
    data: PressArticle;
    hideArticleType?: boolean;
    score?: number;
    status?: string;
    suspicionItemId?: string;
    targetData?: Target;
    caseData?: CaseData;
    suspicionItems?: Array<EntitySuspicion>;
    indicators: IndicatorsWithTooltip[];
}

export const PressCard: FC<PressCardProps> = ({
    query,
    data,
    hideArticleType = false,
    suspicionItems,
    indicators,
    targetData,
    caseData,
    ...props
}) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const isNotProduction = process.env.REACT_APP_STAGE !== 'production';

    const highlightChunks = buildHighlightChunks({
        searchQuery: query,
        indicators,
        target: targetData,
        caseKeywords: caseData?.keywords,
    });

    const highlight = {
        ...highlightChunks,
        caseKeywords: highlightChunks.caseKeywords ?? [],
    };

    const indicatorTooltips = new Map(
        indicators.map((i) => [i.highlightText, i.tooltipForText]),
    );

    const infoBlocks = [
        {
            headline: `${t('source')}`,
            text: data.source?.name,
        },
        {
            headline: `${t('publicationDate')}`,
            text: data.estimatedPublishedDate
                ? [formatDate(data.estimatedPublishedDate)]
                : data.createdDate
                ? [formatDate(data.createdDate)]
                : '/',
        },
        {
            headline: `${t('author')}`,
            text: data.author?.name ?? '',
        },
        {
            headline: `${t('copyright')}`,
            text: data.copyright,
        },
        {
            headline: `Confidence Score`,
            shouldShow: isNotProduction,
            text:
                String(
                    (
                        (Math.max(
                            Math.min(data.confidenceScore ?? 0, 500),
                            -500,
                        ) /
                            500 /
                            2 +
                            0.5) *
                        100
                    ).toPrecision(3),
                ) + '%',
        },
        {
            headline: `Fingerprint Score`,
            shouldShow: isNotProduction,
            text:
                ((data.fingerprintScore ?? 0) * 100)
                    .toPrecision(3)
                    ?.toString() + '%',
        },
        {
            headline: `Unified Score`,
            shouldShow: isNotProduction,
            text:
                ((data.unifiedScore ?? 0) * 100).toPrecision(3)?.toString() +
                '%',
        },
    ];

    const fullwidthInfoBlocks = data.preview
        ? [
              {
                  headline: t('preview'),
                  text: data.preview,
              },
          ]
        : undefined;

    const lastChild = (
        <CollapsibleCardWithBar
            borderColor="border-primary-4"
            expanded={!!query?.length}
        >
            <div className="grid grid-cols-1">
                <Paragraph>
                    <Highlight
                        searchWords={highlight}
                        textToHighlight={data.content}
                        tooltips={indicatorTooltips}
                    />
                </Paragraph>
            </div>
        </CollapsibleCardWithBar>
    );

    return (
        <BaseCard
            item={data}
            entityType="press"
            title={data.title}
            logo={
                !data.subtype || data.subtype === 'lexisNexis' ? (
                    <img
                        alt="LexisNexis Logo"
                        className="w-25"
                        src={`${process.env.PUBLIC_URL}/lexisnexis-poweredby.png`}
                    />
                ) : undefined
            }
            hideArticleType={hideArticleType}
            suspicionItems={suspicionItems}
            highlight={highlight}
            targetData={targetData}
            lastChild={lastChild}
            {...props}
        >
            <ColoredBar className="pb-5 px-6 flex-col transition duration-500 ease bg-neutral-100">
                <div className="gap-4 w-full flex flex-wrap">
                    {infoBlocks
                        ?.filter(({ shouldShow }) => shouldShow !== false)
                        .map(({ headline, text }, index) => (
                            <InfoBlock
                                key={index}
                                headline={headline}
                                content={prepareInfoBlockContent(text)}
                                highlightMatch={highlight}
                                containerClassName="basis-56 flex-grow"
                                labelClassName="whitespace-nowrap"
                                className="whitespace-normal"
                            />
                        ))}
                </div>
                {fullwidthInfoBlocks ? (
                    <div className="pt-4 w-full">
                        {fullwidthInfoBlocks.map(
                            ({ headline, text }, index) => (
                                <InfoBlock
                                    key={index}
                                    headline={headline}
                                    content={prepareInfoBlockContent(text)}
                                    highlightMatch={highlight}
                                />
                            ),
                        )}
                    </div>
                ) : null}
                {typeof data.confidenceScore === 'number' ? (
                    <>
                        <ConfidenceBar
                            confidenceScore={data.confidenceScore}
                            className="mt-5"
                            onClick={() => setShowModal(true)}
                        />
                        <Modal
                            isOpen={showModal}
                            title={t('confidenceScore.matchesLabel')}
                            onClose={() => setShowModal(false)}
                            maxWidth="max-w-4xl"
                            className="text-center"
                        >
                            <div className="text-left space-y-3">
                                <div className="bg-success-1/15 p-3 rounded-xl">
                                    <ConfidenceScoreMatches
                                        data={
                                            data.confidenceScoreMatches
                                                ?.positive ?? {}
                                        }
                                        isPositive={true}
                                    />
                                </div>
                                <div className="bg-error-1/15 p-3 rounded-xl">
                                    <ConfidenceScoreMatches
                                        data={
                                            data.confidenceScoreMatches
                                                ?.negative ?? {}
                                        }
                                        isPositive={false}
                                    />
                                </div>
                            </div>
                            <div className="text-center">
                                <Button
                                    level="primaryGhost"
                                    onClick={() => setShowModal(false)}
                                    className="min-w-48"
                                >
                                    {t('closeButton')}
                                </Button>
                            </div>
                        </Modal>
                    </>
                ) : null}
            </ColoredBar>
        </BaseCard>
    );
};
