import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { PageLayoutProps } from './DefaultPageLayout';
import { Headline } from '_atoms';
import { SiteWideAnnouncements } from './SiteWideAnnouncements';

export interface TwoColumnPageLayoutProps extends PageLayoutProps {
    leftContent: ReactNode;
    rightContent: ReactNode;
    leftBgClassName?: string;
    rightBgClassName?: string;
}

const halfBgClassNames =
    'container-fluid relative flex-basis-1/2 z-1 overflow-hidden py-0 px-9 flex flex-col';

const halfClassNames =
    'container-fluid xl:max-w-half-max-content relative flex flex-col justify-center p-0';

const stickyHeaderClassNames = 'pb-8 xl:max-w-half-max-content mx-auto w-full';

const halfHeaderClassNames = 'pt-9 shrink-0';

const halfContentClassNames = 'pb-9 grow overflow-y-auto';

export const TwoColumnPageLayout: FC<TwoColumnPageLayoutProps> = (props) => {
    const {
        leftContent,
        rightContent,
        leftBgClassName = 'bg-neutral-250',
        rightBgClassName = 'bg-neutral-250',
        className,
        title,
        subtitle,
        headerActions,
        // so they don't get included in the rest
        headerSubActions: _,
        ...rest
    } = props;

    return (
        <>
            <SiteWideAnnouncements addWrapper />
            <div
                className={classnames(
                    'h-content-height overflow-hidden container-fluid p-0 relative flex z-1',
                    className,
                )}
                {...rest}
            >
                <div className={classnames(halfBgClassNames, leftBgClassName)}>
                    {!title && !subtitle && !headerActions ? null : (
                        <div
                            className={classnames(
                                halfHeaderClassNames,
                                stickyHeaderClassNames,
                            )}
                        >
                            {title && <Headline Level="h3">{title}</Headline>}
                            {subtitle && (
                                <span className="text-base text-neutral-500">
                                    {subtitle}
                                </span>
                            )}
                        </div>
                    )}
                    <div
                        className={classnames(
                            halfContentClassNames,
                            halfClassNames,
                        )}
                    >
                        {leftContent}
                    </div>
                </div>
                <div className={classnames(halfBgClassNames, rightBgClassName)}>
                    {!title && !subtitle && !headerActions ? null : (
                        <div
                            className={classnames(
                                halfHeaderClassNames,
                                stickyHeaderClassNames,
                            )}
                        >
                            {headerActions}
                        </div>
                    )}
                    <div
                        className={classnames(
                            halfContentClassNames,
                            halfClassNames,
                        )}
                    >
                        {rightContent}
                    </div>
                </div>
            </div>
        </>
    );
};
