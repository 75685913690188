import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapsibleCard } from '_molecules';
import { CompanyCardTable } from './CompanyCardTable';
import { HighlightChunks } from '../../../_atoms/Highlight/types';

export type SubsidiaryCompanyProps = {
    data: Array<Record<string, string | string[]>>;
    highlight?: HighlightChunks;
};

export const SubsidiaryCompany: FC<SubsidiaryCompanyProps> = ({
    data,
    highlight,
}) => {
    const { t } = useTranslation();
    const columns = [
        {
            key: 'name',
            label: t('companyCard.subsidiaryName'),
            props: {
                className: 'flex-grow',
            },
        },
        {
            key: 'informationDate',
            label: t('companyCard.informationDate'),
        },
        {
            key: 'country',
            label: t('companyCard.country'),
            props: {
                className: 'text-center',
            },
        },
        {
            key: 'directPercent',
            label: t('companyCard.directlyOwned'),
            props: {
                className: 'text-right',
            },
            render: (value: string) =>
                value !== 'WO' ? t('intlNumber', { val: value }) + '%' : value,
        },
        {
            key: 'totalPercent',
            label: t('companyCard.ultimatelyOwned'),
            props: {
                className: 'text-right',
            },
            render: (value: string) =>
                value !== 'WO' ? t('intlNumber', { val: value }) + '%' : value,
        },
    ];

    return (
        <CollapsibleCard
            className="py-4"
            borderColor="border-transparent"
            header={
                <div className="flex flex-col gap-2">
                    <CompanyCardTable
                        data={data}
                        highlight={highlight}
                        columns={columns}
                    />
                </div>
            }
            initiallyExpanded={false}
        />
    );
};
