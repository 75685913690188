import { IFilterFactory } from '../../../../interfaces/filter-factory.interface';
import { BaseFilterProps } from '_types';
import OptionsFilter from './filter';

class OptionsFilterFactory implements IFilterFactory {
    get type() {
        return 'Options';
    }

    create({ field, item, searchState, dispatcher }: BaseFilterProps) {
        const key = [field, JSON.stringify(searchState.filters || {})].join(
            '-',
        );
        return (
            <OptionsFilter
                key={key}
                field={field}
                item={item}
                searchState={searchState}
                dispatcher={dispatcher}
            />
        );
    }
}

export default OptionsFilterFactory;
