import { SuspicionItemInvolvementDecision } from '_types';

export const HighlightType = {
    SearchQuery: 'searchQuery',
    SuspicionKeywords: 'suspicionKeywords',
    CaseKeywords: 'caseKeywords',
    TargetPerson: 'targetPerson',
    TargetCompanies: 'targetCompanies',
} as const;

// eslint-disable-next-line no-redeclare
export type HighlightType = typeof HighlightType[keyof typeof HighlightType];

export type SearchTerms = Array<{
    type: HighlightType;
    words: ReadonlyArray<string | RegExp>;
    tooltip?: IndicatorTooltip;
}>;

type Tooltip = {
    suspicionLabel: string;
    source: string;
    involvementDecision?: SuspicionItemInvolvementDecision;
};

export type IndicatorTooltip = Map<string, Tooltip[]>;

export type IndicatorsWithTooltip = {
    highlightText: string;
    tooltipForText: Tooltip[];
};

export type HighlightChunks = {
    searchQuery?: string[];
    suspicionKeywords?: string[];
    caseKeywords?: string[];
    targetPerson?: ReadonlyArray<RegExp>;
    targetCompanies?: ReadonlyArray<RegExp>;
};
