import {
    Button,
    Card,
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { ArticleSuspicion, CrimeLlmSuspicion } from '_types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditModal } from './EditModal';
import { SuspicionItemStatus } from '_enums';
import { SuspicionStatusIcon } from '../../SuspicionStatusIcon';
import { RELEVANT_SUSPICION_STATUSES } from './constants';
import classNames from 'classnames';
import { SuspicionItemInvolvement } from '../../../../../types/SuspicionItem';
import { MdChevronLeft } from 'react-icons/md';
import { RiEdit2Line } from 'react-icons/ri';

interface ManageSuspicionsProps {
    suspicions: ArticleSuspicion[];
    updateSuspicionStatuses: (
        ids: string[],
        status: SuspicionItemStatus,
    ) => Promise<void>;
    isUpdatingSuspicionStatuses: boolean;
    activeSuspicionId?: string;
    crimeLlmSuspicions: CrimeLlmSuspicion[];
    linkSuspicionItem: (
        suspicionId: string,
        involvement: SuspicionItemInvolvement,
    ) => Promise<void>;
    collapsed?: boolean;
    onCollapse?: () => void;
}

export const ManageSuspicions = (
    props: ManageSuspicionsProps,
): React.ReactElement => {
    const {
        suspicions,
        updateSuspicionStatuses,
        isUpdatingSuspicionStatuses,
        activeSuspicionId,
        crimeLlmSuspicions,
        linkSuspicionItem,
        collapsed = false,
        onCollapse = () => {
            /* noop */
        },
    } = props;

    const { t } = useTranslation();

    const [opened, setIsOpened] = useState(false);

    const statusesToMoveToEnd = [
        SuspicionItemStatus.WrongTarget,
        SuspicionItemStatus.NoProblem,
    ];

    const sortedSuspicions = suspicions?.sort((a, b) => {
        const aIsInEndGroup = statusesToMoveToEnd.includes(a.status);
        const bIsInEndGroup = statusesToMoveToEnd.includes(b.status);

        if (aIsInEndGroup && !bIsInEndGroup) return 1;
        if (!aIsInEndGroup && bIsInEndGroup) return -1;

        return t(`suspicionKeywords.${a.suspicion.label}`, {
            defaultValue: a.suspicion.name,
        }).localeCompare(
            t(`suspicionKeywords.${b.suspicion.label}`, {
                defaultValue: b.suspicion.name,
            }),
        );
    });

    const editSuspicionButton = (
        <Button
            level="custom"
            icon={RiEdit2Line}
            className={classNames(
                'text-primary-4 shadow-none self-end',
                !collapsed && 'pr-0',
            )}
            onClick={() => setIsOpened(true)}
        >
            {!collapsed && t('riskAnalysis.articleMeta.editSuspicions')}
        </Button>
    );

    return (
        <>
            <Card
                className={classNames(
                    'flex flex-col border-2 rounded-lg relative min-h-14 transition',
                    collapsed ? 'p-0 items-center' : 'p-4 pb-0',
                )}
            >
                {collapsed ? (
                    <Button
                        level="custom"
                        icon={MdChevronLeft}
                        onClick={onCollapse}
                        className="text-neutral-500"
                    />
                ) : (
                    <MdChevronLeft
                        className={classNames(
                            'cursor-pointer text-neutral-500 select-none ',
                            !collapsed &&
                                'transform rotate-180 absolute top-3.5 right-3.5', // to offset for 2px invisible border which buttons have
                        )}
                        onClick={onCollapse}
                    />
                )}
                {!collapsed && (
                    <>
                        <div className="font-jost font-bold text-font-dark">
                            {t('riskAnalysis.articleMeta.relatesTo')}
                        </div>
                        <div className="mt-4">
                            <div className="text-neutral-500 font-jost font-bold">
                                {t('riskAnalysis.articleMeta.systemSuspicions')}
                            </div>
                            <div className="flex flex-col gap-2 mt-2">
                                {sortedSuspicions?.map(
                                    (
                                        {
                                            suspicion,
                                            status,
                                            sources,
                                            involvement,
                                        },
                                        index,
                                    ) => (
                                        <TooltipV2
                                            openDelay={500}
                                            placement="right"
                                            key={index}
                                            withArrow={true}
                                            disabled={sources.length === 0}
                                        >
                                            <TooltipContentV2 className="max-w-72 z-20">
                                                <Paragraph
                                                    size="label"
                                                    weight="bold"
                                                    color="white"
                                                >
                                                    {t('source')}
                                                </Paragraph>

                                                <Paragraph
                                                    key={index}
                                                    size="label"
                                                    weight="normal"
                                                    color="white"
                                                >
                                                    {Array.from(
                                                        new Set(
                                                            sources.map(
                                                                (source) =>
                                                                    t(
                                                                        `suspicionItemSource.${source.source}`,
                                                                    ),
                                                            ),
                                                        ),
                                                    ).join(' / ')}
                                                    {involvement
                                                        ? ' (' +
                                                          t(
                                                              `suspicionItemInvolvement.${involvement}`,
                                                          ) +
                                                          ')'
                                                        : ''}
                                                </Paragraph>
                                            </TooltipContentV2>
                                            <TooltipTriggerV2 asChild={true}>
                                                <div
                                                    className="flex items-center"
                                                    key={index}
                                                >
                                                    <div className="text-sm flex flex-1">
                                                        <div className="relative">
                                                            <span
                                                                className={classNames(
                                                                    status &&
                                                                        !RELEVANT_SUSPICION_STATUSES.includes(
                                                                            status,
                                                                        ) &&
                                                                        'line-through',
                                                                )}
                                                            >
                                                                {t(
                                                                    `suspicionKeywords.${suspicion.label}`,
                                                                    {
                                                                        defaultValue:
                                                                            suspicion.name,
                                                                    },
                                                                )}
                                                            </span>
                                                        </div>
                                                        {!!status && (
                                                            <SuspicionStatusIcon
                                                                status={status}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </TooltipTriggerV2>
                                        </TooltipV2>
                                    ),
                                )}
                            </div>
                        </div>
                        <div className="bg-neutral-400 w-full h-[1px] mt-4" />
                    </>
                )}
                {collapsed ? (
                    <TooltipV2 placement="right">
                        <TooltipTriggerV2>
                            {editSuspicionButton}
                        </TooltipTriggerV2>
                        <TooltipContentV2>
                            {t('riskAnalysis.articleMeta.editSuspicions')}
                        </TooltipContentV2>
                    </TooltipV2>
                ) : (
                    editSuspicionButton
                )}
            </Card>
            <EditModal
                opened={opened}
                onOpen={setIsOpened}
                suspicions={suspicions}
                updateSuspicionStatuses={updateSuspicionStatuses}
                isUpdatingSuspicionStatuses={isUpdatingSuspicionStatuses}
                activeSuspicionId={activeSuspicionId}
                crimeLlmSuspicions={crimeLlmSuspicions}
                linkSuspicionItem={linkSuspicionItem}
            />
        </>
    );
};
