import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { User } from '../../services/authenticationService';
import { UserActionTypes, useUserState } from '../../context/User';
import { ConfirmCode } from './ConfirmCode';
import { PasswordResetRequired } from './PasswordResetRequired';
import { SignIn } from './Signin';
import { ReactComponent as Logo } from '../../images/logo-with-name.svg';
import { PasswordForgot } from './PasswordForgot';
import { AuthExceptions, AuthSteps } from './types';
import { PasswordResetCode } from './PasswordResetCode';

type LocationState = {
    redirectUrl?: string;
};

export const Authentication = (): React.ReactElement => {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState<AuthSteps>(AuthSteps.SignIn);
    const [error, setError] = useState<string | null>(null);
    const history = useHistory();
    const [localUser, setLocalUser] = useState<User | null>(null);
    const [, dispatch] = useUserState();
    const [{ initialRoute }] = useUserState();
    const [resetCodeEmail, setResetCodeEmail] = useState<string>('');

    const redirectPath = (history.location.state as LocationState)?.redirectUrl;

    const handleSuccess = (user: User) => {
        dispatch({ type: UserActionTypes.SET, user });

        history.push({
            pathname: redirectPath ?? initialRoute,
            state: { redirectUrl: undefined },
        });
    };

    const handleError = (code: AuthExceptions) => {
        setError(code);
        switch (code) {
            case AuthExceptions.NotAuthorizedException:
                setCurrentStep(AuthSteps.SignIn);
                break;
            case AuthExceptions.PasswordResetRequiredException:
                setCurrentStep(AuthSteps.ResetRequired);
                break;
        }
    };

    const currentStepForm = () => {
        switch (currentStep) {
            case AuthSteps.SignIn:
                return (
                    <SignIn
                        setLocalUser={(user) => setLocalUser(user)}
                        currentStep={currentStep}
                        onSuccess={handleSuccess}
                        onError={handleError}
                        onChangeStep={(step: AuthSteps) => setCurrentStep(step)}
                    />
                );
            case AuthSteps.ConfirmationCode:
                return (
                    <ConfirmCode
                        localUser={localUser}
                        onSuccess={handleSuccess}
                        onError={handleError}
                        onChangeStep={(step: AuthSteps) => setCurrentStep(step)}
                    />
                );

            case AuthSteps.ResetRequired:
                return (
                    <PasswordResetRequired
                        localUser={localUser}
                        setLocalUser={(user) => setLocalUser(user)}
                        onChangeStep={(step: AuthSteps) => setCurrentStep(step)}
                        onError={handleError}
                        onSuccess={handleSuccess}
                    />
                );

            case AuthSteps.Forgot:
                return (
                    <PasswordForgot
                        onChangeStep={(step: AuthSteps) => setCurrentStep(step)}
                        onError={handleError}
                        resetCodeEmail={resetCodeEmail}
                    />
                );
            case AuthSteps.ResetCode:
                return (
                    <PasswordResetCode
                        onError={handleError}
                        onSuccess={(resetCodeEmail) => {
                            setResetCodeEmail(resetCodeEmail);
                            setCurrentStep(AuthSteps.Forgot);
                        }}
                    />
                );
        }
    };

    const formStepHeadline = () => {
        switch (currentStep) {
            case AuthSteps.SignIn:
                return t('signIn.headline');
            case AuthSteps.ResetRequired:
                return t('signIn.passwordResetRequiredHeadline');
            case AuthSteps.Forgot:
            case AuthSteps.ResetCode:
                return t('signIn.passwordForgotHeadline');
            case AuthSteps.ConfirmationCode:
                return t('authentication.headline');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-neutral-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className=" max-w-sm w-full space-y-8">
                <div className="flex flex-col items-center">
                    <Logo
                        width="120"
                        height="120"
                        className="text-primary-4 fill-current"
                    />
                    <h3 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        {formStepHeadline()}
                    </h3>
                </div>
                {currentStepForm()}
                {error && (
                    <div>
                        <p>{t(error)}</p>
                        {error === 'InvalidPasswordException' && (
                            <ul>
                                {(
                                    t('passwordRequirements', {
                                        returnObjects: true,
                                    }) as string[]
                                ).map((req, index) => (
                                    <li key={index}>{req}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
