import { Button, Card } from '_atoms';
import React, {
    FormEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../images/logo-with-name.svg';
import { routes } from '../../routes';
import { updateUserPassword } from '../../services/authenticationService';
import { PasswordValidations } from './PasswordValidations';
import { ResetExceptions } from './types';
import { usePasswordValidation } from './use-password-validation';
import { Link, useHistory } from 'react-router-dom';
import { DefaultPageLayout } from '../../components/Layout/DefaultPageLayout';

export const ChangePassword = (): React.ReactElement => {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState<string | null>(null);
    const [oldPassword, setOldPassword] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [newPasswordConfirmation, setNewPasswordConfirmation] =
        useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const history = useHistory();

    const oldPasswordRef = useRef<HTMLInputElement | null>(null);
    const newPasswordRef = useRef<HTMLInputElement | null>(null);

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            setLoading(true);
            e.preventDefault();
            try {
                if (!oldPassword || !newPassword) {
                    return;
                }

                await updateUserPassword(oldPassword, newPassword);
                history.push(routes.profile.path);
            } catch (error) {
                console.error(error as ResetExceptions);
                setLoading(false);
                setError('WrongPasswordException');
            }
        },
        [history, newPassword, oldPassword],
    );

    useEffect(() => {
        newPasswordRef.current?.focus();
    }, []);

    const validations = usePasswordValidation({
        password: newPassword,
        confirmedPassword: newPasswordConfirmation,
    });

    return (
        <DefaultPageLayout title={''} className="h-content-height">
            <Card className="flex justify-center items-center py-7 rounded-md h-full">
                <div className="max-w-sm w-full space-y-8">
                    <div className="flex flex-col items-center">
                        <Logo
                            width="120"
                            height="120"
                            className="text-primary-4 fill-current"
                        />
                        <h3 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            {t('changePassword')}
                        </h3>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <div className="rounded-md shadow-sm">
                            <div>
                                <div className="relative">
                                    <label
                                        htmlFor="password"
                                        className="sr-only"
                                    >
                                        {t('passwordReset.oldPassword')}
                                    </label>
                                    <input
                                        name="password"
                                        type="password"
                                        autoComplete="password"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-neutral-300 placeholder-neutral-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-4 focus:border-primary-4 focus:z-10 sm:text-sm"
                                        placeholder={t(
                                            'passwordReset.oldPassword',
                                        )}
                                        onChange={(e) =>
                                            setOldPassword(e.target.value)
                                        }
                                        ref={oldPasswordRef}
                                    />
                                </div>
                                <br />
                                <div className="relative">
                                    <label
                                        htmlFor="password"
                                        className="sr-only"
                                    >
                                        {t('passwordReset.password')}
                                    </label>
                                    <input
                                        name="password"
                                        type="password"
                                        autoComplete="password"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-neutral-300 placeholder-neutral-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-4 focus:border-primary-4 focus:z-10 sm:text-sm"
                                        placeholder={t(
                                            'passwordReset.newPassword',
                                        )}
                                        onChange={(e) =>
                                            setNewPassword(e.target.value)
                                        }
                                        ref={newPasswordRef}
                                    />
                                    <PasswordValidations
                                        validations={validations}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="passwordConfirm"
                                        className="sr-only"
                                    >
                                        {t('passwordReset.password')}
                                    </label>
                                    <input
                                        name="passwordConfirm"
                                        type="password"
                                        autoComplete="password"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-neutral-300 placeholder-neutral-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-4 focus:border-primary-4 focus:z-10 sm:text-sm"
                                        placeholder={t(
                                            'passwordReset.newPasswordRepeat',
                                        )}
                                        onChange={(e) =>
                                            setNewPasswordConfirmation(
                                                e.target.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {error && (
                            <div className="text-error-2 text-center">
                                {t(error)}
                            </div>
                        )}

                        <button
                            disabled={
                                !validations.isValid || !oldPassword || loading
                            }
                            type="submit"
                            className={classNames(
                                (!validations.isValid ||
                                    !oldPassword ||
                                    loading) &&
                                    'opacity-30 disabled:bg-primary-4 disabled:cursor-not-allowed',
                                'group relative w-full flex justify-center py-2 px-4 mt-12 border border-transparent text-sm font-medium rounded-md text-font-light bg-primary-4 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-4',
                            )}
                        >
                            {t('passwordReset.updatePassword')}
                        </button>
                        <br />
                        <Link to={routes.profile.path}>
                            <Button className="group relative w-full flex justify-center py-2 px-4 mt-12 border border-transparent text-sm font-medium rounded-md text-font-light bg-primary-4 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-4">
                                {t('back')}
                            </Button>
                        </Link>
                    </form>
                </div>
            </Card>
        </DefaultPageLayout>
    );
};
