import { FC, HTMLAttributes } from 'react';
import { CustomData } from '_atoms';
import classNames from 'classnames';

interface ImageDataProps extends HTMLAttributes<HTMLDivElement> {
    headline?: string;
    src: string;
    alt?: string;
    width?: string;
    enableZoom?: boolean;
}

export const ImageData: FC<ImageDataProps> = ({
    headline,
    src,
    alt = '/',
    width = 'w-1/4',
    className = '',
    enableZoom = true,
    ...props
}) => (
    <CustomData
        headline={headline}
        width={width}
        className={className}
        {...props}
    >
        {src ? (
            <img
                src={src}
                alt={alt}
                className={classNames(
                    'm-3 h-40 z-0 relative z-20',
                    enableZoom && 'transform hover:z-50 hover:scale-300',
                )}
            />
        ) : null}
    </CustomData>
);
