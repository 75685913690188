import { FC } from 'react';
import classnames from 'classnames';
import {
    CaseInfo,
    PersonInfo,
    TableData,
    TableRowAction,
    TableRowActionsMenu,
} from '_organisms/Table';
import { Checkbox } from '_atoms';
import { generatePath, Link } from 'react-router-dom';
import { LinkOption } from '../Table';
import { nonProdDataTestId } from '_utils';
import { rowBorderRadiusClassNames } from '_organisms/Table/utils';

type TableRowProps = {
    result: PersonInfo | CaseInfo;
    backgroundColor: string;
    firstColBold?: boolean;
    secondColBold?: boolean;
    tableRowActions?: TableRowAction[];
    linkOption?: LinkOption;
    checkbox?: boolean;
    isSelected?: boolean;
    squashLayout?: boolean;
    onChangeRowSelection?: (args: { id: string; isSelected: boolean }) => void;
    shouldAnimate?: boolean;
};

const getTestIdFromColumn = (column: string) => {
    switch (column) {
        case 'caseName':
            return 'case title';
        case 'analysisType':
            return 'case analysis type';
        case 'createdOn':
            return 'case created on';
        case 'fullName':
            return 'target name';
        default:
            return undefined;
    }
};

export const TableRow: FC<TableRowProps> = ({
    result,
    backgroundColor,
    firstColBold,
    secondColBold,
    tableRowActions,
    linkOption,
    checkbox,
    isSelected = false,
    squashLayout,
    onChangeRowSelection,
}: TableRowProps) => {
    const { id = '', shouldAnimate: _shouldAnimate, ...displayData } = result;
    const shouldAnimate = _shouldAnimate === 'true';

    const handleChange = (isSelected: boolean) => {
        onChangeRowSelection?.({
            id,
            isSelected,
        });
    };

    return (
        <tr
            className={classnames(
                'relative *:px-7 *:py-5',
                isSelected ? 'bg-primary-4 bg-opacity-15' : backgroundColor,
                shouldAnimate && 'animate-new-row',
            )}
            data-testid={nonProdDataTestId('table row')}
        >
            {checkbox && (
                <td
                    className={classnames(
                        rowBorderRadiusClassNames,
                        'relative',
                        shouldAnimate && 'animate-new-row',
                    )}
                >
                    <label
                        className="absolute inset-0 cursor-pointer"
                        htmlFor={id}
                    />
                    <Checkbox
                        id={id}
                        initialIsSelected={isSelected}
                        onChange={handleChange}
                    />
                </td>
            )}

            {Object.entries(displayData).map(
                // TODO: this is not linked to the order in which the headers are rendered https://produktmacher.atlassian.net/browse/IND-894
                ([column, item], index) => (
                    <TableData
                        text={item}
                        key={index}
                        textWeight={
                            (firstColBold && index === 0) ||
                            (secondColBold && index === 1) ||
                            isSelected
                                ? 'bold'
                                : 'normal'
                        }
                        textColor={
                            (firstColBold && index === 0) ||
                            (secondColBold && index === 1) ||
                            isSelected
                                ? 'dark'
                                : 'default'
                        }
                        dataTestId={getTestIdFromColumn(column)}
                        className={classnames(
                            rowBorderRadiusClassNames,
                            shouldAnimate && 'animate-new-row',
                        )}
                    />
                ),
            )}

            {squashLayout && (
                <td
                    className={classnames(
                        rowBorderRadiusClassNames,
                        'w-1/5',
                        shouldAnimate && 'animate-new-row',
                    )}
                ></td>
            )}

            {linkOption && (
                <td
                    className={classnames(
                        rowBorderRadiusClassNames,
                        'px-6 py-5 text-right',
                        shouldAnimate && 'animate-new-row',
                    )}
                    data-testid={nonProdDataTestId('table row open button')}
                >
                    <button className="text-base text-primary-4 hover:text-primary-3 font-normal focus:outline-none">
                        {/*

              // DIFFERENT ROUTE USE CASES
              // CustomerAdmin: =>  /admin/customer/:customerId
              // CasesAdmin: =>  /cases/:caseId
              // ListTarget: =>  /dashboard/profile/:userId

               */}
                        <Link
                            to={generatePath(
                                linkOption.href,
                                linkOption.param
                                    ? { [linkOption.param]: id }
                                    : undefined,
                            )}
                        >
                            {linkOption.name}
                        </Link>
                    </button>
                </td>
            )}

            {tableRowActions ? (
                <TableRowActionsMenu
                    actions={tableRowActions}
                    id={id}
                    shouldAnimate={shouldAnimate}
                />
            ) : null}
        </tr>
    );
};
