import { Image } from '_atoms';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageAspectRatio } from '../../_atoms/Image/Image';

export const FallbackImage: FC<{
    aspectRatio?: keyof typeof ImageAspectRatio;
}> = ({ aspectRatio }) => {
    const { t } = useTranslation();

    return (
        <div className="relative bg-neutral-100">
            <Image
                aspectRatio={aspectRatio || 'portrait'}
                className="rounded-md"
                alt={t('fallbackProfileImage')}
                src={`${process.env.PUBLIC_URL}/target-person-fallback-image.png`}
            />
            <div className="absolute text-center rounded-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="p-1 rounded-xl text-sm text-neutral-500 bg-primary-4/15">
                    {t('fallbackImageLabel')}
                </div>
            </div>
        </div>
    );
};
