import React, { useState } from 'react';
import {
    Checkbox,
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';

interface RelevanceFilterProps {
    title: string;
    option: {
        label: string;
        value: string[];
        tooltipContent: string;
    };
    onChange: (selectedFilters: string[]) => void;
    isInitiallySelected: boolean;
}

export const RelevanceFilter = ({
    title,
    option,
    onChange,
    isInitiallySelected,
}: RelevanceFilterProps): React.ReactElement => {
    const [isSelected, setIsSelected] = useState(isInitiallySelected);

    return (
        <div className="py-2">
            {title && (
                <Paragraph
                    color="light"
                    size="small"
                    className="uppercase mb-1"
                >
                    {title}
                </Paragraph>
            )}
            <div className="flex gap-5">
                <div className="flex items-center" key={option.label}>
                    <Checkbox
                        onChange={(checked: boolean) => {
                            onChange(checked ? option.value : []);
                            setIsSelected(checked);
                        }}
                        initialIsSelected={isSelected}
                        label={option.label}
                        labelPosition="append"
                    />
                    <TooltipV2 openDelay={500} placement="bottom-start">
                        <TooltipContentV2 className="max-w-72 z-20">
                            {option.tooltipContent}
                        </TooltipContentV2>{' '}
                        <TooltipTriggerV2 asChild={true}>
                            <ExclamationIcon className="w-4 h-4 ml-2 flex justify-center items-center text-neutral-500 rotate-180" />
                        </TooltipTriggerV2>
                    </TooltipV2>
                </div>
            </div>
        </div>
    );
};
