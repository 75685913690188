import { useEffect, FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'i18next';
import TagManager from 'react-gtm-module';
import { routes } from '../../routes';
import { useUserState } from '../../context/User';
import { getUserGroups } from '../../services/authenticationService';

const tagManagerArgs = {
    gtmId: 'GTM-PZ763JZ',
};

TagManager.initialize(tagManagerArgs);

declare global {
    interface Window {
        dataLayer: Record<string, string | Record<string, string> | null>[];
    }
}

const dataLayer = window.dataLayer;

export enum TrackingPageCategory {
    authentication = 'authentication',
    admin = 'admin',
    case = 'case',
}

export enum TrackingPageType {
    login = 'login',
    overview = 'overview',
    forgot_password = 'forgot_password',
    analysis = 'analysis',
    create = 'create',
    update = 'update',
    profile = 'profile',
    change_password = 'change_password',
    setup_mfa = 'setup_mfa',
}

const getWindowSize = () => {
    if (window.innerWidth < 768) {
        return 'S';
    } else if (window.innerWidth < 1024) {
        return 'M';
    } else {
        return 'L';
    }
};

const getPageContent = (path: string) => {
    const route = Object.values(routes).find((route) => route.path === path);

    return {
        category: route ? route.tracking.category : '',
        type: route ? route.tracking.type : '',
        name: route ? route.tracking.name : '',
    };
};

export const Tracking: FC = () => {
    const location = useLocation();
    const [{ user }] = useUserState();

    useEffect(() => {
        dataLayer.push({ site: null });
        dataLayer.push({
            event: 'Init',
            schema: 'site',
            site: {
                environment: process.env.REACT_APP_STAGE ?? 'development',
                viewport: getWindowSize(),
                language: i18n.language,
            },
        });
        dataLayer.push({ content: null });
        dataLayer.push({
            event: 'Content View',
            schema: 'content',
            content: getPageContent(location.pathname),
        });

        const userRoles = user ? getUserGroups(user) : null;

        dataLayer.push({ user: null });
        dataLayer.push({
            event: 'User',
            schema: 'user',
            user: {
                loginStatus: user ? 'logged_in' : 'logged_out',
                type: userRoles && userRoles[0] ? userRoles[0] : 'no_role',
            },
        });
    }, [location, user]);

    return <Fragment />;
};
