import { useQuery, UseQueryResult } from 'react-query';
import { ErrorResponse } from 'src/types/NestAPI';
import { app } from '../../services/nestApiService';

export const useApiVersionQuery = (): UseQueryResult<string, ErrorResponse> =>
    useQuery('version', async () => app.version().then((r) => r.data), {
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
