export const COMPANY_SUFFIXES = [
    '1-Euro-GmbH',
    'AG & Co. KG',
    'AG & Co. KGaA',
    'AG & Co. OHG',
    'AG',
    'Aktiengesellschaft',
    'AöR',
    'B.V.',
    'COMMERCIAL LIMITED',
    'COMMERCIAL LTD',
    'e.V.',
    'eG',
    'Eigenbetrieb',
    'Einzelunternehmen',
    'ENTERPRISES LIMITED',
    'ENTERPRISES LTD',
    'EWIV',
    'gAG',
    'GbR',
    'Gesellschaft mit beschränkter Haftung',
    'gGmbH',
    'GmbH & Co. KG',
    'GmbH & Co. KGaA',
    'GmbH & Co. OHG',
    'gmbh',
    'GmbH',
    'haftungsbeschränkt',
    'Handelsgesellschaft',
    'HOLDING LIMITED',
    'HOLDING LTD',
    'HOLDINGS LIMITED',
    'HOLDINGS LTD',
    'INTERNATIONAL LIMITED',
    'INTERNATIONAL LTD',
    'InvAG',
    'INVESTMENTS LIMITED',
    'INVESTMENTS LTD',
    'KG',
    'KGaA',
    'Kommanditgesellschaft',
    'KöR',
    'Limited & Co. KG',
    'LIMITED',
    'LTD',
    'Mini-GmbH',
    'Offene Handelsgesellschaft',
    'OHG',
    'Partenreederei',
    'PartG mbB',
    'PartG',
    'Regiebetrieb',
    'REIT-AG',
    'SA',
    'SCE',
    'SE & Co. KGaA',
    'SE',
    'SERVICES LIMITED',
    'SERVICES LTD',
    'Small GmbH',
    'Stiftung & Co. KG',
    'Stiftung & Co. KGaA',
    'Stiftung GmbH & Co. KG',
    'Stiftung',
    'Stille Gesellschaft',
    'UG (haftungsbeschränkt) & Co. KG',
    'UG (haftungsbeschränkt)',
    'UGmbH',
    'Unternehmergesellschaft',
    'VVaG',
];
