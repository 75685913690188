export const freqMap = {
    freq: [
        18481, 14007, 7209, 5595, 5881, 4381, 5447, 3527, 2879, 7059, 7752,
        11743, 7680, 5295, 5571, 5517, 380, 97, 3155, 2912, 5186, 8366, 11550,
        8790, 5236, 2675, 2121, 1513, 5037, 8357, 275, 12664, 10612, 12279,
        10087, 14271, 621, 1135, 26836, 21542, 17323, 12356, 13058, 17300, 343,
        352, 2791, 9521, 7925, 3029, 1890, 131, 158, 413, 12502, 10530, 7661,
        7701, 5710, 9883, 10651, 13008, 1295, 2226, 10230, 14298, 500, 448,
        1359, 1712, 4917, 7557, 7403, 5360, 4791, 3272, 454, 590, 1373, 2971,
        2540, 3632, 9561, 9070, 2097, 420, 842, 15074, 10653, 15580, 10852,
        11140, 3988, 3137, 4005, 2306, 5112, 6539, 10548, 11141, 12935, 9985,
        434, 683, 412, 2287, 13292, 13527, 11907, 6290, 5887, 4896, 7211, 899,
        1360, 12058, 3494, 5590, 14880, 15338, 7512, 471, 540, 20120, 27085,
        33589, 30786, 21287, 20170, 3977, 2105, 2688, 2381, 893, 1406, 548,
        1260, 2090, 698, 806, 6494, 1702, 2249, 3946, 52, 2294, 2746, 2042,
        3141, 3453, 3238, 2253, 1892, 1938, 2077, 2488, 348, 9291, 2912, 9147,
        12625, 7720, 2618, 9746, 2791, 496, 18326, 4633, 3199, 5787, 2118,
        15891, 21, 13, 274, 7267, 1117, 2379, 1348, 16, 16, 104, 11148, 1244,
        54, 295, 477, 5442, 3733, 425, 569, 265, 5420, 14994, 4650, 203, 129,
        688, 1346, 1013, 857, 3758, 3189, 1276, 162, 1358, 1241, 823, 488, 389,
        650, 1672, 398, 8, 99, 10362, 7180, 1534, 122, 1062, 2522, 1622, 1657,
        5716, 3078, 778, 1528, 917, 1577, 8351, 394, 240, 34, 1183, 3250, 4059,
        1317, 1732, 756, 3549, 8766, 69, 4398, 5720, 1831, 184, 203, 676, 2835,
        439, 41, 3327, 5840, 64, 26, 2519, 14197, 2889, 1162, 1617, 2020, 4116,
        1021, 663, 2503, 1311, 1005, 1050, 7850, 10352, 1128, 2329, 1250, 1409,
        3973, 1564, 2545, 3623, 3219, 2967, 1970, 1775, 2160, 2103, 489, 1017,
        371, 2856, 4764, 8211, 3387, 1123, 96, 97, 13895, 1392, 1872, 8329,
        4675, 13393, 292, 37, 2385, 5113, 508, 1505, 1341, 82, 18, 146, 11321,
        1610, 606, 650, 530, 3805, 133, 1262, 404, 785, 2846, 6603, 5873, 64,
        24, 687, 2980, 1018, 681, 365, 3373, 1420, 9222, 452, 1561, 1126, 694,
        551, 655, 1373, 1268, 21, 152, 7027, 6958, 2316, 3080, 1599, 3647, 5641,
        10837, 6106, 1265, 653, 2579, 985, 1517, 5404, 1095, 181, 306, 1842,
        2597, 2074, 2580, 839, 1540, 2874, 6020, 561, 401, 2177, 1793, 470, 529,
        1039, 3416, 1213, 477, 5014, 569, 398, 48, 1219, 8985, 1912, 817, 1852,
        4795, 5394, 3541, 1548, 2871, 2067, 1434, 1053, 1595, 1393, 1287, 3139,
        3511, 3550, 2244, 1183, 1859, 2699, 2063, 2402, 1992, 1553, 1459, 1373,
        4848, 170, 606, 3539, 1241, 3155, 1426, 2717, 148, 105, 268, 688, 589,
        4023, 4128, 13276, 193, 1435, 5480, 1238, 458, 896, 1678, 435, 262, 156,
        11914, 2907, 3, 504, 2895, 884, 211, 272, 240, 3205, 2403, 3836, 371, 2,
        201, 1456, 740, 1900, 380, 89, 103, 1994, 7137, 85, 953, 1317, 1060,
        1098, 760, 1388, 1584, 209, 56, 3608, 3138, 1739, 745, 2454, 3556,
        15373, 13963, 4822, 777, 677, 1203, 2522, 1974, 5203, 3105, 55, 1622,
        2415, 3952, 1638, 3465, 2438, 660, 1211, 1420, 428, 1467, 1951, 1610,
        946, 1310, 2304, 3952, 2445, 72, 64, 2573, 853, 884, 1509, 5489, 1933,
        2544, 3857, 5778, 5845, 8805, 6393, 3560, 6414, 1353, 939, 857, 2115,
        2316, 8782, 14793, 722, 1224, 1010, 1526, 1603, 1543, 1582, 1266, 924,
        967, 2057, 2462, 810, 78, 376, 1307, 3518, 5386, 4064, 2344, 2189, 419,
        519, 267, 152, 742, 9764, 3783, 669, 8903, 2865, 1150, 1338, 1459, 1116,
        22, 288, 9660, 2811, 113, 1085, 2316, 426, 468, 471, 4847, 3222, 3258,
        969, 3, 228, 237, 2771, 1432, 439, 335, 245, 898, 69, 18, 6, 44, 1290,
        1811, 1093, 813, 1728, 1543, 347, 4379, 2939, 6933, 2047, 2197, 1175,
        382, 10478, 1221, 1090, 1871, 749, 3718, 5146, 2932, 7266, 2722, 456,
        5284, 1747, 1871, 1645, 3984, 1715, 5469, 3614, 2587, 374, 1060, 902,
        1592, 1711, 2337, 3038, 3463, 2251, 571, 55, 832, 350, 449, 13811, 5439,
        2977, 2338, 1746, 474, 637, 12821, 14639, 9856, 7822, 8610, 3057, 7302,
        1057, 1745, 2305, 829, 111, 814, 1161, 1462, 1718, 1739, 1532, 1296,
        892, 2745, 1754, 4321, 6054, 907, 4392, 2611, 1319, 1045, 3072, 11116,
        8474, 10430, 486, 35, 40, 733, 77, 44, 127, 641, 6526, 1338, 1245, 1108,
        1580, 31, 6, 2185, 5522, 622, 1000, 2902, 562, 1825, 3231, 7045, 1914,
        5858, 1, 9, 271, 7112, 1518, 9, 107, 1289, 48, 3916, 356, 501, 8, 16,
        671, 2214, 1337, 1117, 1785, 1027, 682, 6402, 7114, 1073, 1928, 154,
        800, 1166, 1438, 830, 929, 3952, 438, 207, 1415, 5012, 1984, 35, 22, 3,
        2751, 2043, 3171, 1303, 1021, 6664, 357, 3876, 2091, 2622, 621, 147,
        2537, 2137, 2502, 1608, 501, 5677, 139, 90, 236, 291, 20944, 4010, 7088,
        1401, 6104, 3954, 743, 1227, 4618, 4389, 2395, 3463, 1095, 1553, 2380,
        11064, 13810, 6435, 522, 11, 1647, 1091, 1817, 1457, 1423, 1562, 1497,
        1263, 1534, 1615, 4988, 53, 3060, 1358, 757, 1121, 690, 9059, 13794,
        5555, 2514, 1225, 172, 8086, 2755, 781, 479, 215, 1094, 9219, 2267, 400,
        108, 214, 158, 967, 5788, 5588, 1487, 7045, 808, 1991, 8167, 5122, 11,
        20, 8, 8, 2687, 9799, 458, 41, 47, 46, 115, 112, 467, 1012, 1385, 2593,
        337, 515, 1075, 911, 1839, 672, 401, 6354, 7073, 683, 142, 663, 578,
        456, 922, 703, 3493, 1003, 3374, 840, 1227, 435, 2088, 116, 3580, 1612,
        1046, 168, 1073, 9556, 1150, 12226, 309, 873, 2130, 6567, 8088, 745, 23,
        206, 2535, 5614, 1113, 216, 4026, 5385, 10989, 18908, 23495, 724, 501,
        783, 2362, 13681, 13669, 1809, 287, 3348, 7413, 4753, 1518, 1651, 3735,
        12545, 10588, 3551, 287, 2335, 695, 1334, 2003, 1550, 1096, 877, 569,
        830, 1206, 282, 207, 735, 64, 16, 449, 69, 729, 803, 316, 3083, 804,
        5992, 3102, 3203, 3793, 1716, 706, 419, 4616, 3777, 1682, 278, 569,
        1471, 391, 317, 5557, 211, 4966, 767, 719, 2831, 17822, 402, 1878, 5,
        81, 12, 3460, 159, 33, 1009, 942, 250, 42, 70, 47, 1050, 1926, 832, 127,
        481, 1018, 383, 908, 389, 58, 14563, 2685, 795, 536, 523, 917, 1370,
        6167, 4987, 3152, 2461, 1890, 148, 434, 3105, 311, 5927, 7823, 1594,
        231, 344, 118, 7938, 1163, 18746, 618, 2484, 3351, 13166, 12574, 2874,
        124, 1, 4282, 1152, 414, 1258, 5280, 8346, 10276, 9771, 8286, 1800, 161,
        5233, 251, 2060, 15863, 2063, 1804, 1936, 9016, 3339, 875, 1891, 3113,
        3726, 2342, 910, 335, 1508, 440, 680, 1299, 959, 414, 412, 327, 72, 204,
        115, 198, 509, 1815, 118, 304, 1128, 998, 85, 1, 1016, 345, 193, 740,
        2293, 6405, 2376, 1482, 973, 3, 9, 409, 1346, 1419, 886, 41, 442, 249,
        426, 12394, 2406, 1067, 868, 3947, 2223, 23, 115, 18, 8, 2163, 2, 3,
        1856, 1703, 394, 166, 342, 528, 727, 1399, 2732, 100, 202, 31, 43, 133,
        1245, 4, 386, 3671, 1938, 970, 363, 258, 1880, 9116, 2613, 610, 641,
        105, 213, 1265, 1534, 3107, 7162, 9008, 1141, 68, 31, 2504, 12481, 6878,
        7077, 2010, 3909, 12280, 15159, 5890, 178, 508, 37, 2772, 5127, 123,
        5972, 8453, 3882, 5070, 5288, 7531, 2109, 3495, 1476, 656, 1413, 7264,
        2537, 834, 606, 1482, 14355, 5826, 2072, 1352, 1365, 1410, 1317, 1532,
        5170, 1360, 1715, 95, 72, 227, 166, 298, 199, 819, 5039, 3499, 1243,
        1713, 2100, 1858, 626, 289, 562, 372, 191, 302, 36, 2815, 1968, 6728,
        9848, 3736, 1301, 95, 64, 616, 1592, 1382, 454, 31, 20, 234, 219, 21176,
        4155, 86, 20, 321, 10, 3, 33, 15, 2307, 303, 168, 1295, 2202, 1626,
        4084, 6752, 6200, 4318, 1447, 1888, 1826, 132, 14, 28, 145, 551, 3067,
        5447, 90, 142, 2166, 1631, 1057, 498, 3694, 9395, 2141, 4231, 3392, 783,
        100, 54, 3672, 3220, 3151, 14174, 3711, 552, 517, 1476, 4097, 2341, 762,
        455, 2237, 2433, 8457, 2764, 1589, 27, 31, 1923, 3125, 1918, 791, 220,
        377, 292, 0, 28, 1480, 1660, 2812, 3651, 3717, 7198, 5763, 1240, 638,
        3352, 16769, 12682, 696, 1292, 716, 723, 1616, 11091, 2274, 10843,
        13982, 206, 4378, 11888, 15839, 8607, 13402, 2764, 2189, 2924, 1367,
        2082, 2757, 5695, 5161, 2327, 1274, 1170, 1358, 1288, 1565, 2146, 1965,
        2237, 4716, 7685, 146, 555, 259, 281, 6410, 8781, 233, 271, 137, 500,
        1759, 4222, 4579, 121, 23, 565, 169, 61, 277, 285, 4623, 8242, 202,
        2605, 1848, 1536, 825, 183, 205, 1353, 2157, 2049, 447, 372, 4506, 883,
        1539, 1013, 3073, 1114, 3105, 11, 1883, 688, 801, 2193, 3582, 6272, 974,
        520, 313, 27, 90, 1631, 28, 5, 559, 5719, 11759, 1539, 153, 1355, 212,
        549, 699, 1643, 2130, 284, 553, 2335, 23, 108, 9420, 2497, 9585, 3691,
        908, 2045, 1007, 1937, 410, 5114, 1507, 750, 1051, 1026, 1554, 1331,
        2877, 18011, 14648, 15646, 10567, 2085, 3500, 764, 825, 3090, 14032,
        12519, 1145, 8242, 14478, 1475, 17404, 11420, 2452, 2716, 1065, 927,
        1898, 5690, 1517, 1476, 2610, 4305, 5365, 5407, 9421, 3972, 2064, 9800,
        875, 1427, 1166, 1167, 1691, 5212, 999, 191, 11, 3358, 11127, 3612, 202,
        20, 3, 7, 3, 68, 874, 1113, 24, 317, 290, 903, 1078, 941, 3772, 1234,
        312, 422, 895, 731, 474, 401, 204, 415, 848, 571, 85, 2594, 3038, 344,
        826, 2766, 3165, 428, 2936, 4722, 49, 158, 45, 2296, 3345, 6116, 3036,
        2451, 432, 3266, 5348, 46, 3826, 989, 1655, 2927, 5531, 3186, 4457, 235,
        187, 1402, 43, 87, 414, 423, 892, 557, 838, 1426, 5284, 10417, 12034,
        175, 0, 3215, 2844, 5124, 5878, 5659, 8166, 8245, 5000, 4534, 6592,
        3689, 230, 21451, 6501, 12351, 3261, 3989, 6351, 4637, 13664, 1401,
        8085, 2114, 502, 1396, 1600, 1195, 2129, 1642, 1290, 2394, 1239, 2664,
        1628, 4043, 8785, 720, 834, 752, 1412, 855, 341, 351, 1374, 8027, 8983,
        189, 127, 72, 343, 71, 356, 4, 286, 4639, 4509, 2634, 496, 64, 141, 2,
        21, 26, 334, 292, 3039, 1267, 545, 705, 1235, 771, 352, 383, 59, 524,
        30, 17, 3, 89, 210, 1357, 271, 45, 319, 2117, 1585, 1504, 2151, 2878,
        2099, 1855, 484, 4785, 12786, 36, 18, 44, 15, 3313, 763, 84, 365, 2101,
        1707, 601, 2213, 4245, 641, 413, 3041, 2474, 1479, 392, 794, 1832, 1490,
        315, 295, 155, 116, 173, 2106, 6269, 9952, 3392, 3161, 5, 10, 3222,
        4382, 2173, 3553, 4928, 20955, 20607, 11184, 6056, 4008, 1810, 137, 370,
        1307, 929, 4600, 1012, 7462, 15453, 8373, 524, 3192, 2168, 119, 1580,
        735, 400, 212, 1253, 1232, 2399, 1382, 4436, 2320, 2052, 3184, 7074,
        887, 245, 1356, 1536, 1238, 597, 5300, 9452, 6492, 1653, 13, 30, 35, 13,
        160, 107, 148, 1994, 3228, 3780, 3516, 1012, 25, 24, 1, 1577, 289, 1097,
        522, 2242, 676, 211, 456, 822, 837, 649, 65, 236, 15, 145, 99, 53, 6,
        197, 152, 260, 1015, 1621, 1037, 1192, 1252, 1701, 3118, 2599, 1713,
        7252, 4161, 12779, 4795, 2265, 12, 18, 381, 2404, 6192, 3141, 2506,
        6422, 2546, 1330, 676, 588, 734, 891, 5757, 3685, 584, 2411, 590, 4911,
        1202, 790, 1414, 2890, 1285, 1872, 1250, 2956, 2343, 30, 9, 4320, 3154,
        1960, 1937, 4762, 15474, 3344, 2790, 2062, 1341, 1481, 890, 2301, 1083,
        180, 372, 10626, 807, 53, 684, 11781, 2298, 335, 140, 1442, 257, 8486,
        4103, 6836, 11786, 11396, 3951, 2481, 746, 5235, 3274, 687, 5662, 256,
        735, 4211, 3286, 1578, 3064, 1458, 274, 416, 6948, 421, 849, 932, 791,
        272, 66, 98, 393, 989, 4173, 2448, 536, 466, 2832, 1283, 273, 333, 162,
        435, 882, 98, 264, 362, 392, 715, 455, 424, 717, 629, 135, 205, 98, 113,
        472, 5858, 1973, 1282, 837, 1018, 718, 2206, 1294, 3612, 3887, 3835,
        10165, 10209, 1839, 1510, 4109, 8522, 75, 6338, 6116, 4389, 10492,
        13987, 5043, 397, 511, 50, 1129, 189, 2375, 5602, 3898, 5027, 1423,
        5017, 5914, 2970, 2200, 1150, 972, 1251, 1563, 5051, 6155, 10, 8, 3557,
        2466, 2120, 2738, 6246, 996, 79, 1379, 2373, 2200, 120, 2009, 7785,
        1279, 188, 376, 11236, 4178, 160, 1981, 1679, 1376, 1767, 390, 1297,
        819, 335, 760, 8124, 11839, 7216, 2459, 884, 940, 275, 1606, 1332, 983,
        224, 3159, 10195, 10521, 14364, 1531, 258, 2259, 787, 1950, 934, 456,
        383, 4344, 837, 776, 46, 18, 24, 429, 386, 63, 487, 689, 976, 841, 445,
        432, 674, 1161, 155, 441, 583, 950, 657, 727, 294, 528, 783, 2114, 3609,
        1687, 10, 406, 4318, 1266, 2440, 987, 1374, 1290, 2103, 2717, 3035,
        1639, 1616, 2119, 3894, 3403, 6328, 2510, 5013, 26, 58, 1822, 1441,
        2049, 4334, 4587, 1633, 1853, 126, 600, 1699, 4520, 12164, 19301, 9225,
        2622, 1629, 1954, 3333, 1656, 689, 467, 2347, 4419, 4332, 1306, 173, 97,
        2015, 3301, 5475, 6931, 9281, 609, 748, 620, 166, 640, 346, 2372, 9543,
        2577, 330, 827, 1424, 13953, 854, 228, 1446, 1353, 8471, 2182, 2330,
        1427, 286, 336, 1082, 549, 882, 555, 1564, 870, 880, 1989, 825, 200,
        615, 4212, 5067, 3883, 903, 3036, 1790, 3783, 577, 982, 316, 784, 708,
        2016, 1022, 1015, 850, 578, 2981, 422, 2039, 556, 968, 1226, 1070, 714,
        480, 1126, 3126, 2099, 964, 1495, 1754, 1848, 523, 474, 428, 3670, 5522,
        7685, 4553, 166, 204, 523, 406, 732, 2579, 1110, 1521, 2749, 2637, 2119,
        978, 2622, 1899, 2716, 6170, 7615, 644, 200, 2290, 8, 26, 8103, 1087,
        1696, 1437, 1138, 1746, 980, 3177, 3949, 1028, 3815, 3278, 701, 1791,
        1985, 2303, 967, 1877, 1065, 1673, 450, 601, 8532, 13857, 18647, 160,
        90, 18, 88, 5135, 13444, 14745, 1525, 967, 268, 91, 14, 241, 7134,
        13209, 3124, 395, 249, 526, 3973, 977, 526, 392, 5723, 11427, 7394,
        4892, 2289, 196, 18, 182, 206, 863, 422, 387, 384, 834, 1909, 801, 880,
        1509, 1351, 776, 1113, 401, 459, 1776, 2635, 272, 928, 5679, 2973, 1257,
        1246, 644, 757, 999, 601, 796, 1240, 144, 142, 479, 1436, 1504, 1151,
        761, 1117, 2912, 2319, 759, 894, 1782, 3639, 3562, 451, 48, 174, 1490,
        1397, 785, 22, 4, 777, 668, 321, 2022, 1907, 2103, 3078, 1482, 1062,
        606, 1827, 1879, 3937, 4406, 947, 223, 738, 2583, 324, 1304, 7124, 1745,
        3647, 1851, 2051, 2636, 1841, 2893, 777, 284, 341, 5136, 3271, 653, 385,
        5184, 3091, 370, 4622, 2019, 1246, 3475, 8906, 2839, 380, 415, 861, 290,
        32, 2346, 184, 14437, 3960, 269, 86, 15, 1361, 928, 1430, 8491, 2950,
        3687, 1361, 212, 1671, 202, 97, 749, 1609, 2680, 3437, 3368, 2358, 3410,
        65, 94, 810, 2549, 6403, 1521, 1599, 1142, 1904, 1386, 665, 1697, 1356,
        1481, 1566, 91, 975, 160, 1191, 981, 506, 4751, 4165, 2828, 1283, 947,
        656, 949, 447, 726, 876, 125, 11, 250, 1227, 1302, 1257, 514, 625, 1420,
        982, 242, 126, 676, 1582, 5745, 176, 128, 353, 1439, 4030, 22, 92, 128,
        687, 1650, 2154, 540, 3081, 3601, 2265, 761, 1122, 1082, 2375, 2084,
        634, 729, 708, 593, 551, 1049, 189, 1481, 808, 730, 5218, 2464, 935,
        2024, 2985, 2973, 111, 788, 833, 3016, 1400, 324, 1260, 676, 2123, 7980,
        3093, 1391, 4155, 7856, 6481, 8450, 258, 422, 566, 38, 14, 339, 789,
        3997, 11301, 6370, 3412, 1827, 1455, 450, 1842, 2339, 1763, 1380, 1155,
        853, 1727, 1937, 212, 1256, 1525, 1873, 1294, 1633, 1147, 4348, 7707,
        3108, 10263, 13046, 10274, 3858, 3003, 1413, 1530, 1901, 2493, 1744,
        714, 845, 976, 371, 796, 521, 111, 586, 825, 1621, 2739, 1375, 715, 928,
        600, 547, 479, 643, 749, 87, 89, 7, 467, 882, 686, 426, 357, 1178, 437,
        479, 1233, 497, 435, 7368, 20, 215, 742, 3187, 4354, 24, 215, 360, 1300,
        1128, 1254, 1194, 3371, 3929, 583, 171, 187, 658, 961, 765, 521, 461,
        560, 950, 1405, 670, 1762, 2066, 1239, 998, 4786, 1671, 1573, 1178,
        1411, 538, 28, 270, 80, 2691, 8257, 1220, 3272, 2401, 16420, 19186,
        1164, 6803, 12854, 9987, 10094, 1106, 169, 192, 458, 1989, 23, 6, 205,
        307, 12228, 9799, 5600, 1931, 623, 352, 1759, 3274, 2310, 1141, 892,
        1327, 3595, 8871, 3507, 902, 1853, 556, 12951, 1898, 704, 5782, 2068,
        148, 428, 2854, 1162, 1999, 1696, 651, 1165, 2353, 5920, 5405, 1779,
        5855, 496, 1338, 1105, 163, 595, 1131, 1619, 1250, 2322, 1731, 1254,
        904, 685, 479, 486, 541, 386, 303, 621, 34, 122, 605, 1064, 648, 357,
        546, 308, 815, 1324, 2999, 2994, 1073, 17, 16, 145, 1350, 1048, 124, 8,
        465, 2130, 840, 1049, 2205, 2584, 3390, 977, 1542, 2161, 937, 892, 727,
        435, 1605, 3022, 2392, 5356, 10709, 8547, 11718, 186, 7, 1657, 1058, 39,
        3162, 6570, 1897, 2135, 398, 1200, 7837, 15766, 4417, 2100, 10599,
        13079, 14998, 126, 1677, 2517, 5055, 1865, 18, 65, 110, 681, 1414, 532,
        105, 55, 486, 2205, 6020, 5547, 2930, 4691, 5381, 2040, 1811, 1114, 622,
        1694, 275, 465, 1576, 9094, 12291, 10751, 839, 9804, 16766, 23945, 3172,
        51, 279, 212, 814, 298, 266, 1111, 2269, 3034, 3614, 5437, 3807, 3980,
        1437, 1829, 1461, 1341, 859, 663, 622, 1676, 1004, 2099, 1849, 2450,
        1757, 961, 797, 446, 581, 595, 375, 69, 16, 253, 443, 3346, 524, 1392,
        1001, 421, 543, 504, 587, 5964, 1300, 179, 10, 8, 257, 378, 206, 389,
        2012, 2766, 2388, 1499, 2127, 2326, 1683, 1246, 1415, 1773, 2041, 2397,
        3231, 3132, 1338, 7220, 7135, 2391, 10621, 10756, 7680, 1698, 13, 1110,
        39, 248, 8021, 19525, 5371, 1514, 1672, 2542, 12125, 22472, 1906, 874,
        929, 5703, 2049, 594, 533, 2163, 16541, 1297, 30, 251, 3690, 4011, 6067,
        8787, 12623, 3380, 871, 693, 161, 49, 71, 2904, 7168, 11804, 1285, 628,
        1987, 196, 656, 1015, 2059, 900, 2363, 13008, 2601, 1779, 8765, 8834,
        1352, 279, 260, 1088, 468, 57, 2749, 618, 851, 2939, 5199, 5336, 3184,
        6564, 820, 555, 3942, 1316, 2706, 1375, 846, 1021, 1324, 1217, 1640,
        2523, 2399, 1268, 679, 246, 583, 487, 414, 159, 11, 5, 1120, 244, 3111,
        246, 1206, 285, 35, 7210, 12271, 2862, 19148, 705, 343, 1619, 4590,
        11233, 72, 875, 2221, 2743, 1671, 1054, 1121, 1474, 689, 1152, 1010,
        1168, 842, 602, 298, 289, 40, 1479, 1350, 2223, 2958, 1199, 1700, 895,
        258, 4615, 387, 412, 4969, 1261, 1141, 5014, 8575, 1507, 7273, 2786,
        177, 482, 671, 882, 4280, 639, 1136, 1879, 9272, 18906, 316, 6706, 4397,
        4147, 5344, 5531, 11961, 6466, 715, 477, 89, 6, 111, 2170, 6161, 12325,
        6349, 2241, 1106, 1031, 563, 1159, 587, 683, 3768, 9694, 8647, 18, 148,
        1530, 1888, 386, 362, 708, 272, 252, 9895, 3379, 96, 97, 351, 1783,
        2636, 1233, 416, 3217, 564, 1145, 2613, 2558, 2077, 747, 1367, 982,
        1110, 1493, 2512, 1925, 957, 434, 487, 853, 502, 356, 136, 4960, 2301,
        36, 547, 805, 137, 158, 679, 1783, 13114, 5592, 9800, 890, 8653, 12154,
        1371, 4369, 14, 526, 1323, 83, 417, 520, 268, 160, 265, 707, 1393, 39,
        127, 4, 558, 1459, 77, 104, 74, 937, 1364, 179, 39, 9299, 12617, 16180,
        176, 581, 689, 6, 4725, 7851, 21180, 12690, 782, 1905, 7, 64, 890, 1008,
        2427, 800, 1029, 1302, 12142, 7376, 27, 5857, 7697, 6504, 2522, 492, 6,
        8, 15, 1894, 1597, 2097, 1470, 1079, 2205, 9566, 814, 1226, 1567, 2393,
        3174, 1304, 833, 1587, 1444, 7796, 10154, 152, 3233, 76, 1044, 120, 521,
        643, 613, 528, 4942, 3117, 1806, 128, 159, 365, 201, 1758, 396, 606,
        501, 548, 636, 787, 2097, 1977, 1821, 1484, 1366, 1991, 2730, 2764,
        1624, 968, 762, 1351, 1285, 1426, 7538, 4760, 3721, 270, 39, 244, 1503,
        827, 554, 1462, 1501, 5757, 2944, 5779, 9784, 420, 187, 2744, 627, 190,
        31, 176, 70, 192, 137, 92, 121, 750, 20, 112, 839, 2896, 3088, 2271,
        3345, 2602, 2124, 895, 125, 60, 2185, 394, 2458, 5, 986, 5895, 976, 751,
        11781, 8246, 3713, 9583, 1567, 12777, 5873, 3320, 4374, 8897, 12027,
        5116, 6605, 9758, 14320, 6308, 1854, 1694, 3212, 65, 2725, 493, 4, 80,
        38, 5194, 5496, 3477, 2637, 3406, 1726, 272, 1236, 2434, 817, 4923,
        8658, 6830, 2610, 1226, 981, 545, 6967, 554, 1584, 1573, 1405, 1976,
        599, 408, 527, 337, 405, 2054, 1456, 958, 435, 782, 716, 173, 23, 168,
        1557, 748, 707, 731, 727, 2942, 6348, 6740, 3423, 2475, 2283, 2413,
        1908, 1000, 465, 566, 613, 236, 130, 8329, 561, 666, 775, 1512, 919,
        1040, 1495, 1445, 2197, 2040, 1377, 2045, 1106, 706, 200, 959, 934,
        2178, 884, 1513, 271, 1473, 580, 93, 340, 7167, 50, 1302, 1745, 2132,
        1749, 1207, 1666, 3965, 2197, 1019, 600, 2183, 9575, 4014, 28, 6, 2389,
        10409, 20288, 2717, 3669, 2403, 4010, 3450, 1851, 19259, 10423, 1376,
        879, 3097, 15775, 3591, 2026, 1950, 3083, 8698, 4480, 164, 334, 14, 28,
        111, 17, 3, 150, 18534, 1867, 1087, 1398, 2409, 3962, 801, 734, 1744,
        1295, 1587, 5318, 5440, 4945, 5734, 3517, 93, 1408, 31, 316, 5126, 8138,
        9943, 7669, 1564, 522, 554, 589, 746, 1417, 1152, 647, 917, 1408, 1769,
        78, 120, 2884, 1966, 1436, 1199, 1252, 1639, 5855, 3728, 2286, 1347,
        1088, 714, 489, 2, 19, 30, 195, 55, 193, 615, 4874, 4315, 1887, 646,
        691, 692, 962, 1372, 1332, 634, 865, 109, 343, 1004, 519, 537, 1984,
        2503, 1558, 824, 712, 5303, 1804, 7702, 5841, 7639, 1023, 351, 409, 755,
        2595, 1102, 741, 698, 249, 234, 1831, 1259, 12888, 4822, 284, 131, 347,
        5497, 4387, 1413, 5510, 7335, 5729, 19588, 6597, 1226, 4150, 198, 1218,
        3367, 11775, 8602, 1473, 396, 891, 13647, 12060, 350, 46, 24, 132, 137,
        639, 2571, 3326, 17426, 650, 498, 848, 1455, 2921, 59, 723, 1375, 1403,
        325, 698, 515, 762, 688, 279, 643, 3438, 524, 81, 252, 75, 1552, 3840,
        3258, 1417, 853, 769, 667, 1247, 1457, 862, 743, 803, 697, 1511, 428,
        1088, 1003, 1134, 1365, 1398, 491, 904, 745, 988, 463, 432, 420, 92, 52,
        14, 5, 89, 1108, 203, 207, 793, 3477, 2198, 451, 524, 396, 382, 493,
        1447, 633, 349, 198, 400, 445, 1091, 1164, 1755, 1386, 1354, 486, 1193,
        487, 1083, 7020, 6654, 1006, 582, 151, 190, 329, 4488, 1360, 906, 166,
        101, 802, 3446, 7096, 10267, 5732, 3237, 337, 2033, 3654, 5542, 3866,
        7862, 4970, 5933, 26560, 17199, 1831, 3279, 8246, 4071, 1622, 5633, 593,
        290, 1097, 675, 791, 208, 1414, 2801, 3083, 3720, 4389, 2295, 3044,
        4141, 13769, 1893, 948, 1428, 1700, 2295, 1073, 200, 1083, 31, 508, 419,
        595, 5, 10, 570, 1528, 4081, 4465, 628, 74, 245, 274, 902, 1374, 2005,
        2066, 1011, 599, 1170, 1592, 1420, 1221, 1366, 1262, 1000, 1886, 882,
        1051, 988, 1201, 534, 843, 1188, 401, 1275, 1792, 484, 528, 724, 251,
        751, 140, 1603, 663, 56, 21, 68, 49, 1963, 1849, 1198, 597, 369, 716,
        634, 1020, 1785, 62, 551, 615, 1585, 843, 951, 821, 385, 423, 1861,
        1235, 1099, 3707, 1896, 632, 206, 411, 177, 163, 6326, 5675, 1284, 313,
        1715, 1713, 1497, 1484, 3958, 2732, 1870, 811, 5674, 240, 3865, 2890,
        3561, 2942, 10946, 16923, 22454, 11032, 11988, 11732, 7706, 49, 923,
        244, 56, 76, 2574, 1220, 3021, 1029, 1794, 3610, 2443, 1208, 2018, 3258,
        14354, 6066, 2275, 1350, 456, 1455, 2686, 1079, 9781, 553, 25, 92, 925,
        1097, 110, 58, 228, 711, 895, 2690, 2071, 25, 208, 375, 1, 948, 370,
        565, 748, 744, 1259, 2952, 1636, 1657, 2272, 1456, 1939, 6159, 12451,
        999, 121, 380, 644, 1659, 670, 453, 479, 2020, 79, 83, 46, 110, 425,
        4718, 7435, 3852, 1823, 647, 577, 7, 80, 1995, 4404, 1169, 752, 421,
        532, 345, 243, 49, 651, 502, 656, 612, 671, 318, 181, 382, 411, 243,
        1390, 985, 1220, 1990, 870, 270, 845, 129, 4335, 6018, 2145, 675, 520,
        235, 256, 199, 977, 965, 577, 1011, 8615, 4901, 9896, 9965, 3623, 8372,
        8075, 28721, 22171, 3731, 13767, 483, 102, 11, 236, 1047, 1440, 1984,
        2457, 1553, 121, 57, 70, 1411, 1772, 1441, 2008, 1736, 16684, 4766,
        6389, 5936, 3161, 2509, 926, 10819, 5364, 1365, 1546, 3261, 2923, 6816,
        23, 13, 129, 595, 722, 2358, 3089, 324, 192, 156, 67, 1222, 521, 2323,
        1335, 1953, 1198, 4643, 1379, 2060, 2208, 3973, 5254, 2095, 11544, 24,
        80, 117, 208, 1966, 867, 555, 1096, 435, 10, 7, 7, 284, 1840, 4647,
        4150, 1866, 2313, 818, 834, 547, 161, 5835, 9365, 2089, 474, 257, 348,
        132, 209, 182, 721, 614, 980, 1552, 757, 402, 315, 251, 546, 2783, 226,
        618, 733, 5379, 3219, 1240, 161, 321, 1044, 2404, 1216, 1142, 1945, 107,
        194, 367, 1251, 357, 3001, 5209, 870, 5395, 6418, 2844, 15926, 18669,
        1895, 1684, 524, 5264, 2323, 4392, 1174, 3531, 1933, 2100, 1396, 1111,
        1291, 2067, 613, 27, 181, 1151, 864, 1232, 966, 1673, 15620, 1517, 1506,
        2055, 2143, 932, 2740, 4304, 6642, 1417, 766, 1115, 14438, 16281, 443,
        302, 162, 452, 853, 2860, 5269, 1157, 673, 593, 237, 52, 203, 3576,
        5703, 1095, 1300, 5395, 1338, 2101, 1574, 2817, 2073, 450, 2578, 1787,
        48, 314, 699, 153, 1618, 773, 1469, 3682, 154, 298, 509, 352, 1688,
        1272, 138, 86, 823, 2123, 369, 511, 697, 2035, 8928, 2213, 723, 402,
        100, 496, 743, 688, 220, 725, 1460, 4564, 2645, 829, 427, 1199, 300,
        156, 102, 891, 984, 2763, 1592, 91, 83, 725, 812, 1031, 840, 1535, 1850,
        422, 305, 855, 1547, 25, 1873, 948, 4747, 5024, 13572, 131, 8288, 9022,
        123, 147, 395, 1030, 2808, 9064, 8929, 727, 2223, 903, 930, 618, 852,
        3647, 1100, 341, 485, 171, 1761, 1502, 1872, 3905, 4790, 2133, 1250,
        914, 343, 858, 4413, 5864, 1729, 2226, 416, 715, 1804, 1124, 543, 140,
        330, 240, 811, 3280, 7738, 1645, 262, 54, 29, 148, 1232, 209, 72, 206,
        1034, 5545, 3401, 4333, 1092, 725, 1209, 1323, 1695, 1920, 1306, 1373,
        157, 251, 104, 201, 1324, 903, 501, 151, 411, 481, 1396, 84, 82, 117,
        678, 1456, 104, 929, 1290, 1355, 1339, 824, 865, 788, 1027, 103, 378,
        4333, 52, 60, 181, 3215, 4067, 1345, 587, 588, 253, 883, 608, 286, 586,
        1394, 1224, 747, 845, 297, 1449, 801, 885, 1261, 774, 1136, 864, 3333,
        6851, 2468, 1457, 1863, 1654, 486, 2750, 944, 14368, 1074, 691, 235,
        3035, 1465, 9167, 7944, 7566, 1519, 495, 774, 668, 502, 420, 6955, 3330,
        180, 21, 969, 660, 1115, 1871, 2054, 2453, 1092, 2190, 4009, 2216, 1670,
        1624, 1977, 2707, 14421, 1283, 302, 62, 245, 221, 1350, 767, 639, 880,
        6476, 6393, 1240, 568, 504, 566, 8, 691, 1595, 4558, 164, 121, 130,
        4788, 1610, 1151, 488, 1600, 3636, 2340, 4125, 1697, 2042, 1714, 1199,
        1120, 106, 467, 12, 293, 1390, 259, 656, 1371, 219, 46, 80, 1091, 1900,
        1902, 1030, 1730, 945, 569, 442, 927, 1414, 1542, 297, 3914, 11, 67, 67,
        112, 968, 1158, 941, 503, 615, 745, 598, 2088, 401, 1281, 1017, 4231,
        3715, 1641, 1993, 695, 2475, 1575, 2338, 1653, 384, 1006, 865, 9225,
        7108, 7048, 7184, 5756, 951, 11, 1749, 7576, 6838, 1061, 96, 7349, 2066,
        2215, 913, 4195, 640, 420, 1427, 960, 1007, 3165, 12239, 743, 150, 3850,
        13, 232, 244, 1728, 2373, 2822, 2039, 1771, 1789, 3232, 1807, 2978,
        2533, 8629, 11769, 4294, 175, 106, 523, 60, 1141, 353, 353, 1208, 1798,
        2340, 784, 499, 654, 351, 1187, 955, 2058, 705, 838, 817, 278, 81, 8288,
        1625, 1437, 733, 1104, 1170, 1340, 989, 1939, 3677, 4662, 2287, 172, 64,
        47, 723, 1208, 561, 619, 1632, 846, 197, 11, 634, 3474, 2203, 1103,
        2588, 911, 670, 586, 1247, 1780, 1405, 522, 65, 110, 1072, 95, 354, 798,
        354, 212, 1361, 344, 569, 702, 440, 1745, 3425, 3124, 866, 1634, 3028,
        159, 445, 952, 2536, 1178, 5844, 3318, 601, 603, 261, 108, 340, 442,
        1848, 209, 287, 3143, 1241, 662, 783, 1069, 11043, 3428, 2414, 993,
        1673, 1868, 435, 442, 1017, 3682, 9176, 5536, 367, 585, 1067, 781, 279,
        564, 80, 486, 6297, 2790, 2129, 1365, 825, 1627, 2885, 1703, 1900, 1965,
        3259, 1230, 664, 842, 65, 660, 551, 468, 2188, 1777, 1673, 894, 330,
        277, 199, 2389, 7440, 8861, 3302, 1739, 1083, 761, 491, 9519, 6070,
        2992, 1403, 1123, 1476, 1160, 847, 896, 828, 677, 1353, 395, 211, 1047,
        1754, 909, 625, 746, 1475, 1337, 74, 42, 553, 3607, 1361, 1248, 5055,
        944, 775, 1235, 883, 2041, 3889, 603, 494, 459, 192, 394, 983, 768, 513,
        351, 106, 164, 389, 447, 3000, 4887, 2849, 618, 625, 130, 274, 288,
        1481, 916, 8208, 12239, 7241, 537, 345, 40, 158, 589, 248, 169, 15, 38,
        4299, 3682, 262, 414, 579, 1302, 14366, 13524, 2005, 1059, 989, 590,
        1159, 831, 1819, 3285, 1975, 305, 123, 735, 809, 2125, 13356, 9041, 43,
        32, 763, 4548, 2166, 1541, 864, 2298, 2373, 1337, 5387, 646, 683, 1424,
        1084, 2257, 243, 427, 352, 1120, 3978, 2698, 1436, 1504, 309, 902, 581,
        2414, 2857, 1531, 975, 1208, 791, 921, 754, 323, 7312, 2780, 735, 939,
        1425, 2153, 2036, 1476, 268, 1250, 1862, 2751, 2976, 69, 91, 1418, 1487,
        955, 2053, 3447, 2261, 374, 267, 289, 215, 675, 2904, 251, 2339, 3535,
        1301, 324, 961, 91, 86, 59, 136, 231, 625, 726, 333, 257, 120, 419, 384,
        678, 1027, 2219, 1045, 788, 571, 341, 855, 1297, 3058, 1922, 3113,
        11908, 4440, 4419, 156, 210, 24, 345, 349, 61, 20, 2, 17277, 13416,
        1008, 5270, 704, 1428, 8726, 4879, 2457, 2126, 3183, 934, 896, 1051,
        1553, 1533, 285, 107, 31, 38, 582, 13944, 20386, 2990, 1462, 2649, 4929,
        7041, 1965, 1907, 1888, 1854, 3366, 5462, 3492, 663, 635, 461, 916,
        1665, 406, 226, 316, 684, 5481, 4790, 741, 627, 1128, 1170, 834, 741,
        499, 154, 153, 172, 809, 838, 61, 394, 782, 917, 1001, 576, 3266, 7698,
        4625, 1076, 1066, 1014, 1857, 1718, 1982, 1398, 3, 588, 1507, 787, 1778,
        4221, 1357, 604, 234, 985, 635, 6973, 1177, 2440, 3357, 3513, 3127,
        7834, 6781, 1584, 1457, 824, 5, 21, 351, 808, 396, 212, 382, 932, 704,
        806, 461, 924, 659, 893, 659, 1288, 850, 5110, 7434, 3146, 494, 1173,
        6137, 7286, 1283, 1200, 805, 530, 5501, 365, 117, 951, 7659, 11410,
        1744, 431, 934, 1239, 1254, 2863, 1848, 2381, 761, 794, 843, 2230, 1118,
        796, 514, 441, 90, 82, 2044, 7327, 5389, 1783, 1286, 1451, 9888, 10085,
        1712, 1646, 1596, 688, 2002, 2616, 4208, 632, 692, 523, 1799, 5120,
        3512, 450, 630, 1716, 6096, 7450, 5542, 905, 666, 679, 589, 547, 295,
        209, 156, 115, 850, 1344, 12, 643, 180, 208, 69, 449, 2805, 8031, 4052,
        940, 351, 277, 2353, 1201, 2154, 988, 1374, 338, 1451, 2177, 1584, 3440,
        11236, 1244, 1110, 2980, 1674, 576, 76, 1826, 3108, 10853, 5115, 5999,
        411, 952, 954, 1186, 1457, 84, 627, 2163, 770, 554, 549, 298, 337, 1024,
        641, 570, 192, 282, 816, 1326, 5418, 7587, 2728, 772, 1179, 196, 769,
        2920, 1424, 917, 1462, 9269, 12375, 10564, 2641, 1224, 16287, 4228, 729,
        1449, 605, 250, 1335, 1419, 710, 738, 3981, 3857, 715, 1159, 837, 319,
        452, 143, 131, 1111, 2709, 1184, 696, 666, 1192, 1368, 11767, 10333,
        1367, 1217, 881, 755, 1540, 7819, 1011, 1153, 868, 1105, 1186, 6141,
        1509, 346, 365, 944, 2932, 3721, 2375, 958, 903, 1585, 741, 244, 232,
        316, 165, 222, 1159, 2174, 369, 253, 680, 765, 949, 1229, 3702, 3756,
        2702, 1072, 787, 526, 461, 1632, 123, 90, 332, 4473, 192, 830, 1580,
        4188, 4742, 1782, 3097, 13923, 2944, 473, 107, 4213, 3149, 9871, 5537,
        2658, 357, 1852, 1611, 820, 955, 1487, 4130, 3535, 1752, 622, 1081,
        2540, 150, 184, 695, 943, 329, 287, 400, 1317, 4256, 801, 299, 1177,
        911, 2788, 4116, 1198, 194, 245, 686, 1980, 3393, 4111, 365, 293, 5196,
        10225, 3564, 695, 270, 335, 1242, 1824, 845, 1718, 1083, 1571, 2016,
        1069, 1200, 350, 2555, 1765, 6377, 4035, 1460, 391, 1069, 310, 454,
        5082, 12700, 7883, 1264, 1203, 885, 1454, 935, 7731, 872, 1581, 376,
        139, 659, 2789, 1184, 1140, 406, 2155, 1614, 2701, 1808, 2928, 1064,
        633, 1257, 582, 353, 531, 272, 646, 1992, 2231, 140, 13, 1667, 2210,
        1300, 3276, 4944, 1624, 2529, 1184, 592, 501, 1292, 1957, 65, 251, 2341,
        1292, 391, 968, 2425, 3714, 3581, 506, 11305, 19599, 831, 701, 4049,
        9263, 2820, 1594, 9859, 9766, 974, 1970, 1905, 780, 1193, 2550, 2629,
        1528, 975, 1473, 293, 1555, 990, 1441, 4402, 3649, 947, 989, 1330, 470,
        1413, 427, 885, 231, 797, 2346, 3388, 5307, 1133, 3941, 3368, 602, 7583,
        997, 2130, 1434, 1932, 2249, 1513, 2587, 3167, 4367, 9582, 7425, 4438,
        3025, 3098, 5884, 2459, 6078, 1802, 959, 2211, 11387, 8910, 2786, 1817,
        1213, 761, 276, 1925, 8172, 9059, 5050, 2860, 1814, 1440, 1957, 1794,
        2630, 1437, 233, 316, 582, 711, 1968, 1756, 935, 1619, 8937, 7943, 2203,
        3524, 4495, 5554, 6382, 656, 1335, 778, 1233, 356, 560, 865, 990, 235,
        965, 1999, 1438, 2131, 3475, 1136, 1587, 1400, 673, 858, 600, 1563,
        1455, 294, 510, 785, 1652, 2167, 964, 2084, 1580, 1788, 640, 2563, 9481,
        345, 368, 792, 4605, 4712, 215, 260, 40, 58, 598, 2201, 1358, 784, 821,
        619, 749, 409, 927, 831, 654, 502, 514, 940, 3869, 521, 747, 98, 640,
        1996, 963, 103, 124, 117, 2133, 2097, 5626, 7064, 4379, 4250, 682, 4737,
        1660, 357, 571, 1412, 1284, 1281, 553, 654, 2674, 5395, 1640, 1819,
        1783, 2780, 8146, 9096, 1833, 2986, 4415, 2640, 5563, 8019, 1447, 612,
        1597, 1995, 741, 8034, 1313, 9083, 3268, 3423, 4419, 4931, 4375, 2294,
        3298, 548, 623, 633, 989, 1890, 1857, 1347, 893, 122, 12325, 8529, 1800,
        5790, 6750, 3274, 6793, 556, 945, 446, 191, 750, 760, 173, 75, 47, 2663,
        1585, 1193, 1593, 3025, 1558, 2666, 725, 2407, 1476, 2179, 1297, 10,
        533, 1687, 3878, 1117, 730, 2662, 3817, 1361, 1118, 571, 76, 88, 252,
        906, 3852, 6503, 557, 49, 372, 4446, 156, 22, 426, 854, 550, 231, 405,
        642, 450, 3126, 628, 524, 554, 2600, 11691, 7283, 1206, 256, 191, 242,
        1500, 850, 34, 12, 445, 2891, 3895, 992, 2661, 5791, 6351, 58, 4805,
        945, 1763, 975, 1495, 717, 1371, 1133, 1217, 1897, 767, 1800, 5397,
        5623, 2430, 4246, 1971, 3259, 4787, 6532, 6237, 893, 5286, 2819, 1868,
        1372, 2643, 576, 2063, 2647, 6442, 468, 40, 28, 886, 880, 1110, 5528,
        594, 1284, 1344, 2259, 1484, 260, 54, 346, 26, 366, 325, 207, 6470,
        8925, 1692, 6092, 2020, 345, 479, 586, 600, 372, 605, 422, 109, 259,
        1030, 1077, 1906, 3632, 2011, 5538, 1871, 3891, 951, 2776, 1162, 2, 61,
        7568, 6677, 4230, 4921, 8430, 7621, 1445, 1314, 408, 26, 229, 208, 90,
        2700, 595, 752, 1153, 2621, 3238, 12799, 65, 117, 90, 198, 184, 332,
        902, 563, 2186, 651, 963, 616, 843, 12215, 8148, 1150, 315, 158, 163,
        2503, 4080, 108, 498, 394, 1304, 4059, 3458, 3045, 4837, 2498, 945,
        1118, 2417, 2186, 1489, 896, 571, 833, 965, 1181, 923, 2346, 2953, 7246,
        4830, 608, 1905, 809, 5782, 2986, 1737, 2666, 6787, 1314, 984, 752,
        4528, 4292, 1937, 4082, 5125, 6382, 319, 101, 37, 121, 111, 63, 1958,
        31, 136, 528, 1164, 29, 659, 673, 67, 1005, 869, 896, 580, 518, 422,
        4775, 4763, 1649, 2589, 1654, 631, 598, 2094, 534, 566, 588, 594, 6,
        993, 1827, 1848, 1885, 10518, 3867, 1804, 1585, 2037, 967, 9, 402, 5011,
        3509, 2187, 100, 329, 1730, 1531, 387, 17, 11, 409, 14, 3718, 12362,
        3413, 3220, 3943, 4972, 4077, 11626, 14136, 255, 308, 275, 73, 794, 960,
        737, 820, 557, 2781, 2845, 195, 739, 881, 799, 32, 593, 853, 1389, 8582,
        7185, 1099, 677, 1642, 1046, 3030, 2068, 2798, 922, 42, 327, 3607, 4943,
        2393, 1264, 609, 631, 1113, 1910, 1420, 1499, 1254, 550, 1741, 591,
        1486, 2921, 1438, 151, 60, 2034, 7536, 4185, 4830, 3701, 4034, 3558,
        2904, 1756, 2068, 5757, 11761, 8571, 6510, 3414, 1700, 5954, 887, 556,
        135, 78, 20, 117, 243, 965, 1691, 1480, 1710, 964, 449, 184, 134, 5790,
        3981, 4487, 940, 1069, 709, 1376, 493, 4407, 2198, 673, 171, 10, 625,
        1531, 2938, 1522, 8925, 6528, 200, 2493, 544, 3034, 506, 2790, 2183,
        717, 124, 89, 1803, 975, 1358, 412, 824, 2150, 12337, 2793, 9709, 12709,
        8981, 5670, 1554, 952, 796, 1934, 7228, 2123, 286, 3381, 375, 78, 409,
        2127, 1129, 544, 4231, 757, 563, 914, 648, 126, 779, 877, 954, 1435,
        5454, 9409, 5166, 1548, 3307, 2803, 525, 863, 2245, 3171, 297, 362, 32,
        1505, 3328, 2920, 1770, 1594, 1895, 2087, 1044, 329, 436, 517, 900,
        1241, 2251, 1438, 1037, 1870, 925, 1537, 1349, 4017, 4667, 2828, 2219,
        5321, 3517, 4307, 3000, 7114, 13305, 5504, 4698, 2666, 1508, 9946, 1673,
        2257, 2682, 1804, 412, 986, 3471, 5278, 5389, 3258, 2335, 1806, 401,
        3960, 455, 697, 2376, 4681, 3084, 815, 607, 603, 1746, 1155, 929, 1174,
        339, 343, 538, 1050, 785, 30, 469, 452, 60, 49, 187, 111, 54, 2436,
        2922, 4, 51, 1849, 997, 58, 1382, 1273, 6, 14272, 10618, 2420, 668,
        2431, 5210, 1689, 841, 389, 267, 316, 2133, 1887, 4569, 6074, 1195, 256,
        310, 29, 39, 20, 0, 817, 1053, 4716, 1354, 698, 1264, 2695, 1117, 897,
        937, 1378, 1938, 1751, 1113, 897, 78, 213, 1369, 10287, 5064, 1146, 12,
        193, 5, 289, 3749, 5976, 4849, 3372, 1223, 1140, 2025, 552, 783, 3488,
        3877, 3484, 211, 538, 823, 2730, 1469, 2274, 1065, 1433, 2078, 3291,
        6405, 9678, 11745, 10837, 12028, 1575, 2845, 2877, 2935, 2045, 3860,
        8100, 1568, 8771, 1523, 1112, 226, 483, 863, 4664, 9145, 6449, 1500,
        5169, 911, 2257, 260, 626, 1433, 708, 1199, 417, 935, 734, 1207, 2102,
        2078, 618, 236, 125, 138, 148, 130, 81, 170, 124, 1020, 368, 540, 430,
        249, 403, 2753, 2198, 2, 1288, 36, 173, 0, 520, 272, 815, 344, 983,
        1700, 961, 580, 194, 213, 948, 1145, 1186, 1133, 2057, 1161, 1307, 1345,
        657, 1520, 385, 34, 16, 272, 8350, 4469, 770, 686, 2377, 699, 454, 1140,
        1719, 3939, 1166, 638, 468, 142, 74, 1195, 14311, 7001, 215, 18, 60, 36,
        42, 77, 2689, 4058, 2850, 1469, 1144, 4372, 2663, 5784, 10836, 7172,
        1577, 425, 403, 1063, 2781, 1389, 1843, 1076, 2913, 2804, 2198, 3748,
        3165, 2799, 6730, 8431, 901, 2482, 781, 350, 679, 1055, 1525, 1337,
        9292, 5355, 7996, 965, 1470, 387, 378, 1667, 2002, 877, 389, 433, 27,
        843, 175, 372, 968, 4061, 2151, 1699, 1191, 1203, 1749, 2280, 625, 418,
        973, 1457, 901, 417, 478, 577, 1116, 1242, 1247, 1630, 302, 22, 6531,
        5819, 13, 20, 275, 234, 42, 6609, 642, 351, 923, 716, 780, 29, 323, 771,
        471, 1036, 1038, 418, 190, 569, 419, 324, 251, 933, 1360, 1528, 456,
        411, 583, 579, 10627, 2217, 581, 978, 2668, 7336, 72, 524, 4481, 4531,
        1700, 2564, 62, 11, 76, 210, 6798, 8902, 22, 13, 14, 641, 129, 515, 178,
        1719, 1575, 343, 530, 2565, 2227, 3444, 11276, 10940, 412, 636, 382,
        935, 2685, 2205, 1475, 2340, 3307, 690, 3522, 3284, 3563, 3237, 3631,
        5358, 1069, 1655, 1019, 475, 693, 1763, 2373, 1049, 798, 3247, 13376,
        5578, 621, 623, 685, 412, 483, 2103, 314, 121, 69, 805, 588, 609, 2509,
        5593, 6629, 3771, 2162, 2230, 2465, 510, 5, 6, 1940, 2131, 1322, 532,
        431, 583, 810, 881, 936, 1193, 1799, 389, 11063, 8295, 31, 0, 24, 720,
        979, 1010, 814, 715, 27, 1746, 914, 131, 506, 880, 1096, 1036, 317, 240,
        287, 420, 1027, 579, 878, 783, 6226, 10076, 7163, 6832, 11, 314, 2924,
        907, 498, 3822, 10084, 4998, 304, 188, 1965, 224, 30, 6067, 2362, 27,
        264, 42, 1514, 227, 2082, 477, 32, 60, 1387, 540, 95, 1775, 1085, 62,
        128, 580, 3828, 2429, 4321, 5750, 24, 334, 757, 1609, 3990, 4882, 3665,
        2499, 661, 640, 2799, 3733, 2076, 2765, 4476, 3704, 1920, 1206, 942,
        1670, 1952, 1202, 1780, 1534, 79, 39, 9293, 2389, 312, 1153, 746, 405,
        598, 5028, 345, 6478, 2091, 2568, 1874, 1330, 938, 1474, 1329, 1030,
        725, 974, 1058, 183, 336, 126, 1075, 889, 650, 572, 352, 341, 467, 690,
        797, 1194, 893, 98, 10512, 82, 446, 508, 1884, 2332, 1383, 637, 1272,
        926, 1107, 1131, 1797, 728, 546, 2181, 6026, 4746, 2113, 1914, 1373,
        235, 372, 684, 954, 1928, 2503, 7525, 7382, 7835, 6793, 77, 86, 1461,
        949, 10022, 104, 400, 198, 300, 1915, 64, 10, 3686, 10026, 389, 100,
        201, 1064, 6, 75, 867, 7, 1447, 1749, 995, 46, 4521, 1151, 1328, 244,
        213, 88, 271, 1299, 1867, 38, 160, 1084, 1625, 5603, 5902, 1899, 733,
        1069, 936, 3200, 9701, 8289, 1494, 4350, 2932, 1806, 1629, 565, 643,
        1554, 1254, 1132, 257, 544, 2700, 6915, 917, 1335, 1150, 467, 228, 910,
        114, 377, 1070, 11472, 7646, 714, 295, 56, 129, 195, 547, 2538, 5168,
        2743, 172, 15, 207, 345, 254, 249, 818, 1722, 780, 438, 604, 783, 819,
        664, 1028, 490, 4253, 5862, 2582, 2692, 4367, 4396, 1906, 1707, 949,
        1308, 925, 4658, 3773, 3579, 6260, 6942, 2168, 2267, 1714, 2460, 2043,
        629, 937, 1414, 940, 983, 1269, 3178, 3538, 4952, 3175, 13, 2133, 1760,
        76, 47, 111, 275, 3266, 2093, 569, 1174, 1338, 15275, 266, 28, 4, 1102,
        35, 335, 215, 8155, 17044, 9939, 297, 196, 7500, 1293, 1630, 976, 194,
        248, 671, 657, 926, 42, 1433, 1378, 1666, 2413, 1371, 920, 1169, 1641,
        7474, 2721, 2492, 2722, 3680, 11297, 3018, 1401, 1509, 1588, 1306, 2143,
        1727, 779, 84, 35, 9, 24, 972, 15811, 5000, 754, 5648, 4600, 1082, 267,
        441, 296, 98, 44, 53, 43, 279, 469, 35, 35, 30, 6407, 2648, 660, 324,
        99, 163, 294, 442, 5451, 4626, 876, 667, 535, 805, 1383, 383, 48, 22,
        28, 1139, 1294, 4496, 11470, 2443, 116, 356, 11, 68, 18, 1389, 3686,
        3831, 2548, 2008, 914, 2417, 1365, 2756, 4492, 2323, 1364, 1836, 519,
        1618, 1781, 748, 345, 101, 25, 12283, 9483, 1202, 47, 328, 441, 7462,
        11308, 665, 5719, 1211, 5420, 16, 7, 15, 2320, 781, 98, 352, 13239,
        18121, 3718, 1123, 253, 2468, 674, 1493, 1650, 3378, 7658, 7962, 3859,
        1261, 555, 1842, 1600, 1315, 1457, 1199, 1261, 1281, 1186, 6778, 5216,
        3972, 6541, 12974, 13249, 5085, 4178, 1517, 2474, 1372, 1074, 892, 161,
        93, 50, 618, 302, 616, 6173, 8806, 1995, 604, 4224, 1360, 389, 524,
        3429, 4462, 157, 338, 307, 115, 394, 60, 45, 189, 68, 160, 1073, 421,
        293, 231, 276, 619, 1966, 4978, 727, 402, 149, 8, 129, 482, 254, 373,
        64, 6, 347, 7901, 3847, 3277, 535, 108, 531, 423, 22, 1036, 1364, 4014,
        2810, 771, 1806, 1603, 696, 4276, 7219, 6021, 993, 1773, 208, 2617, 564,
        481, 1233, 337, 598, 564, 14294, 3131, 830, 650, 838, 5251, 8004, 7721,
        1627, 434, 7969, 9661, 0, 73, 1665, 768, 29, 247, 6855, 2224, 1208, 507,
        60, 472, 447, 99, 2107, 4323, 8771, 5196, 2250, 1957, 1401, 697, 655,
        609, 1302, 1416, 2680, 1613, 1204, 5090, 5829, 1421, 9, 2, 960, 367, 23,
        64, 12, 188, 78, 158, 77, 1020, 2216, 273, 453, 628, 211, 199, 1528,
        1706, 835, 1123, 893, 1073, 2686, 119, 990, 780, 642, 91, 516, 34, 47,
        325, 1139, 425, 880, 837, 256, 456, 414, 644, 1043, 741, 407, 165, 217,
        671, 55, 696, 349, 4, 345, 230, 553, 1489, 188, 87, 591, 904, 535, 5,
        988, 881, 536, 533, 2911, 2846, 558, 545, 1409, 4645, 2433, 9284, 416,
        495, 584, 481, 1013, 335, 2314, 8367, 13875, 4842, 627, 2654, 485, 605,
        580, 703, 1127, 1816, 2288, 815, 11196, 2733, 173, 100, 373, 2253, 76,
        160, 385, 720, 1456, 328, 42, 732, 318, 152, 784, 587, 9424, 9324, 7883,
        1270, 2623, 6262, 2909, 1381, 731, 906, 1026, 196, 1712, 3, 2, 17, 4,
        11, 48, 7728, 6429, 2120, 1637, 7, 858, 397, 536, 481, 5196, 11035,
        4611, 61, 11, 15, 6754, 3453, 2258, 3642, 4958, 860, 337, 550, 584,
        1415, 1440, 1360, 5459, 758, 511, 1750, 2583, 588, 3022, 1868, 506, 731,
        754, 763, 527, 1134, 572, 93, 518, 111, 177, 777, 685, 90, 104, 286,
        725, 119, 18, 25, 490, 153, 2969, 7651, 4979, 2945, 1750, 911, 1685,
        1478, 2073, 387, 950, 1098, 1459, 3971, 7996, 2108, 696, 2877, 3029,
        382, 3365, 6724, 3536, 2899, 254, 6647, 125, 605, 542, 646, 669, 1220,
        2809, 5760, 3240, 60, 7922, 92, 442, 1239, 451, 5046, 8314, 2513, 252,
        275, 651, 527, 541, 518, 323, 1185, 1265, 2339, 717, 588, 4353, 4698,
        7932, 6802, 3161, 3156, 4097, 1628, 25, 42, 3, 5834, 6, 96, 29, 21594,
        22576, 7787, 940, 578, 10361, 13536, 2420, 525, 691, 7596, 8028, 154,
        70, 2931, 424, 1104, 1251, 4301, 7446, 1260, 517, 1071, 402, 564, 1908,
        2703, 7829, 8630, 4064, 3819, 4976, 1335, 127, 716, 833, 972, 651, 782,
        321, 1173, 692, 6, 278, 89, 847, 954, 2566, 5540, 1363, 212, 49, 96, 24,
        199, 75, 677, 1110, 9250, 1714, 1567, 1537, 2985, 430, 6267, 2434, 2104,
        2273, 1942, 4113, 1794, 2275, 1507, 1957, 1151, 1905, 768, 800, 464,
        2977, 1902, 5437, 1703, 5859, 4358, 575, 1460, 2238, 2566, 4337, 6882,
        5769, 486, 982, 6150, 4131, 585, 643, 636, 836, 775, 681, 265, 332, 466,
        522, 382, 612, 455, 213, 1425, 677, 660, 4807, 3791, 4494, 6416, 2505,
        3349, 2283, 1872, 5770, 11975, 9944, 6569, 4477, 272, 5294, 12819, 8909,
        5530, 2911, 2058, 7316, 9246, 6486, 94, 4585, 2080, 1816, 1833, 241,
        572, 4833, 421, 1011, 7084, 6030, 957, 37, 82, 237, 380, 1151, 1327,
        1258, 597, 137, 124, 326, 962, 10, 215, 1103, 696, 12, 109, 192, 5170,
        1480, 15, 584, 2131, 1894, 832, 431, 3665, 4835, 187, 34, 206, 38, 13,
        2, 407, 4971, 292, 54, 317, 5, 99, 329, 3195, 98, 7404, 5881, 1217,
        2114, 1137, 1076, 218, 863, 6100, 256, 1352, 568, 3608, 7035, 4758,
        11337, 2982, 2012, 2785, 1836, 3288, 6219, 2848, 11, 725, 1830, 837,
        749, 205, 378, 478, 821, 530, 650, 759, 684, 984, 469, 703, 702, 844,
        815, 1049, 869, 1493, 940, 1458, 1391, 1866, 2030, 1559, 2732, 2739,
        704, 152, 1453, 2181, 6972, 12248, 16896, 23854, 23606, 4424, 4391,
        3563, 3835, 2126, 9214, 1936, 3765, 5318, 5963, 428, 2896, 2050, 713,
        59, 6269, 289, 1327, 5821, 2023, 517, 177, 252, 652, 1536, 1453, 530,
        587, 8, 44, 12, 16, 13, 123, 14993, 665, 222, 64, 239, 735, 4807, 3682,
        113, 98, 1712, 1830, 477, 111, 38, 88, 2835, 149, 409, 992, 243, 214,
        3735, 1735, 204, 66, 104, 872, 35, 2079, 1009, 220, 1452, 9255, 4917,
        4522, 1319, 1330, 404, 5257, 921, 1039, 2386, 459, 918, 900, 6983,
        11203, 7062, 3067, 9675, 7240, 3781, 4639, 159, 235, 1159, 26, 9, 776,
        643, 453, 539, 631, 987, 762, 722, 979, 900, 725, 685, 1319, 844, 533,
        1056, 759, 1102, 1274, 5934, 2731, 2456, 1915, 1633, 775, 509, 1900,
        3989, 3538, 3749, 5123, 3960, 2935, 669, 14439, 1025, 2387, 6713, 6898,
        2583, 6387, 1355, 1024, 3043, 2362, 6368, 5140, 2865, 6348, 4310, 458,
        728, 282, 265, 7993, 2717, 212, 2994, 2376, 2665, 1486, 210, 35, 2, 27,
        27, 88, 3169, 502, 8523, 3501, 27, 1519, 4306, 16, 5117, 3427, 71, 3,
        259, 1014, 310, 127, 19, 29, 4730, 1843, 76, 136, 85, 1577, 4283, 3124,
        1, 3, 756, 63, 1829, 3043, 1389, 1055, 1987, 4111, 5838, 2207, 1345,
        898, 307, 279, 1587, 2634, 8971, 4314, 1097, 1904, 744, 7641, 743, 3564,
        12440, 4130, 3505, 481, 103, 9857, 11431, 148, 20, 678, 740, 436, 560,
        704, 736, 600, 1416, 1518, 980, 749, 753, 970, 587, 538, 686, 820, 643,
        132, 4724, 7635, 4937, 2559, 390, 352, 300, 823, 2679, 3342, 11733, 845,
        3318, 3709, 1881, 4131, 1767, 179, 76, 13814, 5116, 3691, 1626, 657,
        5002, 11311, 8834, 16024, 3579, 14508, 13705, 7123, 4003, 3135, 5427,
        7909, 5173, 6352, 2014, 1347, 386, 76, 164, 1, 7, 257, 318, 14747, 7599,
        4211, 2729, 2218, 151, 907, 8156, 76, 265, 172, 16001, 523, 9, 111, 751,
        142, 82, 76, 5340, 1110, 2147, 81, 140, 1208, 2863, 583, 410, 211, 31,
        404, 3769, 4057, 1862, 393, 393, 556, 1301, 5394, 8106, 1701, 1189, 696,
        1892, 1951, 5406, 11601, 8931, 3753, 180, 916, 823, 4265, 2151, 967,
        296, 5697, 2, 4340, 419, 10561, 252, 337, 567, 472, 454, 911, 1068,
        1226, 2756, 2256, 904, 921, 885, 518, 361, 386, 345, 662, 528, 3201,
        7753, 9237, 22016, 25182, 3653, 670, 493, 793, 675, 1003, 5177, 7164,
        277, 880, 985, 4399, 1676, 754, 561, 148, 8496, 2223, 8738, 10033, 2041,
        22214, 5738, 5511, 8814, 6305, 5089, 5500, 3908, 4722, 1990, 3099, 3730,
        7695, 8759, 208, 71, 195, 242, 7, 54, 19, 3281, 16077, 55, 175, 10, 15,
        139, 229, 9900, 1429, 248, 354, 8805, 16844, 338, 8, 1717, 1269, 98,
        1106, 3964, 911, 6493, 409, 113, 105, 600, 85, 97, 372, 3158, 1659,
        6566, 10506, 972, 64, 26, 132, 1061, 12446, 6287, 2085, 8986, 6546,
        1381, 430, 1999, 3095, 3437, 2232, 6191, 7340, 4027, 3874, 155, 233,
        5989, 9896, 328, 352, 13, 1117, 391, 445, 894, 462, 411, 643, 739, 1902,
        2692, 2190, 1795, 2097, 1218, 868, 866, 1447, 1639, 1645, 3561, 6397,
        10309, 9633, 3689, 2569, 2146, 524, 862, 613, 727, 313, 614, 4187, 2881,
        1684, 37, 3238, 19912, 8336, 2972, 681, 206, 3319, 10556, 5315, 4043,
        1013, 1726, 4022, 15503, 5606, 4043, 2000, 4855, 1320, 2225, 1654, 8558,
        4298, 7530, 1966, 9144, 5974, 259, 282, 540, 1072, 4812, 8445, 1013, 0,
        20, 203, 10, 3026, 11911, 2, 249, 1, 3, 1013, 89, 739, 1264, 1690, 146,
        75, 291, 768, 575, 9573, 5228, 3466, 5789, 258, 1341, 3036, 2163, 1369,
        9843, 4205, 276, 21, 188, 280, 1488, 1969, 319, 2755, 14991, 1912, 827,
        880, 3228, 2566, 1738, 1772, 1739, 6137, 859, 10625, 6428, 1983, 3938,
        3127, 6162, 127, 157, 1103, 39, 74, 1316, 592, 359, 299, 441, 1036,
        1327, 1142, 2648, 8442, 7232, 2291, 1280, 1836, 1706, 1476, 1453, 11513,
        11607, 8443, 8210, 1443, 1702, 1878, 1374, 1133, 875, 542, 526, 1218,
        1375, 2355, 3098, 2794, 21150, 16153, 12723, 3765, 782, 4987, 3512,
        6738, 12548, 9709, 1893, 4374, 9928, 681, 3561, 2624, 6411, 1416, 1968,
        1487, 6843, 8016, 1588, 2701, 1449, 5619, 3667, 1905, 387, 355, 26, 167,
        2212, 609, 4, 103, 142, 4606, 5899, 272, 464, 3, 52, 44, 1, 286, 4900,
        2180, 870, 102, 59, 119, 1094, 3947, 758, 6541, 5249, 2369, 643, 1165,
        1301, 1766, 1371, 573, 544, 534, 45, 1168, 1475, 439, 842, 1408, 16429,
        5050, 7005, 6074, 3841, 520, 2685, 2540, 5668, 5307, 2383, 1832, 1730,
        5977, 2340, 3314, 3686, 1076, 1100, 7828, 5799, 31, 628, 1031, 515, 343,
        593, 988, 1239, 1264, 2126, 3674, 7196, 2644, 1187, 1454, 2077, 2246,
        6248, 2488, 935, 337, 135, 4, 648, 2018, 1543, 1538, 1499, 4468, 841,
        383, 251, 554, 1342, 5763, 15914, 696, 4682, 1548, 834, 12971, 5540,
        9644, 1760, 1205, 855, 1260, 19062, 1265, 5156, 4017, 14275, 2136, 2788,
        1643, 2060, 15262, 3369, 2915, 4921, 5696, 5249, 8610, 10053, 577, 307,
        2045, 10849, 20720, 2390, 620, 2269, 3451, 7, 78, 468, 516, 22, 20, 145,
        6862, 409, 749, 306, 233, 54, 246, 357, 2475, 1090, 4035, 1164, 5508,
        1349, 442, 810, 931, 822, 283, 569, 480, 674, 7652, 7927, 2233, 2062,
        5586, 4713, 4001, 4021, 9985, 6295, 123, 580, 4230, 7396, 5585, 6771,
        3482, 5401, 6199, 1165, 2058, 867, 1682, 7311, 7762, 4308, 88, 130,
        1505, 1183, 580, 924, 1249, 1921, 2067, 1142, 844, 1665, 1826, 1159,
        1401, 1630, 2424, 82, 351, 1246, 3445, 2385, 39, 665, 196, 1735, 1569,
        1255, 3295, 2080, 1770, 962, 1284, 1477, 8855, 8099, 4915, 2287, 773,
        551, 19026, 14211, 18145, 2406, 5084, 10916, 14326, 6034, 4127, 3466,
        2404, 11960, 1129, 1688, 3288, 1621, 16398, 1939, 2412, 2295, 1448,
        2181, 3233, 12543, 1855, 117, 21, 3469, 5598, 61, 494, 1796, 1500, 26,
        79, 98, 496, 5, 8649, 11536, 10119, 309, 5081, 11677, 18673, 12789,
        1847, 1064, 12227, 1685, 3901, 5665, 2497, 5079, 1427, 393, 819, 410,
        456, 605, 968, 7815, 10435, 1629, 3586, 1185, 319, 7644, 3670, 8421,
        3155, 1766, 4631, 10863, 4603, 4545, 4352, 7364, 7053, 630, 1193, 4554,
        2234, 2709, 6649, 7916, 1182, 364, 222, 227, 922, 1170, 1023, 1132,
        1769, 1727, 886, 278, 179, 453, 894, 1133, 1371, 624, 512, 126, 261,
        490, 1950, 4459, 1467, 73, 0, 294, 1443, 9012, 4703, 2193, 2240, 4326,
        6291, 8088, 15684, 3210, 4672, 3476, 5750, 4109, 21137, 3185, 16289,
        3051, 9457, 5987, 1802, 7916, 7469, 16854, 9416, 9137, 1080, 801, 3044,
        1498, 5989, 11452, 8487, 1204, 2544, 1640, 2572, 7200, 202, 329, 151,
        1864, 1512, 76, 2784, 828, 2289, 63, 10, 46, 20, 15537, 20159, 10177,
        947, 12, 279, 424, 820, 505, 2831, 978, 10614, 1444, 1760, 4145, 5988,
        5688, 3962, 5044, 906, 684, 1151, 2616, 4268, 7910, 689, 290, 305, 157,
        244, 7397, 10098, 4089, 11073, 7406, 8081, 9346, 2184, 2138, 3179, 7793,
        752, 694, 6539, 4229, 776, 1432, 3036, 5511, 2613, 1857, 1834, 499, 400,
        29, 1264, 760, 759, 869, 36, 12, 575, 501, 615, 648, 422, 595, 551, 336,
        397, 524, 960, 1926, 1236, 602, 65, 72, 800, 1432, 1643, 2945, 4127,
        5124, 8004, 11191, 11465, 10444, 9440, 15305, 17675, 10599, 4507, 3487,
        4829, 5348, 19804, 25955, 2633, 1341, 1238, 8595, 10238, 16579, 15258,
        5862, 2595, 2769, 1754, 10719, 12527, 10659, 6683, 2176, 1490, 50, 76,
        375, 871, 113, 623, 579, 156, 1089, 6983, 20, 244, 98, 324, 11723, 4781,
        3286, 485, 587, 166, 181, 4095, 7904, 3216, 9305, 2953, 3472, 13407,
        7413, 5187, 3844, 3159, 4644, 4228, 2564, 2000, 2364, 1574, 1064, 683,
        737, 1223, 7049, 6685, 12134, 6192, 4544, 6512, 3859, 1577, 9148, 4638,
        1448, 2931, 6927, 4352, 1284, 1422, 1125, 1324, 314, 1343, 3090, 1115,
        1336, 676, 131, 1295, 71, 53, 2991, 937, 52, 115, 426, 949, 465, 195,
        1356, 470, 137, 242, 528, 435, 821, 2653, 1757, 548, 49, 186, 21, 148,
        1068, 384, 661, 579, 207, 69, 72, 98, 15192, 3769, 1385, 8195, 2211,
        7311, 3156, 6421, 3595, 8349, 24306, 3198, 6260, 11313, 1660, 5857,
        7284, 984, 2135, 7974, 1708, 855, 4974, 3431, 98, 350, 11250, 3048,
        1127, 861, 2173, 1372, 1081, 766, 1018, 182, 2174, 236, 10627, 391, 448,
        20, 6, 68, 953, 489, 2161, 13677, 50, 50, 432, 4968, 10223, 6019, 5262,
        11048, 3235, 10139, 8232, 7458, 6311, 2271, 818, 529, 1675, 893, 1342,
        464, 425, 2659, 7462, 10545, 8481, 1845, 2622, 7157, 7141, 2580, 7389,
        6430, 1718, 1556, 4900, 3017, 5147, 1721, 2405, 873, 1026, 1104, 2599,
        1823, 1339, 168, 1271, 3801, 2231, 666, 6492, 229, 738, 880, 909, 745,
        393, 227, 6574, 818, 675, 331, 421, 405, 2540, 4684, 391, 372, 357, 303,
        104, 239, 338, 572, 11, 14, 50, 60, 19, 136, 9193, 701, 1886, 10317,
        15201, 4988, 4996, 11660, 5943, 7436, 3070, 7521, 8089, 10827, 2204,
        3061, 4467, 1156, 7684, 639, 481, 853, 791, 578, 599, 2231, 114, 813,
        2104, 3534, 3353, 2298, 1102, 2175, 26, 596, 2539, 6293, 13357, 207, 6,
        33, 9, 29, 160, 2014, 7286, 4356, 554, 434, 581, 1136, 2864, 654, 3194,
        1022, 2339, 6038, 3981, 2035, 3175, 4775, 1144, 1603, 3283, 958, 361,
        323, 169, 3095, 614, 7380, 1268, 4119, 6831, 1721, 3435, 1115, 2827,
        3653, 1541, 893, 1828, 5034, 6592, 471, 862, 664, 972, 1133, 832, 2339,
        1970, 2283, 3425, 5062, 4086, 4, 8611, 28, 15, 52, 114, 636, 63, 54,
        826, 1363, 599, 1075, 692, 746, 7669, 4847, 199, 330, 80, 811, 558, 397,
        205, 39, 18, 71, 672, 1831, 6691, 12059, 14614, 6681, 8324, 6148, 21497,
        25793, 7191, 6326, 4391, 3736, 14866, 3444, 1557, 4498, 11414, 2867,
        2715, 7877, 2210, 491, 1417, 2316, 2203, 1628, 602, 76, 61, 811, 660,
        967, 865, 1612, 3411, 543, 332, 470, 888, 1317, 2495, 6550, 1, 9, 6,
        164, 142, 5, 3600, 3162, 2193, 2294, 1274, 507, 152, 4901, 4322, 4725,
        3955, 1610, 2083, 2816, 6116, 4936, 1083, 5806, 6947, 695, 259, 1279,
        877, 3063, 1857, 8262, 8702, 698, 4694, 6612, 842, 1402, 2005, 1034,
        1187, 5503, 5525, 2484, 2975, 6454, 1075, 646, 522, 1017, 858, 3345,
        6907, 8523, 6592, 2436, 5, 2772, 4303, 9861, 269, 33, 76, 342, 108,
        2816, 4987, 1914, 11335, 1600, 1029, 1517, 5627, 6481, 155, 311, 3125,
        2716, 157, 218, 3644, 7745, 6013, 3980, 3214, 5587, 6353, 12452, 18639,
        10659, 7947, 11192, 4886, 21186, 6531, 2801, 2714, 6837, 12445, 4507,
        5032, 1693, 4839, 2796, 4288, 10491, 2602, 476, 2004, 2437, 4176, 8302,
        384, 82, 81, 986, 29, 193, 315, 512, 6442, 9339, 513, 867, 621, 680,
        492, 1162, 1768, 9, 3, 5, 6, 14, 2135, 3726, 1522, 5163, 5911, 13986,
        1932, 4144, 10340, 7301, 3390, 7794, 6237, 3586, 3722, 1221, 1386, 2146,
        7652, 2041, 533, 2282, 3574, 5046, 708, 8012, 1110, 3421, 2244, 4997,
        2593, 2041, 2792, 1141, 1459, 2965, 6208, 1923, 2194, 3216, 3877, 3135,
        2410, 1458, 1517, 2960, 4812, 2252, 1363, 2103, 242, 1329, 2589, 1341,
        1493, 1252, 1132, 1110, 1147, 2520, 2672, 1607, 14309, 1749, 1080, 733,
        983, 2290, 1897, 1067, 198, 2680, 54, 15, 2464, 3824, 1977, 3035, 3817,
        4279, 2900, 7660, 9353, 7420, 8165, 8487, 8320, 1488, 4721, 5203, 1684,
        1406, 3643, 11296, 684, 8483, 1282, 20253, 1555, 10455, 2651, 490, 1611,
        1044, 2530, 8244, 2773, 6, 75, 23, 1885, 1533, 875, 360, 779, 8670,
        6110, 753, 614, 847, 844, 764, 1100, 3733, 67, 13, 78, 0, 842, 4120,
        5773, 3685, 1858, 2568, 2784, 4113, 8709, 6103, 4866, 4806, 2485, 2478,
        1498, 1848, 1210, 4557, 592, 868, 2441, 5223, 699, 1481, 932, 958, 1738,
        2065, 4434, 1976, 626, 2868, 6264, 4847, 1100, 961, 1132, 311, 423,
        3631, 2888, 1991, 842, 867, 1088, 2486, 2044, 1039, 504, 2700, 1232,
        7495, 2392, 801, 975, 1006, 470, 1123, 1064, 1686, 499, 2301, 3345,
        9763, 444, 194, 433, 603, 2041, 905, 168, 1473, 7, 9866, 976, 2272,
        1894, 1307, 1033, 619, 1917, 4356, 9804, 6641, 4724, 6494, 15359, 2617,
        15996, 6613, 22160, 12490, 9881, 9252, 10893, 9405, 1970, 18699, 8814,
        9026, 2671, 3363, 990, 680, 938, 4696, 279, 8, 230, 4826, 6640, 2915,
        1560, 674, 285, 2591, 924, 1429, 318, 212, 642, 938, 2148, 5635, 9185,
        12629, 204, 246, 208, 84, 16, 63, 1287, 1352, 14027, 6525, 6018, 3184,
        2253, 8983, 5929, 3323, 2076, 2836, 8650, 5249, 526, 7198, 3188, 6889,
        6045, 475, 1024, 16723, 14941, 12381, 10738, 1290, 6054, 2682, 3838,
        5139, 2144, 976, 684, 980, 345, 1387, 1336, 1446, 1142, 1081, 1795,
        2020, 2043, 2245, 1769, 405, 33, 3692, 1985, 965, 938, 1198, 1103, 742,
        769, 1451, 1631, 2299, 1628, 599, 8422, 484, 569, 350, 978, 2170, 2272,
        600, 6, 9701, 9877, 2074, 1067, 784, 2155, 1819, 1287, 5627, 12617,
        14837, 14315, 8383, 14518, 1714, 17276, 12610, 22148, 2978, 545, 480,
        16460, 17319, 4609, 10804, 13928, 11808, 7327, 299, 607, 803, 1812,
        4703, 1164, 1, 17, 9346, 1716, 174, 1023, 315, 237, 513, 346, 1548,
        1669, 2117, 1291, 1407, 2262, 2433, 4741, 13030, 5114, 324, 2, 22, 5,
        44, 72, 800, 5204, 13572, 12798, 2148, 3849, 5512, 3280, 1779, 1020,
        701, 1399, 277, 7463, 6474, 533, 5252, 1516, 673, 1093, 6241, 3530,
        9225, 1060, 4108, 8449, 6730, 4069, 2422, 1402, 1712, 953, 1100, 1102,
        1344, 4524, 3570, 1612, 2196, 2933, 1165, 718, 460, 4564, 4709, 7, 1122,
        1082, 513, 1119, 1254, 1291, 1814, 2847, 4008, 1870, 1457, 2989, 9423,
        9586, 1624, 470, 597, 801, 2037, 967, 197, 63, 30, 12252, 7687, 520,
        889, 2429, 3187, 1602, 4820, 16240, 10605, 5771, 3078, 6979, 6535, 3653,
        1767, 3410, 4979, 10661, 13297, 6396, 8201, 2202, 20344, 16012, 10646,
        8692, 306, 743, 676, 1145, 3101, 2379, 652, 204, 1272, 367, 87, 52, 316,
        1018, 1514, 450, 290, 2403, 1300, 1919, 4498, 2900, 947, 112, 159, 187,
        416, 1904, 9599, 4292, 3935, 2827, 248, 5640, 7062, 11630, 14189, 5335,
        12387, 454, 852, 932, 991, 375, 1033, 1036, 51, 948, 1609, 1106, 966,
        779, 214, 1078, 898, 259, 1883, 1780, 922, 1939, 818, 1642, 1128, 1459,
        2534, 1226, 152, 236, 1936, 3106, 3704, 1364, 1699, 1739, 2138, 2105,
        3423, 32, 972, 930, 752, 1050, 971, 332, 4494, 9165, 6713, 2301, 1811,
        503, 452, 2102, 1925, 837, 1971, 913, 1715, 257, 1640, 2361, 86, 1049,
        7190, 229, 378, 946, 1209, 1171, 5561, 3278, 401, 396, 11969, 5122,
        6399, 4117, 10422, 9537, 5568, 11265, 8701, 3788, 4456, 7344, 10761,
        8201, 15443, 17718, 8663, 2095, 46, 2859, 2852, 695, 353, 1120, 773,
        2633, 2699, 610, 45, 498, 1061, 568, 509, 551, 6974, 3211, 207, 38, 254,
        128, 17, 2977, 269, 1437, 16761, 7330, 878, 239, 79, 582, 1037, 6405,
        6870, 12052, 6414, 1922, 1805, 1350, 983, 1145, 719, 307, 230, 2544,
        4621, 2120, 1237, 2180, 298, 1175, 1903, 2204, 1730, 1461, 88, 866,
        1727, 1950, 1128, 1304, 6074, 720, 155, 246, 1631, 2210, 8850, 2033,
        1495, 1272, 1944, 1151, 2977, 58, 1534, 1020, 994, 1753, 3267, 4438,
        5167, 6115, 2233, 1548, 1213, 866, 640, 510, 283, 1227, 851, 396, 2017,
        3558, 3296, 1210, 1632, 54, 1735, 2101, 3308, 3515, 12797, 12354, 8989,
        8101, 7708, 3343, 1149, 5234, 6558, 4004, 669, 616, 7586, 12137, 20628,
        4211, 5772, 8131, 4509, 13001, 7812, 14232, 8391, 9763, 3655, 8076,
        10941, 8183, 523, 841, 3050, 2941, 7005, 5813, 3389, 965, 3151, 1159,
        891, 1028, 8132, 164, 403, 859, 1982, 2083, 3965, 3591, 5590, 323, 21,
        51, 4, 214, 568, 8974, 4048, 221, 3604, 5630, 2990, 1113, 708, 1151,
        916, 1420, 474, 196, 733, 791, 4365, 6086, 1196, 634, 918, 1436, 5901,
        729, 1821, 4363, 327, 246, 1103, 2968, 1837, 1074, 7634, 1350, 358, 460,
        380, 1955, 10094, 2128, 1992, 1785, 1183, 1017, 1311, 18, 1120, 1052,
        1469, 1505, 2795, 1405, 1555, 2078, 1575, 685, 1588, 1902, 773, 478,
        287, 1114, 1039, 805, 2590, 2206, 3739, 3733, 4740, 105, 5383, 7008,
        10049, 4262, 3605, 4706, 1147, 14196, 12670, 4420, 3150, 8152, 19648,
        18594, 12639, 930, 5584, 7966, 2073, 985, 4373, 14646, 3766, 1936, 6346,
        24415, 28327, 1447, 3089, 13572, 6591, 6655, 2851, 2252, 4048, 4474,
        3647, 3702, 7978, 7063, 3701, 2499, 3495, 3875, 6566, 265, 3043, 8952,
        10831, 13151, 8640, 3614, 3644, 596, 392, 5, 1066, 12, 4812, 14067,
        10732, 363, 275, 2645, 2180, 3655, 1018, 496, 1018, 2175, 537, 727,
        1045, 970, 3096, 5635, 2807, 1720, 2155, 4829, 6609, 1156, 7667, 9215,
        1449, 533, 358, 3676, 2065, 1443, 6600, 144, 54, 377, 401, 1929, 11753,
        1668, 585, 525, 600, 1410, 940, 4, 1406, 1630, 2120, 1000, 725, 1447,
        1044, 2179, 1510, 885, 1181, 777, 1546, 1187, 1111, 824, 816, 7796,
        7305, 4139, 2297, 2056, 1628, 959, 211, 10914, 10444, 9731, 1923, 1398,
        1344, 9681, 2422, 2636, 2137, 3247, 12870, 2428, 2719, 1713, 15241,
        30523, 17814, 2361, 13062, 5688, 10507, 151, 507, 13663, 103, 6451,
        9365, 4642, 7330, 9083, 1279, 1071, 1921, 3665, 4281, 5055, 1965, 394,
        560, 2088, 2208, 4245, 69, 85, 24, 2372, 2149, 8258, 10454, 4669, 3691,
        1427, 2203, 139, 42, 534, 8356, 14300, 5648, 994, 68, 728, 1818, 973,
        693, 554, 2877, 7562, 2421, 563, 611, 2277, 3344, 2608, 2175, 1809, 859,
        2111, 2184, 3649, 6567, 8702, 2040, 741, 686, 2978, 1338, 2007, 2103,
        458, 186, 261, 161, 802, 8136, 2616, 815, 339, 870, 1393, 5396, 290, 11,
        2077, 1897, 1085, 152, 2081, 416, 1598, 1093, 1121, 524, 1395, 1558,
        1252, 944, 355, 1360, 5984, 9636, 1533, 977, 440, 1391, 2575, 98, 754,
        1112, 838, 702, 934, 1058, 3438, 2270, 1185, 560, 1733, 16363, 10546,
        289, 556, 1721, 15677, 611, 88, 545, 404, 5538, 7853, 1258, 2787, 5644,
        1444, 8600, 2034, 7003, 7516, 4417, 7325, 1319, 6805, 5218, 379, 9, 54,
        112, 18, 13, 30, 3152, 102, 333, 592, 756, 1710, 4410, 2562, 876, 3719,
        1191, 1188, 502, 2890, 5063, 417, 2477, 1204, 87, 288, 703, 1225, 1109,
        1918, 3071, 6815, 672, 184, 2385, 2243, 3839, 1545, 1108, 534, 220, 742,
        891, 2209, 1113, 2172, 744, 3657, 1618, 2469, 1260, 939, 1221, 2895,
        100, 223, 233, 1446, 3331, 3143, 1281, 1453, 2248, 4832, 4101, 1, 408,
        1493, 1058, 798, 3332, 106, 933, 1161, 1304, 825, 105, 2402, 267, 307,
        1240, 1484, 2773, 6078, 5934, 1146, 1090, 1781, 762, 307, 302, 2992,
        2136, 884, 920, 511, 1812, 2812, 1264, 5989, 8883, 8459, 6882, 9653,
        5240, 7968, 549, 8878, 2207, 487, 3837, 9822, 1027, 485, 2176, 9802,
        7026, 3452, 2614, 2032, 3826, 1149, 3954, 2595, 462, 4020, 136, 183, 43,
        206, 374, 17, 519, 763, 30, 305, 1242, 1113, 247, 500, 5006, 2349, 756,
        2745, 7211, 3302, 4445, 3861, 162, 213, 1210, 2203, 877, 189, 1111,
        1652, 2715, 2482, 1426, 782, 346, 252, 25, 40, 626, 232, 187, 721, 585,
        492, 1264, 1167, 680, 647, 132, 1458, 2045, 1032, 1728, 1410, 373, 3650,
        5000, 146, 486, 1656, 2323, 824, 534, 156, 22, 34, 697, 1, 226, 3051,
        1287, 2684, 1068, 623, 1032, 1001, 1186, 836, 801, 1210, 216, 23, 2493,
        1311, 3086, 5186, 3250, 1180, 723, 1428, 1454, 1909, 770, 1716, 2934,
        1376, 1000, 1003, 2784, 7908, 11101, 11533, 6549, 5967, 2322, 2919,
        1825, 253, 340, 7802, 6191, 4731, 12490, 24251, 586, 378, 7945, 4870,
        3961, 355, 6846, 13443, 5562, 6495, 1360, 9138, 773, 116, 1419, 111, 13,
        33, 178, 424, 1858, 2176, 718, 8998, 4938, 457, 213, 971, 2972, 1507,
        3977, 993, 2359, 14999, 12915, 1822, 988, 184, 2725, 1391, 2199, 5825,
        4570, 1335, 1763, 721, 720, 503, 1416, 895, 1493, 7006, 4016, 305, 97,
        584, 3070, 2425, 4461, 4573, 1938, 1731, 1264, 834, 2380, 592, 424, 258,
        872, 117, 4545, 4678, 829, 2935, 122, 198, 142, 9, 53, 163, 191, 3, 247,
        813, 1876, 1842, 77, 551, 323, 782, 1088, 844, 1576, 430, 516, 2186,
        1655, 1603, 2637, 4264, 2995, 1444, 1086, 1791, 521, 2024, 2208, 1168,
        1907, 1848, 1846, 2091, 11171, 4862, 3212, 2309, 1415, 4759, 641, 1323,
        3023, 1668, 1330, 909, 915, 2742, 17574, 4230, 379, 5572, 8359, 33,
        1259, 3910, 3601, 9917, 4861, 5952, 7145, 9028, 8472, 309, 711, 3380,
        4470, 3847, 4009, 1169, 1945, 1503, 688, 3489, 13955, 4080, 80, 7050,
        1033, 2638, 12128, 1493, 3678, 19075, 3201, 629, 862, 141, 1440, 1794,
        3919, 9277, 3246, 1321, 1476, 582, 797, 1126, 388, 21, 343, 10113, 8173,
        3442, 2753, 4249, 3594, 942, 1267, 2257, 2246, 3433, 1648, 1858, 2398,
        441, 360, 275, 1411, 1038, 1619, 802, 772, 1128, 165, 506, 23, 178, 4,
        15, 289, 1106, 943, 523, 2370, 1279, 278, 915, 659, 532, 711, 549, 750,
        9495, 8401, 5058, 681, 661, 1647, 2509, 2261, 1501, 1022, 1181, 156,
        759, 684, 323, 1174, 1405, 2721, 4688, 15636, 3717, 1189, 1104, 5704,
        790, 1210, 2194, 6322, 13778, 1697, 270, 2841, 7475, 2500, 1718, 1825,
        4994, 1487, 1473, 14893, 20237, 2182, 1421, 2763, 3353, 2036, 11867,
        13458, 1904, 1817, 3068, 1850, 2071, 7424, 2845, 897, 1508, 1360, 82,
        3695, 373, 6847, 10639, 3386, 14330, 12217, 9117, 931, 5873, 5389, 132,
        110, 102, 1033, 1673, 2612, 8297, 10809, 2381, 2995, 104, 646, 1260,
        2502, 2319, 704, 2276, 1344, 1467, 1651, 1604, 1043, 913, 900, 926,
        1513, 2504, 1194, 469, 316, 1552, 1825, 3196, 7071, 5440, 5092, 593,
        4038, 728, 76, 14, 124, 201, 233, 624, 1396, 1744, 4474, 267, 4027, 428,
        846, 1586, 1191, 664, 448, 539, 1472, 1145, 5778, 548, 2590, 1768, 976,
        1409, 1044, 1125, 3053, 1692, 397, 235, 350, 1071, 1711, 1552, 3828,
        7225, 14830, 7694, 3248, 5996, 1922, 1994, 2850, 1104, 2663, 22390,
        20421, 9974, 3592, 285, 477, 5203, 7648, 3352, 1165, 1080, 6635, 8381,
        9005, 8726, 4500, 1409, 9027, 1575, 5652, 2194, 1673, 1198, 917, 4560,
        8129, 4070, 907, 1114, 1112, 2937, 617, 1034, 270, 3092, 1147, 993,
        11442, 4442, 3959, 6408, 4774, 200, 73, 274, 3246, 5042, 10103, 9447,
        9629, 2945, 4798, 4529, 6883, 5961, 2862, 1336, 1697, 979, 1339, 667,
        622, 445, 1735, 1230, 1078, 1691, 3041, 1754, 119, 846, 1200, 1520,
        2012, 8599, 10661, 6068, 135, 125, 1808, 1969, 1762, 2019, 2768, 6501,
        6959, 2017, 834, 703, 1117, 973, 64, 607, 832, 823, 915, 2056, 165, 395,
        107, 8048, 11274, 1554, 1274, 1654, 1163, 969, 1195, 1426, 2137, 5042,
        1916, 200, 1058, 1269, 1694, 1597, 3678, 7083, 321, 8796, 2400, 2249,
        3299, 510, 51, 35, 155, 1829, 5179, 10403, 2096, 2065, 2352, 8731, 2808,
        14483, 9176, 568, 653, 1652, 1057, 1478, 496, 312, 10842, 9631, 5480,
        6042, 936, 1117, 1075, 3117, 1154, 1130, 19, 99, 514, 336, 1315, 120,
        293, 6279, 6042, 3405, 10585, 6929, 5603, 10484, 7024, 2868, 11279,
        1609, 3674, 3087, 6581, 5923, 7860, 6237, 2876, 4768, 2773, 2828, 3224,
        1566, 2708, 302, 1588, 1274, 824, 741, 718, 2058, 5693, 7507, 4839,
        1433, 322, 2, 931, 7, 4, 100, 40, 2, 142, 1324, 5133, 3753, 1988, 1047,
        1723, 1823, 5788, 1644, 1050, 981, 1449, 1554, 468, 416, 89, 93, 33, 10,
        200, 52, 60, 15, 222, 39, 188, 1003, 1645, 1293, 1067, 1092, 1513, 4861,
        678, 5102, 5752, 2036, 5320, 1511, 1125, 1228, 1986, 15157, 1907, 4116,
        1068, 1092, 1077, 1643, 38, 1205, 265, 1440, 1265, 341, 396, 554, 738,
        17728, 7271, 426, 11096, 1266, 635, 491, 491, 639, 556, 7999, 5729, 901,
        528, 829, 933, 578, 721, 250, 297, 605, 247, 4226, 1272, 608, 5186,
        9590, 3236, 3222, 2506, 2912, 10030, 4539, 10050, 11456, 8139, 9767,
        7999, 9587, 2589, 439, 1776, 7395, 4156, 2501, 2447, 2281, 4131, 3621,
        3659, 931, 1736, 3828, 4549, 2326, 1781, 5739, 8539, 10368, 2096, 537,
        312, 143, 150, 10, 37, 48, 1, 1709, 994, 920, 861, 1727, 1651, 1597,
        376, 827, 863, 510, 1622, 4033, 6002, 837, 40, 28, 623, 3048, 678, 349,
        95, 1855, 405, 2649, 44, 23, 486, 13, 1682, 1591, 1005, 630, 648, 742,
        1325, 1214, 4431, 1206, 940, 300, 64, 89, 523, 17792, 16108, 3076, 1444,
        1421, 2392, 3209, 9345, 2510, 164, 10085, 7545, 10534, 9367, 142, 121,
        541, 1698, 10688, 14593, 1085, 1323, 5317, 7623, 839, 624, 707, 104,
        385, 1152, 746, 940, 1319, 14, 155, 956, 223, 9164, 5119, 887, 979,
        3431, 5701, 1492, 1679, 5320, 8820, 10075, 3409, 3068, 2747, 5592, 8878,
        5080, 7016, 1477, 4034, 2008, 4209, 5198, 4114, 2791, 2134, 4577, 6141,
        3851, 3224, 3592, 5068, 1535, 2781, 2039, 957, 1492, 1508, 857, 143,
        466, 2218, 103, 26, 471, 321, 638, 1214, 720, 1988, 500, 1418, 3220,
        523, 547, 769, 176, 468, 308, 299, 930, 29, 231, 480, 4090, 8711, 7869,
        1562, 1313, 782, 1962, 456, 5145, 646, 1163, 107, 8, 1266, 1150, 750,
        766, 822, 421, 239, 3854, 2347, 111, 212, 480, 491, 855, 11765, 4734,
        904, 33, 1809, 4303, 3952, 2642, 1755, 5051, 7048, 1178, 5523, 5747,
        4777, 6012, 6491, 2760, 12184, 9240, 9572, 3006, 3430, 11893, 11985,
        6484, 214, 154, 994, 1432, 685, 1277, 278, 63, 540, 230, 2979, 11274,
        2498, 730, 388, 520, 1924, 1195, 1270, 2186, 2369, 2516, 3245, 2828,
        885, 1746, 2822, 806, 3649, 3684, 1403, 4173, 3553, 5764, 3161, 3261,
        2871, 5346, 11942, 8842, 3377, 1150, 867, 2402, 5667, 2579, 1343, 1343,
        1491, 531, 1450, 1434, 756, 717, 509, 7436, 3734, 813, 422, 795, 3876,
        190, 1188, 3759, 1842, 626, 228, 239, 531, 804, 466, 32, 2, 15, 1927,
        1564, 2279, 5967, 1467, 477, 810, 528, 265, 659, 1668, 868, 1146, 27,
        5652, 746, 977, 1473, 883, 455, 1164, 2833, 2088, 2718, 61, 61, 78, 453,
        268, 352, 14440, 14533, 7827, 12367, 8910, 12879, 4036, 1337, 1416, 319,
        1678, 4874, 6096, 8091, 6487, 2962, 12545, 13523, 8221, 9570, 2235, 912,
        1212, 4523, 1854, 1764, 482, 1862, 481, 903, 251, 315, 167, 1234, 7158,
        9559, 1266, 1236, 235, 269, 1600, 1492, 3478, 4742, 1868, 253, 3863,
        12839, 11958, 2723, 1596, 1351, 5504, 2482, 3311, 1422, 12059, 13879,
        12345, 9589, 8681, 7239, 12923, 3124, 1024, 1289, 962, 1526, 317, 3571,
        798, 2095, 8453, 308, 535, 2391, 720, 258, 5391, 8555, 3402, 704, 137,
        540, 839, 162, 327, 2334, 1991, 1529, 520, 702, 956, 1148, 27, 8, 35,
        1328, 2051, 454, 758, 4721, 1237, 682, 2024, 544, 21, 348, 874, 1638,
        4539, 3192, 37, 366, 469, 10263, 75, 391, 624, 1540, 925, 3390, 6701,
        1500, 1481, 2942, 3709, 1226, 1081, 121, 496, 8593, 8256, 12709, 6765,
        866, 725, 535, 3078, 4073, 3437, 6470, 2490, 614, 4280, 2381, 6851,
        3257, 1009, 568, 509, 1477, 1678, 6644, 6945, 3734, 73, 1596, 1221,
        2372, 2219, 659, 1271, 623, 99, 416, 922, 350, 1050, 1822, 2789, 3102,
        606, 3622, 974, 8246, 1003, 487, 1687, 8025, 2320, 4861, 8320, 5267,
        1800, 1300, 4015, 731, 1785, 2617, 6345, 3116, 1701, 2476, 2784, 455,
        686, 916, 5420, 4606, 2500, 357, 1139, 55, 86, 629, 906, 1318, 2000,
        1534, 885, 742, 727, 360, 587, 748, 2051, 1280, 1624, 2090, 1562, 293,
        236, 164, 1344, 3673, 189, 747, 1782, 3397, 1631, 1651, 627, 317, 93,
        292, 8731, 9400, 12596, 10088, 668, 197, 1625, 8383, 7422, 821, 898,
        1636, 2110, 879, 1027, 861, 1633, 3607, 2603, 3777, 1726, 8745, 157,
        1150, 4506, 2682, 1868, 1861, 692, 2999, 3169, 1368, 2610, 1025, 3248,
        7324, 430, 1092, 10489, 9879, 5910, 154, 662, 2131, 3248, 1936, 9295,
        4280, 5107, 16500, 8588, 722, 368, 704, 1274, 833, 437, 127, 133, 471,
        400, 3597, 5738, 6221, 8093, 8305, 7529, 13147, 1802, 937, 1076, 6602,
        5089, 1672, 2724, 1631, 738, 1654, 3432, 4292, 3203, 776, 1168, 1520,
        3510, 2412, 4261, 5244, 2355, 3380, 7397, 8817, 2080, 104, 1209, 1826,
        713, 1224, 711, 1209, 748, 2376, 2980, 2237, 1456, 986, 4925, 958, 1408,
        1733, 2150, 303, 200, 1564, 133, 12208, 6647, 4338, 3772, 1334, 1653,
        1334, 806, 1239, 607, 121, 548, 2413, 6245, 9420, 3195, 2979, 706, 138,
        367, 2189, 201, 538, 777, 1034, 886, 1260, 772, 1089, 490, 8404, 3700,
        3123, 2215, 880, 8374, 1072, 9626, 1935, 1183, 2438, 860, 3700, 719,
        2180, 1864, 1992, 649, 678, 429, 3703, 2298, 1225, 170, 384, 917, 12276,
        21415, 3363, 1508, 1655, 7750, 4320, 1741, 562, 274, 199, 720, 307, 95,
        1525, 1304, 717, 538, 1824, 1084, 313, 656, 1466, 6866, 12015, 8624,
        1359, 2201, 10088, 13161, 7248, 1711, 2139, 1011, 4636, 4413, 1019,
        1588, 1196, 879, 1198, 530, 4646, 2865, 5656, 410, 1327, 4887, 9273,
        727, 87, 567, 304, 216, 3147, 271, 703, 1418, 1820, 3873, 4101, 1632,
        778, 87, 744, 182, 745, 91, 129, 71, 9160, 8261, 4288, 7801, 5578, 2472,
        1628, 891, 191, 264, 1085, 1133, 570, 1339, 1061, 251, 727, 496, 499,
        124, 7, 913, 58, 12, 405, 983, 931, 1187, 1321, 1003, 2055, 3364, 12201,
        2809, 1603, 1254, 560, 1547, 1328, 5488, 99, 728, 1154, 2144, 2235,
        1748, 2357, 1876, 1290, 2980, 148, 2926, 6536, 2438, 2253, 367, 2703,
        4034, 4266, 8918, 1555, 567, 205, 149, 8612, 1052, 993, 529, 199, 974,
        520, 1813, 1640, 725, 566, 964, 1786, 2037, 766, 676, 594, 576, 1570,
        1213, 206, 566, 1293, 1599, 1266, 2139, 3146, 8185, 7321, 1323, 3273,
        1872, 1787, 1286, 1072, 1706, 300, 5583, 1615, 1563, 3763, 5513, 7720,
        305, 2563, 544, 219, 1625, 755, 3411, 409, 2205, 2023, 2575, 565, 30,
        463, 715, 11, 81, 2471, 60, 1847, 11624, 9377, 3118, 1592, 1555, 1069,
        1141, 1698, 477, 551, 254, 800, 2388, 1809, 1971, 160, 377, 373, 539,
        1055, 888, 4, 8729, 2268, 753, 304, 1193, 1769, 2124, 2162, 1815, 3224,
        7416, 15215, 4501, 1186, 817, 755, 778, 1802, 7875, 331, 1702, 2779,
        2891, 3868, 5778, 2320, 1101, 1425, 87, 173, 5820, 7075, 1898, 3373,
        2881, 1808, 541, 129, 1932, 1142, 1032, 1405, 79, 40, 1744, 3372, 561,
        689, 2394, 1459, 1304, 641, 551, 579, 983, 1840, 1480, 1378, 701, 105,
        3758, 7067, 10318, 920, 902, 503, 16, 476, 10143, 16120, 11139, 2058,
        1918, 3779, 789, 2643, 1582, 548, 1417, 226, 612, 9181, 2851, 1212,
        1355, 3989, 251, 183, 477, 497, 1192, 1261, 70, 325, 1191, 2425, 674,
        457, 4465, 7583, 173, 66, 12383, 10411, 16935, 7898, 1060, 3024, 1342,
        800, 1948, 1037, 552, 2062, 1686, 1947, 128, 425, 3956, 3964, 1535, 182,
        796, 57, 1143, 267, 15, 11636, 5529, 1184, 331, 118, 484, 1404, 1945,
        2151, 1865, 1581, 2655, 3724, 8918, 728, 717, 1055, 1264, 2258, 645, 83,
        2086, 1903, 2141, 8253, 8180, 2446, 1472, 2059, 325, 113, 5676, 7828,
        4617, 1241, 2525, 1829, 384, 589, 1692, 4254, 1303, 2678, 1082, 2, 1286,
        1922, 1209, 1258, 7151, 6122, 2019, 574, 713, 972, 3550, 6885, 1898,
        2129, 1102, 125, 4272, 932, 17547, 16351, 4826, 1370, 468, 162, 26,
        4715, 1463, 1813, 3651, 1065, 787, 2047, 629, 685, 1246, 903, 629, 9887,
        12396, 1233, 2389, 599, 45, 1748, 1927, 4727, 4917, 524, 530, 4513,
        1488, 1275, 15, 386, 5717, 152, 3949, 4081, 518, 4815, 6936, 3022, 3317,
        913, 2082, 1176, 1667, 1072, 220, 3186, 2594, 3405, 1291, 429, 2155,
        9450, 519, 268, 222, 181, 504, 1931, 10785, 2753, 849, 530, 606, 903,
        212, 943, 154, 661, 84, 158, 105, 294, 17290, 356, 783, 1034, 1365,
        2297, 2053, 2082, 384, 6077, 6224, 5450, 6354, 1898, 3213, 2811, 520,
        74, 2671, 11234, 10599, 1055, 1320, 589, 614, 186, 3009, 3504, 987,
        2907, 3292, 358, 422, 6696, 6602, 4900, 4857, 977, 834, 359, 1389, 976,
        7287, 8839, 2187, 1516, 113, 2558, 1124, 1993, 1971, 1161, 7402, 2167,
        4178, 7023, 146, 308, 641, 1074, 1212, 515, 572, 3299, 1175, 1292, 1573,
        3695, 551, 685, 7936, 1656, 3139, 298, 4350, 2909, 351, 979, 8674, 1696,
        1027, 164, 2485, 862, 1016, 1112, 613, 2309, 4963, 411, 1239, 11925,
        523, 2281, 3940, 308, 1543, 1422, 1288, 569, 546, 5436, 8892, 726, 2337,
        2169, 1576, 1625, 2717, 750, 23, 24, 890, 9511, 1930, 549, 1548, 7531,
        7126, 12354, 7606, 5727, 2209, 56, 108, 122, 78, 476, 15957, 995, 675,
        832, 1807, 1892, 1820, 3807, 24, 5220, 8989, 3599, 32, 161, 151, 607,
        18, 1594, 2848, 2966, 4934, 1157, 480, 1531, 188, 5863, 1021, 1070, 779,
        1965, 4481, 15, 414, 1569, 2653, 220, 169, 352, 354, 13447, 915, 154,
        7725, 3152, 669, 547, 5, 3113, 294, 4210, 9687, 6011, 2852, 3185, 3787,
        7004, 192, 2, 0, 125, 240, 823, 3935, 5553, 2285, 685, 1153, 3905, 1368,
        523, 3797, 2232, 3623, 6082, 2831, 755, 49, 337, 7179, 8468, 2178, 479,
        2431, 6118, 5110, 2666, 2621, 6780, 5731, 2144, 11518, 9027, 236, 585,
        2868, 869, 1598, 2337, 1349, 1229, 1959, 9033, 16318, 815, 600, 2542,
        1371, 641, 521, 1937, 1339, 884, 2342, 6427, 620, 714, 654, 1540, 10762,
        6244, 5547, 2340, 6644, 9851, 11253, 9031, 6971, 3747, 14180, 1855,
        1137, 1245, 1747, 1334, 1331, 873, 1196, 4446, 4, 3909, 6, 321, 279, 44,
        29, 69, 2260, 3733, 683, 1076, 1911, 1319, 44, 3127, 1743, 560, 476,
        1461, 3334, 824, 1855, 47, 72, 332, 733, 875, 284, 48, 715, 210, 5659,
        2990, 952, 16, 54, 385, 5726, 6644, 943, 1828, 670, 1590, 3902, 13639,
        7050, 3, 23, 1153, 1471, 4704, 6416, 7344, 4051, 1248, 98, 1047, 5163,
        308, 3928, 2924, 6783, 2502, 1420, 185, 183, 573, 4025, 5885, 2770, 182,
        4411, 9448, 9089, 15062, 5225, 18837, 4672, 466, 178, 960, 134, 487,
        1590, 1749, 2026, 6797, 2594, 1478, 3042, 540, 2912, 2656, 4415, 11795,
        3692, 4168, 6646, 12099, 707, 1880, 1505, 875, 308, 365, 920, 1964,
        2966, 1618, 4800, 5068, 2322, 544, 3650, 4776, 4960, 4382, 4900, 2138,
        1657, 2104, 3592, 3717, 694, 1719, 622, 152, 42, 85, 15, 148, 18, 1198,
        1549, 1870, 760, 5865, 2643, 368, 296, 1136, 1437, 1713, 2912, 1529,
        1511, 2189, 1523, 2228, 199, 2287, 326, 564, 75, 943, 425, 267, 166,
        176, 7666, 407, 1198, 214, 1099, 1060, 1856, 5213, 960, 5826, 488, 372,
        1017, 8663, 11859, 4017, 85, 47, 7313, 6047, 1892, 380, 5282, 4294,
        1047, 98, 6291, 2995, 1794, 4736, 4190, 9589, 503, 1920, 243, 1511,
        2192, 697, 4764, 5227, 13693, 12530, 3442, 8627, 3411, 7887, 994, 2653,
        1302, 652, 67, 205, 2216, 3723, 4767, 8613, 700, 208, 1194, 257, 729,
        259, 18781, 5827, 1440, 525, 405, 372, 1267, 1715, 823, 3464, 2276, 570,
        678, 3296, 10051, 1002, 3275, 845, 388, 1733, 1377, 749, 684, 3261,
        1699, 63, 1270, 3836, 1587, 841, 1243, 902, 370, 1697, 6446, 4896, 1952,
        2391, 3157, 1345, 1755, 2041, 1391, 7212, 9584, 470, 153, 3990, 3553,
        8059, 9395, 8763, 5408, 1906, 106, 2, 553, 3172, 2312, 295, 41, 199,
        1453, 922, 388, 408, 9066, 14441, 1422, 12785, 4092, 282, 272, 1531,
        1005, 5304, 355, 1378, 1712, 2081, 3216, 2660, 655, 8, 8, 2013, 8324,
        1982, 1491, 6733, 7324, 6436, 463, 6741, 3591, 3166, 4772, 9273, 7318,
        2325, 172, 603, 3053, 7712, 3150, 1247, 7272, 5104, 3062, 9469, 2375,
        372, 1852, 1979, 3925, 2727, 317, 460, 289, 4925, 9572, 7801, 584, 28,
        1410, 1191, 164, 6163, 59, 1963, 1945, 617, 44, 3335, 2302, 1021, 3420,
        1850, 960, 438, 588, 5164, 11047, 2765, 485, 1422, 4300, 1326, 553, 14,
        8, 2600, 1965, 60, 56, 63, 5171, 9290, 1318, 560, 103, 1559, 9372, 8226,
        10532, 8106, 6733, 2182, 593, 410, 1145, 8165, 9952, 2071, 1201, 1116,
        3188, 1804, 2063, 2966, 755, 317, 14, 315, 288, 3462, 2042, 1604, 1062,
        2533, 7649, 2892, 1490, 1397, 171, 3364, 16752, 2682, 4369, 621, 330,
        618, 935, 1793, 295, 1153, 2698, 542, 279, 2854, 1815, 1443, 26, 16,
        3011, 8048, 434, 4994, 4577, 2839, 9, 1329, 659, 74, 983, 3339, 2926,
        6059, 7173, 6170, 9954, 6081, 5144, 3370, 2678, 1465, 2412, 8078, 1667,
        1444, 587, 1644, 5727, 10942, 1389, 372, 2278, 1391, 7219, 3028, 709,
        41, 692, 48, 397, 118, 1047, 100, 152, 135, 2026, 1382, 1047, 1060, 390,
        4379, 7366, 3164, 1142, 1222, 986, 915, 249, 2653, 5727, 224, 105, 370,
        434, 5452, 4426, 2573, 1821, 506, 317, 1522, 944, 157, 198, 5658, 3399,
        740, 158, 2768, 1860, 2282, 1429, 3024, 2257, 430, 326, 5620, 2198,
        1796, 758, 795, 642, 1419, 1413, 277, 515, 44, 142, 2208, 2583, 3522,
        6463, 17560, 15341, 6387, 2243, 3106, 1018, 1830, 4327, 5587, 506, 1836,
        2071, 1040, 1028, 1137, 424, 2329, 3971, 448, 508, 1164, 4725, 2833,
        2128, 105, 26, 5498, 1, 34, 118, 61, 1671, 1019, 10240, 3240, 6485,
        8359, 3755, 5670, 9165, 4340, 3578, 2561, 3609, 2645, 2357, 3313, 972,
        3912, 1435, 1940, 1254, 685, 2082, 14010, 8530, 1151, 985, 580, 3249,
        877, 1331, 577, 109, 311, 760, 232, 210, 245, 511, 2159, 2451, 4822,
        2915, 577, 544, 3406, 2783, 1855, 4583, 6947, 3546, 368, 79, 894, 3709,
        1893, 790, 2193, 2746, 10485, 2001, 99, 74, 444, 3372, 879, 2467, 1351,
        39, 2578, 2138, 193, 1096, 130, 1096, 1375, 3036, 2086, 1230, 369, 882,
        1117, 1206, 1236, 1449, 747, 507, 1634, 1570, 1180, 2268, 26, 4256,
        1754, 2350, 4720, 6208, 261, 5158, 72, 2286, 29, 788, 181, 3785, 91,
        328, 1713, 5274, 3981, 5487, 5799, 1061, 1110, 2425, 2399, 870, 1748,
        8195, 9990, 10432, 8029, 2208, 779, 195, 281, 4738, 3488, 1703, 2223,
        6215, 1791, 2376, 4700, 5046, 4794, 4965, 6472, 10381, 6488, 3029, 1525,
        4500, 1168, 2302, 1158, 4293, 4850, 2106, 640, 676, 2173, 7866, 1469,
        165, 176, 1191, 132, 2464, 329, 1616, 653, 228, 2151, 5234, 4279, 2524,
        2358, 1151, 1829, 48, 674, 1301, 559, 256, 899, 6186, 5471, 1491, 2273,
        242, 398, 4701, 6524, 8173, 9928, 6931, 13996, 1093, 195, 908, 1085,
        1136, 797, 1333, 933, 451, 660, 1748, 100, 60, 2587, 546, 467, 2234,
        2830, 5314, 2244, 1690, 122, 456, 3317, 10245, 2095, 516, 1668, 1643,
        2379, 390, 4754, 5285, 5118, 4204, 1713, 9, 1081, 58, 222, 57, 160, 475,
        160, 590, 937, 661, 3574, 2549, 417, 1922, 7933, 8344, 1683, 594, 774,
        1442, 1117, 1347, 4136, 1419, 988, 1623, 1243, 1881, 3907, 4739, 1786,
        840, 1807, 6140, 3139, 3849, 2757, 4489, 4848, 4728, 5677, 3110, 2491,
        2962, 1328, 7338, 2491, 2481, 3508, 11673, 9508, 5272, 1235, 456, 1369,
        1151, 745, 424, 189, 1275, 200, 1251, 1995, 2497, 166, 705, 1138, 1783,
        1974, 2444, 5164, 4364, 26, 151, 874, 1650, 461, 572, 716, 4076, 1207,
        1218, 2842, 2428, 623, 1067, 4236, 617, 442, 219, 6896, 1109, 772, 1054,
        2248, 2273, 1906, 1631, 1015, 364, 264, 57, 41, 5026, 1578, 330, 1780,
        1542, 4126, 7548, 12607, 1651, 56, 762, 3958, 9701, 2944, 4912, 446,
        3517, 5694, 3, 951, 3627, 8130, 3545, 1, 2451, 2, 53, 236, 29, 955, 809,
        2970, 320, 901, 1270, 262, 1173, 255, 222, 5807, 6508, 2370, 1734, 1833,
        725, 352, 555, 756, 525, 4267, 342, 1274, 1187, 4571, 3582, 966, 600,
        2939, 3925, 5844, 9882, 4069, 4789, 3864, 2605, 3625, 3446, 1874, 1970,
        1849, 4932, 7953, 2075, 2156, 4846, 2200, 3765, 1343, 508, 534, 479,
        237, 863, 29, 54, 66, 1788, 8189, 6038, 91, 312, 46, 2208, 2080, 1988,
        8025, 5874, 521, 1235, 1305, 892, 1111, 683, 677, 3833, 1283, 564, 2697,
        3640, 409, 1063, 1677, 942, 978, 562, 2786, 4801, 2823, 2306, 2248,
        1957, 1554, 1339, 1360, 568, 64, 137, 2881, 4318, 2040, 1103, 1576,
        4970, 3846, 4609, 4078, 489, 3536, 323, 3798, 114, 1140, 3882, 1085,
        5799, 4850, 100, 221, 106, 117, 105, 88, 3617, 31, 44, 77, 26, 563,
        1214, 80, 149, 1235, 2378, 391, 1054, 593, 1524, 2795, 3008, 1184, 2115,
        1715, 3086, 475, 559, 431, 231, 6647, 2366, 2600, 1086, 8046, 8853,
        1053, 1246, 1554, 1072, 1697, 2870, 3330, 1311, 2176, 4034, 5823, 1738,
        1499, 3383, 7014, 12118, 16871, 12178, 1886, 10959, 15102, 5570, 11960,
        1999, 62, 248, 1722, 2841, 318, 874, 31, 121, 4945, 16675, 215, 905, 12,
        536, 2704, 3933, 5880, 3084, 1965, 1839, 3627, 1399, 1807, 896, 216,
        2974, 1708, 1577, 1288, 1341, 69, 90, 680, 1329, 458, 485, 3452, 13447,
        1553, 1387, 1784, 1768, 1322, 1292, 1142, 723, 628, 563, 77, 366, 4142,
        4451, 4602, 2347, 1241, 2877, 2504, 764, 1251, 2774, 7609, 8763, 1712,
        3075, 4390, 3581, 407, 163, 1276, 2225, 51, 66, 3, 4, 7, 80, 4411, 1166,
        942, 93, 15, 266, 877, 1558, 4725, 517, 1996, 102, 2459, 2312, 1147,
        1120, 1624, 724, 3250, 927, 178, 1736, 1768, 2307, 241, 664, 994, 5914,
        955, 1443, 1085, 7166, 7445, 5838, 11796, 3713, 56, 8252, 3082, 2179,
        746, 1123, 5885, 18733, 3811, 8999, 9283, 799, 10357, 6358, 13128, 6873,
        137, 4221, 1486, 480, 1168, 1499, 77, 99, 352, 6742, 8039, 241, 11, 329,
        3413, 6894, 2251, 1963, 198, 166, 3439, 2911, 3071, 2617, 2488, 2912,
        2161, 1498, 982, 206, 43, 168, 3714, 806, 1148, 1042, 2169, 11276, 776,
        1731, 1381, 2193, 1461, 1361, 681, 495, 820, 2023, 563, 10056, 16001,
        8661, 3013, 1699, 921, 2356, 1374, 860, 1346, 14789, 16267, 1275, 651,
        2406, 5403, 7050, 4426, 3476, 2257, 1796, 1984, 288, 182, 3, 42, 5306,
        10837, 5786, 266, 372, 158, 338, 940, 760, 1787, 357, 357, 1376, 2400,
        2161, 1132, 1253, 804, 646, 1219, 1571, 1152, 1387, 3063, 1662, 558,
        233, 359, 2181, 234, 512, 441, 563, 6253, 6680, 184, 6, 7546, 7190,
        4193, 6124, 3991, 16157, 12197, 12295, 916, 11774, 399, 842, 807, 434,
        574, 3528, 720, 1085, 1187, 1007, 1447, 2143, 1093, 29, 633, 154, 6349,
        1230, 1144, 158, 1418, 3839, 4037, 256, 208, 901, 878, 2196, 1322, 2602,
        3768, 5880, 2327, 856, 489, 272, 550, 337, 333, 425, 1348, 1396, 2551,
        9267, 667, 705, 1211, 2167, 745, 58, 1367, 259, 9748, 1868, 23, 204,
        3214, 7991, 4512, 3118, 3992, 5666, 5977, 766, 1083, 4490, 10935, 10920,
        1051, 2446, 4775, 9975, 7785, 2226, 1453, 1576, 2421, 2255, 16, 47, 39,
        6041, 10053, 75, 207, 314, 12, 718, 1015, 657, 574, 1266, 152, 1099,
        2817, 1984, 1020, 946, 1032, 670, 1084, 2341, 3339, 6287, 8591, 5145,
        506, 118, 335, 2536, 394, 436, 686, 1969, 2375, 2803, 761, 193, 16,
        9005, 9439, 6273, 9296, 8439, 2131, 19734, 13501, 245, 7561, 17646,
        2034, 45, 490, 187, 298, 1532, 1393, 964, 1128, 1164, 2024, 72, 38,
        1241, 883, 552, 199, 208, 2254, 2505, 1964, 375, 119, 4900, 1143, 184,
        888, 104, 4394, 5934, 1580, 1302, 1406, 742, 1169, 1691, 3149, 4415,
        3397, 848, 5977, 2326, 4495, 10045, 7867, 1011, 2594, 4719, 309, 16027,
        527, 2358, 16098, 4275, 100, 598, 1250, 3600, 13057, 13010, 11708, 879,
        381, 11312, 11778, 3029, 499, 1853, 7560, 10747, 2854, 2101, 1006, 4825,
        9471, 9089, 17, 42, 45, 2877, 6965, 241, 1330, 36, 35, 1717, 1542, 867,
        857, 952, 1266, 274, 2035, 1232, 928, 1489, 544, 1035, 1233, 3901, 5668,
        7878, 1160, 340, 30, 127, 366, 3215, 2401, 744, 784, 1838, 1363, 1239,
        887, 218, 2756, 175, 5007, 7222, 11266, 9307, 320, 4485, 1574, 3556,
        18449, 12998, 168, 220, 497, 92, 2266, 801, 1187, 800, 549, 1049, 1788,
        540, 9, 173, 1101, 54, 124, 290, 730, 2222, 1533, 98, 2727, 147, 196,
        82, 777, 2155, 3540, 2308, 1055, 739, 1118, 254, 957, 2024, 2911, 3893,
        7701, 7799, 9307, 2384, 778, 1723, 1520, 1258, 4876, 2505, 20881, 16691,
        1889, 12176, 15961, 2953, 5398, 567, 1673, 14118, 8328, 5735, 16716,
        637, 393, 3539, 15736, 8464, 1480, 18857, 12279, 11851, 2029, 1540,
        5154, 2839, 11416, 9418, 7500, 9, 28, 2588, 9893, 1785, 10, 355, 382,
        813, 695, 1062, 2179, 1976, 1836, 1932, 1373, 1957, 896, 1590, 858,
        1264, 1953, 1866, 2530, 2159, 1644, 577, 274, 1000, 342, 2039, 928, 165,
        3795, 2634, 2246, 1445, 1380, 117, 423, 2165, 1082, 4796, 5124, 809,
        588, 27507, 8074, 5126, 4245, 5317, 1164, 441, 1420, 128, 23, 2874, 818,
        2269, 866, 599, 1715, 1066, 144, 919, 8, 69, 3, 1025, 32, 12, 297, 393,
        1142, 19, 180, 971, 940, 1141, 1221, 1032, 712, 477, 509, 131, 490,
        2310, 3288, 3886, 1201, 384, 5106, 3004, 1832, 6056, 4505, 3106, 2806,
        5183, 16245, 1309, 1017, 2713, 4803, 1409, 3321, 17576, 833, 563, 11884,
        11610, 5569, 6988, 600, 2168, 15717, 2562, 27951, 29693, 4977, 2643,
        1379, 669, 6644, 2855, 5933, 5359, 11473, 1274, 13, 6049, 7058, 32, 33,
        344, 834, 695, 493, 347, 1497, 4049, 3350, 2261, 1302, 1576, 577, 1059,
        2160, 1905, 1019, 527, 320, 102, 1537, 797, 656, 1491, 335, 2390, 333,
        3864, 1358, 2811, 1352, 1340, 274, 460, 923, 1112, 838, 15, 9240, 205,
        287, 14592, 8755, 2204, 253, 27, 1205, 63, 3715, 192, 297, 1412, 726,
        968, 1321, 1093, 8072, 7640, 380, 1590, 1328, 238, 160, 4897, 3883, 301,
        1274, 474, 4467, 819, 1794, 1004, 645, 642, 664, 597, 619, 946, 622, 76,
        363, 2522, 5471, 1310, 165, 86, 5085, 2432, 473, 1472, 8415, 1325,
        13931, 9262, 3042, 1769, 1203, 1801, 1989, 999, 3154, 13032, 2031, 1581,
        18437, 6981, 17382, 4844, 643, 569, 5848, 10460, 4051, 3328, 19816,
        10758, 5749, 1690, 6247, 7418, 2365, 3396, 8970, 10596, 943, 350, 1322,
        2953, 1009, 1073, 1598, 1361, 1254, 1082, 1676, 2024, 3445, 1903, 1947,
        2448, 1257, 909, 1491, 566, 769, 1439, 350, 108, 170, 1360, 490, 950,
        912, 1874, 2868, 1514, 1887, 1329, 358, 195, 246, 645, 1095, 640, 30,
        44, 14458, 9994, 694, 657, 28, 84, 986, 120, 5, 2602, 3447, 55, 2043,
        665, 1822, 1771, 1305, 1329, 2832, 7388, 461, 111, 292, 1715, 23, 964,
        656, 212, 123, 60, 2217, 2579, 1669, 760, 565, 730, 1028, 970, 926,
        1140, 431, 75, 1210, 3160, 790, 518, 149, 434, 4777, 10764, 3646, 3875,
        989, 14967, 2532, 1391, 2261, 1251, 4235, 8919, 2173, 3836, 2631, 9138,
        5159, 3692, 4630, 19864, 20549, 2525, 6657, 9576, 36873, 4130, 820, 475,
        240, 6454, 14261, 15639, 4692, 7941, 1325, 2073, 701, 647, 2782, 658,
        5271, 4135, 1222, 951, 1175, 1819, 673, 908, 1468, 1580, 1391, 882,
        1341, 3269, 2047, 1212, 1355, 1131, 2164, 602, 1360, 205, 176, 531,
        1073, 966, 664, 1237, 1408, 1820, 2182, 1200, 460, 127, 490, 1211, 1169,
        200, 484, 4575, 4269, 2448, 801, 2267, 393, 353, 934, 78, 12, 10878,
        6829, 4253, 2474, 259, 2821, 3082, 2563, 1773, 1400, 1879, 1944, 1071,
        2041, 41, 2, 722, 686, 2, 274, 598, 559, 1642, 1641, 677, 563, 762,
        1113, 1144, 996, 1481, 391, 447, 467, 341, 744, 1398, 2238, 4465, 4444,
        8111, 761, 3591, 1492, 3865, 10083, 14377, 1107, 752, 12343, 9393, 2291,
        8597, 13991, 10886, 21739, 17728, 2513, 757, 739, 8734, 13409, 39623,
        12023, 13821, 3734, 6164, 425, 201, 187, 260, 4850, 11543, 1312, 560,
        323, 392, 976, 1035, 9349, 49, 1714, 1042, 1078, 1476, 1334, 1692, 1299,
        1214, 927, 504, 340, 4969, 3358, 1292, 1411, 1519, 2472, 5300, 786, 85,
        327, 433, 868, 1327, 940, 1406, 1497, 1521, 1551, 1626, 596, 423, 936,
        1560, 977, 406, 145, 1244, 7812, 2087, 2329, 15335, 11483, 864, 1262,
        987, 261, 13791, 10934, 156, 84, 403, 108, 105, 34, 436, 182, 38, 1336,
        1528, 2181, 3770, 7927, 20465, 8419, 1676, 433, 325, 977, 1123, 555,
        418, 466, 672, 486, 976, 1103, 512, 398, 405, 969, 4102, 11143, 3146,
        3399, 4243, 3440, 2738, 982, 211, 118, 3716, 3238, 2831, 511, 1546,
        2725, 3343, 1567, 3124, 16975, 18243, 24041, 11291, 7607, 11001, 17310,
        13684, 14568, 13888, 5129, 10221, 1990, 2810, 4622, 8408, 378, 273, 408,
        3827, 213, 1095, 1438, 1389, 1608, 1576, 4136, 313, 21, 3877, 5311,
        1934, 1389, 1898, 1460, 1076, 1013, 989, 1008, 4339, 3057, 494, 424,
        877, 996, 6544, 4461, 91, 546, 341, 627, 2661, 1454, 5063, 3686, 2847,
        2498, 2122, 1834, 1215, 1162, 1325, 602, 687, 1568, 4261, 8364, 3561,
        3800, 12317, 47, 23, 9, 19, 54, 579, 4510, 547, 238, 100, 48, 118, 1490,
        1243, 61, 20, 3, 507, 51, 884, 5358, 5258, 22, 2, 9, 5, 171, 503, 713,
        53, 19, 1220, 118, 160, 416, 251, 571, 1375, 9812, 11618, 9584, 2870,
        2151, 1936, 3217, 8689, 249, 1278, 228, 1768, 1141, 7490, 453, 1653,
        3206, 12723, 6590, 18278, 2371, 7053, 2664, 1245, 7809, 1757, 10449,
        26205, 32486, 2130, 2666, 10830, 1461, 1516, 2224, 11622, 11154, 1397,
        157, 62, 28, 978, 1863, 1552, 759, 1553, 341, 39, 36, 6795, 1407, 1641,
        1737, 1130, 964, 891, 719, 1484, 1499, 1863, 2678, 2301, 1118, 495, 0,
        44, 22, 16, 117, 83, 26, 2575, 3342, 3727, 4958, 1472, 1476, 1394, 1061,
        929, 628, 527, 358, 241, 940, 2340, 4509, 236, 490, 2405, 1352, 66, 252,
        15, 419, 747, 12, 94, 1202, 2492, 14, 0, 238, 115, 11, 95, 92, 70, 80,
        2128, 14, 2194, 3043, 121, 268, 2859, 19, 2129, 609, 54, 285, 301, 51,
        4, 2, 934, 1459, 5885, 6835, 5896, 6253, 303, 565, 94, 673, 10312, 1631,
        4243, 1298, 1521, 3407, 1093, 904, 1070, 4206, 13268, 19937, 19862,
        19184, 2169, 52, 1361, 528, 9217, 353, 20552, 563, 175, 2221, 5072,
        1607, 1206, 2093, 2467, 5685, 4397, 3222, 1995, 778, 274, 127, 439, 246,
        1088, 1406, 573, 728, 16, 4654, 4712, 3420, 2387, 1658, 1892, 1804,
        1780, 1577, 1425, 722, 2818, 1139, 14, 17, 17, 1551, 2035, 317, 815,
        217, 104, 181, 1286, 1530, 900, 633, 188, 309, 493, 171, 1164, 1033,
        700, 47, 662, 2249, 1578, 805, 1056, 987, 695, 532, 409, 520, 641, 399,
        28, 5484, 8736, 3638, 650, 1092, 2697, 1306, 1857, 1476, 44, 12777, 360,
        639, 855, 561, 8, 4850, 1418, 12847, 6428, 236, 258, 97, 2, 32, 119,
        521, 202, 17013, 13398, 1717, 118, 1, 1045, 145, 535, 478, 17825, 9772,
        7418, 4347, 5052, 10592, 5595, 2311, 1579, 2476, 21765, 20638, 43, 317,
        951, 1999, 1463, 1044, 496, 1758, 8542, 262, 6172, 4266, 4398, 5131,
        332, 980, 1361, 1698, 3651, 1286, 6004, 1455, 2109, 820, 344, 262, 623,
        2059, 706, 45, 722, 366, 5565, 5824, 5249, 3680, 2634, 962, 1495, 1779,
        1954, 1384, 1095, 74, 194, 624, 2096, 2651, 2258, 819, 122, 1698, 972,
        1054, 1602, 1264, 757, 769, 1268, 1153, 359, 170, 186, 1171, 321, 1093,
        1426, 868, 1135, 1256, 575, 846, 893, 601, 728, 638, 1232, 2206, 18,
        2885, 3430, 5311, 5845, 10763, 1902, 1760, 2100, 1140, 893, 24469, 1999,
        65, 438, 14, 633, 2495, 814, 7686, 5478, 2690, 877, 570, 241, 650, 928,
        411, 163, 3655, 13020, 1035, 184, 6, 320, 1530, 2727, 2540, 14522, 1863,
        1516, 3258, 1994, 6445, 9980, 7387, 2227, 1053, 363, 5731, 293, 563,
        1121, 1874, 1369, 898, 2231, 1306, 7094, 7, 3468, 4759, 3163, 685, 430,
        1000, 504, 864, 9344, 2217, 5072, 3652, 1123, 454, 647, 416, 349, 901,
        2245, 547, 403, 94, 143, 65, 1768, 2329, 709, 931, 1882, 2968, 2325,
        1872, 373, 114, 24, 964, 2864, 1260, 3654, 1835, 275, 228, 1849, 1231,
        1922, 1231, 1300, 778, 1037, 1150, 3000, 66, 437, 982, 305, 1342, 2279,
        7650, 4847, 885, 814, 1009, 504, 1128, 484, 3052, 1011, 350, 784, 437,
        661, 199, 537, 8835, 9785, 1542, 1690, 2893, 7066, 9241, 1438, 589, 309,
        348, 3219, 3310, 1736, 52, 2763, 1837, 1990, 1126, 674, 1188, 1597, 255,
        1737, 167, 8710, 3572, 2921, 67, 314, 1922, 5954, 6741, 11314, 1794,
        1968, 3285, 1843, 2913, 3430, 3282, 4117, 500, 155, 1601, 3667, 2953,
        6791, 1247, 393, 1875, 5288, 6758, 9930, 34, 513, 15441, 10985, 1203,
        1324, 618, 998, 1803, 9226, 7077, 2555, 633, 523, 1077, 2399, 1510, 650,
        236, 1693, 2956, 1833, 304, 79, 97, 36, 115, 500, 1795, 3646, 3307, 203,
        28, 57, 6, 919, 1147, 2425, 947, 2225, 4486, 1110, 118, 1308, 1675,
        1792, 1387, 997, 1198, 1158, 1001, 3671, 440, 171, 1101, 176, 392, 687,
        4727, 646, 754, 583, 894, 1101, 1005, 534, 1227, 9336, 1005, 610, 3786,
        1901, 18, 448, 524, 18, 480, 1373, 1850, 7651, 8646, 1222, 1142, 852,
        1258, 6644, 1379, 1327, 328, 4471, 1357, 1652, 1245, 1236, 1262, 1395,
        1161, 75, 337, 5660, 7790, 3238, 39, 256, 864, 1057, 15869, 11727, 6240,
        3713, 573, 6279, 6000, 3502, 2039, 1355, 537, 554, 3529, 3300, 2010,
        1820, 1287, 1820, 1945, 1466, 2201, 2159, 10, 2131, 3090, 10109, 3048,
        2529, 2548, 1285, 365, 3579, 2712, 459, 368, 1392, 1794, 2380, 1295,
        740, 1060, 1387, 3521, 3513, 1008, 238, 31, 128, 127, 96, 3101, 732, 13,
        542, 4, 243, 2161, 1600, 995, 910, 2191, 1594, 2671, 1178, 1256, 1529,
        2019, 1084, 1330, 1202, 1107, 838, 1232, 1679, 132, 43, 386, 552, 381,
        235, 1128, 1153, 1241, 1041, 988, 593, 555, 1128, 781, 4115, 27, 5, 23,
        424, 966, 305, 436, 41, 21, 1325, 1837, 12785, 3660, 468, 352, 518,
        1632, 6108, 1113, 251, 484, 1880, 1771, 2031, 1583, 1159, 1835, 1411,
        1852, 50, 145, 4921, 10740, 577, 568, 98, 2815, 6514, 22442, 21395,
        11551, 3845, 551, 3330, 2858, 3479, 1136, 55, 111, 2367, 2325, 3227,
        1500, 1519, 1784, 702, 3679, 8045, 2557, 2322, 522, 5687, 7543, 10186,
        7672, 6960, 1027, 498, 1079, 2493, 760, 2639, 1684, 3582, 2131, 633,
        1123, 639, 1173, 2303, 1562, 1875, 1595, 359, 153, 178, 242, 256, 8,
        2976, 103, 26, 90, 122, 205, 1897, 629, 719, 2152, 2293, 2146, 2239,
        1438, 1505, 1450, 991, 1418, 453, 546, 1221, 4315, 4493, 189, 5, 1962,
        520, 318, 290, 1077, 1431, 1920, 1944, 1353, 774, 617, 1244, 733, 887,
        1201, 632, 1748, 736, 111, 349, 564, 548, 321, 96, 3638, 24740, 3140,
        433, 697, 2024, 2304, 5834, 1015, 144, 1536, 3314, 909, 1185, 1384,
        1277, 1826, 1018, 1623, 389, 397, 4234, 12913, 2053, 59, 415, 206, 5157,
        21690, 21360, 2908, 3512, 1891, 2792, 1100, 12733, 15966, 145, 147,
        2882, 1735, 4748, 2589, 2099, 1929, 1428, 1633, 3776, 2579, 3290, 78,
        2173, 1482, 1357, 1119, 1133, 1669, 910, 1571, 2524, 545, 2729, 3417,
        2294, 3928, 1790, 1742, 1246, 1713, 2856, 2916, 1487, 1021, 706, 420,
        2313, 1773, 145, 794, 2972, 1423, 163, 121, 137, 147, 194, 1458, 352,
        2864, 3672, 2425, 2414, 524, 922, 1118, 1345, 1274, 2332, 1674, 2945,
        1444, 2001, 21, 8, 2321, 1647, 347, 625, 670, 1631, 2051, 2018, 2110,
        1012, 1002, 1039, 1555, 32, 144, 8239, 9728, 667, 1885, 854, 596, 2229,
        2592, 1474, 15850, 20596, 4300, 629, 305, 2719, 2106, 8313, 554, 140,
        2728, 2989, 1485, 1589, 2232, 1914, 4059, 1509, 1521, 1072, 2136, 10684,
        15126, 4160, 111, 336, 112, 16, 9554, 13671, 788, 776, 1346, 1475, 9700,
        15017, 4851, 464, 249, 1567, 2027, 6607, 3543, 2262, 1003, 1541, 3953,
        5064, 3875, 3294, 79, 3105, 1462, 964, 445, 490, 507, 728, 1094, 1703,
        625, 1670, 1549, 1482, 3261, 790, 1255, 1832, 2416, 2608, 2743, 1498,
        877, 333, 1013, 3290, 3496, 672, 251, 1090, 694, 100, 90, 175, 178, 295,
        503, 487, 3267, 4322, 2369, 1765, 795, 1571, 1116, 1452, 1478, 1733,
        811, 1112, 4199, 6125, 557, 90, 1489, 1699, 717, 641, 628, 965, 1926,
        2813, 3312, 2287, 1409, 1111, 841, 13, 95, 1759, 7282, 3107, 287, 1911,
        2591, 4225, 3628, 1473, 13511, 6819, 12484, 1618, 569, 2980, 570, 12281,
        646, 63, 2216, 4139, 1485, 1518, 2446, 2634, 6062, 1693, 1194, 1615,
        837, 11172, 14077, 6202, 66, 29, 2, 648, 5676, 13366, 12342, 11609,
        7759, 8398, 5783, 16677, 15220, 213, 832, 721, 2204, 7067, 14943, 17511,
        13921, 10457, 6479, 4304, 4415, 711, 228, 5728, 6101, 4510, 4953, 7812,
        9854, 12649, 9621, 8844, 6286, 4240, 4793, 1601, 2233, 2276, 3694, 4115,
        5301, 6004, 7766, 8510, 7011, 7194, 5312, 4323, 2603, 750, 28, 678, 531,
        317, 160, 255, 280, 197, 153, 500, 3452, 6702, 6462, 5657, 3682, 4373,
        4904, 5662, 6605, 3046, 3174, 5093, 7272, 4713, 5445, 2542, 621, 926,
        1675, 8237, 10658, 15139, 10006, 7873, 5192, 4982, 4086, 2571, 373, 504,
        5355, 5983, 3763, 5191, 13420, 13245, 15595, 4938, 3717, 3990, 8587,
        13681, 5560, 3996, 3821, 1910, 9466, 12628, 4162, 2673, 4953, 2709,
        3377, 4730, 12143, 11175, 12588, 2339, 1075, 2409, 2695, 8477, 9795,
        5457, 437, 1524, 18769, 21148, 12749,
    ],
    total_freq_sum: 43866142,
    total_terms: 835634,
};
