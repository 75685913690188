import React, { useEffect, useState } from 'react';
import { StepCaseTypeSettings } from './StepCaseSettings';
import { StepCaseAccessSettings } from './StepCaseAccessSettings';
import { CaseSettings, CaseType, Step } from './CaseNew.types';
import { SiteWideAnnouncements } from '../../../components/Layout/SiteWideAnnouncements';

const essentialSettings: CaseSettings = {
    backgroundCheck: true,
    publicInfo: true,
    keywords: [],
    privateSocialMedia: false,
    customSearchTerms: false,
    creditRating: false,
    icijLeaks: false,
};

export const CaseNew = (): React.ReactElement => {
    const [step, setStep] = useState(Step.CaseSettings);
    const [caseType, setCaseType] = useState<CaseType>();

    const [caseSettings, setCaseSettings] =
        useState<CaseSettings>(essentialSettings);

    useEffect(() => {
        if (caseType === CaseType.Plus) {
            setCaseSettings((prev) => ({
                ...essentialSettings,
                keywords: prev.keywords,
                privateSocialMedia: true,
            }));
        }
        if (caseType === CaseType.Complete) {
            setCaseSettings((prev) => ({
                ...essentialSettings,
                keywords: prev.keywords,
                privateSocialMedia: true,
                customSearchTerms: true,
                icijLeaks: true,
                creditRating: true,
            }));
        }
        if (caseType === CaseType.Essential) {
            setCaseSettings((prev) => ({
                ...essentialSettings,
                keywords: prev.keywords,
            }));
        }
    }, [caseType]);

    const [userIds, setUserIds] = useState<string[]>([]);
    const [caseName, setCaseName] = useState('');

    return (
        <>
            <SiteWideAnnouncements addWrapper />
            {step === Step.CaseSettings && (
                <StepCaseTypeSettings
                    caseType={caseType}
                    settings={caseSettings}
                    setSettings={setCaseSettings}
                    setStep={setStep}
                    setCaseType={setCaseType}
                />
            )}
            {caseType && step === Step.CaseAccessSettings && (
                <StepCaseAccessSettings
                    settings={caseSettings}
                    caseType={caseType}
                    setStep={setStep}
                    caseName={caseName}
                    setCaseName={setCaseName}
                    userIds={userIds}
                    setUserIds={setUserIds}
                />
            )}
        </>
    );
};
