import { useQuery, UseQueryResult } from 'react-query';
import { SummaryReport } from '_types';
import { targets } from '../../services/nestApiService';

export const useSummaryReportsQuery = (
    targetId: string,
    shouldRefetch: boolean,
    onSuccess: () => void,
): UseQueryResult<SummaryReport[]> =>
    useQuery(
        ['target', targetId, 'summary'],
        () => targets.getSummaryReports(targetId),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            keepPreviousData: true,
            refetchInterval: shouldRefetch ? 60000 * 3 : undefined, // refetch every three minutes
            onSuccess,
        },
    );
